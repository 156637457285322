import { DRAWER_OPEN, DRAWER_CLOSE, GRID_HEATMAP_OPEN_CLOSE } from "../types";

export const openDrawer = () => {
    return {
        type: DRAWER_OPEN
    }
}
export const openCloseGridHeatmap = ({close=true}) => {
    // console.log("red",close)
    return {
        type: GRID_HEATMAP_OPEN_CLOSE,payload:close
    }
}


export const closeDrawer = () => {
    return {
        type: DRAWER_CLOSE
    }
}