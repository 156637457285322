import React, { useEffect,useState } from 'react';
import { Box } from '@mui/material';


const Integration = ({myVariable,baseURL}) => {
    const [frameHeight, setFrameHeight] = useState("80vh");
    
    useEffect(() => {
        window.addEventListener("message", receivedMessage, false);
        function receivedMessage(evt) {
            try {
                // console.log("evt receive",evt)
                if (evt.origin === baseURL && evt.data) {
                    let data;
                    if (typeof evt.data === "string") {
                        data = JSON.parse(evt.data);
                    }
                    else {
                        data = evt.data;
                    }
                    // console.log("data key",data,data?.perkey,data?.perkey === 'sendphrase')
                    if (data?.perkey == 'sendphrase') {
                        // console.log("source");
                        evt.source.postMessage({ phrase: localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : "" }, evt.origin);
                    }
                    // console.log("evt.data",evt.data);
                    // if (data.frameHeight) {
                    //     const iframe = document.getElementById('frame');
                    //     iframe.style.height = evt.data.frameHeight + 'px';
                    // }
                    if (data?.frameHeight) {
                        // Update the iframe height state based on the message from the child
                        if(data?.frameHeight < 800){
                            setFrameHeight('800px');
                        }
                        else{
                            setFrameHeight(data?.frameHeight + 'px');
                        }
                    }
                }
            } catch (e) {
                // console.log("evt parsing error", e)
                // console.log(e);
            }
        }


        return () => {
            window.removeEventListener("message", receivedMessage, false);
        };
    }, []);

    // console.log('iframe URL:', myVariable);

    return (
        <Box sx={{
            overflow: "hidden"
        }}>
            <Box sx={{
               overflow: "hidden"
            }}>
                <iframe id="frame" src={myVariable} style={{
                    border: "none",
                    // height: "800px",
                    // height: "80vh",
                    height: frameHeight, // Dynamically set the iframe height
                    width: "100%",
                }}
                ></iframe>
            </Box>
        </Box>
    );
};

export default Integration;
