import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    FormControl, Grid, Menu, InputAdornment, OutlinedInput, Button, TextField, Select, MenuItem,
    FormLabel, InputLabel, Typography, Tab, Tabs, Box, Checkbox, Autocomplete
} from '@mui/material';
import CustomModel from '../../../Components/CustomModels/CustomModel1';
import { getCompany } from '../../../../redux/actions/SuperAdmin';
import { valid_email, valid_mobile, isEmptyObject, valid_password } from '../../../../services/validation';
import { showToast } from '../../../../services/functions';
import { ToastContainer, toast } from 'react-toastify';
import { addUsers, editUsers } from '../../../../redux/actions/ManageTeam';
import { buttonClasses, textClasses } from '../../../../static/styles/theme';
import MultiSelectAll from '../../MainDashboard/MultiSelect';
import { useHistory } from 'react-router-dom';
import { selectStylesOverride } from "../../../../utils/util";
import { Search } from '@mui/icons-material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

const errorobj = {
    "name": "",
    "email": "",
    "password": "",
    "mobile": "",
    "warehouseid": "",
    "floorid": "",
    "userRole": "",
    "designation": "",
    "permissions": ""
}
function AddUsersModel(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const { open, hide, selectedValue={}, warehouseData, action, AllUserRoleData } = props
    const { floorDetail } = useSelector(state => state.superadmin);
    const warehouseDetail = useSelector(
        (state) => state.superadmin.warehouseDetail
      );

    const [designationValue, setDesignationValue] = useState("");
    const [userRole, setUserRole] = useState("");
    const [userId, setUserId] = useState('')
    const [fullName, setFullName] = useState("");
    const [warehouseId, setWarehouseId] = useState([]);
    const [floor, setFloor] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    // const [confirmPassword, setConfirmPassword] = useState("");
    // ////('warehouseData',warehouseData);
    const [permissions, setPermissions] = useState({});
    const [selectPermissions, setSelectPermissions] = useState({});
    const [loader, setLoader] = useState(false);
    const [showPassword, setShowPassword] = useState(true);


    const handleClickShowPassword = () => {
        setShowPassword((show) => !show)
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const [errorMessage, setErrorMessage] = useState({
        "name": "",
        "email": "",
        "password": "",
        "mobile": "",
        "warehouseid": "",
        "floorid": "",
        "userRole": "",
        "designation": "",
        "permissions": ""
    });


    const handleFloorMultiSelect = (result) => {
        const valueToSave = result.map((itm) => itm._id);
        setFloor(valueToSave);
        // fetch_data(valueToSave, dateRange);
      };

    const handlePermission = (permissiondata) => {
        let obj = { ...permissiondata }
        for (let key in obj) {
            obj[key] = false;
        }
        setPermissions(obj);
        return obj;
    }
    const handleWarehouseSelect = (warehouse_Id) => {
        if (warehouse_Id) {
            dispatch(getCompany({
                "warehouseId": warehouse_Id?.map(dl=>dl?._id),
                "type": "floor",
                "status": "active",
                "projection":{_id:1,name:1,warehouseId:1,type:1}
            }));
        }
    }


    const handleValidationSubmit = () => {
        if (!userRole || userRole === "" || userRole === "all") {
            setErrorMessage({ ...errorobj, userRole: "Please select the user role" })
            showToast("Please select the user role", true);
            return;
        }
        if (warehouseId?.length ===0) {
            setErrorMessage({ ...errorobj, warehouseid: "Please select the warehouse" })
            showToast("Please select the warehouse", true);
            return;
        }
        if (floor?.length === 0) {
            setErrorMessage({ ...errorobj, floorid: "Please select the floor" })
            showToast("Please select the floor", true);
            return;
        }
        if (fullName.trim() === "") {
            setErrorMessage({ ...errorobj, name: "Please fill the name" })
            showToast("Please fill the name", true);
            return;
        }
        if (email.trim() === "") {
            setErrorMessage({ ...errorobj, email: "Please fill the email" })
            showToast("Please fill the email", true);
            return;
        }
        if (valid_email(email) === false) {
            setErrorMessage({ ...errorobj, email: "Please fill the correct email address" })
            showToast("Please fill the correct email address", true);
            return;
        }
        if (phoneNumber.trim() === "") {
            setErrorMessage({ ...errorobj, mobile: "Please fill the phone number" })
            showToast("Please fill the phone number", true);
            return;
        }
        if (valid_mobile(phoneNumber) === false) {
            setErrorMessage({ ...errorobj, mobile: "Please fill the correct Phone number" })
            showToast("Please fill the correct Phone number", true);
            return;
        }
        if (action === "addnew") {
            if (password.trim() === "") {
                setErrorMessage({ ...errorobj, password: "Please fill the password" })
                showToast("Please fill the password", true);
                return;
            }
            if (valid_password(password) === false) {
                setErrorMessage({ ...errorobj, password: "Please fill the password" })
                showToast("Password is not a valid. It should have at least 8 characters, contain at least one capital letter one small letter, one number and one special character (@#$%)", true);
                return;
            }
        }
        if (designationValue.trim() === "") {
            setErrorMessage({ ...errorobj, designation: "Please fill the designation" })
            showToast("Please fill the designation", true);
            return;
        }
        if (action === "addnew") {
            handleCreateUser()
        } else {
            handleEditUser()
        }

    }

    const handleCreateUser = () => {
        let bodydata = {
            "name": fullName,
            "email": email,
            "password": password,
            "warehouseId": warehouseId?.map(dl=>dl?._id),
            "floorId": floor?.map(dl=>dl?._id),
            "mobile": phoneNumber,
            "userRole": userId,
            "designation": designationValue,
            "permissions": selectPermissions
        }
        setLoader(true)
        dispatch(addUsers(bodydata, ({success,message}) => {
            showToast(message,!success)
            setLoader(false)
            if(success){
                props.callFetchUsers(true)
                hide("success")
            }
        }))
    }

    const handleEditUser = () => {
        let bodydata = {
            "_id": selectedValue._id,
            "name": fullName,
            "email": email,
            "mobile": phoneNumber,
            "warehouseId": warehouseId?.map(dl=>dl?._id),
            "floorId": floor?.map(dl=>dl?._id),
            "userRole": userId,
            "designation": designationValue,
            "permissions": selectPermissions
        }
        setLoader(true)
        dispatch(editUsers(bodydata,  ({success,message}) => {
            showToast(message,!success)
            setLoader(false)
            if(success){
                props.callFetchUsers(true)
                hide("success")
            }
        }))
    }




    useEffect(() => {
        let userData = localStorage.getItem('user');
        handlePermission(userData.permissions)
        if (action === "edit") {
            if (selectedValue._id) {
                const warehouseId = selectedValue?.warehouseDetails ||  []
                handleWarehouseSelect(warehouseId)
                const selectedID = selectedValue && selectedValue.roleId ? selectedValue.roleId : '-'
                const floorIds = selectedValue?.floorDetails || []
                const roleName = AllUserRoleData.find(dl => dl._id == selectedID)?.roleName || ""
                setWarehouseId(warehouseId)
                setFloor(floorIds)
                setUserRole(roleName)
                setUserId(selectedID)
                setFullName(selectedValue.name)
                setPhoneNumber(selectedValue.mobile)
                setEmail(selectedValue.email)
                setDesignationValue(selectedValue.designation)
                const newPermission = Object.assign(handlePermission(userData.permissions), selectedValue.permissions)
                setPermissions(newPermission)
                setSelectPermissions(selectedValue.permissions)
            } else {
                setUserRole("")
                setFullName("")
                setWarehouseId([])
                setFloor([]);
                setPhoneNumber("")
                setEmail("")
                setDesignationValue("")
            }
        }
        else if (action === "view") {
            if (selectedValue?._id) {
                const warehouseId = selectedValue?.warehouseDetails ||  []
                handleWarehouseSelect(warehouseId)
                const selectedID = selectedValue && selectedValue.roleId ? selectedValue.roleId : '-'
                const floorIds = selectedValue?.floorDetails || []
                const roleName = AllUserRoleData.find(dl => dl._id == selectedID)?.roleName || ""

                setWarehouseId(warehouseId)
                setFloor(floorIds)
                setUserRole(roleName);
                setUserId(selectedID)
                setFullName(selectedValue.name)
                setPhoneNumber(selectedValue.mobile)
                setEmail(selectedValue.email)
                setDesignationValue(selectedValue.designation)
                setPermissions(selectedValue.permissions)
                setSelectPermissions(selectedValue.permissions)
            } else {
                setUserRole("")
                setFullName("")
                setWarehouseId([])
                setFloor([]);
                setPhoneNumber("")
                setEmail("")
                setDesignationValue("")
            }
        } else {
            handlePermission(userData.permissions)
        }

    }, [selectedValue])

    const handleDropdownModal = (e, option, key) => {
        if (key === 'userRoleModal') {
            if (option?.value === 'create') {
                hide("success")
                setTimeout(() => {
                    history.push({
                        pathname: "/dashboard/user_management/create-role",
                        state: { status: 'addnew', selectedCard: [] }
                    })
                }, 50)
            }
            else {
                if (option !== null) {
                    setUserRole(option?.label || '')
                    setUserId(option?.value || '')
                }
                else {
                    setUserRole('')
                    setUserId('')
                }
            }
        }
        else if (key === 'warehouse') {
            setWarehouseId(option || [])
            if (option?.length > 0) {
                handleWarehouseSelect(option || [])
            }
            setFloor([])
        }
        else if (key === 'floor') {
            // const valueToSave = option.map((itm) => itm._id);
            setFloor(option || [])
        }
    }

    const passwordType = showPassword ? "text" : "password"
    return (
        <>
            <CustomModel
                autoClose={false}
                show={open || false}
                minWidth={500} maxWidth={open == "Add" && 550 || 580}
                pt={1} pb={3} pl={3} pr={3}
                maxHeight={"90vh"} viewCloseIcon={["Edit", "Add"].includes(open)}
                childrenMaxHeight={"inherit"}
                // childrenMaxHeight={"85vh"}
                Title={["Edit", "Add"].includes(open) && `${open} Job Type`}
            >
                <Grid container padding={1} >
                    <Grid item sm={12} flexDirection={'column'} paddingRight={2} sx={{ width: '100%', height: "auto", overflow: "auto" }}>
                        <Typography variant='h6' sx={{ paddingLeft: "15px", fontSize: "20px", fontWeight: "600" }}>{action === "edit" ? 'Edit' : (action === "view") ? "View" : 'Add New'} Team Member</Typography>
                        <Box marginTop={1} sx={{
                            height: (action === 'edit' || action === 'view') ? "54vh" : '61vh'
                            , overflow: "auto"
                        }}>

                            <Box margin={2}>
                                <Autocomplete
                                    size='small'
                                    data-testid="userRoles"
                                    options={AllUserRoleData && [{ label: '+ Create New', value: 'create', isCreateOption: true }, ...AllUserRoleData.map(dl => ({ label: dl.roleName, value: dl._id }))]}
                                    sx={{ height: "1.1rem", }}
                                    onChange={(e, option) => {
                                        handleDropdownModal(e, option, 'userRoleModal')
                                    }}
                                    disabled={action === "view" ? true : false}
                                    error={errorMessage.userRole ? true : false}
                                    renderInput={(params) => (
                                        <TextField
                                            sx={{
                                                fontSize: "12px",
                                                ...selectStylesOverride,
                                            }}
                                            {...params}

                                            label={<Typography sx={{ fontSize: "12px" }}>User Role </Typography>}
                                        />
                                    )}

                                    renderOption={(props, option) => (

                                        <li {...props} style={{ color: option.isCreateOption ? '#ff7200' : '#727376', fontSize: '14px' }}>
                                            {option.label}
                                        </li>
                                    )}
                                    value={{ label: userRole, value: AllUserRoleData.find(dl => dl._id == userRole)?._id || "" }}
                                />
                            </Box>

                            <Box margin={2} sx={{ marginTop: '30px' }}>
                                <MultiSelectAll
                                    sx={{ maxheight: "700px" }}
                                    items={warehouseDetail?.data || []}
                                    selectAllLabel="Select All"
                                    value={warehouseId}
                                    onChange={(value) => {
                                        handleDropdownModal("", value, 'warehouse')
                                    }}
                                    label="Warehouse*"
                                    defaultSelectAll={false}
                                    disabled={action=="view"}
                                />
                                {/* <Autocomplete
                                    size='small'
                                    options={warehouseData && warehouseData.map(dl => ({ label: dl.name, value: dl._id }))}
                                    sx={{ height: "1.1rem", }}
                                    onChange={(e, option) => {
                                        handleDropdownModal(e, option, 'warehouse')
                                    }}
                                    disabled={action === "view" ? true : false}
                                    error={errorMessage.warehouseid ? true : false}
                                    renderInput={(params) => (
                                        <TextField
                                            sx={{
                                                fontSize: "12px",
                                                ...selectStylesOverride,
                                            }}
                                            {...params}

                                            label={<Typography sx={{ fontSize: "12px" }}>Warehouse *</Typography>}
                                        />
                                    )}

                                    renderOption={(props, option, { selected }) => (
                                        <MenuItem value={option?.value || ""} key={option?.value || ""}  {...props}>
                                            <Typography sx={{ fontSize: ".8rem", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                                {option?.label || ""}
                                            </Typography>
                                        </MenuItem>
                                    )}
                                    value={{ value: warehouseId, label: warehouseData.find(dl => dl._id == warehouseId)?.name || "" }}
                                /> */}
                            </Box>

                            <Box margin={2} sx={{  }}>
                                <MultiSelectAll
                                    sx={{ maxheight: "700px" }}
                                    items={floorDetail || []}
                                    selectAllLabel="Select All"
                                    value={floor}
                                    onChange={(option) => {
                                        handleDropdownModal("", option, 'floor')
                                    }}
                                    // onChange={handleFloorMultiSelect}
                                    label="Floor*"
                                    defaultSelectAll={false}
                                    disabled={action=="view"}
                                    renderOption={[
                                        {
                                            "label": "",
                                            "multiple": false,
                                            "prefix": "",
                                            "postfix": "",
                                            "key": "name",
                                            "keys": []
                                        },
                                        {
                                            "label": "",
                                            "multiple": false,
                                            "prefix": "(",
                                            "postfix": ")",
                                            "key": "warehouseName"
                                        }
                                    ]}
                                />
                                {/* <Autocomplete
                                    size='small'
                                    options={floorDetail && floorDetail.map(dl => ({ label: dl.name, value: dl._id }))}
                                    sx={{ height: "1.1rem", }}
                                    onChange={(e, option) => {
                                        handleDropdownModal(e, option, 'floor')
                                    }}
                                    disabled={action === "view" ? true : false}
                                    error={errorMessage.warehouseid ? true : false}
                                    renderInput={(params) => (
                                        <TextField
                                            sx={{
                                                fontSize: "12px",
                                                ...selectStylesOverride,
                                            }}
                                            {...params}

                                            label={<Typography sx={{ fontSize: "12px" }}>Floor *</Typography>}
                                        />
                                    )}

                                    renderOption={(props, option, { selected }) => (
                                        <MenuItem value={option?.value || ""} key={option?.value || ""}  {...props}>
                                            <Typography sx={{ fontSize: ".8rem", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                                {option?.label || ""}
                                            </Typography>
                                        </MenuItem>
                                    )}
                                    value={{ value: floor, label: floorDetail.find(dl => dl._id == floor)?.name || "" }}
                                /> */}
                            </Box>

                            <Box margin={2} sx={{ marginTop: '32px' }}>
                                <FormControl sx={{ ...selectStylesOverride, width: '100%' }}>
                                    <OutlinedInput
                                        value={fullName}
                                        onChange={(e) => {
                                            setFullName(e.target.value)
                                        }}
                                        sx={{
                                            fontSize: "12px",
                                            width: '100%',
                                            height: '35px',
                                            ...selectStylesOverride,
                                            "& input::placeholder": {
                                                fontSize: "12px",
                                            },
                                        }}
                                        disabled={action === "view" ? true : false}
                                        error={errorMessage.name ? true : false}
                                        placeholder='Full Name *'
                                    />
                                </FormControl>
                            </Box>

                            <Box margin={2} sx={{ marginTop: '6px' }}>
                                <FormControl sx={{ ...selectStylesOverride, width: '100%' }}>
                                    <OutlinedInput
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value)
                                        }}
                                        sx={{
                                            fontSize: "12px",
                                            width: '100%',
                                            height: '35px',
                                            ...selectStylesOverride,
                                            "& input::placeholder": {
                                                fontSize: "12px",
                                            },
                                        }}
                                        type='email'
                                        required
                                        disabled={["view", "edit"].includes(action) ? true : false}
                                        error={errorMessage.email ? true : false}
                                        placeholder='Email *'
                                    />
                                </FormControl>
                            </Box>

                            <Box margin={2} sx={{ marginTop: '6px' }}>
                                <FormControl sx={{ ...selectStylesOverride, width: '100%' }}>
                                    <OutlinedInput
                                        value={phoneNumber}
                                        onChange={(e) => {
                                            if (e.target.value.length <= 10) {
                                                setPhoneNumber(e.target.value)
                                            }

                                        }}
                                        onKeyDown={(e) => {
                                            if(['e', 'E', '+', '-'].includes(e.key)){
                                                e.preventDefault();
                                            }
                                            else if(e.key == '0' && (e.target.value == "" || e.target.value[0] == '0')){
                                                e.preventDefault();
                                            }
                                        }}
                                        sx={{
                                            fontSize: "12px",
                                            width: '100%',
                                            height: '35px',
                                            ...selectStylesOverride,
                                            "& input::placeholder": {
                                                fontSize: "12px",
                                            },
                                        }}
                                        type='number'
                                        required
                                        disabled={action === "view" ? true : false}
                                        error={errorMessage.mobile ? true : false}
                                        placeholder='Phone number *'
                                    />
                                </FormControl>
                            </Box>
                            {action === "addnew" && <>
                                <Box margin={2} sx={{ marginTop: '6px' }}>
                                    <FormControl sx={{ ...selectStylesOverride, width: '100%' }}>
                                        <OutlinedInput
                                            value={password}
                                            onChange={(e) => {
                                                setPassword(e.target.value)
                                            }}
                                            sx={{
                                                fontSize: "12px",
                                                width: '100%',
                                                height: '35px',
                                                ...selectStylesOverride,
                                                "& input::placeholder": {
                                                    fontSize: "12px",
                                                },
                                            }}
                                            className='customDatePicker'
                                            placeholder='Password *'
                                            type={showPassword ? "text" : "password"}
                                            autoComplete='off'
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Box>
                            </>}

                            <Box margin={2} sx={{ marginTop: '6px' }}>
                                <FormControl sx={{ ...selectStylesOverride, width: '100%' }}>
                                    <OutlinedInput
                                        value={designationValue}
                                        onChange={(event) => {
                                            const inputValue = event.target.value;
                                            const numericValue = inputValue.replace(/[^a-zA-Z\s!@#$%^&*(),.?":{}|<>]/g, ''); // Remove non-numeric characters
                                            setDesignationValue(numericValue)
                                        }}
                                        sx={{
                                            fontSize: "12px",
                                            width: '100%',
                                            height: '35px',
                                            ...selectStylesOverride,
                                            "& input::placeholder": {
                                                fontSize: "12px",
                                            },
                                        }}
                                        type='text'
                                        disabled={action === "view" ? true : false}
                                        required
                                        error={errorMessage.designation ? true : false}
                                        placeholder='Designation *'
                                    />
                                </FormControl>
                            </Box>





                        </Box>

                        {<Box sx={{ marginTop: "10px", padding: "0px 10px 0px 10px", display: "flex", justifyContent: "space-between" }}>
                            <Button
                                fullWidth
                                variant="outlined"
                                onClick={() => { hide()}}
                                sx={{ ...buttonClasses.lynkitOrangeEmpty, ml: 0, mr: 2, fontSize: ".7rem" }}

                            >Cancel</Button>
                            {action !== 'view' ?
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    sx={{ ...buttonClasses.lynkitOrangeFill, ml: 0, fontSize: ".7rem", border: 'none' }}
                                    onClick={() => handleValidationSubmit()}
                                > {action === "addnew" ? 'Create' : 'Update'}</Button>
                                : null}
                        </Box>}
                    </Grid>
                </Grid>

            </CustomModel>
            <ToastContainer position="bottom-center" autoClose={false} closeOnClick={false} />
        </>
    )
}

export default AddUsersModel;
