import React, { useEffect, useState } from 'react';
import {
    Grid, Button, Typography, Box, Card, CardHeader, CardContent, Breadcrumbs, Link, FormControl, OutlinedInput, InputAdornment, IconButton, Autocomplete, TextField
} from "@mui/material";
import Paper from '@mui/material/Paper';
import { Add, ExpandMore, Edit, Search, Clear } from '@mui/icons-material';
import theme, { buttonClasses, textClasses } from '../../../../static/styles/theme';
import { getAllUserRoles, getSuperAdminCompanyData } from '../../../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import GenericLoader from '../../../Components/Generic/GenericLoader';
import { inputClasses } from '../../../../static/styles/theme';
import { useDebounce } from '../../../../utils/hooks';
import { selectStylesOverride } from '../../../../utils/util';
import { getCamelCaseText } from '../../../../services/functions';
import { DataNotFound } from '../../../Components/TemplateInputConfiguration';
let initialSearchFilter = {
    name: "",
    permission: ""
}
const UserRole = (props) => {

    const users = JSON.parse(localStorage.getItem("user")) || {}
    const userType = users?.userType || ""
    const currUserRoleId = users?.roleId || ""
    const history = useHistory();
    const dispatch = useDispatch();
    const [actionStatus, setActionStatus] = useState("addnew");;
    const { AllUserRoleData } = useSelector((state) => state.UserRoles);
    const [cardsData, setCardsData] = useState({ worker: [], admin_viewer: [] })
    const [selectedCard, setSelectedCard] = useState([])
    const [loader, setLoader] = useState(false)
    const [initial, setInitial] = useState(true)
    const [more, setMore] = useState({})
    const [companiesList, setCompaniesList] = useState([])
    const [selectedCompanyDetail, setSelectedCompanyDetail] = useState({})
    const { userPermissions } = useSelector((state) => state.userPermission);
    const [searchFilter, setSearchFilter] = useState(initialSearchFilter)
    const debouncedSearchFilter = useDebounce(searchFilter, 500)

    const handleOpenAddNew = (data, companyId) => {
        setActionStatus("edit")
        setSelectedCard(data)
        history.push({
            pathname: "/dashboard/user_management/edit-role",
            state: { status: 'edit', selectedCard: data, companyId: companyId }
        }
        )

    }
    const addClass = (item, index, Id) => {
        if (item === 'admin') {
            var element = document.getElementById(`admin-${index}`);
            if (element.classList.length === 6) {
                element.classList.remove("priyanshuScroll");
                setMore({ ...more, [Id]: false })
            }
            else {
                element.classList.add("priyanshuScroll");
                setMore({ ...more, [Id]: true })
            }
        }
        else {
            var element = document.getElementById(`custom-${index}`);
            if (element.classList.length === 6) {
                element.classList.remove("priyanshuScroll");
                setMore({ ...more, [Id]: false })
            }
            else {
                element.classList.add("priyanshuScroll");
                setMore({ ...more, [Id]: true })
            }
        }

    }
    const getRoleData = () => {
        setLoader(true)
        const payload = {
            makeGroup: true,
            roleName: searchFilter.name,
            permissionName: searchFilter.permission,
            companyId: selectedCompanyDetail?._id || ""
        }

        dispatch(getAllUserRoles(payload, () => {
            setLoader(false)
        }))
    }


    useEffect(() => {
        if (initial && userType == "superAdmin") {
            dispatch(getSuperAdminCompanyData({ projections: { _id: 1, companyName: 1, type: 1 }, type: 'company' }, ({ data = [] }) => {
                setCompaniesList(data)
                if (data) {
                    setSelectedCompanyDetail(data?.at(0) || {})
                }
            }))
        }
        else {
            getRoleData()
        }
        setInitial(false)
    }, [debouncedSearchFilter.name, debouncedSearchFilter.permission, selectedCompanyDetail])

    useEffect(() => {
        if (AllUserRoleData) {
            let wor = [], adm = []
            for (let ele of AllUserRoleData) {
                if (['Admin', "Viewer"].includes(ele.roleType || "")) adm.push(ele)
                if (['Custom', "custom"].includes(ele.roleType || "")) wor.push(ele)
            }
            setCardsData({ worker: wor, admin_viewer: adm })
        }

    }, [AllUserRoleData])

    const isFilterApplied = (() => {
        if (searchFilter.name !== "" || searchFilter.permission !== "") {
            return true;
        }
        return false;
    })();


    const handleClearFilter = () => {
        setSearchFilter(initialSearchFilter)
    }
    return (
        <>
            {/* sx={{ flexDirection: "column" }} */}
            <Grid container data-testid="loaderid">
                <Grid item sm={6} sx={{ fontSize: "24px", fontWeight: 600 }}>
                    <Typography sx={{ ...textClasses.cardTitle }}>
                        Manage User Role's Access
                    </Typography>
                    {/* <Breadcrumbs sx={{ mb: 1 }}>
                        <Typography
                            sx={{
                                ...textClasses.normalText,
                                fontSize: "12px",
                                color: theme.themeOrange,
                            }}
                        >
                            <Link
                                style={{ color: theme.themeOrange, textDecoration: "none" }}
                                to="/dashboard"
                            >
                                Dashboard
                            </Link>
                        </Typography>
                    </Breadcrumbs> */}
                </Grid>

                {(userPermissions?.permissions?.find(dl => dl.permissionId == "addRole") || userType == "superAdmin") && <Grid
                    container
                    item
                    sm={6}
                    justifyContent="end"
                    alignItems="start"
                    gap={1}
                >
                    <Button
                        data-testid="customRole"
                        variant="contained"
                        size="small"
                        startIcon={<Add />}
                        sx={{
                            ...buttonClasses.lynkitOrangeEmpty,
                            paddingTop: "5.5px",
                            paddingBottom: "5.5px",
                            textTransform: 'none'
                        }}
                        onClick={() => history.push({
                            pathname: "/dashboard/user_management/create-role",
                            state: { status: actionStatus, selectedCard: selectedCard, companyId: selectedCompanyDetail?._id }
                        }
                        )}
                    >
                        Create Custom Role
                    </Button>

                </Grid>}
            </Grid>
            <Grid container>

                <Grid item sx={4} sm={2.2} >
                    <FormControl variant="outlined" size="small">
                        <OutlinedInput
                            data-testid="searchNameFilter"
                            value={searchFilter.name}
                            onChange={(e) => {
                                setSearchFilter({ ...searchFilter, name: e.target.value }); // 2nd technique using spread oeprator
                                //   setPage(0);
                                //   setLimit(10);
                            }}

                            placeholder="Search by name"
                            sx={{
                                ...inputClasses.filterField,
                                "& input::placeholder": { fontSize: "14px" },
                                "& .MuiSelect-iconOutlined": {
                                    display: searchFilter.name != "" ? "none" : "",
                                },
                            }}
                            endAdornment={
                                searchFilter.name == "" ? (
                                    <InputAdornment position="end">
                                        <Search fontSize="small" />
                                    </InputAdornment>
                                ) : (
                                    <IconButton
                                        sx={{
                                            visibility: searchFilter.name != "" ? "visible" : "hidden",
                                            fontSize: "14px",
                                        }}
                                        onClick={() => {
                                            setSearchFilter({ ...searchFilter, name: "" })
                                            // setPage(0);
                                            // setLimit(10);
                                        }}
                                    >
                                        <Clear fontSize="inherit" />
                                    </IconButton>
                                )
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid item sx={4} sm={2} >
                    <FormControl variant="outlined" size="small">
                        <OutlinedInput
                            data-testid="searchPermissionFilter"
                            value={searchFilter.permission}
                            onChange={(e) => {
                                setSearchFilter({ ...searchFilter, permission: e.target.value })
                                //     setPage(0);
                                //   setLimit(10);
                            }}
                            placeholder="Search by permissions"
                            sx={{
                                ...inputClasses.filterField,
                                "& input::placeholder": { fontSize: "14px" },
                                "& .MuiSelect-iconOutlined": {
                                    display: searchFilter.permission != "" ? "none" : "",
                                },
                            }}
                            endAdornment={
                                searchFilter.permission == "" ?
                                    (
                                        <InputAdornment position="end">
                                            <Search fontSize="small" />
                                        </InputAdornment>
                                    ) : (
                                        <IconButton
                                            sx={{
                                                visibility: searchFilter.permission != "" ? "visible" : "hidden",
                                                fontSize: "14px",
                                            }}

                                            onClick={() => {
                                                setSearchFilter({ ...searchFilter, permission: "" })
                                                // setPage(0);
                                                // setLimit(10);
                                            }}
                                        >
                                            <Clear fontSize="inherit" />
                                        </IconButton>
                                    )
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid item sx={4} sm={2}>
                    {userType == "superAdmin" && (userPermissions?.permissions?.find(dl => dl.permissionId == "createAdmin"))
                        &&
                        <Autocomplete
                            data-testid="select-template-dropdown"
                            value={selectedCompanyDetail || {}}
                            onChange={(e, option) => {
                                setSelectedCompanyDetail(option)
                            }}
                            getOptionLabel={(option) => getCamelCaseText(option?.companyName)}
                            size="small"
                            options={companiesList || []}
                            sx={{
                                ...inputClasses.textField,
                                ...selectStylesOverride,
                                minWidth: "250px",
                                m: 0,

                                ".MuiInputBase-input": {
                                    fontSize: "14px",
                                    color: "#455A64",
                                },
                            }}
                            renderInput={(params) => (
                                <TextField
                                    sx={{ color: "#455A64", ...textClasses.normalText }}
                                    {...params}
                                    label="Select Company*"
                                />
                            )}
                        />

                    }
                </Grid>
                {isFilterApplied && (
                    <Grid item sx={4} sm={2} p={1} ml={2}>
                        <Typography
                            sx={{
                                ...textClasses.boldText,
                                color: theme.themeOrange,
                                textDecoration: "underline",
                                cursor: "pointer",
                            }}
                            onClick={handleClearFilter}
                        >
                            Clear Filter(s)
                        </Typography>
                    </Grid>
                )}

            </Grid>
            {loader
                ? <Grid container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15 }}>
                    <Grid item><GenericLoader /></Grid>
                </Grid>
                : AllUserRoleData?.length > 0 ?
                    <Grid data-testid='card' container spacing={2} sx={{ marginTop: "2px" }}>

                        {cardsData?.admin_viewer?.length ? cardsData?.admin_viewer?.map((item, index) => {
                            return (
                                <Grid data-testid='card' key={index} item sm={3.85} sx={{ margin: '0.5vw' }}>
                                    <Paper elevation={3} >
                                        {item && item?.roles ? item?.roles?.map((data, ind) => {
                                            return (
                                                <Card key={ind} sx={{ boxShadow: 'none', margin: '0px' }}>
                                                    <CardHeader
                                                        title={<Grid container direction={"row"} justifyContent={"space-between"} alignItems="center" >
                                                            <Typography sx={{ textAlign: "left", fontWeight: "bold" }} variant="h6" gutterBottom>
                                                                {data && data?.roleName ? data?.roleName : '-'}
                                                            </Typography>
                                                            {userPermissions?.permissions?.find(dl => dl.permissionId == "createAdmin") && userType == "superAdmin" && <Button
                                                                data-testid='editBtn'
                                                                variant="contained"
                                                                size="small"
                                                                startIcon={<Edit />}
                                                                sx={{
                                                                    ...buttonClasses.lynkitBlackFill,
                                                                    paddingTop: "5.5px",
                                                                    marginRight: "7px",
                                                                    paddingRight: "15px",
                                                                    paddingBottom: "5.5px",
                                                                    height: '30px',
                                                                    borderRadius: '7px',
                                                                    textTransform: 'none'
                                                                }}
                                                                onClick={() => handleOpenAddNew(data, selectedCompanyDetail?._id)}
                                                            >
                                                                Edit Role
                                                            </Button>
                                                            }
                                                        </Grid>}
                                                    />
                                                    <CardContent className='priyanshuScollable pt-0 pb-2' id={`admin-${index}`}>
                                                        {data && data?.permissions ? data?.permissions?.map((permission, num) => {
                                                            return (
                                                                <Typography key={num} sx={{ textAlign: "left", paddingBottom: '6px', paddingLeft: "10px" }} variant="body1">
                                                                    {permission && permission.permissionName ? permission.permissionName : '-'}
                                                                </Typography>
                                                            )
                                                        }) : '-'}


                                                    </CardContent>

                                                    <CardHeader
                                                        title={
                                                            <Typography onClick={() => addClass('admin', index, data._id)} sx={{
                                                                textAlign: "left", paddingBottom: '0px', color: '#ff7200', fontWeight: '900', paddingLeft: "10px",
                                                                cursor: 'pointer', visibility: data && data.permissions.length > 6 ? '' : 'hidden'
                                                            }}
                                                                variant="body2"
                                                            > {(more[data._id] && "Collapse" || "Show All") + " (" + data.permissions.length + ")"}
                                                            </Typography>}
                                                    />

                                                </Card>
                                            )
                                        }) : ""}
                                    </Paper>
                                </Grid>
                                // item.roleType === 'Admin' || item.roleType === 'Viewer' ?
                                //     : ''
                            )
                        }) :
                            ""
                        }

                        {cardsData?.worker?.length ? cardsData?.worker?.map((item, index) => {
                            return (
                                item && item?.roles?.map((data, ind) => {
                                    return (
                                        < Grid key={ind} item sm={3.85} sx={{ margin: '0.5vw' }}>
                                            <Paper elevation={3} >
                                                <Card sx={{ boxShadow: 'none' }}>
                                                    <CardHeader
                                                        // sx={{
                                                        //     ".css-185gdzj-MuiCardHeader-root": {
                                                        //         padding: "5px 15px 0px!important",
                                                        //     }
                                                        // }}
                                                        title={
                                                            <Grid container direction={"row"} justifyContent={"space-between"} alignItems="center" >
                                                                <Typography sx={{ textAlign: "left", fontWeight: "bold" }} variant="h6" gutterBottom>
                                                                    {data && data?.roleName ? data?.roleName : '-'}
                                                                </Typography>
                                                                {userPermissions?.permissions?.find(dl => dl.permissionId == "editRole") && currUserRoleId !== data?._id &&<Button
                                                                    data-testid='editBtn'
                                                                    variant="contained"
                                                                    size="small"
                                                                    startIcon={<Edit />}
                                                                    sx={{
                                                                        ...buttonClasses.lynkitBlackFill,
                                                                        paddingTop: "5.5px",
                                                                        marginRight: "7px",
                                                                        paddingRight: "15px",
                                                                        paddingBottom: "5.5px",
                                                                        height: '30px',
                                                                        borderRadius: '7px',
                                                                        textTransform: 'none'
                                                                    }}
                                                                    onClick={() => handleOpenAddNew(data, selectedCompanyDetail?._id)}
                                                                >
                                                                    Edit Role
                                                                </Button>}
                                                            </Grid>
                                                        }
                                                    />
                                                    <CardContent data-testid='scrollDiv' className='priyanshuScollable pt-0 pb-2' id={`custom-${ind}`}>
                                                        {data && data?.permissions ? data?.permissions?.map((permission, num) => {
                                                            return (
                                                                <Typography key={num} sx={{ textAlign: "left", paddingBottom: '6px', paddingLeft: "10px" }} variant="body1">
                                                                    {permission && permission.permissionName ? permission.permissionName : '-'}
                                                                </Typography>
                                                            )
                                                        }) : ''}
                                                    </CardContent>

                                                    <CardHeader
                                                        data-testid="showAll"
                                                        title={
                                                            <Typography onClick={() => addClass('custom', ind, data._id)} sx={{
                                                                textAlign: "left", paddingBottom: '0px', color: '#ff7200', fontWeight: '900', paddingLeft: "10px",
                                                                cursor: 'pointer', visibility: data && data.permissions.length > 6 ? '' : 'hidden'
                                                            }}
                                                                variant="body2"
                                                            >
                                                                {(more[data._id] && "Collapse" || "Show All") + " (" + data.permissions.length + ")"}
                                                            </Typography>}
                                                    />
                                                </Card>
                                            </Paper>
                                        </Grid>
                                    )
                                })
                                // item.roleType === 'Custom' || item.roleType === 'custom' ?

                                //     : ''
                            )
                        }) :
                            ""
                        }
                    </Grid>
                    :
                    <Grid container item sx={{ display: 'flex', justifyContent: "center" }}>
                        <DataNotFound height={400} width={800} label="No Role Data Found" />
                    </Grid>
            }

        </>
    )
}

export default UserRole;