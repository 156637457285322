import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { FormControl, Radio, RadioGroup, FormControlLabel, Typography } from "@mui/material";
import { Divider, Grid, Tab, Tabs } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getGridBlockData } from "../../../../redux/actions";
import theme, {
  buttonClasses,
  textClasses,
} from "../../../../static/styles/theme";
import GenericTable from "../../CustomTable/GenericTable";
import { useSelector, useDispatch } from "react-redux";
import GenericLoader from "../../Generic/GenericLoader";
import { dateTimeFormater } from "../../../../services/functions";
import AlertDialog from "../../DialogBox/dialogbox";
// const style = {
//   dispay: "flex",
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 800,
//   height: "85vh",
//   overflow: "hidden",
//   bgcolor: "background.paper",
//   boxShadow: 24,
//   padding: "20px",
//   borderRadius: "8px",
//   overflow: "auto",
//   border: "none !important ",
// };

const useStyles = makeStyles({
  root: {
    width: "85px",
    height: "20px",
    padding: "0px",
  },
  switchBase: {
    padding: "0px",
    top: "2px",
    left: "2px",
    "&$checked": {
      "& + $track": {
        backgroundColor: "#FF7200",
        "&:after": {
          content: "'Grid View'",
        },
      },
    },
  },
  thumb: {
    color: "white",
    width: "16px",
    height: "16px",
  },
  track: {
    borderRadius: "24px",
    backgroundColor: "#818181",
    opacity: "1 !important",
    "&:after, &:before": {
      color: "white",
      fontSize: "12px",
      position: "absolute",
      top: "2px",
      left: "16px",
    },
    "&:after": {
      content: "'Table View'",
    },
  },
  checked: {
    color: "#FF7200",
    position: "absolute",
    top: "2px",
    transform: "translateX(65px) !important",
  },
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const initialState = {
  shelveCapacity: "",
  totalBoxAvailable: "",
  remainingSpacing: "",
  filledSpacing: "",
  unitValue: ""
}

export default function BinModel({
  showBinModel,
  setShowBinModel,
  selectedCell,
}) {
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [loader, setLoader] = useState(true);
  const [gridBlockDataByLocation, setGridBlockDataByLocation] = useState({});
  const [utilizationTypeValue, setUtilizationTypeValue] = useState('countPercentageFilled');
  const [analyticsDetailData, setAnalyticsDetailData] = useState(initialState)
  const binTableHeader = [
    "S.No",
    "Shipment Id",
    "Package Name",
    "Package Details",
    "SKU ID",
    "SKU Name",
    "Available Box",
    "Available Weight",
    "Total Quantity",
    ["block", "rack"].includes(selectedCell?.type) ? "Gate In Time" : "Staging Time",
  ];

  if (selectedCell?.type == "staginArea") {
    binTableHeader.splice(4, 1, "Staging Time")
  }

  const binTableRows = gridBlockDataByLocation?.data?.map((element, index) => {
    let row = [];
    let {
      count = 0,
      uniquePackageCount = 0,
      totalWeight = 0,
      shipmentId,
      packageName = "-",
      packageDescription = "-",
      SKU_Number = "-", 
      SKU_Name = "-",
      gateInTime = "-",
    } = element;

    const rowData = [(index + 1) + (pageNumber * pageLimit)];
    if (tabIndex == 1) {
      rowData.push(packageName,packageDescription,Number(uniquePackageCount),Number(totalWeight), Number(count), dateTimeFormater(gateInTime));
    } else if (tabIndex == 2) {
      rowData.push(SKU_Number, SKU_Name, Number(count), dateTimeFormater(gateInTime));
    } else {
      rowData.push(shipmentId, Number(count), dateTimeFormater(gateInTime));
    }
    row.push(...rowData);
    return row;
  });

  const getBinTableheader = () => {
    if (tabIndex === 2) {
      return binTableHeader.filter(
        (header) => !["Shipment Id", "Package Name","Package Details","Available Box","Available Weight"].includes(header)
      );
    } else if (tabIndex == 1) {
      return binTableHeader.filter(
        (header) => !["SKU ID", "Shipment Id","SKU Name"].includes(header)
      );
    } else {
      return binTableHeader.filter(
        (header) => !["SKU ID", "Package Name","Package Details","Available Box","Available Weight","SKU Name"].includes(header)
      );
    }

  };

  function handlePageChange(page,limit, tab_Index = 0) {
    setLoader(true);
    setGridBlockDataByLocation({ data: [], count: 0, stockDetail: {} });
    let req_payload = {
      id: selectedCell?.cellId,
      floorId: selectedCell?.floorId,
      fetchAnalytics: true,
      warehouseId: selectedCell?.warehouseId,
      entityType:
        tab_Index == 2
          ? "itemWise"
          : tab_Index == 1
            ? "packageWise"
            : "shipmentWise",
    };
    dispatch(
      getGridBlockData(
        { ...req_payload, page, limit: limit },
        ({ success = false, message, data = [], count = 0, stockDetail = {} }) => {
          setGridBlockDataByLocation({data, count,stockDetail});
          setLoader(false);
        }
      )
    );
  }

  const handleClose = () => setShowBinModel(false);
  const classes = useStyles();
  const [isGridView, setIsGridView] = React.useState(true);

  const handleChange = (event) => {
    setIsGridView(event.target.checked);
  };

  const handleTabChange = (event, value) => {
    setTabIndex(value);
    setPageNumber(0);
    handlePageChange(1,pageLimit, value);
  };

  useEffect(() => {
    setPageNumber(0);
    setPageLimit(10);
    setTabIndex(0);
    handlePageChange(1,10, 0);
  }, [selectedCell?.cellId]);

  const filledSpace =
    (selectedCell?.currentStockCount * 100) /
    selectedCell?.quantityCapacity?.quantity || 0;

  const remainingSpace = () => {
    let remian = 100 - Number(filledSpace || 0);
    return remian > 0 ? remian : 0;
  };

  const showDimension = () => {
    let dimensions = selectedCell?.dimension
      ? `${selectedCell?.dimension?.length} ${selectedCell?.dimension?.unit} * ${selectedCell?.dimension?.width} ${selectedCell?.dimension?.unit} * ${selectedCell?.dimension?.height} ${selectedCell?.dimension?.unit}`
      : "Not Defined";
    return dimensions
  };

  const handleUtilizationValueChange = (event) => {
    setUtilizationTypeValue(event.target.value);
  };

  useEffect(() => {
    const stockDetail = gridBlockDataByLocation?.stockDetail;

    const calculateAnalyticsDetailData = (shelveCapacity, totalAvailability, filledPercentage, unit) => {
      let tempRemainingSpace = 100 - filledPercentage;
      return {
        shelveCapacity: shelveCapacity,
        totalBoxAvailable: totalAvailability,
        remainingSpacing: tempRemainingSpace > 0 ? tempRemainingSpace : 0,
        filledSpacing: filledPercentage,
        unitValue: unit
      };
    };

    if (utilizationTypeValue === "countPercentageFilled") {
      setAnalyticsDetailData(
        calculateAnalyticsDetailData(
          stockDetail?.totalCount,
          stockDetail?.existingCount,
          stockDetail?.countPercentageFilled,
          'Box'
        )
      );
    } else if (utilizationTypeValue === "weightPercentageFilled") {
      setAnalyticsDetailData(
        calculateAnalyticsDetailData(
          stockDetail?.totalWeight,
          stockDetail?.existingWeight,
          stockDetail?.weightPercentageFilled,
          'Kg'
        )
      );
    } else if (utilizationTypeValue === "volumePercentageFilled") {
      setAnalyticsDetailData(
        calculateAnalyticsDetailData(
          stockDetail?.totalVolume,
          stockDetail?.existingVolume,
          stockDetail?.volumePercentageFilled,
          'Feet'
        )
      );
    }
  }, [utilizationTypeValue, gridBlockDataByLocation]);
  return (
    <>
      <AlertDialog
        viewDialog={showBinModel}
        handleClose={handleClose}
        showDivider={true}
        width="70%"
        title={
          <>
            <Box
              sx={{display: "flex",justifyContent: "space-between"}}
            >
              <Box sx={{ marginBottom: '6px'}}>
                <Typography  id="modal-modal-title" variant="h6" component="h2"
                >
                  Area Utilization Analysis :{" "}
                  <span style={{color: "#FF7200",}}>{selectedCell?.name}</span>
                </Typography>
              </Box>
          
            </Box>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                marginTop: "-15px",
              }}
            >
              <Tabs
                data-testid="tab-headers"
                value={tabIndex}
                aria-label="disabled tabs example"
                onChange={handleTabChange}
                // style={{ height: "20px" }}
                sx={{
                  "& .MuiTabs-indicator": {
                    backgroundColor: theme.themeOrange,
                  },
                  "& .MuiTab-root.Mui-selected": {
                    color: theme.themeOrange,
                  },
                }}
              >
                <Tab
                  label={`All Shipment(s)`}
                  {...a11yProps(0)}
                  sx={{ ...textClasses.normalText, textTransform: "none" }}
                />
                <Tab
                  label={`All Packages/Box`}
                  {...a11yProps(1)}
                  sx={{ ...textClasses.normalText, textTransform: "none" }}
                />
                <Tab
                  label={`All Items`}
                  {...a11yProps(2)}
                  sx={{ ...textClasses.normalText, textTransform: "none" }}
                />
              </Tabs>
            </Box>
            {gridBlockDataByLocation?.stockDetail?.isCapacityDefined?.isTrue == true &&
              <Grid container sx={{ mt: 1 }}>
                <Grid item sm={3} md={3} xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body2" fontWeight="700">Utilization Percentage Type</Typography>
                </Grid>

                <Grid item sm={9} md={9} xs={12}>
                  <FormControl>
                    {/* <FormLabel id="demo-controlled-radio-buttons-group">Utilization Type*</FormLabel> */}
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      data-testid="radio-btns"
                      value={utilizationTypeValue}
                      onChange={handleUtilizationValueChange}
                    >
                      <FormControlLabel sx={{
                        color: "#000",
                        "& .MuiFormControlLabel-label": {
                          fontSize: "12px",
                        },
                      }} value="countPercentageFilled" control={<Radio size="small"
                        sx={{
                          "&.Mui-checked": {
                            color: "#FF7200",
                          },
                        }} />} label="QTY" />
                      <FormControlLabel sx={{
                        color: "#000",
                        "& .MuiFormControlLabel-label": {
                          fontSize: "12px",
                        },
                      }}
                        value="weightPercentageFilled" control={<Radio size="small"
                          sx={{
                            "&.Mui-checked": {
                              color: "#FF7200",
                            },
                          }} />} label="Weight" />
                      <FormControlLabel sx={{
                        color: "#000",
                        "& .MuiFormControlLabel-label": {
                          fontSize: "12px",
                        },
                      }} value="volumePercentageFilled" control={<Radio size="small"
                        sx={{
                          "&.Mui-checked": {
                            color: "#FF7200",
                          },
                        }} />} label="Dimensions" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={12} xs={12} sx={{ display: 'flex', gap: 2, margin: 1 }} rowSpacing={2}>
                  <Typography
                    data-testid="shelve-capacity"
                    sx={{
                      padding: "5px 10px",
                      borderRadius: "8px",
                      border: "1px solid #FF7200",
                      backgroundColor: "#ff720021",
                      ...textClasses.t12n,
                      color: "#FF7200",
                    }}
                  >
                    Shelve Capacity : {analyticsDetailData?.shelveCapacity} {analyticsDetailData?.unitValue}
                  </Typography>
                  <Typography
                    data-testid="available"
                    sx={{
                      padding: "5px 10px",
                      borderRadius: "8px",
                      border: "1px solid #FF7200",
                      backgroundColor: "#ff720021",
                      ...textClasses.t12n,
                      color: "#FF7200",
                    }}
                  > Total Box Available : {analyticsDetailData?.totalBoxAvailable} {analyticsDetailData?.unitValue}
                  </Typography>
                  <Typography
                    data-testid="remaining"
                    sx={{
                      padding: "5px 10px",
                      borderRadius: "8px",
                      border: "1px solid #3E621E",
                      backgroundColor: "#c4e4d570",
                      ...textClasses.t12n,
                      color: "#3E621E",
                    }}
                  >  {`Remaining Space : ${analyticsDetailData?.remainingSpacing} %`}
                  </Typography>
                  <Typography
                    data-testid="filled"
                    sx={{
                      padding: "5px 10px",
                      borderRadius: "8px",
                      border: "1px solid #E9001A",
                      color: "#E9001A",
                      backgroundColor: "#ff720021",
                      ...textClasses.t12n,
                    }}
                  >  {`Filled Space : ${analyticsDetailData?.filledSpacing} %`}
                  </Typography>
                </Grid>
              </Grid>
            }
          </>
        }
        actionsCSS={{ justifyContent: 'flex-start' }}
        body={
          <>
            {
              <Box
              //  sx={style}
              >

                <Box
                  sx={{
                    // height: "58vh",
                    overflowX: "auto",
                  }}
                >
                  {/* {!isGridView ? <GenericTable header={binTableHeader} /> : ""} */}
                  {loader ? (
                    <Grid
                      data-testid="loader"
                      container
                      direction={"column"}
                      justifyContent={"center"}
                      alignItems="center"
                      sx={{ p: 15 }}
                    >
                      <Grid item>
                        <GenericLoader />
                      </Grid>
                    </Grid>
                  ) : (
                    <GenericTable
                      header={getBinTableheader()}
                      rows={binTableRows}
                      data-testid="grid-table"
                      pageCount={gridBlockDataByLocation?.count || 0}
                      pageNumber={pageNumber}
                      handleChangePage={(event, pagevalue) => {
                        setPageNumber(pagevalue);
                        // setLoader(true);
                        handlePageChange(pagevalue,pageLimit, tabIndex);
                      }}
                      handleChangeRowsPerPage={(event) => {
                        setPageLimit(+event.target.value);
                        // setPageNumber(1)
                        handlePageChange(0,+event.target.value, tabIndex);
                      }}
                      rowsPerPage={pageLimit}
                    />
                  )}
                </Box>
              </Box>
            }
          </>
        }

      >
        <Button
          variant="outlined"
          data-testid="cancel-btn"
          onClick={handleClose}
          sx={{ ...buttonClasses.lynkitOrangeEmpty, m: 1, fontSize: ".7rem", minWidth: "130px" }}
        >Cancel
        </Button>
      </AlertDialog>

    </>
  );
}
