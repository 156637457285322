import socketIOClient from "socket.io-client";
import config from "./config";
import {parseJwt,b64toBlob} from "../utils/services";
import { getDimensions,setJobQtyData,setStockUnitData} from "../redux/actions";
import { showToast } from "./functions";

function socketServices() {
    if (localStorage.getItem("token")) {
        var tokenData = parseJwt(localStorage.getItem("token"))
        var userData = localStorage.getItem("user")
        // console.log(tokenData,userData)
        if (userData && tokenData && tokenData.iat) {
            const { _id, name, email,base } = JSON.parse(userData) || {}
            var socket = socketIOClient(config.socketURL,
                {
                    transports: ['websocket',"polling"],
                    query: `name=${name}&userId=${_id}&userName=${email}&iat=${tokenData.iat}&socketID=${localStorage.getItem("socketID")}&base=${base}&exp=${tokenData.exp}`
                });

            socket.on("socket-added", socketData => {
                localStorage.setItem("socketID", socketData?.data)
                console.log("socket-added::",socketData?.data)
            });
            return socket
        }

        return null;
    }

    return null;
}


function Operation(obj_data, dispatch) {
    // console.log("socket-recived",obj_data)
    const { type, parcelID, data = "", success = false, message } = obj_data;
    if (type == 'ParcelDimensions' && parcelID) {
        dispatch(getDimensions({ parcelID: parcelID }))
    }
    else if (['ReportDownload','LocationSuggestion'].includes(type)) {
        showToast(message, !success)
        if (!success) {
            return;
        }

        let contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        let blob = b64toBlob(data, contentType);
        let url = URL.createObjectURL(blob);
        window.open(url, "_blank");
        console.log("socket-detected-event::", type)
    }
    else if (type == "InventoryJourney") {
        dispatch(setStockUnitData(obj_data))
    }
    else if (type == "InventoryJourneyJobs") {
        dispatch(setJobQtyData(obj_data))
    }
}


export {socketServices,Operation};
