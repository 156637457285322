import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme,backgroundColor}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: backgroundColor || theme.palette.common.black,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme,backgroundColor }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: backgroundColor || theme.palette.common.black,
        zIndex: theme.zIndex.modal - 1
    },
}));

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme,backgroundColor }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: backgroundColor || '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

export default function CustomizedTooltips(props = {}) {

    let property = {
        title: props.title || "",
        backgroundColor: props.backgroundColor || "",
        color: props.color || "",
        placement: props.placement || "top",
        arrow: !!props.arrow || true,
       
    }
    if (props?.toolTipType == "HTML") {
        delete property["title"]
    }
    if (props.hasOwnProperty("open")) {
        property["open"] = !!props.open || false
    }
    return (
        <>
            {
                props.toolTipType == "HTML" ?
                    <HtmlTooltip
                        title={
                            <React.Fragment>
                                {property?.title && <Typography color="inherit">{property?.title}</Typography>}
                                {props?.body}
                                {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '}
                        {"It's very engaging. Right?"} */}
                            </React.Fragment>
                        }
                        {...property}
                    >
                        {props?.children || ""}
                    </HtmlTooltip>
                    : props.toolTipType == "Bootstrap" ?
                        <BootstrapTooltip {...property}>
                            {props?.children || ""}
                        </BootstrapTooltip>
                        : <LightTooltip {...property}>
                            {props?.children || ""}
                        </LightTooltip>
            }
        </>
    );
}
