import { Card, CardContent, CardHeader, Dialog, DialogContent, Grid, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchFile, getTemplateHeader } from "../../../redux/actions";
import FileModal from "../../Modules/EntityMaster/FileModal";
import React from "react";
import theme, { textClasses, buttonClasses } from "../../../static/styles/theme";
import { Close } from "@mui/icons-material";
import { dataReadFunction, dateTimeFormater } from "../../../services/functions";
import GenericLoader from "../../Components/Generic/GenericLoader";


const GenericDetailRowData = ({ detailData, onClose, cardHeader = [], loader }) => {
    // console.log("detailData",detailData)
    // console.log("cardHeader",cardHeader)
  const dispatch = useDispatch();
  const [displayUrl, setDisplayUrl] = useState("");
  const [openModal, setOpenModal] = React.useState(false);
//   let titleElement = cardHeader?.find((dl) => dl?.name == readKey) || {}
  const DetailRow = ({ title, children ,h={}}) => {
    return (
      <Grid container sx={{ mt: 1 }}>
        <Grid item sm={5} xs={5}>
          <Typography sx={{ ...textClasses.t12n, color: "#827878", fontSize:h?.fontSize || '14px', ml:h?.headerMarginLeft || "10px"}}>{title} : </Typography>
        </Grid>
        <Grid container item sm={7} xs={7} direction="row" alignItems={"center"}>
          {children}
        </Grid>
      </Grid>
    )
  }



  const displayFile = (fileUrl, fileKey) => {
    const imageUrlRegex = /\.(jpg|jpeg|png|gif|bmp|tiff)$/i;
    const isValidImageUrl = imageUrlRegex.test(fileKey);
    if (isValidImageUrl) {
      setOpenModal(true);
      setDisplayUrl(fileUrl);
    } else {
      window.open(fileUrl, "_blank");
    }
  };

  const handleFile = (fileKey) => {
    setOpenModal(false);
    setDisplayUrl('');
    dispatch(fetchFile({ fileKey }, displayFile));
  };

  const handleShowDetail = (handleType) => {
    // setViewModal({ ...viewModal, type: handleType, action: detailData?._id, origin: detailData?.type })
  }

  return (
    <>
      {
        loader ?
          <Grid
            data-testid="loader"
            container
            direction={"column"}
            justifyContent={"center"}
            alignItems="center"
            sx={{ p: 10 }}
          >
            <Grid item>
              <GenericLoader />
            </Grid>
          </Grid> :<>
          {
                cardHeader?.length > 0 && cardHeader?.map((h, i) => {
                  return (
                    <DetailRow title={h.label} h={h}>
                      <Typography sx={{ ...textClasses.normalText, fontSize:h?.fontSize || '14px', ml:h?.valueMarginLeft || "10px" ,p:0.3}}>
                        {dataReadFunction(h, detailData || {}) ?
                          h.type === "file" ? (
                            <div onClick={() => handleFile(dataReadFunction(h, detailData || {}))}>
                              <span
                                style={{
                                  ...buttonClasses.viewFile,
                                  cursor: "pointer"
                                }}
                              >
                                View File
                              </span>
                            </div>
                          ) :
                            h.type === "date" ?
                              dateTimeFormater(dataReadFunction(h, detailData || {}), h?.format)
                              :
                              h.type === "showDetail" ?
                                (<div onClick={() => { handleShowDetail(h?.handleType) }}>
                                  <span style={{
                                    ...textClasses.boldText,
                                    color: theme.themeOrange,
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}>View ({dataReadFunction(h, detailData || {})})</span>
                                </div>)
                                :
                                dataReadFunction(h, detailData || {}) : "-"}
                      </Typography>
                    </DetailRow>
                  )
                })
              }</>
         
      }
      <FileModal
        openModal={openModal}
        displayUrl={displayUrl}
        setOpenModal={setOpenModal} />
      {/* {viewModal?.type &&
        <ViewWarehouseFloorModal
          viewModal={viewModal}
          setViewModal={setViewModal}
        />
      } */}
    </>
  )

};

export default GenericDetailRowData;