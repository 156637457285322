import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { getFieldsLayoutForEntry } from '../TemplateInputConfiguration';
import { useDispatch } from 'react-redux';
import { getTemplate} from '../../../redux/actions';
import GenericLoader from '../Generic/GenericLoader';

export const set_level_values = (initial_obj = {}, key, data = "", initial_index = 0) => {
    const keys = key.split(".");

    const setNestedValue = (obj, keys, value, index) => {
        const currentKey = keys[index];
        if (index === keys.length - 1) {
            obj[currentKey] = value;
        }
        else {
            if (!obj[currentKey]) {
                obj[currentKey] = {};
            }
            setNestedValue(obj[currentKey], keys, value, index + 1);
        }
    };

    setNestedValue(initial_obj, keys, data, initial_index);
    return initial_obj;
};

export default function DynamicFormRender({ formHooks, templateId, disabled=[]}) {
    const dispatch = useDispatch();
    const { register,  errors, action,control,setValue,getValues,reset } = formHooks;
    
    const [loader, setLoader] = useState(true)
    const [attributes, setAttributes] = useState([])

    const autoFillOnFocus = "";

    let handleChange = (e, _id) => {
        let split_id = _id.split(".") || []
        if (split_id?.length > 1 && getValues) {
            let values = getValues()
            set_level_values(values, "fff._id", e.target.value, 0)
            reset(values)
        }
        else {
            setValue(_id, e.target.value)
        }
    }
    const layout = getFieldsLayoutForEntry(attributes, register, control, autoFillOnFocus, errors, action,handleChange);

    useEffect(() => {
        if (templateId) {
            setLoader(true)
            dispatch(getTemplate({ template_id: templateId }, (data) => {
                setLoader(false)
                if (data?.attributes?.length > 0) {
                    let tempAttr=data.attributes?.map((dl)=>({...dl,disabled:disabled?.includes(dl?._id)}))
                    setAttributes(tempAttr)
                }
                else {
                    setAttributes([])
                }
            }));
        }
        else {
            setLoader(false)
            setAttributes([])
        }
    }, [templateId])
 

    return (
        <>
            {loader
                ? <Grid data-testid='loader' container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15 }}>
                    <Grid item><GenericLoader /></Grid>
                </Grid>
                :
                <Grid container>
                    <Grid item container spacing={2}>
                        {
                            layout.single.map((field, i) => {
                                return (
                                    <Grid container item xs={6} sm={4} justifyContent={"center"} alignItems="center" key={i}>
                                        {field}
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                    <Grid container spacing={2} sx={{ mt: 0.1 }}>
                        {
                            layout.multi.map((field, i) => {
                                return (
                                    <Grid container item xs={6} sm={4} justifyContent={"center"} alignItems="center" key={i}>
                                        {field}
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Grid>
            }
        </>
    )
}