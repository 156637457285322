import React, { useState, useEffect } from 'react';
import { Button, Box,  } from '@mui/material';
import { DialogActions, Typography, Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  Clear,
  Search,
} from "@mui/icons-material";
import theme, {
  inputClasses,
  textClasses,
} from '../../../static/styles/theme';
export default function AlertDialog({ viewDialog, children, title, subTitle, body, showDivider = false, bodyCSS = null, actionsCSS = { display: "flex", justifyContent: 'flex-end' },handleClose, ...rest }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(viewDialog)
  }, [viewDialog])

  // Escape key handler on pressing esc btn form get closed (i.e. same as cancel btn scenerio)
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        handleClose(); // Close the dialog when Escape is pressed
      }
    };

    window.addEventListener('keyup', handleEsc);

    return () => {
      window.removeEventListener('keyup', handleEsc);
    };
  }, [handleClose]); // Include handleClose as a dependency to avoid stale closures


  return (
    <div>
      <Dialog
        open={open}
        // fullWidth={false}
        // maxWidth={"xs"}
        scroll={"paper"}
        // fullScreen={fullScreen}
        PaperProps={{ sx: { width: rest?.width || "60%", maxHeight: rest?.maxHeight || '90%' } }}
        maxWidth="60%"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        
      >
        {(title || subTitle) && (
          <DialogTitle id="responsive-dialog-title" sx={{ fontWeight: '1000', fontSize: '22px', display: 'flex', justifyContent: 'space-between',
          padding: rest?.viewSearchInput ? '8px 13px !important' : '4px 13px !important' }}>
            <Box>
              {title && <span>{title}</span>}
              {subTitle && (
                <>
                  <br />
                  <span>{subTitle}</span>
                </>
              )}
            </Box>
              {rest?.viewSearchInput && 
              <Box sx={{mr:2}}>
                {rest.searchInputDisplay}
                </Box>
              }
              {rest?.viewCloseIcon && (
                <span className='curser'>
                  <CloseIcon onClick={() => handleClose()} />
                </span>
              )}
          </DialogTitle>
        )}

        <DialogContent dividers={false}>
        {/* <DialogContent dividers={showDivider}> */}
          <DialogContentText id="alert-dialog-description" sx={bodyCSS || { fontSize: '16px', color: '#000', fontWeight: '500', pt: showDivider ? 0 : 2 }}>
            {body}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={actionsCSS} >
            {children}
          {/* <Box >
          </Box> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}