import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { getGridDetail, getUtilizationAnalytics,getGridBehaviourAnalytics } from '../../../../redux/actions';
import { CircularProgress, Grid } from '@mui/material';
import { getGridBlockData } from '../../../../redux/actions';
import { textCapitalize } from '../../../../services/functions';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { TextField, InputLabel, OutlinedInput, FormControl, Radio, RadioGroup, FormControlLabel, FormLabel } from "@mui/material";
import { useForm } from 'react-hook-form';
import GenericLoader from '../../Generic/GenericLoader';
import BinModel from './BinModel';
import CustomizedTooltips from '../../Generic/GenericTooltip';
import theme from '../../../../static/styles/theme';


let blockHeight=70
let blockWidth=70

const styles = {
  dataSpan: {
    color: 'white',
    backgroundColor: 'rgb(231, 94, 46)',
    padding: '4px 10px',
    borderRadius: '13px',
    // textTransform: 'capitalize',

  },
  titleSpan: {
    padding: '4px 0',
  },
  noStyle: {

  }
}

const WarehouseModel = (props) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { cellInfo, onClose, show, colorHanlder, handleAddRack,floorInfo ,isViewEnabled={}} = props
  const [showBinModel, setShowBinModel] = useState(false);
  const [selectedCell, setSelectedCell] = useState({});
  const [open, setOpen] = useState(false);
  const [rackNameObj, setRackNameObj] = useState({});
  const [rackDetails, setRackDetails] = useState([]);
  const [modalLoader, setModalLoader] = useState(false);
  const [analyticsRackDetail, setAnalyticsRackDetail] = useState([]);
  const [utilizationTypeValue, setUtilizationTypeValue] = useState('countPercentageFilled');
  const [heatMapAnalytics, setHeatMapAnalytics] = useState({
    "1727185818240201": {
      "name": "R1",
      "count": 619,
      "movementStatus": "fastMoving"
    },
    "1727185818240101": {
      "name": "R1",
      "count": 484,
      "movementStatus": "slowMoving"
    },
    "deb7d424-9b57-4390-8961-467f3a2a2f9e": {
      "name": "R1",
      "count": 619,
      "movementStatus": "fastMoving"
    },
    "df396e43-2c37-423d-abcb-cdf2892c2f3a": {
      "name": "R1",
      "count": 619,
      "movementStatus": "slowMoving"
    },
    "dec421b2-6409-4cac-830b-66b2b48a2e75": {
      "name": "R1",
      "count": 619,
      "movementStatus": "slowMoving"
    },
    "6ff6f530-b5a1-44fa-a801-9b486dc399d2": {
      "name": "R1",
      "count": 619,
      "movementStatus": "fastMoving"
    }
  });

  const handleUtilizationValueChange = (event) => {
    setUtilizationTypeValue(event.target.value);
  };
  const getGridInventory = (fetchRack = false) => {

    if (!cellInfo.isRackExist && cellInfo?.status == "available") {
      return
    }

    if (cellInfo.isRackExist && fetchRack) {
      setRackDetails([])
      setRackNameObj({})
      setModalLoader(true)
      dispatch(getGridDetail({
        "parentId": cellInfo.cellId, "type": "rack", "fetch_for": "gridView"
      }, ({ data = [] }) => {
        setModalLoader(false)
        setRackDetails(data)
        if (data?.length) {
          let tempObj = {}
          for (let rack of data) {
            tempObj[`${rack.rowIndex || 0}${rack.colIndex || 0}`] = rack
          }
          setRackNameObj(tempObj)
        }
      }))
      dispatch(
        getUtilizationAnalytics({
          floorId: cellInfo.floorId,
          cellId: cellInfo.cellId, type: "rack"
        }, (data) => {
          // if (data && data.length == 1) {
          setAnalyticsRackDetail(data.data)
          // }
        }))

    }
  }


  const [errorMessage, setErrorMessage] = useState({
    row: "",
    column: ""
  })


  const handleLogin = (data) => {
    if (data.row > 1000) {
      return setErrorMessage({ ...errorMessage, row: "No of Row excced from 1000" })
    }
    if (data.row < 1) {
      return setErrorMessage({ ...errorMessage, row: "No of Row can't negative below from 1" })
    }
    if (data.col > 1000) {
      return setErrorMessage({ ...errorMessage, column: "No of Column excced from 1000" })
    }
    if (data.col < 1) {
      return setErrorMessage({ ...errorMessage, row: "No of Column can't negative below from 1" })
    }
    handleAddRack({ ...data, cellId: cellInfo.cellId || "" })
  }


  const handleClose = (e) => {
    setOpen(!open);
    props.onClose(false);
  };

  const calculateCellUtilization = (cellId) => {
    let utilizationInfo = analyticsRackDetail?.find(dl => dl?.cellId === cellId);
    if (!utilizationInfo) {
      return 0
    }
    let percentage = 0
    if (utilizationInfo[utilizationTypeValue] > 0) {
      percentage = utilizationInfo[utilizationTypeValue] || 0
    }

    return percentage

  }


  const getRackInfo = (detail={}) => {
  
    let name = detail.name || ""
    // console.log("floorInfo", floorInfo)
    name = `${name}${floorInfo?.isCapacityDefined == true && utilizationTypeValue ?" : " + calculateCellUtilization(detail.cellId) + '%' : ""}  `;
    return name
  }
  useEffect(() => {

    getGridInventory(true)
    setOpen(props.show);
  }, [props.show, cellInfo.cellId]);

  useEffect(() => {
    if (isViewEnabled.view == "heatMap") {
      let payload = {
        floorId: floorInfo?.floorId || "",
        warehouseId: floorInfo?.warehouseId || "",
        cellId: cellInfo?.cellId || "",
        fromDate: isViewEnabled?.fromDate || "",
        toDate: isViewEnabled?.toDate || "",
        level: "rack",
      }
      dispatch(getGridBehaviourAnalytics(payload, (output) => {
        setHeatMapAnalytics(output?.data || {})
      }))
    }
    else {
      setHeatMapAnalytics({})
    }
  }, [isViewEnabled.view,cellInfo.cellId,isViewEnabled.fromDate, isViewEnabled.toDate,])

  const handleBinModel = (data) => {
    setSelectedCell(data);
    setShowBinModel(true);
  };

  return (
    <>
      <Box
        sx={{
          padding: "8px",
          borderRadius: "8px",
          maxHeight: "100%",
          minHeight: "50%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="body2"
            component="h2"
            sx={{
              marginBottom: "5px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>
              <span className="header">Rack & Shelves Details</span>
              &nbsp;:&nbsp;
              <span className="blockHeader">
                {props.cellInfo.name ? props.cellInfo.name.toUpperCase() : ""}
              </span>
            </span>

          </Typography>
          <CloseIcon sx={{ cursor: "pointer" }} onClick={onClose} />
        </Box>
        <Box >
          {floorInfo?.isCapacityDefined == true && <Grid container sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Grid item sm={3} md={3} xs={12}>
              <Typography variant="body2"  fontWeight="700">Utilization Percentage Type (Filled)</Typography>
            </Grid>

            <Grid item sm={9} md={9} xs={12}>
              <FormControl>
                {/* <FormLabel id="demo-controlled-radio-buttons-group">Utilization Type*</FormLabel> */}
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={utilizationTypeValue}
                  onChange={handleUtilizationValueChange}
                >
                  <FormControlLabel sx={{
                    color: "#000",
                    "& .MuiFormControlLabel-label": {
                      fontSize: "12px",
                    },
                  }} value="countPercentageFilled" control={<Radio size="small"
                    sx={{
                      "&.Mui-checked": {
                        color: "#FF7200",
                      },
                    }} />} label="QTY" />
                  <FormControlLabel sx={{
                    color: "#000",
                    "& .MuiFormControlLabel-label": {
                      fontSize: "12px",
                    },
                  }}
                    value="weightPercentageFilled" control={<Radio size="small"
                      sx={{
                        "&.Mui-checked": {
                          color: "#FF7200",
                        },
                      }} />} label="Weight" />
                  <FormControlLabel sx={{
                    color: "#000",
                    "& .MuiFormControlLabel-label": {
                      fontSize: "12px",
                    },
                  }} value="volumePercentageFilled" control={<Radio size="small"
                    sx={{
                      "&.Mui-checked": {
                        color: "#FF7200",
                      },
                    }} />} label="Dimensions" />
                </RadioGroup>
              </FormControl>

            </Grid>
          </Grid>
          }
        </Box>
        <Box sx={{ maxHeight: "inherit", width: "inherit", overflow: "auto" }}>
        {/* <Box sx={{ maxHeight: "70vh", width: "inherit", overflow: "auto" }}> */}
          {modalLoader ? (
            <Grid
              container
              direction={"column"}
              justifyContent={"center"}
              alignItems="center"
              sx={{ p: 10 }}
            >
              <Grid item>
                <GenericLoader />
              </Grid>
            </Grid>
          ) : (
            <Box>
              {cellInfo.isRackExist ? (
                rackDetails?.length > 0 ? (
                  <Box sx={{ mb: 2 }}>
                    <div
                      className={`grid-section gx-flex-row gx-justify-content gx-align-items-center gx-mt-1`}
                      style={{
                        justifyContent: "space-evenly",
                        margin: "auto",
                      }}
                    >
                      <div className="">
                        <table
                          className="warehouseTable"
                          style={styles.tableStyle}
                        >
                          {Array.from(
                            { length: cellInfo.row },
                            (_, rowIndex) => {
                              return (
                                <tr key={rowIndex}>
                                  <td
                                    style={{
                                      border: "2px solid #FF7200 ",
                                      opacity: ".8",
                                      cursor: "not-allowed",
                                      height: "100%",
                                      width: "80px",
                                      height: "50px",
                                      backgroundColor: "#FF7200",
                                    }}
                                    cellSpacing="0"
                                    cellPadding="0"
                                  >
                                    <div
                                      className="gx-d-flex gx-justify-content-center gx-align-items-center gx-text-black gx-p-1"
                                      style={{
                                        backgroundColor: "#FF7200",
                                        height: "100%",
                                        minWidth: "80px",
                                        width: "100%",
                                        color: "white",
                                      }}
                                    >
                                      <span>S{rowIndex + 1}</span>
                                    </div>
                                  </td>
                                  {Array.from(
                                    { length: cellInfo.col },
                                    (_, colIndex) => {
                                      let gridBlock = rackNameObj[`${rowIndex}${colIndex}`] || {}
                                      let isHeatMapEnabled=isViewEnabled.view=="heatMap"
                                      let isHeatMapValue = heatMapAnalytics && gridBlock?.cellId && heatMapAnalytics[gridBlock?.cellId] || 0
                                      if (isHeatMapValue?.movementStatus) {
                                        
                                        // isHeatMapValue = `${textCapitalize(isHeatMapValue?.movementStatus)} (${isHeatMapValue?.count || 0})`
                                        isHeatMapValue = `${isHeatMapValue?.count || 0}`
                                      }
                                      else {
                                        isHeatMapValue = "0"
                                        // isHeatMapValue = "No Moving(0)"
                                      }
                                      return (
                                        <td
                                          style={{
                                            border: "2px solid #FF7200",
                                            opacity: ".8",
                                            cursor: "pointer",
                                            height: "100%",
                                            // width: "80px",
                                            // height: "50px",
                                            width: blockWidth+"px",
                                            height: blockHeight+"px",
                                          }}
                                          key={colIndex}
                                          cellSpacing="0"
                                          cellPadding="0"
                                        >
                                          <div
                                            className="gx-d-flex gx-justify-content-center gx-align-items-center gx-text-black gx-p-1"
                                            style={{
                                              backgroundColor: colorHanlder(gridBlock, analyticsRackDetail,),
                                              height: "100%",
                                              width: "100%",
                                            }}
                                            onClick={() => handleBinModel(gridBlock, 1, 10)}
                                          >
                                            <CustomizedTooltips
                                              title={isHeatMapValue}
                                              open={isHeatMapEnabled}
                                              toolTipType="Bootstrap"
                                              backgroundColor={theme.themeRoyalBlue}
                                            >
                                              <span>
                                                {" "}
                                                {getRackInfo(gridBlock)}

                                              </span>
                                            </CustomizedTooltips>
                                          </div>
                                        </td>
                                      );
                                    }
                                  )}
                                </tr>
                              );
                            }
                          )}
                        </table>
                          {showBinModel && <BinModel
                            showBinModel={showBinModel}
                            setShowBinModel={setShowBinModel}
                            selectedCell={selectedCell}
                            cellInfo={props.cellInfo}
                          />}
                      </div>
                    </div>
                  </Box>
                ) : (
                  <Box
                    sx={{ mb: 2 }}
                    className="warehouseTable-no_data_found"
                  >
                    <h6>Grid detail not found!</h6>
                  </Box>
                )
              ) : cellInfo.status == "available" &&
                (!cellInfo.subProductType ||
                  cellInfo.subProductType.length == 0) ? (
                <>
                  <Box paddingRight={2}>
                    <FormControl fullWidth sx={{ m: 1 }}>
                      <TextField
                        id="outlined-adornment-Rows"
                        label="No. Of Rows"
                        type="number"
                        size="small"
                        error={errors.row || errorMessage.row ? true : false}
                        helperText={
                          errors.row
                            ? "Enter the rows"
                            : errorMessage.row
                              ? errorMessage.row
                              : ""
                        }
                        {...register("row", {
                          required: true,
                          maxLength: 1000,
                        })}
                        onChange={() => setErrorMessage("")}
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }}>
                      <TextField
                        id="outlined-adornment-Column"
                        label="No. Of Column"
                        type="number"
                        size="small"
                        error={
                          errors.col || errorMessage.column ? true : false
                        }
                        helperText={
                          errors.col
                            ? "Enter the column"
                            : errorMessage.column
                              ? errorMessage.column
                              : ""
                        }
                        onChange={() => setErrorMessage("")}
                        {...register("col", {
                          required: true,
                          maxLength: 1000,
                        })}
                      />
                    </FormControl>
                  </Box>
                  <Box
                    padding={1}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      className="rackCancel-btn"
                      sx={{ width: "50%", marginRight: "5px" }}
                      onClick={() => handleClose()}
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="addRack-btn"
                      sx={{
                        width: "50%",
                        marginLeft: "5px",
                        bgcolor: "warning.main",
                      }}
                      variant="contained"
                      onClick={handleSubmit(handleLogin)}
                    >
                      Add Rack
                    </Button>
                  </Box>
                </>
              ) : (
                ""
              )}

              {/* {cellInfo.status == "available" ? (
                  ""
                ) : loader ? (
                  <Grid
                    container
                    direction={"column"}
                    justifyContent={"center"}
                    alignItems="center"
                    sx={{ p: 10 }}
                  >
                    <Grid item>
                      <GenericLoader />
                    </Grid>
                  </Grid>
                ) : detail?.data?.length > 0 ? (
                  <GenericTable
                    data-testid="grid-table"
                    header={allHeader()}
                    rows={getRows}
                    pageCount={detail.count || 0}
                    pageNumber={filter.page - 1}
                    handleChangePage={(event, pagevalue) => {
                      setFilter((f) => ({
                        ...f,
                        page: pagevalue + 1,
                      }));
                    }}
                    handleChangeRowsPerPage={(event) => {
                      setFilter((f) => ({
                        ...f,
                        limit: +event.target.value,
                      }));
                    }}
                    rowsPerPage={filter.limit}
                  />
                ) : (
                  <div className="warehouseTable-no_data_found">
                    <h6>Data not found!</h6>
                  </div>
                )} */}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}



export default WarehouseModel;