import React from 'react';
import Login from "../../views/Landing/Login"

import history from '../../services/history'
import { useDispatch, useSelector } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material';

import { userLogin } from "../../redux/actions/Auth" 
// import "./../../static/css/toast.css";

import { ToastContainer } from 'react-toastify';

const loginFont = createTheme({
  zIndex: {
    modal: 1400, // Ensure the modal is above the tooltip
    tooltip: 1300,
  },
  typography: {
    fontFamily: [
      'Nunito',
      'serif',
    ].join(','),
},});


export default function App() {
  const dispatch = useDispatch();
  // const token = useSelector(state => state.message.token);

  const _onLogin = (data,callback) => {

    // dispatch(
    //   authActions.loginAction.call({...data, toast: true}, (res) => {
    //     // Temporary for Checking Login
    //     // localStorage.setItem('secretkey', "dummyTokenValue");
    //     // history.push("/dashboard")
    //     ///////////////////////////////
        
    //     if (res) {
    //       localStorage.setItem('secretkey', res.token);
    //       history.push("/dashboard")
    //     }
    //   })
    // )
    dispatch(userLogin(data, () => {
      if (callback) callback()
    }))
    
  }
  
  return (
    <ThemeProvider theme={loginFont}>
        <div>
          {/* <Navbar logo={logo}/> */}
          <Login _onLogin={_onLogin} />
          {/* <Footer/> */}
        </div>
    </ThemeProvider>
    
  )
}
