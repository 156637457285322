import React, { useEffect, useState } from "react";
import {
    Grid,
    Button,
    Typography,
    Dialog,
    DialogContent,
    IconButton,
} from "@mui/material";
import theme, { textClasses, cardClasses, inputClasses, buttonClasses } from "../../../../static/styles/theme";
import { useDispatch, useSelector } from "react-redux";
import {  Cancel } from "@mui/icons-material"
import { deleteSkuGroupRule, disableEnableSkuGroupRule } from "../../../../redux/actions/AutomationRule";
import { showToast, textCapitalize } from "../../../../services/functions";
const ConfirmSkuGroupDialogPopUp = ({
    status,
    handleClose,
    selectedEntries,
    setSelectedEntries,
    refreshTableData,
    handleRemoveSelection,
    selectedTab

}) => {
    const dispatch = useDispatch();
    const [showAll, setShowAll] = useState(false);
    const size = selectedEntries.length;
    const { AllUserData } = useSelector((state) => state.users);
    const [isAPICalled, setIsAPICalled] = useState("");

    const callBack = (data) => {
        showToast(data.message, !data.success);
        setIsAPICalled("")
        if(data.success){
            refreshTableData();
            handleClose();
            if (setSelectedEntries) {
              setSelectedEntries([]);
            }
        }
      };
    
      const handleStatusChange = () => {
        if(status=="delete"){
            setIsAPICalled("apiCalled")
            let payload = {
              ruleId: selectedEntries?.map((e) => e._id)
            };
            dispatch(
                deleteSkuGroupRule(payload, (data) => {
                if (data) {
                  callBack(data);
                }
              })
            );
        }
        else{
            setIsAPICalled("apiCalled")
            let payload = {
              ruleId: selectedEntries?.map((e) => e._id),
              approved:status=="enable"?true:false,
              ruleType:selectedTab
            };
            dispatch(
                disableEnableSkuGroupRule(payload, (data) => {
                if (data) {
                  callBack(data);
                }
              })
            );
        }
      };
    
    return (
        <Dialog
            open={status != null}
            onClose={handleClose}
            scroll={"paper"}
            PaperProps={{ sx: { width: "100%" } }}
            maxWidth={"sm"}
        >
            <DialogContent>
                <Grid container justifyContent={"center"} alignItems="center">
                    <Typography sx={{ ...textClasses.cardTitle }}>Are you Sure ?</Typography>
                </Grid>
                <Grid container justifyContent={"center"} alignItems="center">
                    <Typography sx={{ ...textClasses.cardTitle }}>You want to {status} the selected rule(s)</Typography>
                </Grid>
                <Grid container sx={{ mt: 1 }} spacing={1}>
                    {selectedEntries.slice(0, showAll ? size : 5).map((st, i) => {
                        return (
                            <Grid item sm={4} xs={6} key={i}>
                                <Grid
                                    container
                                    direction={"row"}
                                    justifyContent="center"
                                    alignItems={"center"}
                                    sx={{
                                        backgroundColor: theme.themeLightOrange,
                                        border: `1px solid ${theme.themeOrange}`,
                                        borderRadius: "12px",
                                        pl: 1,
                                        pr: 1,
                                        minHeight: "40px",
                                    }}
                                >
                                    <Grid item sm={10} xs={8}>
                                        <Typography
                                            sx={{
                                                ...textClasses.normalText,
                                                color: theme.themeOrange,
                                                textAlign: "start",
                                            }}
                                        >
                                            {
                                                st?.ruleName && st?.ruleName?.length > 15
                                                    ? st?.ruleName?.substring(0, 15) + "..."
                                                    : st?.ruleName
                                            }
                                        </Typography>
                                    </Grid>
                                    {handleRemoveSelection && (
                                        <Grid item sm={2} xs={4}>
                                            <IconButton
                                                sx={{ alignContent: "right" }}
                                                onClick={() => handleRemoveSelection(st._id)}
                                            >
                                                <Cancel sx={{ color: theme.themeOrange }} />
                                            </IconButton>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        );
                    })}
                    {size > 5 && (
                        <>
                            <Grid
                                container
                                item
                                sm={4}
                                xs={6}
                                justifyContent="center"
                                alignItems={"center"}
                            >
                                <Typography
                                    sx={{
                                        ...textClasses.boldText,
                                        color: theme.themeOrange,
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => setShowAll(!showAll)}
                                >
                                    {showAll ? <>Collapse</> : <>View All ({size})</>}
                                </Typography>
                            </Grid>
                        </>
                    )}
                </Grid>
                <Grid container sx={{ p: 1, mt: 3 }}>
                    <Button
                        variant="outlined"
                        size="small"
                        sx={{
                            ...buttonClasses.lynkitBlackFill,
                            height: "40px",
                            minWidth: "150px",
                        }}
                        disabled={isAPICalled=="apiCalled"}
                        onClick={handleClose}
                    >
                        No, Cancel
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        sx={{
                            ml: 1,
                            ...buttonClasses.small,
                            backgroundColor: theme.themeOrange,
                            minWidth: "150px",
                        }}
                        disabled={selectedEntries.length <= 0 || isAPICalled=="apiCalled"}
                        onClick={handleStatusChange}
                    >
                        Yes, {textCapitalize(status)}
                    </Button>
                </Grid>
            </DialogContent>
        </Dialog>
    );

};

export default ConfirmSkuGroupDialogPopUp;

