import React, { useEffect, useState,useRef} from 'react'
import {
    TextField, MenuItem,Autocomplete,
    FormControl,
    Tooltip
} from '@mui/material'
import { inputClasses} from '../../../static/styles/theme'
import CheckIcon from "@mui/icons-material/Check";
import { customAPI_For_APIPara } from "../../../services/formFunction"
import { debounce } from '@mui/material/utils';
import { selectStylesOverride } from '../../../utils/util';
import { Add } from '@mui/icons-material';
import theme from '../../../static/styles/theme';
import AddReason from '../AddModel/AddReason';

const TypeAndSearch = ({
    component,label, value,options=[], handleChange,setEmptyOnChange=false,resetOption=true,
     disabled,set_Value=false,addNewOptions={},reasonType=""
    }) => {

    // console.log("TypeAnsSearch",{value,set_Value,_id:component?._id},)
        //  addNewOptions ={isEnabled:false,label:""}
    const [selectOptions, setSelectOptions] = useState([]);
    const [initial, setInitial] = useState(true);
    const [inputValue, setInputValue] = useState('');
    const [searching, setSearching] = useState("");
    const [openReasonModal,setOpenReasonModel]=useState(false);
    const [enableFetch, setEnableFetch] = useState(false);

    const usePrevious = (value="") => {
        const ref = useRef();

        useEffect(() => {
            ref.current = value;
        }, [value]);

        return ref.current;
    };

    const prevInput = usePrevious(inputValue);
    const prevDataRef = useRef(); // Store previous state for comparison

    // Helper function to compare specific key values in array of objects
    const hasDataChanged = (prevArray, newArray, key) => {
        if (!prevArray || prevArray.length !== newArray.length) return true; // Detect new elements or length changes
        for (let i = 0; i < newArray.length; i++) {
            if (prevArray[i][key] !== newArray[i][key]) {
                return true; // Detect change in key value
            }
        }
        return false;
    };

    const handleOpenModal = ()=>{
        setOpenReasonModel(true)
    }


    const fetch = React.useMemo(
        () =>
            debounce(async(component, value, callback) => {
                setSearching("Searching...")
                await customAPI_For_APIPara(component, value, callback)
            }, 400),
        [],
    );


    useEffect(async () => {
        // console.log(value,"value",component)
        let active = true;
        if (initial) {
            // console.log(component,"component")
            if (component?.dynamic) {
                let temp_option = []
                if ((label && value) || options?.length > 0) {
                    // console.log(label,"temp_option1")
                    temp_option = options?.length > 0 && options || [{ label, value }];
                    if (addNewOptions.isEnabled == true) {
                        temp_option.unshift({ label: addNewOptions.label, value: 'addNew' })
                    }

                    setSelectOptions(temp_option)
                    setInitial(false)
                    setSearching("")
                }
                else if (!component.dontFetch) {
                    // console.log(label,"temp_option2")
                    fetch(component, label || value, (results) => {
                        // console.log(results,"results",active)
                        if (active && results) {
                            temp_option = [...results];
                        }
                        if (addNewOptions.isEnabled == true) {
                            temp_option.unshift({ label: addNewOptions.label, value: 'addNew' })
                        }
                        setSelectOptions(temp_option)
                        setSearching("")
                    });
                    setInitial(false)
                }
                else {
                    // console.log(label,"temp_option2")
                    if (addNewOptions.isEnabled == true) {
                        temp_option.unshift({ label: addNewOptions.label, value: 'addNew' })
                    }
                    setSelectOptions(temp_option)
                    setInitial(false)
                    setSearching("")
                }
            }
            else {
                setInitial(false)
            }
        }
        else {
            if ((label && value) || options?.length > 0) {
                // console.log(label,"temp_option1")
                let temp_option = options?.length > 0 && options || [{ label, value }];
                if (addNewOptions.isEnabled == true) {
                    temp_option.unshift({ label: addNewOptions.label, value: 'addNew' })
                }

                setSelectOptions(temp_option)
                setInitial(false)
                setSearching("")
            }
        }
        return () => {
            active = false;
        };
    }, [value, label]);


    useEffect(() => {
        let active = true;
        // console.log("nnnn",2,initial,component?.dynamic,enableFetch)
        if (!initial && component?.dynamic && enableFetch) {
            // console.log("nnnn",3,inputValue)
            if (!inputValue) {

                let temp_option = []
                if (addNewOptions.isEnabled == true) {
                    temp_option.unshift({ label: addNewOptions.label, value: 'addNew' })
                }
                // console.log("nnnn",4,component.dontFetch)
                if(!component.dontFetch){
                    fetch(component, "", (results) => {
                        setSearching("")
                        if (active && results) {
                            temp_option = [...temp_option, ...results];
                        }
                        setSelectOptions(temp_option); 
                        setEnableFetch(false)
                    });
                }
                else{
                    setSelectOptions([...temp_option,...selectOptions])
                    setSearching("")
                    setEnableFetch(false)
                }
            }
            else if(prevInput!==inputValue){
                fetch(component, inputValue, (results) => {
                    setSearching("")
                    let newOptions = [];
                    if (active && results) {
                        newOptions = [...newOptions, ...results];
                    }
                    if (addNewOptions.isEnabled == true) {
                        newOptions.unshift({ label: addNewOptions.label, value: 'addNew' })
                    }
                    setSelectOptions(newOptions); 
                    setEnableFetch(false)
                });
            }
            else{
                setEnableFetch(false)
            }
        }

        return () => {
            active = false;
        };
    }, [inputValue]);

    // useEffect(() => {
    //     if(component?.apiParameters?.length>0){
    //         // console.log("nnnn",1)
    //         fetchNewData()
    //     }
    // }, [component?.apiParameters]);

    // useEffect(() => {
    //     if (component?.apiParameters?.length > 0 && !searching && !initial) {
    //         // Detect change in the "value" key of each object in the data array
    //         if (hasDataChanged(prevDataRef.current, component?.apiParameters, 'value')) {
    //             // Process the changes only if the "value" key has changed
    //             // console.log('Array has changed', component?.apiParameters);
    //             // Your effect logic here
    //             fetchNewData()
    //         }
    //         // Update the ref to hold the current data for the next comparison
    //         prevDataRef.current = component?.apiParameters || [];
    //     }
    // }, [component?.apiParameters]); // Only run the effect when the array changes


    const fetchNewData = (tempSearch=null) => {
        let temp_option = []
        if (addNewOptions.isEnabled == true) {
            temp_option.unshift({ label: addNewOptions.label, value: 'addNew' })
        }
        fetch(component, tempSearch || inputValue, (results) => {
            setSearching("")
            if (results) temp_option = [...temp_option, ...results];
            setSelectOptions(temp_option);
        });
    }

    
    return (
        <FormControl fullWidth={true} size={"small"}>

            <Autocomplete
                id={component?._id || "hhhhh"}
                disableClearable={!inputValue}
                sx={{ ...inputClasses.themeBoundary }}
                filterOptions={(x) => x}
                options={selectOptions}
                disabled={disabled}
                // {...(set_Value && (selectOptions?.length>0)? {
                //     value: value && selectOptions.find(dl => dl.value == value)?.label || ""
                // } : {})}
                value={label || (value && selectOptions.find(dl => dl.value == value)?.label) || ""}
                autoComplete
                size="small"
                includeInputInList
                filterSelectedOptions
                onFocus={() => {
                    if ((!label && !value)) {
                        setSearching("wait....")
                        setSelectOptions([])
                        fetchNewData(label || "")
                    }
                }}
                noOptionsText={searching
                    ?searching
                    :inputValue && "No Data Found" || "Type Here..."
                }
                onChange={(event, newValue) => {
                    handleChange({ target: { value: newValue?.value || "" } },newValue)
                    setEnableFetch(!newValue)
                    // setEnableFetch((!newValue && newValue!= prevInput) || false)
                    if(setEmptyOnChange){
                        setInputValue("")
                        // setSelectOptions(addNewOptions.isEnabled==true ? [{ label: addNewOptions.label, value: 'addNew' }]:[]);
                    }
                    else{
                        // setSelectOptions(newValue? [newValue] : addNewOptions.isEnabled==true ? [{ label: addNewOptions.label, value: 'addNew' }]:[]);
                    }
                    // if(!newValue || !newValue.value){
                    //     setSelectOptions([])
                    // }
                    // console.log(enableFetch,"initial1")
                    // setEnableFetch(false)
                }}
                onInputChange={(event, newInputValue,reason) => {
                    setInputValue(newInputValue);
                    // setInputValue(reason=="clear" ? "" :newInputValue);
                    if (["input","clear"].includes(reason)) {
                        setSelectOptions([])
                    }
                    
                    setSearching(newInputValue ? "Typing..." : "")
                    // setEnableFetch(true)
                    // console.log(newInputValue,prevInput,"nnnn2",reason)
                    setEnableFetch(newInputValue != prevInput || false)
                    // setEnableFetch(reason=="clear" ? true :newInputValue != prevInput || false)
                    // setEnableFetch(reason=="clear"  || newInputValue != prevInput || false)
                    // console.log(enableFetch,"initial2",newInputValue,reason)
                }}
                renderInput={(params) => (
                    <TextField {...params}
                        label={`${component?.placeholder || component.label}${component.required ? "*" : ""}`}
                        fullWidth
                        sx={{
                            ...selectStylesOverride,
                        }}
                    />
                )}
                renderOption={(props, option, { selected }) => {
                    return (
                        <>
                            {
                                option?.value == "addNew" ? <MenuItem
                                    sx={{ color: theme.themeOrange }}
                                    onClick={handleOpenModal}
                                >
                                    <Add fontSize='inherit' /> {option.label}
                                </MenuItem>
                                    :
                                    // <Tooltip title={option.label}>
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}
                                            sx={{ justifyContent: "space-between" }}
                                            {...props}
                                        >
                                            {option.label}
                                            {selected ? <CheckIcon color="info" /> : null}
                                        </MenuItem>
                                    // </Tooltip>
                            }
                        </>
                    )
                }}
                inputValue={inputValue}
            />
                {openReasonModal && <AddReason open={openReasonModal} setOpenReasonModel={setOpenReasonModel} type={reasonType || ""} fetchNewData={fetchNewData}/>}

        </FormControl>
    )
}

export default TypeAndSearch;
