import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    SHOW_MESSAGE,
    INIT_URL,
  } from "../types";
import axios from "../../services/Api";
import { encryptData } from "../../services/Api"; 
import history from "../../services/history";
import { showToast } from "../../services/functions";
  
  
export const setInitUrl = (url) => {
    return {
        type: INIT_URL,
        payload: url
    };
};


export const dummyAction = (url) => {
    return {
        type: "DUMMY",
        payload: "shavika"
    };
};

export const userLogin = (payload,callback) =>
{
    return (dispatch) =>
    {
      dispatch({type: FETCH_START});
      axios.post('users/login',  {
        ...payload
    }
      ).then(({data}) => {
        if (data.success) 
        {
          afterLoginProcess();
        } 
        else 
        {
          dispatch({type: FETCH_ERROR, payload: data.message});
          showToast(data.message,true)
        }
  
        function afterLoginProcess(){
          localStorage.setItem("token", JSON.stringify(data.token));
          localStorage.setItem("user", JSON.stringify(data.user));
          axios.defaults.headers.common['authorization'] = data.token;
          dispatch({ type: FETCH_SUCCESS });
          history.push(data.initUrl || "/dashboard");
        }
        if(callback) callback()
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        //("Error****:", error.message);
        showToast(error.message,true)
        if(callback) callback()
      });
    }
};
 