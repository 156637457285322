import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { FormControl, Grid, InputAdornment, OutlinedInput, Button, TextField, Select, MenuItem, FormLabel, InputLabel, Typography, Tab, Tabs, Box, Checkbox, Dialog, DialogContent, Chip, Paper, IconButton, Menu, Tooltip, Autocomplete } from '@mui/material'
// import { makeStyles } from '@mui/system'
import { DataGrid } from '@mui/x-data-grid'
import EmptyPage from './EmptyPage';
import { Add, ArrowDownward, Cancel, Clear, Delete, ExpandMore, KeyboardArrowDown, Search, WrongLocation } from '@mui/icons-material';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { changeTemplateStatus, getAllTemplates, getAllUsers, getStatusCount, removeTemplates, getTemplateTypes } from '../../../redux/actions';
import theme, { buttonClasses, inputClasses, textClasses } from '../../../static/styles/theme';
import { Link } from 'react-router-dom';
// import TemplatesTable from "./TemplatesTable"
import TemplatesTable from "./TemplateTable"
import GenericTable from '../../Components/CustomTable/GenericTable';

import { templateTypes, statusTypes, getTemplateTypeName } from '../../../services/constants';
import { getCamelCaseText, getShortText } from '../../../services/functions';
import { useForm } from 'react-hook-form';
import history from '../../../services/history';
import { DatePicker } from '@mui/x-date-pickers';
import { FETCH_TEMPLATES } from '../../../redux/types';
import GenericLoader from '../../Components/Generic/GenericLoader';

const ConfirmDialog = ({ status, handleClose, selectedTemplates, setSelectedTemplates, refreshTableData, handleRemoveSelection }) => {
    const dispatch = useDispatch();
    const [showAll, setShowAll] = useState(false);
    const size = selectedTemplates.length;
    ///

    const isDraft = status && selectedTemplates.length > 0 && status == "Publish" && selectedTemplates[0].status == "drafted";
    const callBack = (data) => {
        refreshTableData();
        handleClose();
        if (setSelectedTemplates) {
            setSelectedTemplates([]);
        }
    }
    const handleStatusChange = () => {

        let payload = {
            template_id: selectedTemplates.length == 1 ? selectedTemplates[0]._id : selectedTemplates.map(st => st._id)
        }
        if (status == "Publish") {
            payload["status"] = "published"
            dispatch(changeTemplateStatus(payload, callBack))
        }
        else if (status == "Disable") {
            payload["status"] = "disabled";
            dispatch(changeTemplateStatus(payload, callBack))
        }
        else if (status == "Delete") {
            dispatch(removeTemplates(payload, callBack))
        }

    }
    return (
        <Dialog
            open={status != null}
            onClose={handleClose}
            scroll={"paper"}
            PaperProps={{ sx: { width: "100%" } }}
            maxWidth={"sm"}
        >
            <DialogContent>
                <Grid container justifyContent={"center"} alignItems="center">
                    <Typography sx={{ ...textClasses.cardTitle }}>Are you Sure ?</Typography>
                </Grid>
                <Grid container justifyContent={"center"} alignItems="center">
                    <Typography sx={{ ...textClasses.cardTitle }}>{status} the selected templates</Typography>
                </Grid>
                <Grid container sx={{ mt: 1 }} spacing={1}>
                    {
                        selectedTemplates.slice(0, showAll ? size : 5).map((st, i) => {
                            return (
                                <Grid item sm={4} xs={6} key={i}>
                                    <Grid container direction={"row"} justifyContent="center" alignItems={"center"} sx={{ backgroundColor: theme.themeLightOrange, border: `1px solid ${theme.themeOrange}`, borderRadius: "12px", pl: 1, pr: 1, minHeight: "40px" }}>
                                        <Grid item sm={10} xs={8}>
                                            <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, textAlign: "start" }}>
                                                {(st.name && st.name.length > 15) ? st.name.substring(0, 15) + "..." : st.name}
                                            </Typography>

                                        </Grid>
                                        {
                                            handleRemoveSelection &&
                                            <Grid item sm={2} xs={4}>
                                                <IconButton sx={{ alignContent: "right" }} onClick={() => handleRemoveSelection(st._id)}>
                                                    <Cancel sx={{ color: theme.themeOrange }} />
                                                </IconButton>
                                            </Grid>
                                        }

                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                    {
                        size > 5 &&
                        <>
                            <Grid container item sm={4} xs={6} justifyContent="center" alignItems={"center"}>
                                <Typography sx={{ ...textClasses.boldText, color: theme.themeOrange, textDecoration: "underline", "cursor": "pointer" }} onClick={() => setShowAll(!showAll)}>
                                    {
                                        showAll
                                            ?
                                            <>Collapse</>
                                            :
                                            <>View All ({size})</>
                                    }
                                </Typography>
                            </Grid>

                        </>
                    }
                </Grid>
                <Grid container sx={{ p: 1, mt: 3 }} justifyContent="center">
                    <Button variant='outlined' size='small' sx={{ ...buttonClasses.outlined, borderColor: theme.themeOrange, color: theme.themeOrange, minWidth: "150px" }} onClick={handleClose}>
                        No, Cancel
                    </Button>
                    {
                        isDraft && 
                        <Button variant='outlined' size='small' sx={{ ml: 1, ...buttonClasses.outlined, borderColor: theme.themeOrange, color: theme.themeOrange, minWidth: "150px" }} onClick={() => history.push(`/dashboard/templates/edit/${selectedTemplates[0]._id}`)}>
                            Edit & Publish
                        </Button>
                    }
                    
                    <Button variant='contained' size='small' sx={{ ml: 1, ...buttonClasses.small, backgroundColor: theme.themeOrange, minWidth: "150px" }} disabled={selectedTemplates.length <= 0} onClick={handleStatusChange}>
                        Yes, {status}
                    </Button>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const getSpentTime = (then, now) => {
    if (then != null) {
        then = moment(then);
        now = now ? moment(now) : moment();

        let dayDiff = now.diff(then, "days");
        let hourDiff = now.diff(then, "hours");
        let minDiff = now.diff(then, "minutes");
        let timeSpent;

        if (dayDiff < 1) {
            if (hourDiff < 1) {
                timeSpent = `${minDiff} min ago`
            }
            else {
                timeSpent = `${hourDiff} hrs ago`
            }
        }
        else {
            timeSpent = `${dayDiff} d ago`
        }
        return timeSpent
    }
    else {
        return "-"
    }
}

export default function Home() {

    const { register, handleSubmit } = useForm();
    const dispatch = useDispatch();
    const templates = useSelector(state => state.templates.allTemplates)
    const totalRecords = useSelector(state => state.templates.totalRows)
    const statusCount = useSelector(state => state.templates.statusCount);
    const { AllUserData } = useSelector((state) => state.users);
    const allTemplateTypes = useSelector(state => state.templates.templateTypes);
    const [tabIndex, setTabIndex] = useState(0);

    // ////("All Template Types", allTemplateTypes.map(t => t.allTemplateTypes).flat(1));

    const [templateFilter, setTemplateFilter] = useState(null);
    ////("Template filter", templateFilter);
    const [statusFilter, setStatusFilter] = useState(null)
    const [nameFilter, setNameFilter] = useState("")
    const [createdFilter, setCreatedFilter] = useState(null);
    const [dateFilter, setDateFilter] = useState(null);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [loader, setLoader] = useState(false);

    const isFilterApplied = (() => {
        if(tabIndex == 0 && statusFilter != null){
            return true;
        }
        if(nameFilter != "" || templateFilter != null || createdFilter != null || dateFilter != null){
            return true;
        }
        return false;
    })();

    const [anchorEl, setAnchorEl] = useState(null);
    const [actionIndex, setActionIndex] = useState(null);
    const openAction = Boolean(anchorEl);
    const { userPermissions } = useSelector((state) => state.userPermission);
    let permissions = userPermissions || {};

    const handleOpenAction = (event, i) => {
        setAnchorEl(event.currentTarget);
        setActionIndex(i);

    }
    const handleCloseAction = () => {
        setActionConfirmModal(null);
        setActionIndex(null);
        setTimeout(() => {
            setAnchorEl(null);
        }, 1);

    }

    const [selectedTemplates, setSelectedTemplates] = useState([]);
    const [confirmModal, setConfirmModal] = useState(null);
    const [actionConfirmModal, setActionConfirmModal] = useState(null);
    ////("permisionss")
    ////(permissions)

    const isSelectionPublishable = (() => {
        let flag = true;
        if(selectedTemplates.filter(st => ["published", "drafted"].indexOf(st.status) >= 0).length > 0){
            flag = false;
        }
        if(selectedTemplates.length == 1 && selectedTemplates[0].status == "drafted"){
            flag = true;
        }
        return flag;
    })();

    const isSelectionDisable = (() => {
        let flag = true;
        if (selectedTemplates.filter(st => (st.status == "drafted" || st.status == "disabled")).length > 0) {
            flag = false;
        }
        return flag;
    })();

    const handleTabChange = (event, value) => {
        switch (value) {
            case 0: setStatusFilter(null); break;
            case 1: setStatusFilter("published"); break;
            case 2: setStatusFilter("drafted"); break;
            case 3: setStatusFilter("disabled"); break;
            default: setStatusFilter(null)
        }
        setPage(0);
        setTabIndex(value)
    }

    const handleCheckAll = () => {
        if (selectedTemplates.length < templates.length) {
            let sT = templates.map(t => ({ _id: t._id, status: t.status, name: t.name }))
            setSelectedTemplates(sT);
        }
        else {
            setSelectedTemplates([]);
        }
    }

    //"Action"
    const tableHeader = [<Checkbox data-testid="checkbox" checked={templates.length != 0 && selectedTemplates.length == templates.length} onChange={handleCheckAll} size='small' />,"S. No", "Template Name", "Template Type", "Description", "Fields", "Current Status", "Usage Frequency", "Last Used", "Created By", "Created On", "Action"]
    const tableRows = templates.map((row, i) => {
        let items = [];
        items.push(
            <Checkbox
                size="small"
                checked={selectedTemplates.map(o => o._id).indexOf(row._id) >= 0}
                onChange={(e) => handleCheckChange(e, row._id, row?.status, row?.name)}
            />
        );
        items.push(
            <Typography sx={{ ...textClasses.normalText, textAlign: "center" }}>
            {page == 0 ? i+1 : (page*limit)+(i+1)}
          </Typography>
        )
        items.push(
            <Tooltip title={row?.name} placement="top">
                <Link to={`/dashboard/templates/view/${row._id}`} style={{ ...textClasses.normalText, textDecoration: "none", color: theme.themeOrange }}>
                    {getShortText(row?.name, 18)}
                    {/* {row.name} */}
                </Link>
            </Tooltip>
        );
        items.push(
            getTemplateTypeName(row?.templateType)
        );
        items.push(
            <Tooltip title={row.description} placement="top">
                <Typography sx={{ ...textClasses.normalText, textAlign: "left" }}>
                    {
                        row?.description
                            ?
                            getShortText(row?.description, 18)
                            :
                            "-"
                    }
                </Typography>
            </Tooltip>
        );
        items.push(
            <Typography sx={{ ...textClasses.normalText, textAlign: "center" }}>
                {
                    row?.totalElement || 0
                }
            </Typography>
        );
        const statusColor = {
            published: "#3E621E",
            drafted: "#FF7200",
            disabled: "#E9001A"
        }
        items.push(
            <Typography sx={{ ...textClasses.normalText, textAlign: "center", color: statusColor[row.status],textTransform:"capitalize" }}>{row.status}</Typography>
        );
        items.push(

            <Typography sx={{ ...textClasses.normalText, textAlign: "left" }}>
                {row?.frequency && row?.frequency > 0 ? `${row.frequency} times` : 'Never Used'}
            </Typography>
        );
        // let now = moment();
        // let then = moment(row.updatedAt);
        // let diff = now.diff(then, 'days');

        items.push(
            <Typography sx={{ ...textClasses.normalText, textAlign: "left", textTransform:"none" }}>
                {
                    row?.lastUsedTime ? getSpentTime(moment(row.lastUsedTime), moment()) : "Never Used"
                }
            </Typography>
        );

        items.push(
            <Typography sx={{ ...textClasses.normalText, textAlign: "center" }}>
                {row?.createdBy?.name || "-"}, {row?.createdBy?.designation || "-"}
            </Typography>
        );
        items.push(
            <Typography sx={{...textClasses.normalText, textAlign: "center"}}>
                {/* {moment(row.createdAt).format("-yyyy hh:mm:ss")} */}
                {moment(row?.createdAt).format("DD MMM YYYY hh:mm A")}
            </Typography>
        );
        // actions
        items.push(
            <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, cursor: "pointer" }} onClick={(e) => handleOpenAction(e, i)}>Action <KeyboardArrowDown /></Typography>
        )
        return items;

    })

    const handleCheckChange = (event, templateId, status, name) => {
        ////("Clicking value " + event.target.checked);
        if (event.target.checked) {
            handleAddSelection(templateId, status, name);
        }
        else {
            handleRemoveSelection(templateId);
        }
    }


    const handleFilterChange = (data) => {
        ////(data);
    }

    const handleAddSelection = (templateId, status, name) => {
        let obj = {
            _id: templateId,
            status: status,
            name: name
        }
        setSelectedTemplates([...selectedTemplates, obj]);
    }

    const handleRemoveSelection = (templateId) => {
        let oldTemplates = [...selectedTemplates];
        const i = oldTemplates.map(o => o._id).indexOf(templateId);
        if (i >= 0) {
            oldTemplates.splice(i, 1);
            setSelectedTemplates(oldTemplates);
            if(oldTemplates.length == 0){
                handleCloseConfirmModal();
            }
        }

    }

    const handleCloseConfirmModal = () => {
        setConfirmModal(null);
    }

    const handleClearFilter = () => {
        setTemplateFilter(null)
        
        setNameFilter("")
        setCreatedFilter(null);
        setDateFilter(null);
        setPage(0);
        setLimit(10);
        if(tabIndex == 0){
            setStatusFilter(null)
        }
    }

    useEffect(() => {
        
        dispatch(
            getTemplateTypes()
        )
    }, [])

    const refreshTableData = (pageNo, limitNo) => {
        setLoader(true);
        const payload = {
            type: "wms",
            page: pageNo,
            limit: limitNo,
            templateName: nameFilter.length >= 3 ? nameFilter : "",
            templateType: templateFilter ? templateFilter.templateTypekey : "",
            status: statusFilter ? statusFilter.toLowerCase() : "",
        }
        if (createdFilter && createdFilter != "" && createdFilter != null) {
            payload["createdBy"] = createdFilter._id
        }
        if (dateFilter && dateFilter != null) {
            payload["createdOn"] = dateFilter;
        }
        // ////(payload);
        dispatch(getAllUsers({ "isAdminIncluded": true }, ({data: allUsers}) => {
            if(allUsers){
                dispatch(
                    getAllTemplates(payload, (data, totalCount) => {
                        setLoader(false);
                        if(data && data.length > 0){
                            
                            let newData = data.map(r => {
                                let user = allUsers.filter(u => u._id == r.createdBy._id);
                                ////("User found", user);
                                if(user.length > 0){
                                    r.createdBy.designation = user[0].designation;
                                }
                                return r;
                            })
                            
                            dispatch({type: FETCH_TEMPLATES, payload: {row: newData, count: totalCount}})
                        }
                        
                    })
                )
                delete payload.status;
                dispatch(getStatusCount(payload));
            }
        }));
        
    }
    ///
    

      ///

    useEffect(() => {
        refreshTableData(page + 1, limit);
        setSelectedTemplates([]);
        setConfirmModal(null);
        setActionConfirmModal(null);
        setAnchorEl(null);
        setActionIndex(null);

    }, [templateFilter, statusFilter, createdFilter, dateFilter, page, limit])

    useEffect(() => {
        if(nameFilter.length >= 3 || nameFilter.length == 0){
            refreshTableData(page + 1, limit);
            setSelectedTemplates([]);
            setConfirmModal(null);
            setActionConfirmModal(null);
            setAnchorEl(null);
            setActionIndex(null);
        }
    }, [nameFilter])

    return (
        <Box>
            {
                // !loader && templates.length == 0
                // ?
                //     <EmptyPage/>
                // :
                <Box>
                    <Grid container>
                        <Grid item sm={6}>
                            <Typography sx={{ ...textClasses.cardTitle }}>Manage Templates</Typography>
                        </Grid>
                        <Grid container item sm={6} justifyContent="end" alignItems="end">
                        <Button data-testid="createNew" variant='outlined' size='small' startIcon={<Add />} sx={{ ...buttonClasses.outlined }} onClick={() => history.push("/dashboard/templates/create")}>
                                    {/* <Link to={"/dashboard/templates/create"} style={{textDecoration: "none", color: theme.themeOrange}}>
                                    
                                </Link> */}

                                    Create New
                                </Button>
                        </Grid>
                    </Grid>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 1 }}>
                        <Tabs value={tabIndex} aria-label="disabled tabs example" onChange={handleTabChange} sx={{ '& .MuiTabs-indicator': { backgroundColor: theme.themeOrange }, "& .MuiTab-root.Mui-selected": { color: theme.themeOrange } }}>
                            <Tab data-testid= "All Templates" label={`All Templates (${statusCount ? statusCount.total : 0})`} {...a11yProps(0)} sx={{ ...textClasses.normalText, textTransform: "none" }} />
                            <Tab data-testid = "Published" label={`Published (${statusCount ? statusCount.published: 0})`} {...a11yProps(1)} sx={{ ...textClasses.normalText, textTransform: "none" }} />
                            <Tab data-testid="Drafted" label={`Drafted (${statusCount ? statusCount.drafted: 0})`} {...a11yProps(1)} sx={{ ...textClasses.normalText, textTransform: "none" }} />
                            <Tab data-testid="Disabled" label={`Disabled (${statusCount ? statusCount.disabled: 0})`} {...a11yProps(1)} sx={{ ...textClasses.normalText, textTransform: "none" }} />
                        </Tabs>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={4} sm={2}>
                            <FormControl variant='outlined' size='small' fullWidth>
                                <OutlinedInput
                                    data-testid="search-input"
                                    value={nameFilter}
                                    onChange={(e) => { setNameFilter(e.target.value); setPage(0); setLimit(10); }}
                                    placeholder='Search by Template name'
                                    sx={{ ...inputClasses.filterField, "& input::placeholder": { fontSize: "14px" } ,"& .MuiSelect-iconOutlined": {display: nameFilter != "" ? 'none': ''}}}
                                    endAdornment={
                                        nameFilter == ""
                                        ?
                                            <InputAdornment position='end'><Search fontSize='small' /></InputAdornment>
                                        :
                                            <IconButton sx={{visibility: nameFilter != "" ? "visible": "hidden", fontSize: "14px"}} onClick={() => {setNameFilter(""); setPage(0); setLimit(10);}}><Clear fontSize='inherit'/></IconButton>
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <Autocomplete
                                data-testid="type"
                                value={templateFilter}
                                onChange={(e, option) => {
                                    setTemplateFilter(option);
                                    setPage(0);
                                    setLimit(10);
                                }}
                                getOptionLabel={(option) => option.templateTypeLabel}
                                size='small'
                                fullWidth
                                options={allTemplateTypes.map(t => t.allTemplateTypes).flat(1)}
                                sx={{...inputClasses.filterField, ".MuiInputBase-input": {
                                    fontSize: "15px",
                                    color: "#455A64"
                                }}}
                                renderInput={(params) => <TextField sx={{color: "#455A64", ...textClasses.normalText}} {...params} label="Template Type" />}
                            />
                        </Grid>
                        {
                            tabIndex == 0 &&
                            <Grid item xs={4} sm={2}>
                                {/* <FormControl variant='outlined' size='small' fullWidth>
                                    <Select value={statusFilter} onChange={(e) => { setStatusFilter(e.target.value); setPage(0); setLimit(10); }} size='small' placeholder='Current status'
                                        sx={{ ...inputClasses.filterField, "& .MuiSelect-iconOutlined": {display: statusFilter != "all" ? 'none': ''}}}
                                        endAdornment={<IconButton sx={{visibility: statusFilter != "all" ? "visible": "hidden", fontSize: "14px"}} onClick={() => {setStatusFilter("all"); setPage(0); setLimit(10);}}><Clear fontSize='inherit'/></IconButton>}
                                    >
                                        <MenuItem value="all" sx={{ color: "#455A64", ...textClasses.normalText }}>Status</MenuItem>
                                        {
                                            statusTypes.map((t, i) => <MenuItem value={t} sx={{ color: "#455A64", ...textClasses.normalText, textTransform: "capitalize" }}>{t}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl> */}
                                <Autocomplete
                                    data-testid = "status"
                                    value={statusFilter}
                                    onChange={(e, option) => {
                                        setStatusFilter(option);
                                        setPage(0);
                                        setLimit(10);
                                    }}
                                    // getOptionLabel={(option) => option.label}
                                    size='small'
                                    fullWidth
                                    options={statusTypes}
                                    sx={{...inputClasses.filterField, ".MuiInputBase-input": {
                                        fontSize: "15px",
                                        color: "#455A64"
                                    }}}
                                    renderInput={(params) => <TextField sx={{color: "#455A64", ...textClasses.normalText}} {...params} label="Status" />}
                                />
                            </Grid>
                        }
                        <Grid item xs={4} sm={2}>
                            {/* <FormControl variant='outlined' size='small' fullWidth>
                                <Select value={createdFilter} onChange={(e)=> {setCreatedFilter(e.target.value); setPage(0); setLimit(10);} } size='small' placeholder='Created by' MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                                    sx={{ ...inputClasses.filterField, "& .MuiSelect-iconOutlined": {display: createdFilter != "all" ? 'none': ''}}}
                                    endAdornment={<IconButton sx={{visibility: createdFilter != "all" ? "visible": "hidden", fontSize: "14px"}} onClick={() => {setCreatedFilter("all"); setPage(0); setLimit(10);}}><Clear fontSize='inherit'/></IconButton>}
                                >
                                    <MenuItem value="all" sx={{color: "#455A64", ...textClasses.normalText}}>Created by</MenuItem>
                                    {
                                        AllUserData && AllUserData.data && AllUserData.data.map((t, i) => <MenuItem value={t._id} sx={{ color: "#455A64", ...textClasses.normalText, textTransform: "capitalize" }}>{t.name}</MenuItem>)
                                    }
                                </Select>
                            </FormControl> */}
                            <Autocomplete
                                    value={createdFilter}
                                    onChange={(e, option) => {
                                        setCreatedFilter(option);
                                        setPage(0);
                                        setLimit(10);
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    size='small'
                                    fullWidth
                                    options={AllUserData ? AllUserData.data : []}
                                    sx={{...inputClasses.filterField, ".MuiInputBase-input": {
                                        fontSize: "15px",
                                        color: "#455A64"
                                    }}}
                                    renderInput={(params) => <TextField sx={{color: "#455A64", ...textClasses.normalText}} {...params} label="Created By" />}
                                />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <DatePicker
                                value={dateFilter}
                                defaultValue={null}
                                onChange={(d) => {setDateFilter(d); setLimit(10); setPage(0)}}
                                label="Created On"
                                format="DD/MM/YYYY"
                                sx={{...inputClasses.filterField, maxHeight: "38px", "& .MuiOutlinedInput-input": {height: "38px", borderRadius: "12px", pt: 0}, "& .MuiOutlinedInput-notchedOutline": {height: "43px", borderRadius: "8px"}, "&:hover .MuiOutlinedInput-notchedOutline": {borderColor: theme.themeOrange}}}
                                slotProps={{textField: {size: "small"}}}
                            />
                            {/* <FormControl variant='outlined' size='small' fullWidth>
                                <OutlinedInput
                                    type='date'
                                    value={dateFilter}
                                    onChange={(e) => {setDateFilter(e.target.value); setPage(0); setLimit(10);}}
                                    inputProps = {{placeholder:"Created On"}}
                                    sx={{ ...inputClasses.filterField }}
                                    // sx={{ ...inputClasses.filterField, "& .MuiSelect-iconOutlined": {display: dateFilter != "" ? 'none': ''}}}
                                    // endAdornment={
                                    //     dateFilter == ""
                                    //     ?
                                    //         <InputAdornment position='end'><Search fontSize='small' /></InputAdornment>
                                    //     :
                                    //         <IconButton sx={{visibility: nameFilter != "" ? "visible": "hidden", fontSize: "14px"}} onClick={() => {setDateFilter(""); setPage(0); setLimit(10);}}><Clear fontSize='inherit'/></IconButton>
                                    // }
                                    

                                />
                                
                            </FormControl> */}
                        </Grid>
                        {
                            isFilterApplied && 
                            <Grid container item xs={"auto"} alignItems={"center"}>
                                <Typography sx={{...textClasses.boldText, color: theme.themeOrange, textDecoration: "underline", cursor: "pointer"}} onClick={handleClearFilter}>
                                    Clear Filter(s)
                                </Typography>
                            </Grid>
                        }
                        
                    </Grid>
                    <ConfirmDialog status={confirmModal} handleClose={handleCloseConfirmModal} selectedTemplates={selectedTemplates} setSelectedTemplates={setSelectedTemplates} refreshTableData={() => refreshTableData(page + 1, limit)} handleRemoveSelection={handleRemoveSelection} />
                    {
                        selectedTemplates.length > 0 &&
                        <Grid container sx={{ mt: 2 , textTransform:"none"}}>
                            {
                                selectedTemplates.length == 1 &&
                                <>
                                    {permissions && (permissions.permissions || []).some(item => item.permissionId === "editTemplate") ?
                                        <Button size='small' variant="contained" sx={{ ...buttonClasses.lynkitOrangeFill, mr: 1,textTransform:"none" }} onClick={() => history.push(`/dashboard/templates/edit/${selectedTemplates[0]._id}`)}>Edit</Button>
                                        : ''}
                                    {permissions && (permissions.permissions || []).some(item => item.permissionId === "viewTemplate") ?
                                        <Button size='small' variant="contained" sx={{ ...buttonClasses.lynkitOrangeFill, mr: 1,textTransform:"none"  }} onClick={() => history.push(`/dashboard/templates/view/${selectedTemplates[0]._id}`)}>View</Button>
                                        : ''}
                                </>
                            }
                            {
                                permissions && (permissions.permissions || []).some(item => item.permissionId === "enabledisableTemplate") ?
                                    isSelectionDisable &&
                                    <Button size='small' variant="contained" sx={{ ...buttonClasses.lynkitOrangeFill, mr: 1,textTransform:"none" }} onClick={() => setConfirmModal("Disable")}>Disable</Button>
                                    : ''
                            }
                            {
                                permissions && (permissions.permissions || []).some(item => item.permissionId === "enabledisableTemplate") ?
                                    isSelectionPublishable &&
                                    <Button size='small' variant="contained" sx={{ ...buttonClasses.lynkitOrangeFill, mr: 1,textTransform:"none"  }} onClick={() => setConfirmModal("Publish")}>Publish</Button>
                                    : ''
                            }
                            { permissions && (permissions.permissions || []).some(item => item.permissionId === "deleteTemplate") ?
                            <Button size='small' variant="contained" sx={{ ...buttonClasses.lynkitOrangeFill, backgroundColor: "black", border: "1px solid black" ,textTransform:"none" }} onClick={() => setConfirmModal("Delete")}>Delete</Button>
                            :''}
                        </Grid>
                    }
                    <Grid container sx={{ mt: 2}} xs={12}>
                        {
                            loader
                                ?
                                <Grid container direction={"column"} justifyContent={"center"} alignItems="center" sx={{ p: 15 }}>
                                    <Grid item >
                                        <GenericLoader />
                                    </Grid>
                                </Grid>
                                :
                                <Grid item xs={12}>
                                    <GenericTable
                                    header={tableHeader}
                                    rows={tableRows}
                                    pageCount={totalRecords}
                                    pageNumber={page}
                                    handleChangePage={(event, pageValue) => {
                                            setPage(pageValue)
                                            }}
                                    handleChangeRowsPerPage={(event)=>{
                                        setLimit(event.target.value)
                                    }}
                                    rowsPerPage={limit}
                                    />
                                </Grid>
                        }

                    </Grid>
                    <ConfirmDialog status={actionConfirmModal} handleClose={handleCloseAction} selectedTemplates={actionIndex != null ? [templates[actionIndex]] : []} refreshTableData={() => refreshTableData(page + 1, limit)} onSuccess={handleCloseAction} />
                    {
                        actionIndex != null &&
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={actionIndex != null}
                            onClose={handleCloseAction}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                            PaperProps={{sx: {boxShadow: 0, border: "1px solid #D7D7D7"}}}
                            sx={{ display: actionIndex != null ? "block !important" : "none !important", boxShadow: 0 }}
                        >
                            {permissions && (permissions.permissions || []).some(item => item.permissionId === "editTemplate") ?
                                <MenuItem sx={{...textClasses.normalText}} key={1} onClick={() => history.push(`/dashboard/templates/edit/${templates[actionIndex]._id}`)}>Edit</MenuItem>
                                : ''}
                            {permissions && (permissions.permissions || []).some(item => item.permissionId === "viewTemplate") ?
                                <MenuItem sx={{...textClasses.normalText}} key={2} onClick={() => history.push(`/dashboard/templates/view/${templates[actionIndex]._id}`)}>View</MenuItem>
                                : ''}
                            {
                                permissions && (permissions.permissions || []).some(item => item.permissionId === "enabledisableTemplate") ?
                                    ["published"].indexOf(templates[actionIndex].status) >= 0 &&
                                    <MenuItem sx={{...textClasses.normalText}} key={3} onClick={() => setActionConfirmModal("Disable")}>Disable</MenuItem>
                                    : ''
                            }
                            {
                                ["disabled"].indexOf(templates[actionIndex].status) >= 0 &&
                                <MenuItem sx={{...textClasses.normalText}} key={5} onClick={() => setActionConfirmModal("Publish")}>Publish</MenuItem>
                            }
                            {
                                ["drafted"].indexOf(templates[actionIndex].status) >= 0 &&
                                <MenuItem sx={{...textClasses.normalText}} key={5} onClick={() => setActionConfirmModal("Publish")}>Publish</MenuItem>
                            }
                            {
                                permissions && (permissions.permissions || []).some(item => item.permissionId === "deleteTemplate") ?
                                    (!templates[actionIndex].frequency || templates[actionIndex].frequency == 0) &&
                                    <MenuItem sx={{...textClasses.normalText}} key={4} onClick={() => setActionConfirmModal("Delete")}>Delete</MenuItem>
                                    : ''
                            }



                        </Menu>

                    }


                </Box>
            }
        </Box>
    )
}