import React, { useEffect, useState } from "react"
import AlertDialog from "../../../Components/DialogBox/dialogbox";
import { Grid, Box, Typography, Button, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import GenericLoader from "../../../Components/Generic/GenericLoader";
import { getSkuGroupDetail } from "../../../../redux/actions/AutomationRule";
import theme, { textClasses, buttonClasses, inputClasses } from "../../../../static/styles/theme";
import GenericAutoSizerComponent from "../../../Components/Generic/GenericAutoSizerComponent";


const ViewMappedSKUsModal = ({ viewModal, setViewModal }) => {
    const dispatch = useDispatch();
    const selectedRuleId = viewModal?.action
    const [mappedSKUs, setMappedSKUs] = useState([])
    const [loader, setLoader] = useState(true);

    const getMappedSKUs = () => {
        setLoader(true)
        dispatch(getSkuGroupDetail({ projections: { SKUs: 1 }, ruleId: selectedRuleId, includeDefaultProjections: false }, ({ data = {}, success }) => {
            setLoader("")
            if (success) {
                setMappedSKUs(data?.SKUs)
            }
        }))
    }

    useEffect(() => {
        getMappedSKUs()
    }, [selectedRuleId])
    return (
        <>

            <AlertDialog
                viewDialog={viewModal?.type}
                handleClose={() => { setViewModal({ ...viewModal, type: '' }) }}
                // viewSearchInput={true}
                showDivider={true}
                width="70%"
                title={`Mapped SKUs`}
                body={
                    <>
                        {loader ?
                            <Grid data-testid="loader" container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15 }}>
                                <Grid item><GenericLoader /></Grid>
                            </Grid> :
                            mappedSKUs?.length === 0 ? null :
                                <GenericAutoSizerComponent
                                    mappedData={mappedSKUs || []}
                                    containerHeight="200px"
                                    xsCol={1}
                                    smCol={2}
                                    mdCol={3}
                                    lgCol={4}
                                    xlCol={6}
                                />
                        }
                    </>
                }

            >
                <Button
                    variant="outlined"
                    data-testid="cancel-btn"
                    onClick={() => { setViewModal({ ...viewModal, type: '' }) }}
                    sx={{ ...buttonClasses.lynkitOrangeEmpty, m: 1, fontSize: ".7rem", minWidth: "130px" }}
                >Cancel
                </Button>
            </AlertDialog>
        </>
    )
}
export default ViewMappedSKUsModal;