import React, { useEffect, useState } from "react";
import {
    Grid,
    Button,
    MenuItem,
    Typography,
    Checkbox,
    Paper,
    Box,
    Menu,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import theme, {
    buttonClasses,
    inputClasses,
    textClasses,
    cardClasses,
} from "../../../../static/styles/theme"
import { useDebounce } from "../../../../utils/hooks";
import { dataReadFunction, dateTimeFormater, textCapitalize, renderHeadersFilter, showToast } from "../../../../services/functions";
import GenericCustomiseHeader from "../../../Components/Generic/GenericCustomiseHeader";
import GenericTable from "../../../Components/CustomTable/GenericTable";
import { getSkuGroupTableData, getSkuGroupDetail,disableEnableSkuGroupRule } from "../../../../redux/actions/AutomationRule";
import {
    Add,
    KeyboardArrowDown,
} from "@mui/icons-material";
import SelectImage from "../../../../../src/static/images/data_entry/select_location.svg"
import AddIcon from '@mui/icons-material/Add';
import GenericLoader from "../../../Components/Generic/GenericLoader";
import ViewRowDetailCard from "../../SuperAdmin/ViewRowDetailCard";
import ViewMappedSKUsModal from "./ViewMappedSKUsModal";
import { useHistory } from "react-router-dom";
import ConfirmSkuGroupDialogPopUp from "./ConfirmSkuGroupDialogPopUp";
import GenericToggle from "../../../Components/GenericToggle"

let initialTemplateHeaders = [
    {
        "label": "Rule Name",
        "name": "ruleName",
        "type": "input",
        "format": "",
    },
    {
        "label": "Rule Category",
        "name": "ruleCategory",
        "type": "input",
        "format": "",
        "splitWord": true,
        "splitOn": "(?=[A-Z])",
        "joinWith": " ",
        "formatWord": "toCapitalize"
    },
    {
        "label": "Grouped SKUs",
        "name": "skuCount",
        "type": "showDetail",
        "handleType": "viewSKUs",
        "format": "",
    },
    {
        "label": "Created At",
        "name": "created_by.date",
        "type": "date",
        "format": "",
    },
    {
        "label": "Updated At",
        "name": "updatedAt",
        "type": "date",
        "format": "",
    },
]
let initial_state = {
    "search": {},
    "other": {},
}

const SkuGroupPlacementRule = ({ dataCount, selectedTab }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { userPermissions } = useSelector((state) => state.userPermission);
    let permissions = userPermissions || {};
    const [skuGroupDetailTemplateHeaders, setSkuGroupDetailTemplateHeaders] = useState(initialTemplateHeaders)
    const [actionIndex, setActionIndex] = useState(null);
    const [confirmModal, setConfirmModal] = useState(null);
    const [actionConfirmModal, setActionConfirmModal] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [filter, setFilter] = useState(initial_state);
    const [loader, setLoader] = useState("");
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [statusFilter, setStatusFilter] = useState({});
    const [tabIndex, setTabIndex] = useState(0);
    const [skuGroupTableData, setSkuGroupTableData] = useState({ totalRows: 0, data: [] })
    const [viewSkuGroupDetail, setViewSkuGroupDetail] = useState(false)
    const [skuGroupDetailData, setSkuGroupDetailData] = useState({})
    const [selectedEntries, setSelectedEntries] = useState([]);
    const [viewModal, setViewModal] = useState({ type: "", action: "" })
    const [initialRender, setInitialRender] = useState(true)
    const debouncedFilterSearch = useDebounce(filter?.search, 500);

    const isFilterApplied = (() => {
        if (tabIndex == 0 && statusFilter?.value) {
            return true;
        }
        if (Object.values(filter["search"])?.some(dl => dl && dl.length > 0) || Object.values(filter["other"]).some(dl => dl && dl.length > 0)) {
            return true;
        }
        return false;
    })();

    const handleToggle = (event, rowData, rowIndex = 0) => {
        let checked = event.target.checked
        let payload = {
            ruleId: [rowData?._id],
            approved: checked ? true : false,
            ruleType: selectedTab
        };
        dispatch(
            disableEnableSkuGroupRule(payload, (data) => {
                showToast(data.message, !data.success);
                if (data) {
                    refreshTableData();
                }
            })
        );
    }
    const handleClearFilter = () => {
        setFilter(initial_state)
        setPage(0);
        setLimit(10);
        if (tabIndex == 0) {
            setStatusFilter(null);
        }
    };
    const handleOpenAction = (event, i) => {
        setAnchorEl(event.currentTarget);
        setActionIndex(i);
    };

    const handleCloseAction = () => {
        setActionConfirmModal(null);
        if (setSelectedEntries) {
            setSelectedEntries([]);
        }
        setActionIndex(null);
        setTimeout(() => {
            setAnchorEl(null);
        }, 1);
    };
    const handleCheckAll = () => {
        if (selectedEntries.length < skuGroupTableData?.data?.length) {
            let sT = skuGroupTableData?.data?.map((t) => ({
                _id: t._id,
                ruleName: t.ruleName
            }));
            setSelectedEntries(sT);
        } else {
            setSelectedEntries([]);
        }
    };
    const handleAddSelection = (obj) => {
        setSelectedEntries([...selectedEntries, obj]);
    };

    const handleRemoveSelection = (id) => {
        let oldEntries = [...selectedEntries];
        const i = oldEntries.map((o) => o._id).indexOf(id);
        if (i >= 0) {
            oldEntries.splice(i, 1);
            setSelectedEntries(oldEntries);
            if (oldEntries.length == 0) {
                handleCloseConfirmModal();
            }
        }
    };
    const handleCloseConfirmModal = () => {
        setConfirmModal(null);
    };

    const handleCheckChange = (event, row) => {
        if (event.target.checked) {
            handleAddSelection(row);
        } else {
            handleRemoveSelection(row._id);
        }
    };
    const tableHeader = useSelector((state) => state.customHeader["placementRuleTable"]);
    let header = tableHeader && tableHeader?.validHeader && tableHeader.validHeader.map((h) => {
        if (h.handleType === "selectRow") {
            return (
                <Checkbox
                    data-testid="checkbox"
                    checked={
                        skuGroupTableData?.data?.length != 0 &&
                        selectedEntries.length == skuGroupTableData?.data?.length
                    }
                    onChange={handleCheckAll}
                    size="small"
                />
            )
        }
        return h.fieldName
    })
        || [];

    const getTableCell = (header, row, i) => {

        let value = ""
        if (header?.mappingPath) {
            value = dataReadFunction({ name: header?.mappingPath || "" }, row)
        }

        if (header?.handleType) {
            if (header?.handleType === "selectRow") {
                return (
                    <Checkbox
                        size="small"
                        checked={selectedEntries?.map((o) => o._id).indexOf(row._id) >= 0}
                        onChange={(e) => handleCheckChange(e, row)}
                    />
                );
            }
            else if (header?.handleType === "showDetail") {
                return (
                    <Typography
                        onClick={() => handleRowAndViewClick(row)}
                        style={{
                            ...textClasses.normalText,
                            color: theme.themeOrange,
                            textAlign: "center",
                            cursor: "pointer",
                        }}
                    >
                        {value ?? "-"}
                    </Typography>
                );
            }
            else if (header?.handleType === "viewSKUs") {
                return (
                    value?.length > 0 ? <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, cursor: "pointer" }}
                        onClick={() => {
                            setViewModal({ ...viewModal, type: header?.handleType, action: row?._id })
                        }}>
                        {value
                            ? Array.isArray(value)
                                ? value?.length > 0 && value.join(", ") || "-"
                                : value
                            : "-"
                        }
                    </Typography> : "-"
                )
            }
            else if (header?.handleType === "action") {
                return (
                    <Typography
                        data-testid="dropdown"
                        sx={{
                            ...textClasses.normalText,
                            color: header?.color || theme.themeOrange,
                            cursor: "pointer",
                        }}
                        onClick={(e) => handleOpenAction(e, i)}
                    >
                        Action <KeyboardArrowDown />
                    </Typography>
                );
            }
        }
        else if (header?.isFromRenderFunction === true) {
            let no;
            if (page === 0) {
                no = i + 1;
            } else {
                no = page * 10 + (i + 1);
            }
            if (header?.renderFunction === "srNo") {
                return (
                    <Typography sx={{ ...textClasses.normalText, textAlign: "center", color: header?.color || theme.themeText }}>
                        {no}
                    </Typography>
                );
            }
            else if (header?.renderFunction === "status") {
                const statusColor = {
                    enabled: "#3E621E",
                    disabled: "#ff0c64",
                };
                const statusText = {
                    enabled: "Enabled",
                    disabled: "Disabled",
                };
                return (
                    <>
                        {permissions &&
                            (permissions.permissions || []).some(
                                (item) => item.permissionId === "updateSKUPlacementRulesStatus"
                            ) ? (
                            <Typography
                                sx={{
                                    ...textClasses.normalText,
                                    textAlign: "center"
                                }}
                            >
                                <GenericToggle
                                    disbledName="'Disable'"
                                    enabledName="'Enable'"
                                    isEnable={row?.approved === true}
                                    handleToggle={(event) => handleToggle(event, row, i)}
                                    name="drop"
                                    width={80}
                                />
                            </Typography>
                        ) : (
                            // Show Typography with status if permission is not available
                            <Typography
                                sx={{
                                    ...textClasses.normalText,
                                    textTransform: "capitalize",
                                    textAlign: "center",
                                    color: statusColor[row?.approved ? "enabled" : "disabled"],
                                }}
                            >
                                {row?.approved ? statusText["enabled"] : statusText["disabled"]}
                            </Typography>
                        )}
                    </>
                );
            }
        }
        else {
            if (header?.fieldType === "array") {
                return (
                    <Typography sx={{ ...textClasses.normalText, textAlign: "center", textTransform: "capitalize", color: header?.color || theme.themeText }}>
                        {value && Array.isArray(value) ? value.join(", ") : value || "-"}
                    </Typography>
                );
            }
            else if (header?.fieldType === "date") {
                return (
                    <Typography
                        sx={{ ...textClasses.normalText, textAlign: "center", textTransform: "none", color: header?.color || theme.themeText }}
                    >
                        {value ? dateTimeFormater(value) : "-"}
                    </Typography>
                );
            }
            else {
                return (
                    <Typography
                        sx={{ ...textClasses.normalText, textAlign: "center", textTransform: "none", textTransform: "capitalize", color: header?.color || theme.themeText }}
                    >
                        {textCapitalize(value) ?? "-"}
                    </Typography>
                );
            }
        }
    };

    const tableRows = skuGroupTableData?.data?.map((row, i) => {
        let items = [];

        const validHeaders = tableHeader?.validHeader ?? [];
        for (const header of validHeaders) {
            items.push(getTableCell(header, row, i));
        }
        return items;
    });

    const handleRowAndViewClick = (selectedRowData) => {
        setViewSkuGroupDetail(true)
        let payload = {
            ruleId: selectedRowData?._id,
        }
        setLoader("cardRowDetail")
        dispatch(getSkuGroupDetail(payload, ({ data = {}, success }) => {
            setLoader("")
            if (success) {
                setSkuGroupDetailData({ ...data, ...(data?.data || {}) })
            }
        }))
    }
    const handleCloseDetail = () => {
        setViewSkuGroupDetail(false)
    }

    const refreshTableData = (page, limit) => {
        setLoader("tableData");
        const payload = {
            page: page,
            limit: limit,
        };
        if (filter && Object.keys(filter).length > 0) {
            for (let key in filter) {
                if (key && Object.keys(filter[key]).length > 0) {
                    Object.assign(payload, filter[key])
                }
            }

        }
        dispatch(getSkuGroupTableData(payload, (data) => {
            setLoader("");
            if (data?.success) {
                setSkuGroupTableData({ ...skuGroupTableData, data: data?.data, totalRows: data?.count })
            }
        }));
    };

    let fetchData = (fetchCount = false) => {
        refreshTableData(page + 1, limit);
        // setSelectedTemplates([]);
        setConfirmModal(null);
        // setReceiveModal(null);
        setActionConfirmModal(null);
        setAnchorEl(null);
        setActionIndex(null);
        setViewSkuGroupDetail(false)
    };

    useEffect(() => {

        fetchData(initialRender);
        if (initialRender) {
            setInitialRender(false)
        }

    }, [page, limit, filter.other, debouncedFilterSearch]);
    useEffect(() => {
        if (skuGroupDetailData?.gapDistance) {
            const gapDistanceKeys = Object.keys(skuGroupDetailData.gapDistance);

            // Remove existing gapDistance headers
            let updatedHeaders = skuGroupDetailTemplateHeaders.filter(header =>
                !header.name.startsWith('gapDistance.')
            );

            // Create new gapDistance headers
            const gapDistanceHeaders = gapDistanceKeys.map(key => {
                let headerObject = {
                    "label": textCapitalize(key),
                    "name": `gapDistance.${key}`,
                    "type": "input",
                    "format": "",
                };

                if (key === "additionalConditions") {
                    headerObject = {
                        "label": textCapitalize(key),
                        "name": `gapDistance.${key}`,
                        "type": "input",
                        "format": "",
                        "splitWord": true,
                        "splitOn": "(?=[A-Z])",
                        "joinWith": " ",
                        "formatWord": "toCapitalize"
                    };
                }

                return headerObject;
            });

            // Append the new gapDistance headers to the existing headers
            setSkuGroupDetailTemplateHeaders([...updatedHeaders, ...gapDistanceHeaders]);
        } else {
            // Remove gapDistance headers if no gapDistance data is present
            setSkuGroupDetailTemplateHeaders(prevHeaders =>
                prevHeaders.filter(header => !header.name.startsWith('gapDistance.'))
            );
        }
    }, [skuGroupDetailData?.gapDistance]);
    return (
        <>
            {dataCount > 0 ? (
                loader == "tableData" ? (
                    <Grid container sx={{ mt: 2 }}>
                        <Grid item
                            xs={12}>
                            <Paper>
                                <Grid
                                    data-testid="loader"
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{ p: 10 }}
                                >
                                    <Grid item
                                        xs={12}>
                                        <GenericLoader />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                ) : (
                    <>
                        <Grid container spacing={1} xs={12} data-testid="filters">
                            {renderHeadersFilter(tableHeader?.validHeader?.filter(dl => dl.isSearchable && dl?.mappingPath), filter, setFilter, setPage, setLimit, tabIndex)}
                            {isFilterApplied && (
                                <Grid container item xs={"auto"} alignItems={"center"}>
                                    <Typography
                                        sx={{
                                            ...textClasses.boldText,
                                            color: theme.themeOrange,
                                            textDecoration: "underline",
                                            cursor: "pointer",
                                        }}
                                        onClick={handleClearFilter}
                                    >
                                        Clear Filter(s)
                                    </Typography>
                                </Grid>
                            )}
                            <Grid container item sx={4} sm={2} alignItems={"center"} data-testid="customise-table">
                                <GenericCustomiseHeader
                                    tableName={"placementRuleTable"}
                                    tableType={"placementRuleTable"}
                                />
                            </Grid>
                        </Grid>
                        {selectedEntries?.length > 0 &&
                            <Grid container sx={{ mt: 2, textTransform: "none" }}>

                                {selectedEntries.length === 1 && permissions && (
                                    <>
                                        {(permissions.permissions || []).some(
                                            (item) => item.permissionId === "viewSKUPlacementRules"
                                        ) && (
                                                <Button
                                                    data-testid="view-btn"
                                                    size="small"
                                                    variant="contained"
                                                    sx={{
                                                        ...buttonClasses.lynkitOrangeFill,
                                                        mr: 1,
                                                        textTransform: "none",
                                                    }}
                                                    onClick={() => handleRowAndViewClick(selectedEntries[0])}
                                                >
                                                    View
                                                </Button>
                                            )}
                                    </>
                                )}
                                    {
                                        permissions &&
                                        (permissions.permissions || []).some(
                                            (item) => item.permissionId === "updateSKUPlacementRulesStatus"
                                        ) && selectedEntries?.every(dl=>dl?.approved==false) && (
                                            <>
                                                {
                                                    // selectedEntries?.every(entry => entry.status === "active") && 
                                                    (
                                                        <Button
                                                            data-testid="enable-btn"
                                                            size="small"
                                                            variant="contained"
                                                            sx={{
                                                                ...buttonClasses.lynkitOrangeFill,
                                                                mr: 1,
                                                                textTransform: "none",
                                                            }}
                                                            onClick={() => setConfirmModal("enable")}
                                                        >
                                                            Enable
                                                        </Button>
                                                    )}
                                            </>
                                        )
                                    }
                                    {
                                        permissions &&
                                        (permissions.permissions || []).some(
                                            (item) => item.permissionId === "updateSKUPlacementRulesStatus"
                                        ) && selectedEntries?.every(dl=>dl?.approved==true) && (
                                            <>
                                                {
                                                    // selectedEntries?.every(entry => entry.status === "active") && 
                                                    (
                                                        <Button
                                                            data-testid="disable-btn"
                                                            size="small"
                                                            variant="contained"
                                                            sx={{
                                                                ...buttonClasses.lynkitOrangeFill,
                                                                mr: 1,
                                                                textTransform: "none",
                                                            }}
                                                            onClick={() => setConfirmModal("disable")}
                                                        >
                                                            Disable
                                                        </Button>
                                                    )}
                                            </>
                                        )
                                    }
                                    {
                                        permissions &&
                                        (permissions.permissions || []).some(
                                            (item) => item.permissionId === "deleteSKUPlacementRules"
                                        ) && (
                                            <>
                                                {
                                                    // selectedEntries?.every(entry => entry.status === "active") && 
                                                    (
                                                        <Button
                                                            data-testid="delete-btn"
                                                            size="small"
                                                            variant="contained"
                                                            sx={{
                                                                ...buttonClasses.lynkitOrangeFill,
                                                                mr: 1,
                                                                textTransform: "none",
                                                            }}
                                                            onClick={() => setConfirmModal("delete")}
                                                        >
                                                            Delete
                                                        </Button>
                                                    )}
                                            </>
                                        )
                                    }
                            </Grid>

                        }

                        <Grid container sx={{ mt: 2 }}>

                            <Grid item
                                sm={viewSkuGroupDetail ? 8 : 12}
                                xs={12}>
                                <Paper>
                                    <GenericTable
                                    data-testid="skuGroupTable"
                                        header={header}
                                        rows={tableRows}
                                        pageCount={skuGroupTableData?.totalRows}
                                        pageNumber={page}
                                        handleChangePage={(event, pagevalue) => {
                                            setPage(pagevalue);
                                        }}
                                        handleChangeRowsPerPage={(event) => {
                                            setLimit(+event.target.value);
                                        }}
                                        rowsPerPage={limit}
                                    />
                                </Paper>
                            </Grid>
                            {
                                viewSkuGroupDetail && (
                                    <Grid item sm={4} xs={12} sx={{ p: 1, pt: 0 }}>
                                        <ViewRowDetailCard
                                            detailData={skuGroupDetailData}
                                            onClose={handleCloseDetail}
                                            readKey={'ruleName'}
                                            cardHeader={skuGroupDetailTemplateHeaders}
                                            loader={loader == "cardRowDetail"}
                                            onHandleClick={(handleType, detailData) => {
                                                setViewModal({ ...viewModal, type: handleType, action: detailData?._id })
                                            }}
                                        />
                                    </Grid>
                                )
                            }
                            {
                                viewModal?.type &&
                                <ViewMappedSKUsModal
                                    viewModal={viewModal}
                                    setViewModal={setViewModal}
                                />
                            }
                            <ConfirmSkuGroupDialogPopUp
                                status={confirmModal}
                                handleClose={handleCloseConfirmModal}
                                selectedEntries={selectedEntries}
                                setSelectedEntries={setSelectedEntries}
                                refreshTableData={() => {
                                    refreshTableData(page + 1, limit)
                                    setTabIndex(0)
                                }}
                                handleRemoveSelection={handleRemoveSelection}
                                selectedTab={selectedTab}
                            />
                            <ConfirmSkuGroupDialogPopUp
                                status={actionConfirmModal}
                                handleClose={handleCloseAction}
                                selectedEntries={
                                    actionIndex != null ? [skuGroupTableData?.data[actionIndex]] : []
                                }
                                refreshTableData={() => {
                                    refreshTableData(page + 1, limit)
                                    setTabIndex(0)
                                }}
                                selectedTab={selectedTab}
                                />

                            {actionIndex != null && (
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={actionIndex != null}
                                    onClose={handleCloseAction}
                                    MenuListProps={{
                                        "aria-labelledby": "basic-button",
                                    }}
                                    sx={{
                                        display: actionIndex != null ? "block !important" : "none !important",
                                    }}
                                >
                                        {permissions &&
                                            (permissions.permissions || []).some(
                                                (item) => item.permissionId === "viewSKUPlacementRules"
                                            ) && (
                                                <MenuItem
                                                    key={1}
                                                    sx={{ ...textClasses.normalText }}
                                                    onClick={() => {
                                                        handleRowAndViewClick(skuGroupTableData?.data[actionIndex]);
                                                        handleCloseAction();
                                                    }}
                                                >
                                                    View
                                                </MenuItem>
                                            )}
                                        {permissions &&
                                            (permissions.permissions || []).some(
                                                (item) => item.permissionId === "updateSKUPlacementRulesStatus"
                                            ) && skuGroupTableData?.data[actionIndex]?.approved==false && (
                                                <MenuItem
                                                    key={1}
                                                    sx={{ ...textClasses.normalText }}
                                                    onClick={() => setActionConfirmModal("enable")}
                                                >
                                                    Enable
                                                </MenuItem>
                                            )}
                                        {permissions &&
                                            (permissions.permissions || []).some(
                                                (item) => item.permissionId === "updateSKUPlacementRulesStatus"
                                            ) && skuGroupTableData?.data[actionIndex]?.approved==true &&(
                                                <MenuItem
                                                    key={1}
                                                    sx={{ ...textClasses.normalText }}
                                                    onClick={() => setActionConfirmModal("disable")}
                                                >
                                                    Disable
                                                </MenuItem>
                                            )}
                                        {permissions &&
                                            (permissions.permissions || []).some(
                                                (item) => item.permissionId === "deleteSKUPlacementRules"
                                            ) && (
                                                <MenuItem
                                                    key={1}
                                                    sx={{ ...textClasses.normalText }}
                                                    onClick={() => setActionConfirmModal("delete")}
                                                >
                                                    Delete
                                                </MenuItem>
                                            )}
                                </Menu>
                            )}
                        </Grid>
                    </>
                )
            ) : (
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ p: 4, border: `1px solid ${theme.themeGray}`, borderRadius: 2 }}
                >
                    <img src={SelectImage} />
                    <Typography sx={{ ...textClasses.cardTitle, mt: 2 }}>
                        Create your first SKU group and placement rule(s)
                    </Typography>
                    <Typography
                        sx={{ ...textClasses.t12n, textAlign: "center", maxWidth: "500px", mt: 2 }}
                    >
                        Hey, you need to create a group of previously created SKU(s), apply
                        some placement related rules on SKU groups to build a smart space
                        utilization.
                    </Typography>
                    {(permissions.permissions || []).some((item) => item.permissionId === "createSKUPlacementRules") &&
                        (<Button
                            variant="contained"
                            data-testid="create-group"
                            spacing={1}
                            onClick={() => {history.push("/dashboard/setting/automation-rules/createSKUPlacementRule")}}
                            sx={{
                                ...buttonClasses.lynkitBlackFill,
                                mt: 2,
                                ...textClasses.t14n,
                            }}
                            startIcon={<AddIcon />}
                        >
                            Create Placement Rule
                        </Button>)
                    }
                </Grid>
            )}
        </>
    );

}

export default SkuGroupPlacementRule