import React, { useEffect, useState, useRef } from 'react'
import { Grid, Paper, Typography, Button, TextField, IconButton, ButtonGroup, Select, MenuItem, CircularProgress, Dialog, DialogContent, FormControl, FormControlLabel, Box, Checkbox, Divider, InputAdornment, Card, CardHeader, CardContent } from '@mui/material';
import { Visibility, Delete, Download, Upload, Add, Remove, AddOutlined, FileUpload, KeyboardArrowLeft, KeyboardArrowRight, Search, Sell, Close } from '@mui/icons-material';
import theme, { textClasses, buttonClasses, inputClasses } from '../../../../../static/styles/theme';
import { useDispatch, useSelector } from 'react-redux';
import { getItemMasterDetailForInward } from '../../../../../redux/actions';
import {  getInputFieldForTableRow } from '../../../../Components/TemplateInputConfiguration';

import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style'
import { customAlphabet } from 'nanoid';
// import * as xlsx from 'xlsx';
import { read, utils } from 'xlsx';
import moment from 'moment';
import { showToast } from '../../../../../services/functions';
import ViewFileModal from '../Components/ViewExcelDialog';
import PackageDialog from './PackageDialog';
import { deepCopy_Object,validateFieldsMultiple } from '../../../../../services/functions';
import { handleDynamicChange,calculateTarget } from '../../../../../services/formFunction';
import { getItemPackageComponent } from './constant';
import { DetailDialog } from './ItemSection';

const alphabet =
"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"; // Excluding hyphen (-)
const nanoid = customAlphabet(alphabet, 21);

export default function ItemtoPackageSection({
    asn, spiType, sectionKey, open, handleCloseModal, totalObjectCount, template, pIndex, packageObj,
     packages, setPackages, addItems, objects, setObjects, PackageList, packageFields, isManual,
      setIsManual, DynamicForm, handlePackageAddAndOther, handleSubmit, setValue,bundleType= "itemToPackage"
    }) {
    const dispatch = useDispatch();

    const objectTemplateDetails = template?.otherTemplate?.filter(ot => ot.templateType == `itemDetailTemplate`);

    const mappingLocations = useSelector(state => state.inward.allMapping);
    const allUom = useSelector(state => state.uom.allUom);
    const allFields = objectTemplateDetails.length > 0 ? objectTemplateDetails[0].attributes: [];

    const remaining = (() => {
        
        let itemsInPackage = objects.map(o => (
                o.itemDetail.map(i => Number(i.count)).reduce((a, b) => a+b, 0)
            )).reduce((a, b) => a+b, 0)
        let itemsInTable = packageObj.itemDetail.map(p => p.count ? Number(p.count) : 0).reduce((a, b) => a + b, 0);
        //// //("Items in Package", itemsInPackage);
        //// //("Items in Table", itemsInTable);
        return totalObjectCount - itemsInPackage - itemsInTable;
    })();

    const packageCountTillNow = objects.map(o => (
        o.itemDetail.map(i => Number(i.count)).reduce((a, b) => a+b, 0)
    )).reduce((a, b) => a+b, 0)
    //// //("Remaining", remaining);
    
    const [fileLoading, setFileLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    
    const fileInputRef = useRef();
    const [showFileData, setShowFileData] = useState(false);
    const [fileData, setFileData] = useState([]);
    const [shellState, setShellState] = useState(false);
    const [apiDataIndex, setApiDataIndex] = useState(null);

    // const [items, setItems] = useState(packageObj.itemDetail);
    const items = packageObj?.itemDetail || []
    const [errors, setErrors] = useState({})
    const [selectedItems, setSelectedItems] = useState([]);
    const [completeFields, setCompleteFields] = useState([]);

    const [openPackage, setOpenPackage] = useState("")

    const handleAddSelection = (obj) => {
        setSelectedItems([...selectedItems, obj]);
    }

    const handleRemoveSelection = (id) => {
        let oldSelected = [...selectedItems];
        const i = oldSelected.map(o => o._id).indexOf(id);
        if (i >= 0) {
            oldSelected.splice(i, 1);
            if(oldSelected.length == 0){
                setOpenPackage("");
            }
            setSelectedItems(oldSelected);
        }
    }

    const handleCheckChange = (event, obj) => {
        if (event.target.checked) {
            handleAddSelection(obj);
        }
        else {
            handleRemoveSelection(obj._id);
        }
    }

    const handleCheckAll = () => {
        //// //("Items length", items.length);
        //// //("Selected items length", selectedItems.length);
        if (selectedItems.length < items.length) {
            //// //("here ", selectedItems.length);
            // let sT = inventories.map(t => ({ _id: t._id, status: t.status, name: t.name }))
            setSelectedItems(items);
        }
        else {
            setSelectedItems([]);
        }
    }

    //// //("Items", items);
    const countTillNow = items.map((i) => Number(i.count)).reduce((a, b) => a+b , 0)

    const dimensions = useSelector(state => state.inventory.parcelgetDimensions);


    let generateFormFields=(fieldAll=[],UOMList=[])=>{
        let tempFeilds=deepCopy_Object(fieldAll)
        let mappingAreaField = {
            type: "select",
            label: "Mapping Area",
            required: true,
            _id: "mappingArea",
            values: mappingLocations || [],
            defaultValue: "PUT IT ANY WHERE"
        }
    
        // const mandatoryFields = objectTemplateDetails.length > 0 ? objectTemplateDetails[0].attributes.filter(f => mandatoryFieldsId.indexOf(f._id) >= 0) : [];    
        // const otherFields = objectTemplateDetails.length > 0 ? objectTemplateDetails[0].attributes.filter(f => mandatoryFieldsId.indexOf(f._id) < 0) : [];    
    
        let uomIndex = tempFeilds.map(f => f._id).indexOf("UOM");
        if(uomIndex >= 0){
            tempFeilds[uomIndex].values = UOMList.map(u => ({
                label: u.unitName,
                value: u.unitValue
            }));
        }
        else{
            let field = {
                "_id": "UOM",
                "type": "select",
                "label": "Unit Of Measurement (UOM)",
                "description": "unit of measurements",
                "placeholder": "select Unit",
                "values": UOMList.map(u => ({
                    label: u.unitName,
                    value: u.unitValue
                })),
                "required": true,
                "forType": "all",
                "elementType": "Selection Type",
                "sortType": 2,
            }
            tempFeilds.push(field);
        }
    
        
        tempFeilds = tempFeilds.concat([mappingAreaField])
       return tempFeilds
    }

    
    //// //("COMPLETE FIELDS", completeFields);

    const handleFileChange = (e) => {
        //// //(e.target.files[0]);
        setSelectedFile(e.target.files[0]);
    }

    const getTableHeader = (headerType) => {
        let base = ["Count"]
        let otherHeader = completeFields.filter(f => f._id != "count").map((of) => {
            let label = of.label;
            if(headerType == "excel" && ["select", "radio", "checkbox"].indexOf(of.type) >= 0 && of.values != null && Array.isArray(of.values)){
                label = `${label} (${of.values.map(o => o.value).join(",")})`
            }
            return label
        })
        return [...base, ...otherHeader];
    }

    const getLabelToIdMapping = () => {
        let mapping = {
            "Count": "count"
        }

        for (let index = 0; index < completeFields.length; index++) {
            if(completeFields[index]._id != "count"){
                let label = completeFields[index].label;
                if(["select", "radio", "checkbox"].indexOf(completeFields[index].type) >= 0 && completeFields[index].values != null && Array.isArray(completeFields[index].values)){
                    label = `${label} (${completeFields[index].values.map(o => o.value).join(",")})`
                }
                mapping[label] = completeFields[index]._id;
            }
        }
        return mapping;
    }

    const handleShowFileModal = () => {
        setShowFileData(true);
    }

    const handleCoseFileModal = () => {
        setShowFileData(false);
    }

    const handleCloseDetailDialog = () => {
        setApiDataIndex(null);
    }

    const handleDeleteFile = () => {
        setShellState(Math.random().toString(36));
        setSelectedFile(null);
        setFileData([]);
        setShowFileData(false);
    }

    const matchFileHeader = (fileHeader, uploadedHeader) => {
        const diff = uploadedHeader.filter( h => !fileHeader.includes(h));
        if(diff.length > 0){
            return false;
        }
        return true;
    }

  
    const getAndValidateField = (mappedField, raw) => {
        //// //("Found Excel Field", mappedField);
        //// //("With Excel Data", raw);
        if(mappedField._id == "UOM"){
            if(!mappedField.required){
                return [raw, true, ""]
            }
            else if(mappedField.required && raw && raw != ""){
                return [raw, true, ""]
            }
            else{
                return [raw, false, `File Error: ${mappedField.label} is required`]
            } 
        }
        else if(mappedField.type == "date"){
            let newFormat = (raw && raw != "") ? moment(raw).format("YYYY-MM-DD") : null
            if(!mappedField.required){
                return [newFormat, true, ""]
            }
            if(mappedField.required && newFormat){
                return [newFormat, true, ""]
            }
            
            else{
                return [newFormat, false, `File Error: ${mappedField.label} is required`]
            } 
        }
        else if(["input", "number", "textarea"].indexOf(mappedField.type) >= 0){
            if(!mappedField.required){
                if(mappedField.regex && !(new RegExp(mappedField.regex)).test(raw)){
                    return [raw, false, `Invalid value for ${mappedField.label}`]
                }
                return [raw, true, ""]
            }
            else if(mappedField.required && raw && raw != ""){
                if(mappedField.regex && !(new RegExp(mappedField.regex)).test(raw)){
                    return [raw, false, `Invalid value for ${mappedField.label}`]
                }
                return [raw, true, ""]
            }
            else{
                return [raw, false, `File Error: ${mappedField.label} is required`]
            } 
        }
        else if(mappedField.type == "file"){
            if(!mappedField.required){
                return [raw, true, ""]
            }
            if(mappedField.required && raw && raw != ""){
                return [raw, true, ""]
            }
            else{
                return [raw, false, `File Error: ${mappedField.label} is required`]
            }
        }
        else if(mappedField.type == "checkbox"){
            let options = raw.split(",").map(o => o.trim());
            let invalidOptions = options.filter(o => (o != "" && mappedField.values.map( m => m.value).indexOf(o) < 0));
            if(invalidOptions.length > 0){
                return [options, false, `File Error: Invalid ${mappedField.label} values passed`]
            }
            if(!mappedField.required){
                return [options, true, ""]
            }
            else if(mappedField.required && options.length > 0){
                return [options, true, ""]
            }
            else{
                return [options, false, `File Error: ${mappedField.label} is required`]
            }
        }
        else if(mappedField.type == "radio" || mappedField.type == "select"){
            let pos = mappedField.values.map(m => m.value).indexOf(raw);
            if(mappedField.required){
                if(raw == "" && pos < 0){
                    return [raw, false, `File Error: ${mappedField.label} is required`]
                }
                else if(raw != "" && pos < 0){
                    return [raw, false, `File Error: Invalid value for ${mappedField.label}`]
                }
                return [raw, true, ""]
            }
            else{
                if(raw != "" && pos < 0){
                    return [raw, false, `File Error: Invalid value for ${mappedField.label}`]
                }
                return [raw, true, ""]
            }
        }
    }

    const handleUploadFile = (upload_type) => {
        const file = selectedFile;
        const reader =  new FileReader();
        setFileLoading(true);

        reader.onerror = (e) => {
            handleDeleteFile();
            setFileLoading(false);
            showToast("File Error: Error reading file. Please select again", true);
        }

        reader.onabort = () => {
            //// //("aborted");   
        }

        reader.onload = (e) => {
            const wb = read(e.target.result);
            const sheets = wb.SheetNames;
            if (sheets.length) {
                const rows = utils.sheet_to_json(wb.Sheets[sheets[0]], {raw: false});
                if (rows.length == 0) {
                    showToast("Cannot upload blank file", true)
                    setFileLoading(false);
                    reader.abort();
                    return;
                }
                const labelToIdMap = getLabelToIdMapping();
                const tHeader = getTableHeader("excel");

                let newItems = []

                for (let i = 0; i < rows.length; i++) {
                    const row = rows[i];
                    if(!matchFileHeader(tHeader, Object.keys(row))){
                        showToast("File header doesnt match. Please re-upload with correct header", true)
                        setFileLoading(false);
                        reader.abort();
                        return;
                    }
                    else{
                        let obj = {}
                        for (let j = 0; j < tHeader.length; j++) {
                            let mappedField = completeFields.filter((of) => of._id == labelToIdMap[tHeader[j]])
                            if(mappedField.length > 0){
                                mappedField = mappedField[0];
                                const [cleanValue, status, errorMessage] = getAndValidateField(mappedField, rows[i][tHeader[j]]);
                                if(status){
                                    obj[labelToIdMap[tHeader[j]]] = cleanValue
                                }
                                else{
                                    showToast(errorMessage, true);
                                    setFileLoading(false);
                                    reader.abort();
                                    return;
                                }
                            }
                            else{
                                obj[labelToIdMap[tHeader[j]]] = rows[i][tHeader[j]]
                            }
                        }
                        // if(!obj.count){
                        //     obj.count = 1
                        // }
                        obj._id = nanoid();
                        if(obj.count > 1){
                            obj.USN_Value = "Not Applicable"
                        }
                        newItems.push(obj)
                    }
                    
                }
                // setFileData(rows); 
                if(upload_type == "view"){
                    setFileData(rows)
                    setShowFileData(true);
                }
                else{
                    let newItemsCount = newItems.map((i) => Number(i.count)).reduce((a, b) => a + b, 0);
                    // addItems(pIndex, newItems);
                    
                    if(newItemsCount > remaining){
                        showToast(totalObjectCount > 0 ? `File Error: Total items cannot exceed ${totalObjectCount}` : "Please enter total quantity first", true)
                    }
                    else{
                        addItems(pIndex, newItems);
                        // handleCloseModal();
                    }
                }
                
            }
            setFileLoading(false);
        }
        if(file){
            reader.readAsArrayBuffer(file);
        }
        else{
            showToast("File Error: Something wrong with file")
        }
    }
    
    const handleGenericChange = async(event, i, fieldId) => {
        let oldPackages = deepCopy_Object(packages);
        // oldPackages[pIndex].itemDetail = [...oldPackages[pIndex].itemDetail];
        let oldItems = [...oldPackages[pIndex].itemDetail]
        const targetField = completeFields.find(f => f._id == fieldId) || {};
        // let oldItems = [...items];
        let oldObject = {...oldItems[i]};
        if(targetField.type == "file"){
            oldObject[fieldId] = event.target.files;
        }
        else if(targetField.type == "checkbox") {
            let oldValues = oldObject[fieldId] ? oldObject[fieldId] : [];
            if(event.checked){
                oldValues.push(event.value);
            }
            else{
                let pos = oldValues.indexOf(event.value);
                oldValues.splice(pos, 1)
            }
            oldObject[fieldId] = [...oldValues];
        }
        else{
            oldObject[fieldId] = event.target.value;
        }

        if (["select"].includes(targetField.type) && targetField.setTargetValue) {
            if (event.target.value) {
                await handleDynamicChange(oldObject, targetField, {value:event.target.value}, "itemDetail",["tableHeader"])
            }
            else {
                calculateTarget(oldObject, targetField, {})
                oldObject.disabled=[]
                oldObject.itemDetail=null
                oldObject.tableHeader=[]
            }

            oldObject.fetchedFromItemMaster = false
            if(oldObject?.itemDetail?.formData && Object.keys(oldObject?.itemDetail?.formData).length > 0){
                oldObject.fetchedFromItemMaster=true
            }
            if (oldObject?.itemDetail?.mappingArea?.length) {
                oldObject["restrictedMappingArea"] = oldObject?.itemDetail?.mappingArea || []
                oldObject["mappingArea"] = oldObject?.itemDetail?.mappingArea?.at(0) || ""
            }
        }
        
        oldItems[i] = {...oldObject};
        oldPackages[pIndex].itemDetail = oldItems;
        setPackages([...oldPackages])
        // setItems([...oldItems]);
    }

    const autoFillOnFocus = (index) => {
        if(dimensions){
            let oldPackages = [...packages];
            let oldItems = [...oldPackages[pIndex].itemDetail]
            // let oldItems = [...items];
            for (let i = 0; i < completeFields.length; i++) {
                let compId = completeFields[i]._id
                if(["weight", "length", "breadth", "height"].indexOf(compId) >= 0 && dimensions[compId]){
                    oldItems[index][compId] = dimensions[compId];
                }
            }
            oldPackages[pIndex].itemDetail = oldItems;
            setPackages([...oldPackages])
            // setItems(oldItems)
        }
    }

    const handleAdornmentSearchButton = (i) => {
        // let payload = {
        //     templateWise: false,
        //     fetch_for: "item",
        //     SKU_ID: items[i].SKU_Number
        // }
        let payload = {
            base_ID: items[i].SKU_Number,
            master_Type: "item-master",
            fetch_for: "item",
        }
        // setScanLoader(true);
        dispatch(getItemMasterDetailForInward(payload, (data) => {
            if(data && data.success){
                // setItemMasterDetail(data);
                let newPackages = JSON.parse(JSON.stringify(packages));
                newPackages[pIndex].itemDetail[i].apiData = data
                newPackages[pIndex].itemDetail[i].SKU_Name = data.data.formData.SKU_Detail
                newPackages[pIndex].itemDetail[i].UOM = data.data.formData.UOM
                newPackages[pIndex].itemDetail[i].fetchedFromItemMaster = true
                if(data?.data?.mappingArea?.length){
                    newPackages[pIndex].itemDetail[i]["restrictedMappingArea"]=data?.data?.mappingArea || []
                    newPackages[pIndex].itemDetail[i]["mappingArea"]=data?.data?.mappingArea?.at(0) || ""
                }
                
                setPackages(newPackages)
                // setItems(newItems);

            }
            else{
                showToast(data ? data.message: "Something went wrong", true)
                // setItemMasterDetail(null);
            }
        }))
        
    }

    const handleSaveForNext = (i, e) => {
        if(!items[i].mappingArea){
            showToast("Please select mapping area", true)
        }
        else if(items[i].mappingArea && e.target.checked){
            let oldPackages = [...packages];
            let oldItems = [...oldPackages[pIndex].itemDetail]
            let object = {...oldItems[i]};
            let newItems = oldItems.map((oi, j) => {
                let ni = {...oi};
                if(j > i){
                    ni.mappingArea = object.mappingArea;
                }
                return ni;
            })
            // oldObject.mappingArea = items[i].mappingArea;
            // oldItems[i] = {...oldObject};
            oldPackages[pIndex].itemDetail = newItems
            setPackages([...oldPackages])
            // setItems([...oldItems]);
        }
    }

    const getItemRow = (i) => {
        let item = items[i];
        const adornmentForScan = (
            <InputAdornment position='end'>
                <IconButton
                    onClick={() => handleAdornmentSearchButton(i)}
                    disabled={item.SKU_Number.length < 2}
                >
                    <Search/>
                </IconButton>
            </InputAdornment>
        )
        let base = [
            <Checkbox
                size="small"
                value={1}
                checked={selectedItems.map(o => o._id).indexOf(items[i]._id) >= 0}
                onChange={(e) => handleCheckChange(e, items[i])}
            />,
            (
                <ButtonGroup size='small' sx={{minWidth: "24px", ".MuiButtonGroup-grouped": {minWidth: "25px"}}}>
                    <Button key="one" size='small' sx={{...buttonClasses.smallOrange, fontSize: "12px"}} onClick={() => handleItemCount(i, -1)}>
                        <Remove fontSize='inherit'/>
                    </Button>
                    {/* <Button key="two" disabled size='small' sx={{...buttonClasses.smallOrange, border: "1px solid #FF2700", backgroundColor: "white", fontSize: "12px"}}>
                        {items[i].count}
                    </Button> */}
                    <input
                        type="number"
                        style={{border: "1px solid #D9D9D9", maxWidth: "60px", fontSize: "12px", textAlign: "center"}}
                        value={items[i].count || null}
                        onChange={(e) => handleItemCount(i, e)}
                        onWheel={(event) => event.target.blur()}
                        
                        onKeyDown={(e) => {
                            if(['e', 'E', '+', '-'].includes(e.key)){
                                e.preventDefault();
                            }
                            else if(e.key == '0' && (e.target.value == "" || Number(e.target.value) == 0)){
                                e.preventDefault();
                            }
                        }}
                    />
                    <Button key="three" size='small' sx={{...buttonClasses.smallOrange, fontSize: "12px"}} onClick={() => handleItemCount(i, 1)}>
                        <Add fontSize='inherit'/>
                    </Button>
                </ButtonGroup>
            )
        ]

        const others = completeFields.filter( f => f._id != "count").map((of) => {
            let component = {...of};
            if(of._id == "USN_Value"){
                component.disabled = items[i].count > 1
            }
            else if(items[i]?.itemDetail && ["SKU_Number", "SKU_Name","UOM"].indexOf(component._id) >= 0 && items[i][component._id] != null && items[i][component._id] != ""){
                component.disabled = true;
            }
            else if(item?.disabled?.includes(of._id)){
                component.disabled = true;
            }
            else{
                component.disabled = false;
            }
            let isVisible=true

            if(of._id=="mappingArea"){
                if(((asn  && items[i]?.masterType) || items[i]?.fetchedFromItemMaster) && items[i]?.restrictedMappingArea?.length>0){
                    //(component?.values?.find(dl=>dl.isDefault) || {}
                    let restrict=items[i]?.restrictedMappingArea?.map(dl=>({label:dl,value:dl}))
                    component.values=[...restrict]
                    component.label=component.label + " (Restricted)"
                    isVisible=false
                }
            }
            return (
                <>
                    {
                        getInputFieldForTableRow(component, items,  i, handleGenericChange, autoFillOnFocus, errors, false, null)
                        // getInputFieldForTableRow(component, items,  i, handleGenericChange, autoFillOnFocus, errors, false, !asn && !isManual && of._id == "SKU_Number" ? adornmentForScan : null)
                    }
                    {
                        isVisible && of._id == "mappingArea" &&
                        <FormControlLabel control={<Checkbox onClick={(e) => handleSaveForNext(i, e)} size='small' />} label={<Typography sx={{fontSize: "12px"}}>Same for next</Typography>}/>
                    }
                    {
                        !asn && !isManual && of._id == "SKU_Number" && 
                        <Typography sx={{...textClasses.boldText, color: theme.themeOrange, cursor: "pointer"}} onClick={() => setApiDataIndex(i)}>{items[i]?.itemDetail ? "View Detail" : "No Data yet"}</Typography>
                    }
                </>
                
            )
        })
        
        return [...base, ...others]
        
    }

    const rows = items.map((item, i) => {
        return getItemRow(i);
    })

    const addItemRow = () => {
        let fieldsData = {}
        let fields = completeFields.filter(f => f._id != "count")
        for (let i = 0; i < fields.length; i++) {
            if (fields[i].type == "checkbox"){
                fieldsData[fields[i]._id] = []    
            }
            else if (fields[i].type == "file"){
                fieldsData[fields[i]._id] = null;
            }
            else{
                fieldsData[fields[i]._id] = ""
            }
            
        }
        let newRow = {
            count: 1,
            _id: nanoid(),
            ...fieldsData,
            mappingArea: "PUT IT ANY WHERE"
        }
        let oldPackages = [...packages];
        let oldItems = [...oldPackages[pIndex].itemDetail, newRow]
        oldPackages[pIndex].itemDetail = oldItems;
        setPackages([...oldPackages])
        // setItems([...items, newRow]);
    }

    
    
    const downloadSample = async () => {
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml;charset=UTF-8";
        const fileExt = ".xlsx";

        const excelData = {};
        const theaders = getTableHeader("excel");
        for (let i = 0; i < theaders.length; i++) {
            excelData[theaders[i]] = "Enter Value"
        }
        
        const ws = XLSX.utils.json_to_sheet([excelData]);
        const wb = { Sheets: {data: ws}, SheetNames: ['data']};
        const buffer = XLSX.write(wb, {bookType: 'xlsx', type: "array"});
        const data = new Blob([buffer], {type: fileType});
        FileSaver.saveAs(data, "Item_Template_name" + fileExt);

    }

    // const deleteItemRow = (index) => {
    //     let oldItems = [...items];
    //     oldItems.splice(index, 1);
    //     setItems(oldItems);
    // }

    const handleDeleteItem = (i) => {
        let oldPackages = [...packages];
        let oldItems = [...oldPackages[pIndex].itemDetail]
        
        // let oldItems = [...items];
        oldItems.splice(i, 1);
        oldPackages[pIndex].itemDetail = oldItems;
        setPackages([...oldPackages])

        // Remove Error object
        // let oldErrors = [...errors];
        // oldErrors.splice(i);
        // setErrors(oldErrors)

        // setItems(oldItems);
    }

    const handleItemCount = (i, direction) => {
        let oldPackages = [...packages];
        let oldItems = [...oldPackages[pIndex].itemDetail]
        
        // let oldItems = [...items];
        if(direction == 1){
            if(remaining > 0){
                oldItems[i].count = Number(oldItems[i].count) + 1
                if(oldItems[i].count > 1){
                    oldItems[i].USN_Value = "Not Applicable"
                }
                oldPackages[pIndex].itemDetail = oldItems;
                setPackages([...oldPackages])
                // setItems(oldItems);
            }
        }
        else if(direction == -1 ){
            if(Number(oldItems[i].count) > 1){
                oldItems[i].count = Number(oldItems[i].count) - 1
                if(Number(oldItems[i].count) > 1){
                    oldItems[i].USN_Value = "Not Applicable"
                }
                else if(Number(oldItems[i].count) == 1){
                    oldItems[i].USN_Value = ""
                }
                oldPackages[pIndex].itemDetail = oldItems;
                setPackages([...oldPackages])
                // setItems(oldItems);
            }
            else if(Number(oldItems[i].count) == 1){
                oldItems.splice(i, 1);
                if(oldItems[i] && Number(oldItems[i].count) > 1){
                    oldItems[i].USN_Value = "Not Applicable"
                }
                oldPackages[pIndex].itemDetail = oldItems;
                setPackages([...oldPackages])
                // setItems(oldItems)
            }
            
        }
        else{
            //// //("Remaining", remaining);

            let maxCap = remaining + oldItems[i].count;

            oldItems[i].count = Number(direction.target.value) > maxCap ? maxCap : Number(direction.target.value)
            if(Number(oldItems[i].count) > 1){
                oldItems[i].USN_Value = "Not Applicable"
            }
            else if(Number(oldItems[i].count) <= 1){
                oldItems[i].USN_Value = ""
            }
            oldPackages[pIndex].itemDetail = oldItems;
            setPackages([...oldPackages])
            
        }
        
    }

    // const handleUSNChange = (e, i) => {
    //     let oldItems = [...items];
    //     oldItems[i].USN_Value = e.target.value;
    //     setItems(oldItems);
    // }

    // const handleLocationChange = (event, i) => {
    //     //// //("event " + event.target.value + " from index " + i);
    //     let oldItems = [...items];
    //     oldItems[i].mappingArea = event.target.value;
    //     setItems(oldItems);
    // }

    const validateFields = () => {

        let selectedIDs=selectedItems?.map(dl=>dl._id)
        let selectItem=items?.filter(dl=>dl._id && selectedIDs.includes(dl._id))
        return validateFieldsMultiple(completeFields,selectItem)
   

        let newErrors = [];
        
        for (let j = 0; j < items.length; j++) {
            const row = items[j];
            let rowErrors = {};
            const keys = Object.keys(row);
            let isSelected = selectedItems.filter(s => s._id == row._id);
            //// //("IsSelected", isSelected);
            if(isSelected.length > 0){
                for (let i = 0; i < keys.length; i++) {
                    const fieldId = keys[i];
                    const element = row[fieldId];
                    const targetField = completeFields.filter(f => f._id == fieldId)[0];
                    
                    if(targetField){
                        if(targetField.required && (!element || element == "")){
                            // //// //(`${targetField.label} failing required test with value ${element}`);
    
                            rowErrors[fieldId] = {
                                name: targetField.label,
                                message: `${targetField.label} is required`
                            }
                        }
                        else if(targetField.pattern && !(new RegExp(targetField.pattern)).test(element)){
                            // //// //(`${targetField.label} failing pattern test ${targetField.pattern} with value ${element}`);
                            rowErrors[fieldId] = {
                                name: targetField.label,
                                message: `Invalid value for ${targetField.label}`
                            }
                        }
                    }
                }
            }
            newErrors.push(rowErrors)
        }
        return newErrors;
    }

    // const handleItemAdd = () => {
    //     let newErrors = validateFields();
    //     if(newErrors.filter(row => Object.keys(row).length != 0).length != 0){
    //         showToast("Form Error: Please check for errors", true);
    //     } else if(countTillNow != packageObj.count){
    //         showToast(`Form Error: Total items should be equal to ${packageObj.count}, currently = ${countTillNow}`, true)
    //     }
    //     else{
            
    //         addItems(pIndex, items);
    //         handleCloseModal();
    //     }
    //     setErrors(newErrors);
    // }

    // const header = ["Count", "Item Name", "USN", "Allocate Location", "Item Code", "Item Category"]
    const header = [<Checkbox value={1} checked={items.length != 0 && selectedItems.length == items.length} onChange={handleCheckAll} size='small' />].concat(getTableHeader())
    
    const handleCreatePackage = () => {
        let zeroCount = items.filter(it => !it.count );
        let newErrors = validateFields();
        if(zeroCount.length > 0){
            showToast("Item count cannot be 0", true)
        }
        else if(newErrors.filter(row => Object.keys(row).length != 0).length != 0){
            showToast("Form Error: Please check for errors", true);
        }
        else {
            setOpenPackage("Package")
            // let newPackage = {};
            // let fields = packageFields.filter(f => f._id != "count")
            // for (let i = 0; i < fields.length; i++) {
            //     if (fields[i].type == "checkbox"){
            //         newPackage[fields[i]._id] = []    
            //     }
            //     else if (fields[i].type == "file"){
            //         newPackage[fields[i]._id] = null;
            //     }
            //     else{
            //         newPackage[fields[i]._id] = ""
            //     }
            // }
            
            // newPackage.itemDetail = [...selectedItems];
            // newPackage.count = selectedItems.map((i) => Number(i.count)).reduce((a, b) => a+b , 0)
            // if(newPackage.count + packageCountTillNow <= totalObjectCount){
            //     setObjects([...objects, newPackage])
            //     setSelectedItems([]);
            //     // Remove added items
            //     let addedIds = selectedItems.map(s => s._id);
            //     let newItems = items.filter(i => addedIds.indexOf(i._id) < 0);
            //     // setItems(newItems);
            //     let oldPackages = [...packages];
            //     oldPackages[pIndex].itemDetail = [...newItems];
            //     setPackages(oldPackages)
            //     showToast("Package Added", false);
            // }
            // else{
            //     showToast(`Cannot add more than ${totalObjectCount} items`, true);
            // }
            
            
        }
        setErrors(newErrors);
    }

    const handleClosePackage = () => {
        setOpenPackage("");
    }

    const handleScanChange=(manual=false)=>{
        if(![ "SPI"].includes(spiType) || bundleType!="itemToPackage") return
        let checked = !manual
        let tempAll=deepCopy_Object(completeFields)
        let componentId="SKU_Number"
        let compIndex = tempAll?.findIndex(dl => dl._id == componentId)
        // console.log(compIndex,"compIndex",tempAll)
        if(componentId &&  compIndex>=0){
            if (checked  && ["shipmentItemOnly","SPI"].includes(spiType)) {
                let temComp = getItemPackageComponent(tempAll,tempAll[compIndex])
                // console.log("temComp",temComp)
                
                tempAll.splice(compIndex, 1, temComp)
            }
            else{
                let initialCom=allFields.find(dl=> dl._id == componentId) || {}
                tempAll.splice(compIndex, 1, {...initialCom})
            }
            // console.log("tempAll",tempAll)
            tempAll=tempAll.map(dl=>({...dl,disabled:false}))
            setCompleteFields([...tempAll])
        }
        setValue("itemMasterDetail",{})
        setSelectedItems([])
    }

    useEffect(() => {
        handleScanChange(isManual)
    }, [isManual])

    useEffect(() => {
        let out=generateFormFields(allFields,allUom)
        setCompleteFields(out)
    }, [template,allUom])

    useEffect(() => {
        setSelectedItems([]);
        setSelectedFile(null);
        setErrors({});
    }, [open])

    return (
        <>
            {
                isManual &&
                <>
                    <ViewFileModal
                        showFileData={showFileData}
                        fileData={fileData}
                        handleClose={handleCoseFileModal}
                        header={getTableHeader()}
                    />
                    <Grid container sx={{mt: 1}}>
                        <Grid container item sm={4} alignItems="center">
                            
                        </Grid>
                        <Grid container item sm={8} justifyContent="end" alignItems="end">
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Box border={1} sx={{ padding: "5px", borderColor: 'grey.500', borderRadius: "4px", width: "fit-content" }}>
                                    <a target="_blank" rel="noopener noreferrer" download>
                                        <Button
                                            variant="contained"
                                            spacing={1}
                                            className='RF_sampledownload-btn'
                                            sx={{ height: "34px", fontSize: "12px", marginRight: "5px", borderRadius: "8px", backgroundColor: "black" }}
                                            onClick={downloadSample}
                                        >
                                            <Download /> Sample Download
                                        </Button>
                                    </a>
                                    <FormControl spacing={1} sx={{ marginRight: "5px", marginTop: "2px" }}>
                                        <input
                                            type="file"
                                            name="upload"
                                            id="upload"
                                            className='RF_fileupload-input'
                                            onChange={handleFileChange} ref={fileInputRef} key={shellState}
                                        />
                                    </FormControl>
                                    <Button
                                        variant="outlined"
                                        spacing={1}
                                        className='RF_outline-btn'
                                        sx={{ marginRight: "5px", borderRadius: "8px" }}
                                        disabled={selectedFile == null} onClick={handleDeleteFile}
                                        >
                                        <Delete />
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        spacing={1}
                                        className='RF_outline-btn'
                                        color="primary"
                                        sx={{ marginRight: "5px", borderRadius: "8px" }}
                                        onClick={() => handleUploadFile("view")}
                                        // disabled={!fileData || fileData.length  == 0} onClick={handleShowFileModal}
                                    >
                                        <Visibility />
                                    </Button>
                                    {
                                        fileLoading 
                                        ?
                                            <>
                                                <CircularProgress size="2rem" sx={{color: theme.themeOrange}}/>
                                                <Typography sx={{ ...textClasses.t12n, ml: 1}}>Please wait...</Typography>
                                            </>
                                        :
                                            <Button
                                                variant="contained"
                                                spacing={1}
                                                className={selectedFile == null ? "" : 'RF_uploadfile-btn'}
                                                sx={{ height: "34px", fontSize: "12px", marginRight: "5px", borderRadius: "8px" }}
                                                disabled={selectedFile == null} onClick={() => handleUploadFile("upload")}
                                            >
                                                <FileUpload /> Upload File
                                            </Button>
                                    }
                                    
                                </Box>
                            </Box>
                        </Grid>
                        
                    </Grid>
                    <Divider sx={{mt: 1}}/>
                </>
            }
            
            {
                objects.length > 0 &&
                PackageList
            }
            <PackageDialog
                open={openPackage}
                onClose={handleClosePackage}
                items={selectedItems}
                setSelectedAsnItems={setSelectedItems}
                setValue={setValue}
                DynamicForm={DynamicForm}
                handleRemoveSelection={handleRemoveSelection}
                handleSubmit={handleSubmit}
                handlePackageAddAndOther={(data, cb) => {
                    handlePackageAddAndOther(data, () => {
                        cb();
                        setSelectedItems([]);
                        // Remove added items
                        let addedIds = selectedItems.map(s => s._id);
                        let newItems = items.filter(i => addedIds.indexOf(i._id) < 0);
                        let oldPackages = [...packages];
                        oldPackages[pIndex].itemDetail = [...newItems];
                        setPackages(oldPackages)
                        showToast("Package Added", false);

                    })
                    
                }}
                normalInward
            />
            
            <Grid container sx={{p: 2, pt: 1}}>
                {/* <ViewExcelDialog
                    showFileData={showFileData}
                    fileData={fileData}
                    handleClose={handleCoseFileModal}
                /> */}
                <Grid container>
                    {
                        selectedItems.length > 0 &&
                        <>
                            {/* <Button variant='contained' size='small' sx={{...buttonClasses.small, minHeight: "40px", backgroundColor: theme.themeOrange, minWidth: "150px", ml: 2}} onClick={handleCreatePackageAndDetail}>
                                Create Package & Edit Detail
                            </Button> */}
                            <Button variant='contained' size='small' sx={{...buttonClasses.small, minHeight: "40px", backgroundColor: theme.themeOrange, minWidth: "150px", ml: 2}} onClick={handleCreatePackage}>
                                Create Package
                            </Button>
                        </>
                    }
                </Grid>
                <Grid container sx={{mt: 2}} justifyContent="center">
                    <div style={{overflow: "auto", width: "1250px", border: `1px solid ${theme.themeGray}`, borderRadius: "8px"}}>
                        <Table header={header} rows={rows} addItemRow={addItemRow} countTillNow={countTillNow} packageObj={packageObj} handleDeleteItem={handleDeleteItem} remaining={remaining}/>
                        {apiDataIndex >= 0 && items[apiDataIndex] && items[apiDataIndex].itemDetail && Object.keys(items[apiDataIndex].itemDetail).length > 0 && <DetailDialog
                            data={apiDataIndex != null ? items[apiDataIndex] || {} : null}
                            onClose={handleCloseDetailDialog}
                        />}
                    </div>
                    
                </Grid>
                
            </Grid>
        </>
                
            
    )
}

const Table = ({header, rows, addItemRow, remaining, packageObj, handleDeleteItem}) => {
    return (
        <Paper elevation={0}>
            <table
                style={{
                  
                width:"inherit",
                whiteSpace: "nowrap",
                borderRadius: "8px",
                border: `1px solid ${theme.themeGray}`
                }}
            >
                <thead style={{backgroundColor: theme.themeGray}}>
                    <tr className='roundedRow'>

                        {
                            header.slice(0, 1).map((head, i) => {
                                return (
                                    <td style={{padding: "13px 10px 13px 10px", width: "50px", ...textClasses.boldText, textAlign: "center"}} key={i}>{head}</td>
                                )
                            })
                        }
                        {
                            header.slice(1, 2).map((head, i) => {
                                return (
                                    <td style={{padding: "13px 10px 13px 10px", width: "140px", ...textClasses.boldText, textAlign: "center"}} key={i}>{head}</td>
                                )
                            })
                        }
                        {
                            header.slice(2, header.length).map((head, i) => {
                                return (
                                    <td style={{padding: "13px 10px 13px 10px", width: "200px", ...textClasses.boldText, textAlign: "center"}} key={i}>{head}</td>
                                )
                            })
                        }
                        <td style={{padding: "13px 10px 13px 10px", width: "100px", ...textClasses.boldText, textAlign: "center"}}>Action</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        rows.length > 0
                        &&
                        <>
                            {
                                rows.map((row, i) => (
                                    <tr
                                        key={i}
                                        style={{borderBottom: "1px solid #0000002B"}}   
                                    >
                                        {
                                            row.map((col, j) => {
                                                return (
                                                    // <td component="th" scope="row" style={{...textClasses.normalText, textAlign: "left", overflowWrap: "break-word", padding: "5px"}}>
                                                    <td style={{padding: "10px 5px 10px 10px", ...textClasses.normalText, textAlign: "center"}}>
                                                        {col}
                                                    </td>    
                                                )
                                            })
                                        }
                                        <td style={{padding: "10px 5px 10px 10px", ...textClasses.normalText, textAlign: "center"}}>
                                            <IconButton size='small' sx={{backgroundColor: "red", color: "white"}} onClick={() => handleDeleteItem(i)}>
                                                <Remove fontSize='inherit'/>
                                            </IconButton>
                                        </td>
                                    
                                    </tr>
                                ))
                            }  
                            
                        </>    
                    }
                    {
                        <tr
                        style={{borderBottom: "1px solid #0000002B"}}   
                        >
                            {
                                <td style={{padding: "10px 5px 10px 10px", ...textClasses.normalText, "textAlign": "center"}}>
                                    <IconButton sx={{backgroundColor: theme.themeOrange, color: "white"}} size="small" onClick={()=> addItemRow()} disabled={remaining <= 0}>
                                        <AddOutlined/>
                                    </IconButton>
                                </td>
                            }
                        
                        </tr>  
                    }
                </tbody>
            </table>
        </Paper>
        
    )
}
