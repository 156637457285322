

function NumberInt(data) {
    return data
}

export const component = ({ type = "jobName",filter=[]}) => {

    if (type == "jobName") {
        return {
            "_id": "jobName",
            "type": "select",
            "label": "Search By Job Name",
            "description": "Search By Job Name",
            "placeholder": "Search By Job Name",
            "elementType": "Selection Type",
            "sortType": NumberInt(2),
            "value": "",
            "dynamic": true,
            "auth": true,
            "apiBindingValue": "name",
            "apiBindingKey": "name",
            "extraValue": [{ label: "_id", value: "_id" }],
            "apiDomain": "",
            "baseURL":"",
            "apiEndPoint": "jobType/getJobTypesList",
            "apiMethod": "post",
            "name": "Supplier List",
            "apiDataReturnKey": "data",
            "apiParameters": [
                {
                    "label": "fetch_for",
                    "value": "getForFilter"
                },
                {
                    "label": "page",
                    "value": NumberInt(1)
                },
                {
                    "label": "limit",
                    "value": NumberInt(25)
                },
                {
                    "label": "jobName",
                    "value": "onSearch"
                }
            ],
            "setTargetValue": false,
        }
    }
    else if (type == "mappingArea") {
        return {
            "_id": "MappingArea",
            "type": "select",
            "label": "Search By Mapping Area",
            "description": "Search By Mapping Area",
            "placeholder": "Search By Mapping Area",
            "elementType": "Selection Type",
            "sortType": NumberInt(2),
            "value": "",
            "dynamic": true,
            "auth": true,
            "apiBindingValue": "value",
            "apiBindingKey": "label",
            "extraValue":[],
            "apiDomain": "",
            "baseURL":"",
            "apiEndPoint": "areaMapping/getAreaMapping",
            "apiMethod": "post",
            "name": "Supplier List",
            "apiDataReturnKey": "data",
            "apiParameters": [
                {
                    "label": "allAreaNameOnly",
                    "value": true
                },
                {
                    "label": "page",
                    "value": NumberInt(1)
                },
                {
                    "label": "limit",
                    "value": NumberInt(25)
                },
                {
                    "label": "areaName",
                    "value": "onSearch"
                }
            ],
            "setTargetValue": false,
        }
    }
    else {
        return {
            _id: "SKU",
            type: "select",
            icon: "simple-icon-note",
            label: "Search By SKU ID/NAME",
            description: "Search By SKU ID/NAME",
            placeholder: "Search By SKU ID/NAME",
            className: "form-control",
            subtype: "text",
            regex: "",
            handle: true,
            forType: "all",
            elementType: "Selection Type",
            value: "",
            required: true,
            dynamic: true,
            auth: true,
            apiBindingValue: "label",
            apiBindingKey: "value",
            apiDomain: "/v1/",
            apiEndPoint: "inventory/getDistinctMasterList",
            "renderOption" : [
                {
                    "label" : "",
                    "multiple" : false,
                    "prefix" : "",
                    "postfix" : "",
                    "key" : "label",
                    "keys" : [

                    ]
                },
                {
                    "label" : "",
                    "multiple" : false,
                    "prefix" : " (",
                    "postfix" : ")",
                    "key" : "SKU_Detail"
                }
            ],
            apiMethod: "post",
            name: "Item Master List",
            apiDataReturnKey: "data",
            apiParameters: [
              {
                label: "isSkipTemplate",
                value: true,
              },
              {
                label: "master_Type",
                value: "item-master",
              },
              {
                label: "page",
                value: 1,
              },
              {
                label: "limit",
                value: 25,
              },
              {
                label: "base_ID",
                value: "onSearch",
                },
                ...filter
            ],
            "setTargetValue": true,
            "targetAPI": "scan/scanEntityMaterData",
            "targetAPIMethod": "post",
            "targetParameters": [
                {
                    "label": "isSkipTemplate",
                    "value": true
                },
                {
                    "label": "base_ID",
                    "value": "$self.value"
                },
                {
                    "label": "master_Type",
                    "value": "item-master"
                }
            ],
            "targetApiDataReturnKey": "data",
            "onChangeSet": {
                "SKU_ID": "$response.formData.SKU_ID",
            },
            "values": [],
          };
    }
}