import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { getFieldsLayoutForEntry } from '../TemplateInputConfiguration';
import { useDispatch } from 'react-redux';
import { getTemplate, trackUser } from '../../../redux/actions';
import GenericLoader from '../Generic/GenericLoader';

let initial_state = { single: [], multi: [] }



export const set_level_values = (initial_obj = {}, key, data = "", initial_index = 0) => {
    const keys = key.split(".");

    const setNestedValue = (obj, keys, value, index) => {
        const currentKey = keys[index];
        if (index === keys.length - 1) {
            obj[currentKey] = value;
        }
        else {
            if (!obj[currentKey]) {
                obj[currentKey] = {};
            }
            setNestedValue(obj[currentKey], keys, value, index + 1);
        }
    };

    setNestedValue(initial_obj, keys, data, initial_index);
    return initial_obj;
};
export default function StaticFormRender({ formHooks, tempAttr=[], disabled=[],smWidth=4}) {
  
    const [loader, setLoader] = useState(false);
    const { register, control, errors, action ,getValues,setValue,reset} = formHooks;
    const autoFillOnFocus = "";
    let attr=tempAttr?.map((dl)=>({...dl,disabled:disabled?.includes(dl?._id)})) || []

    let handleChange = (e, _id) => {
        let split_id = _id.split(".") || []

        let value=e.target.value || ""
        
        if (split_id?.length > 1 && getValues && reset) {
            let values = getValues()
            set_level_values(values, "fff._id", value, 0)
            reset(values)
        }
        else {
            if(_id=="pan") value=(value+"").toUpperCase()
            setValue(_id,value)
        }
    }

    const layout =  getFieldsLayoutForEntry(attr, register, control, autoFillOnFocus, errors, action,handleChange) || initial_state;
    return (
        <>
            {loader
                ? <Grid data-testid='loader' container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15 }}>
                    <Grid item><GenericLoader /></Grid>
                </Grid>
                :
                <Grid container>
                    <Grid item container spacing={3}>
                        {
                            layout.single.map((field, i) => {
                                return (
                                    <Grid container item xs={6} sm={smWidth} justifyContent={"center"} alignItems="center" key={i}>
                                        {field}
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                    <Grid container spacing={2} sx={{ mt: 0.1 }}>
                        {
                            layout.multi.map((field, i) => {
                                return (
                                    <Grid container item xs={6} sm={smWidth} justifyContent={"center"} alignItems="center" key={i}>
                                        {field}
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Grid>
            }
        </>
    )
}