import {
    Divider, Grid, MenuItem, Paper, Select, Typography, Button, Modal, Card, Breadcrumbs,
    Autocomplete, TextField, FormControlLabel, FormLabel, Switch, Box, FormControl, IconButton, CircularProgress
} from '@mui/material';
import React, { useEffect, useState } from 'react'
import theme, { cardClasses, inputClasses, textClasses, buttonClasses } from '../../../../static/styles/theme';
import SelectImage from "../../../../..//src/static/images/data_entry/select_location.svg"
import { Link, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAllTemplates, getSelectedTemplate, saveInwardEntry,
    getAsnDetails, getTemplateTypes, initCanvas, saveInwardEntryThroughExcel,
    getReceivingConfig,getCompany,getFloorMapping
} from '../../../../redux/actions';

import { ShipmentSection } from "./Sections"
import ShipmentAdded from './Sections/ShipmentAdded';
import { useForm } from 'react-hook-form';
import history from '../../../../services/history';
import XLSX from 'sheetjs-style'
import * as FileSaver from 'file-saver';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { getCamelCaseText, showToast } from '../../../../services/functions';
import GenericSection from './Sections/GenericSection';
import { capitalizeSentence, deepCopy_Object } from '../../../../services/functions';
import { FETCH_ASN_DETAIL } from '../../../../redux/types';
import { convertToCanvasObject } from '../../../../redux/reducers/Canvas';
import axios from '../../../../services/Api';
import { Add, Delete, Download } from '@mui/icons-material';
import GenericLoader from '../../../Components/Generic/GenericLoader';


const style = (width) => {
    return {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width,
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 3,
    }
};

const useSpiType = (template) => {
    const [spiType, setSpiType] = useState(null);

    useEffect(() => {
        ////("Template changed in useSPI", template);
        if (template) {
            let type;
            if (!template.otherTemplate || template.otherTemplate.length == 0) {
                type = "shipmentOnly";
            }
            else if (template.otherTemplate.length == 2) {
                type = "SPI";
            }
            else if (template.otherTemplate.length == 1 && template.otherTemplate[0].templateType == "packageDetailTemplate") {
                type = "shipmentPackageOnly";
            }
            else {
                type = "shipmentItemOnly";
            }

            setSpiType(type);

        }
    }, [template])

    return spiType;
}

const getSpiType = (template) => {
    if (template) {
        let type;
        if (!template.otherTemplate || template.otherTemplate.length == 0) {
            type = "shipmentOnly";
        }
        else if (template.otherTemplate.length == 2) {
            type = "SPI";
        }
        else if (template.otherTemplate.length == 1 && template.otherTemplate[0].templateType == "packageDetailTemplate") {
            type = "shipmentPackageOnly";
        }
        else {
            type = "shipmentItemOnly";
        }

        return type;
    }
}



export default function Inward() {

    const { state } = useLocation();
    const { asnDetail = null, inwardTemplate, asnTemplateHeader, receivingMethod } = state ? state : {}
    ////("ASN Linked", state);
    const dispatch = useDispatch();
    const fileInputRef = React.useRef();
    // For asn linked entry
    const [rMethod, setReceivingMethod] = useState(null)

    const [receivingConfig, setReceivingConfig] = useState({
        "pkgMultiple": {
            "isActive": false,
        },
        "itemMultiple": {
            "isActive": false,
        },
        "receivingTypeVisibility": {
            "isActive": false
        },
        "isWarehouseInput": {
            "isActive": false,
            "isRequired": false
        },
        "isFloorInput": {
            "isActive": false,
            "isRequired": false
        }
    })
    const asn = useSelector(state => state.asnEntry.asnDetail)
    /// 

    const template = useSelector(state => state.inward.selectedTemplate);
    const spiType = useSpiType(template);

    const allTemplates = useSelector(state => state.templates.allTemplates).filter((t) => {
        if (!asnDetail) return true;
        if ((rMethod && rMethod.value == "pallets" || rMethod && rMethod.value == "packages") && (getSpiType(t) == "SPI" || getSpiType(t) == "shipmentPackageOnly")) {
            return true;
        }
        else if (rMethod && rMethod.value == "bothPackageItem" && getSpiType(t) == "SPI") {
            return true;
        }
        else if (rMethod && rMethod.value == "bothPalletItem" && getSpiType(t) == "SPI") {
            return true;
        }
        else if (rMethod && rMethod.value == "packagingPI" && getSpiType(t) == "SPI") {
            return true;
        }
        else if (rMethod && rMethod.value == "palletingPI" && getSpiType(t) == "SPI") {
            return true;
        }
        else if (rMethod && rMethod.value == "individualItems" && getSpiType(t) == "shipmentItemOnly") {
            return true;
        }
        return false;
    });
    const dimensions = useSelector(state => state.inventory.parcelgetDimensions);
    const [packages, setPackages] = useState([]);
    const [showSuccess, setShowSuccess] = useState(null);
    const [bundleType, setBundleType] = useState("packageToItem")
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [excelDataEntry, setExcelDataEntry] = useState({ action: false, file: "" });
    const [wfDetail, setWfDetail] = useState({ warehouseList: [], floorList: [],selectedWarehouse:{},selectedFloor:{}});

    const [templateLoader, setTemplateLoader] = useState(false);
    const [asnLoader, setAsnLoader] = useState(false);
    const [submitInProgress, setSubmitInProgress] = useState(false)


    const { register, handleSubmit, watch, getValues, setValue, control, reset, formState: { errors } } = useForm();

    let allMethods = [
        { label: "Pallets", value: "pallets", templateType: ["SPI", "SP"], isBoth: true, isPackage: true },
        { label: "Packages", value: "packages", templateType: ["SP", "SPI"], isPackage: true, isBoth: true },
        { label: "Both(Package & Item)", value: "bothPackageItem", templateType: ["SPI"], isBoth: true },
        { label: "Both(Pallet & Item)", value: "bothPalletItem", templateType: ["SPI"], isBoth: true },
        { label: "Loose or Individual Items", value: "individualItems", templateType: ["SI"], isItem: true },
        // {label:"Only Quantity",value:"onlyQuantity",templateType:["S"],isBoth:false,isPackage:false,isItem:false},
        // {label: "Packaging (Package & Item)",value: "packagingPI",templateType: ["SPI"],isBoth: true},
        // {label: "Palleting (Package & Item)",value: "palletingPI",templateType: ["SPI"],isBoth: true},
    ]

    const isDataSubmittable = (() => {
        const totalObjectCount = watch("packageQuantity");
        if (!totalObjectCount || totalObjectCount < 1) {
            // ////("c1");
            return false;
        }

        // console.log("gggg",excelDataEntry);
        if (spiType == "SPI" && excelDataEntry?.action) {
            return excelDataEntry?.file?.length > 0
        }
        if (spiType == "shipmentOnly") {
            return true;
        }
        let objectsTillNow;
        if (asnDetail?.ASN_Id) {
            // objectsTillNow = packages[0].itemDetail.map((i) => Number(i.count)).reduce((a, b) => a+b , 0);
            objectsTillNow = packages.map((i) => Number(i.count)).reduce((a, b) => a + b, 0);
        }
        else if ((spiType == "SPI" || spiType == "shipmentPackageOnly") && bundleType == "packageToItem") {
            objectsTillNow = packages.length;
        }
        else {
            objectsTillNow = packages.map((i) => Number(i.count)).reduce((a, b) => a + b, 0);
            // objectsTillNow = packages.map((i) => Number(i.count)).reduce((a, b) => a+b , 0);
        }

        ////("Objects till now", objectsTillNow);
        if (asnDetail?.ASN_Id && objectsTillNow > 0) {
            // For partial Receiving of ASN
            return true;
        }
        if (objectsTillNow != totalObjectCount) {
            ////("c2");
            return false;
        }
        if (spiType == "SPI") {
            let incompletePackages = packages.filter((p, i) => {
                let itCount = p && p.itemDetail && p.itemDetail.length > 0 && p.itemDetail.map((it) => Number(it.count)).reduce((a, b) => a + b, 0) || 0;
                return p.count != itCount
            })
            if (incompletePackages.length > 0) {
                // ////("c3");
                return false;
            }
        }
        return true;
    })();

    const handleOpenSuccessModal = (response) => {
        setShowSuccess(response);
    }
    const handleCloseSuccessModal = () => {
        setShowSuccess(null);
    }

    const handleTemplateChange = (e) => {
        if (e.target.value != "") {
            setSelectedTemplate(e.target.value);
        }
        else {
            setSelectedTemplate("-");
        }
    }

    const handleAddMore = () => {
        setSelectedTemplate(null);
        setPackages([]);
        setBundleType("packageToItem")
        handleCloseSuccessModal();
    }

    const saveEntry = () => {

        handleSubmit((data) => {
            if (excelDataEntry?.action) {
                if (!excelDataEntry?.file || excelDataEntry?.file?.length == 0) {
                    showToast("Excel File required!", true);
                    return;
                }

                const formData = new FormData();
                formData.append('file', excelDataEntry?.file[0]);
                formData.append('template_id', template._id);
                formData.append('isSelectFromList',data?.isSelectFromList?.customShipment==true);
                delete data["isSelectFromList"];
                if(wfDetail?.selectedWarehouse?.value){
                    formData.append('warehouseId', wfDetail?.selectedWarehouse?.value); 
                }
                if(wfDetail?.selectedFloor?.value){
                    formData.append('floorId', wfDetail?.selectedFloor?.value);
                    formData.append('warehouseId', wfDetail?.selectedFloor?.warehouseId);
                }
                formData.append('shipmentData', JSON.stringify({ ...data }));
                setSubmitInProgress(true)
                dispatch(
                    saveInwardEntryThroughExcel(formData, (response) => {
                        setSubmitInProgress(false)
                        if (response) {
                            handleOpenSuccessModal(response);
                            if (fileInputRef.current) {
                                fileInputRef.current.value = ""
                            }
                        }
                    })
                )
            }
            else {
                
                let payload = {
                    template_id: template._id,
                    shipmentData: {}
                }
               
                payload['isSelectFromList']= data?.isSelectFromList?.customShipment==true; 
                delete data["isSelectFromList"];
                payload['shipmentData']=data

                if (fileInputRef.current) {
                    fileInputRef.current.value = ""
                }
                if(wfDetail?.selectedWarehouse?.value){
                    payload['warehouseId']= wfDetail?.selectedWarehouse?.value; 
                }
                if(wfDetail?.selectedFloor?.value){
                    payload['floorId']= wfDetail?.selectedFloor?.value;
                    payload['warehouseId']= wfDetail?.selectedFloor?.warehouseId;
                }

                if (asnDetail?.ASN_Id) {
                    payload.receivingMethod = rMethod.value
                    if (rMethod.value == "packagingPI" || rMethod.value == "palletingPI" || rMethod.value == "bothPalletItem") {
                        payload.receivingMethod = "bothPackageItem"
                    }
                    payload.ASN_ID = asnDetail?.ASN_Id
                }
                if (bundleType == "itemToPackage") {
                    payload.shipmentData.packageQuantity = packages.length;
                }
                if (data.customShipment) {
                    payload.customShipment = data.customShipment
                }

                if (spiType == "shipmentOnly") {
                    payload['mappingArea'] = data.mappingArea;
                    delete payload.shipmentData.mappingArea;
                }
                else {
                    let isRejectedInventory=false
                    let packagesToSubmit = deepCopy_Object(packages);
                    packagesToSubmit = packagesToSubmit.map((p) => {
                        let newP = deepCopy_Object(p);
                        if (newP.USN_Value && Number(newP.count) > 1) {
                            newP.USN_Value = ""
                        }
                        if (newP.itemDetail) {
                            let newItemDetail = newP.itemDetail.map((i) => {
                                let newItem = deepCopy_Object(i);
                                if (Number(newItem.count) > 1) {
                                    newItem.USN_Value = ""
                                }
                                delete newItem["tableHeader"]
                                delete newItem["itemDetail"]
                                delete newItem["disabled"]
                                delete newItem["defaultItems"]
                                delete newItem["sameForNext"]
                                delete newItem["maxCount"]
                                delete newItem["product_ID"]
                                delete newItem["masterDetail"]
                                delete newItem["asnDetail"]
                                delete newItem["masterType"]
                                if(!isRejectedInventory && newItem?.receivingType=="rejectedQuantity"){
                                    isRejectedInventory=true
                                }
                                return newItem;
                            })
                            newP.itemDetail = [...newItemDetail];
                        }
                        else{
                            if(!isRejectedInventory && newP?.receivingType=="rejectedQuantity"){
                                isRejectedInventory=true
                            }
                        }
                        delete newP["tableHeader"]
                        delete newP["itemMasterDetail"]
                        delete newP["disabled"]
                        delete newP["defaultItems"]
                        delete newP["sameForNext"]
                        delete newP["maxCount"]
                        delete newP["product_ID"]
                        delete newP["masterDetail"]
                        delete newP["asnDetail"]
                        delete newP["masterType"]
                        return newP
                    })

                    payload.isRejectedInventory=isRejectedInventory

                    if (asnDetail?.ASN_Id && (spiType == "shipmentItemOnly")) {
                        payload.otherData = [...packagesToSubmit];
                        payload.shipmentData.packageQuantity = packagesToSubmit.map((p) => p.count).reduce((a, b) => a + b, 0)
                    }

                    else if (asnDetail?.ASN_Id && (spiType == "SPI" || spiType == "shipmentPackageOnly")) {
                        payload.otherData = [...packagesToSubmit];
                        payload.shipmentData.packageQuantity = packagesToSubmit.length
                    }

                    else {
                        payload.otherData = [...packagesToSubmit];
                    }
                }
                // console.log("payload",payload)
                setSubmitInProgress(true)
                dispatch(
                    saveInwardEntry(payload, (response) => {
                        setTimeout(() => {
                            setSubmitInProgress(false)
                        }, 500)
                        if (response) {
                            handleOpenSuccessModal(response);
                        }
                    })
                )
            }
        }, (errors) => {

            let errorField = Object.keys(errors)[0];
            showToast(`${errors[errorField].message}`, true);

        })();


    }

    const handleClearAll = (event,skipShipment=false) => {
        if (fileInputRef.current) {
            fileInputRef.current.value = ""
        }
        if(!skipShipment){
            // console.log("skipShipment",skipShipment)
            let resetD = { ...getValues() };
            let rKeys = Object.keys(resetD);
            for (let i = 0; i < rKeys.length; i++) {
                if (asnDetail?.ASN_Id && rKeys[i] == "packageQuantity") {
                    continue;
                }
                else if (asnDetail?.ASN_Id && rKeys[i] != "packageQuantity") {
                    resetD[rKeys[i]] = ""
                }
                else if (rKeys[i] == "mappingArea") {
                    resetD[rKeys[i]] = "PUT IT ANY WHERE"
                }
                else {
                    resetD[rKeys[i]] = resetD[rKeys[i]] && Array.isArray(resetD[rKeys[i]]) ? [] : ""
                }
            }
            reset({
                ...resetD
            });
        }
        setValue("isSelectFromList",{})
        setValue("customShipment","")
        setPackages([]);
        setTemplateLoader(false);
        setSubmitInProgress(false)
    }

    const showWarehosueFloorHandler=(configDetail)=>{
        if (configDetail?.isWarehouseInput?.isActive==true || configDetail?.isFloorInput?.isActive==true) {   
            if (configDetail?.isWarehouseInput?.isActive) {
                dispatch(getCompany({ type: "warehouse", status: "active",projection:{name:1,_id:1} }, (data) => {
                    let list=data?.data?.map((dl=>({label:dl?.name,value:dl?._id}))) || []
                    if(list?.length==1){
                        selectWarehouse(list?.at(0),list || [])
                    }
                    else{
                        setWfDetail({ warehouseList: list || [], floorList: [], selectedWarehouse: {}, selectedFloor: {} });
                    }
                }))
            }
            else {
                dispatch(getCompany({ type: "floor", status: "active", includeCount: false,projection:{name:1,_id:1,warehouseId:1}}, (data) => {
                    let list=data?.data?.map((dl=>({label:dl?.name,value:dl?._id,warehouseId:dl?.warehouseId}))) || []
                    if(list?.length==1){
                        selectFloor(list?.at(0) || {},list || [])
                    }
                    else{
                        setWfDetail({ warehouseList: [], floorList:list || [], selectedWarehouse: {}, selectedFloor: {} });
                    }
                }))
            }
            if(configDetail?.isWarehouseInput?.isRequired==true || configDetail?.isFloorInput?.isRequired==true){
                return;
            }
            else{
                fetchMappingArea()
            }
            
        }
        else{
            fetchMappingArea()
        }
    }

    const selectWarehouse = (selectedWarehouse = {}, dataList = []) => {
        let tempWfDetail = { warehouseList:dataList, selectedWarehouse, floorList: [], selectedFloor: {} }
        setWfDetail({...tempWfDetail})
        // console.log(tempWfDetail,"tempWfDetailW")
        if (receivingConfig?.isFloorInput?.isActive == true && selectedWarehouse?.value) {
            dispatch(getCompany({ type: "floor", status: "active", warehouseId: selectedWarehouse?.value || "", projection: { name: 1, _id: 1, warehouseId: 1 } }, ({ data = [] }) => {
                let list=data?.map((dl=>({label:dl?.name,value:dl?._id,warehouseId:dl?.warehouseId}))) || []
                if (list?.length == 1) {
                    // console.log(list,"list")
                    selectFloor(list?.at(0) || {}, list || [],selectedWarehouse,dataList)
                }
                else{
                    setWfDetail({warehouseList:dataList, selectedWarehouse, floorList: list || [], selectedFloor: {}})
                    fetchMappingArea(selectedWarehouse?.value || "", "")
                }
            }))
        }
        else{
            fetchMappingArea(selectedWarehouse?.value || "", "")
        }
    }

    const selectFloor = (selectedFloor={}, dataList = [],selectedWarehouse={},warehouseList=[]) => {
        // console.log("tempWfDetailF",{selectedFloor, dataList,selectedWarehouse,warehouseList})
        setWfDetail({selectedWarehouse,warehouseList, floorList: dataList, selectedFloor: selectedFloor || {} });
        fetchMappingArea(selectedWarehouse?.value || "",selectedFloor?.value || "")
    }
    const fetchMappingArea = (warehouseId="", floorId="") => {
        dispatch(getFloorMapping({warehouseId,floorId}))
        setPackages([])
    }


    useEffect(() => {
        if (fileInputRef.current) {
            fileInputRef.current.value = ""
        }
        dispatch(
            getTemplateTypes((data) => {
                if (data && data.success) {
                    dispatch(
                        initCanvas(convertToCanvasObject(data.data))
                    )
                }
            })
        )
        ////("Removing Asn detail now");

        dispatch({ type: FETCH_ASN_DETAIL, payload: { data: null, count: 0 } })
        dispatch(
            getAllTemplates({ type: "wms", templateType: "inwardDataEntry", status: "published", fetchAttributes: !!asnDetail?.ASN_Id }, (data) => {
                if (data && data.length == 1) {
                    setSelectedTemplate(data[0])
                }
            })
        )
        if (asnDetail?.ASN_Id) {
            ////("Going to call Asn detail now");
            setAsnLoader(true);
            dispatch(getAsnDetails({
                ASN_ID: asnDetail.ASN_Id,
                projection: {
                    "closed_by": 0, "isCloseforceFully": 0, "received_by": 0, "template_id": 0, "purchaseOrderID": 0, "ASN_ItemId": 0,
                    "base": 0, "created_by": 0, "savingMethod": 0, "itemMasterId": 0, "__v": 0
                },
                shipmentProjection: {
                    "closed_by": 0, "isCloseforceFully": 0, "received_by": 0, "template_id": 0, "purchaseOrderID": 0, "product_ID": 0, "ASN_ItemId": 0,
                    "base": 0, "created_by": 0, "savingMethod": 0, "itemMasterId": 0, "__v": 0
                },
                page: 1, limit: 1
            }, (data) => {
                let method = allMethods?.filter(m => m.value == receivingMethod)[0];
                setReceivingMethod(method);
                setSelectedTemplate(inwardTemplate);
                setAsnLoader(false);
            }))
        }
        dispatch(getReceivingConfig({ configType: "asnReceiving" }, (data) => {
            setReceivingConfig(data?.configDetail || {})
           
        }))
    }, []);

useEffect(() => {
    handleClearAll();
    if (selectedTemplate != null) {
        setTemplateLoader(true);
        ////("Dispatching", selectedTemplate._id);
        dispatch(
            getSelectedTemplate({ template_id: selectedTemplate._id, fetchForInward: true, ASN_ID: asnDetail?.ASN_Id || "" }, () => {
                setTemplateLoader(false)
            })
        )
        showWarehosueFloorHandler(receivingConfig || {})
    }
}, [selectedTemplate])


return (
    <>
    
        <Grid>
            <Typography sx={{ ...textClasses.cardTitle }}>{asnDetail?.ASN_Id ? "ASN Receiving" : "Inward Data Entry"}</Typography>
            <Breadcrumbs sx={{ mb: 1 }}>
                <Typography sx={{ ...textClasses.normalText, fontSize: "12px", color: theme.themeOrange }}>
                    <Link style={{ color: theme.themeOrange, textDecoration: "none" }} to={asnDetail?.ASN_Id ? "/dashboard/inventory/asn" : "/dashboard/inventory/all"}>
                        Manage {asnDetail?.ASN_Id ? "ASN" : "Inventory"}
                    </Link>
                </Typography>

                <Typography sx={{ ...textClasses.normalText, fontSize: "12px" }} >
                    {asnDetail?.ASN_Id ? `ASN Receiving - (${asnDetail?.ASN_Id})` : "Inward Data Entry"}
                </Typography>
            </Breadcrumbs>
            <Paper sx={{ ...cardClasses.basic, mt: 1 }}>
                <Grid container>
                    {
                        asnDetail?.ASN_Id &&
                        <Grid container item sm={3} xs={12} sx={{ p: 2 }} alignContent="center" justifyContent={"left"}>
                            <Autocomplete
                                value={rMethod}
                                onChange={(e, option) => {
                                    setReceivingMethod(option);
                                    setSelectedTemplate(null);
                                    if (fileInputRef.current) {
                                        fileInputRef.current.value = ""
                                    }
                                    handleClearAll()
                                }}
                                getOptionLabel={(option) => capitalizeSentence(option.label || "")}
                                size='small'
                                options={allMethods}
                                sx={{
                                    ...inputClasses.shadowField,
                                    minWidth: "220px",
                                    m: 0,
                                    ml: 2,
                                    ".MuiInputBase-input": {
                                        fontSize: "14px",
                                        color: "#455A64"
                                    }
                                }}
                                renderInput={(params) => <TextField sx={{ color: "#455A64", ...textClasses.normalText }} {...params} label="Receiving Method" />}
                            />
                        </Grid>
                    }
                    <Grid container item sm={9} xs={12} sx={{ p: 2 }} alignContent="center" justifyContent={asnDetail?.ASN_Id ? "end" : "left"}>
                        <Autocomplete
                            value={selectedTemplate}
                            onChange={(e, option) => {
                                setSelectedTemplate(option);
                                setExcelDataEntry({ action: false, file: "" })
                                handleClearAll()
                            }}
                            getOptionLabel={(option) => capitalizeSentence(option.name || "")}
                            size='small'
                            options={allTemplates}
                            sx={{
                                ...inputClasses.shadowField,
                                minWidth: "220px",
                                m: 0,
                                ml: 2,
                                ".MuiInputBase-input": {
                                    fontSize: "14px",
                                    color: "#455A64"
                                }
                            }}
                            renderInput={(params) => <TextField sx={{ color: "#455A64", ...textClasses.normalText }} {...params} label="Template" />}
                        />
                        {receivingConfig?.isWarehouseInput?.isActive == true && selectedTemplate &&
                            <Autocomplete
                                id="warehouse"
                                value={wfDetail?.selectedWarehouse?.value && wfDetail?.selectedWarehouse || null}
                                onChange={(e, option) => {
                                    selectWarehouse(option, wfDetail?.warehouseList || [])
                                    setExcelDataEntry({ action: false, file: "" })
                                    handleClearAll({}, true)
                                }}
                                size='small'
                                options={wfDetail?.warehouseList || []}
                                getOptionLabel={(option) => option.label || ""}  // Ensure it displays the label properly
                                sx={{
                                    ...inputClasses.shadowField,
                                    minWidth: "220px",
                                    m: 0,
                                    ml: 2,
                                    ".MuiInputBase-input": {
                                        fontSize: "14px",
                                        color: "#455A64"
                                    }
                                }}
                                renderInput={(params) => <TextField
                                    sx={{ color: "#455A64", ...textClasses.normalText }}
                                    {...params}
                                    label={`Warehouse ${receivingConfig?.isWarehouseInput?.isRequired == true ? "*" : ""}`}
                                />
                                }
                            />
                        }
                        {receivingConfig?.isFloorInput?.isActive == true && selectedTemplate &&
                            <Autocomplete
                                id="floor"
                                value={wfDetail?.selectedFloor?.value && wfDetail?.selectedFloor || null}
                                onChange={(e, option) => {
                                    selectFloor(option, wfDetail?.floorList || [], wfDetail?.selectedWarehouse, wfDetail?.warehouseList || [])
                                    setExcelDataEntry({ action: false, file: "" })
                                    handleClearAll({}, true)
                                }}
                                size='small'
                                getOptionLabel={(option) => option.label || ""}  // Ensure it displays the label properly
                                options={wfDetail?.floorList || []}
                                sx={{
                                    ...inputClasses.shadowField,
                                    minWidth: "220px",
                                    m: 0,
                                    ml: 2,
                                    ".MuiInputBase-input": {
                                        fontSize: "14px",
                                        color: "#455A64"
                                    }
                                }}
                                renderInput={(params) => <TextField
                                    sx={{ color: "#455A64", ...textClasses.normalText }}
                                    {...params}
                                    label={`Floor ${receivingConfig?.isFloorInput?.isRequired == true ? "*" : ""}`}
                                />
                                }
                            />
                        }
                    </Grid>
                    {selectedTemplate != null && spiType == "SPI" && (!asnDetail || !asnDetail.ASN_Id) &&
                        <Grid container item sm={6} xs={6} sx={{ p: 2 }} alignContent="center" justifyContent={"end"}>
                            <FormControlLabel
                                control={
                                    <>
                                        <Switch
                                            color="warning"
                                            checked={excelDataEntry?.action == true}
                                            onChange={(event) => {
                                                setExcelDataEntry({ action: event.target.checked, file: "" })
                                                if (fileInputRef.current) {
                                                    fileInputRef.current.value = ""
                                                }
                                                reset()
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                        <FormLabel sx={{ color: theme.themeOrange }}>Excel Data Entry</FormLabel>
                                    </>
                                }
                            />
                        </Grid>
                    }


                </Grid>
                <Divider sx={{ m: "0 5px 0 5px" }} />
                {
                    (asnLoader || templateLoader) &&
                    <Grid container justifyContent={"center"} sx={{ m: 2 }}>
                        <GenericLoader />
                    </Grid>
                }
                {
                    (!asnLoader && !templateLoader && selectedTemplate != null && template) &&
                    <>
                        <Grid container sx={{ p: 2 }}>
                            <Grid item sm={12} xs={12}>
                                <ShipmentSection
                                    template={template}
                                    spiType={spiType}
                                    asn={asn}
                                    asnTemplateHeader={asnTemplateHeader}
                                    receivingMethod={receivingMethod}
                                    formHooks={{ register, control, setValue, errors,getValues,reset }}
                                />
                            </Grid>
                            {
                                excelDataEntry?.action == true
                                    ? <ExcelDataEntrySPI
                                        otherTemplate={template?.otherTemplate || []}
                                        setExcelDataEntry={setExcelDataEntry}
                                        excelDataEntry={excelDataEntry}
                                        fileInputRef={fileInputRef}
                                    />
                                    :
                                    <>
                                        {
                                            spiType == "SPI" && (!asnDetail || !asnDetail.ASN_Id) &&
                                            <Grid item sm={12} xs={12} sx={{ mt: 1, p: 1 }}>
                                                <FormControlLabel
                                                    control={
                                                        <>
                                                            <FormLabel sx={{ color: theme.themeOrange }}>Package to Item</FormLabel>
                                                            <Switch
                                                                color="warning"
                                                                checked={bundleType == "itemToPackage"}
                                                                onChange={(event) => {
                                                                    setBundleType(event.target.checked ? "itemToPackage" : "packageToItem")
                                                                    setPackages([])
                                                                }}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                            <FormLabel sx={{ color: theme.themeOrange }}>Item to Package</FormLabel>
                                                        </>
                                                    }


                                                />
                                            </Grid>
                                        }
                                        {
                                            (spiType != "shipmentOnly") &&
                                            <Grid item sm={12} xs={12} sx={{ mt: 1 }}>
                                                <GenericSection
                                                    bundleType={bundleType}
                                                    sectionKey={spiType == "shipmentItemOnly" ? "item" : "package"}
                                                    template={template}
                                                    spiType={spiType}
                                                    asn={asn}
                                                    asnTemplateHeader={asnTemplateHeader}
                                                    receivingMethod={rMethod}
                                                    watch={watch}
                                                    objects={packages}
                                                    setObjects={setPackages}
                                                    dimensions={dimensions}
                                                    receivingConfig={receivingConfig}
                                                />
                                            </Grid>
                                        }
                                    </>

                            }

                            <Grid container item direction={"row"} sm={12} xs={12} sx={{ mt: 2 }}>
                                <Button variant='outlined' size='small' sx={{ ...buttonClasses.outlined, color: theme.themeOrange, minWidth: "150px" }} onClick={handleClearAll}>
                                    Clear All
                                </Button>
                                <Button variant='contained' size='small'
                                    sx={{
                                        ...buttonClasses.small, minHeight: "40px",
                                        backgroundColor: theme.themeOrange, minWidth: "150px", ml: 2
                                    }}
                                    onClick={() => saveEntry()}
                                    disabled={!isDataSubmittable || submitInProgress}
                                >
                                    Submit
                                </Button>
                            </Grid>

                            <Modal
                                open={showSuccess != null}
                            >
                                <Card sx={style("50%")}>
                                    <ShipmentAdded
                                        spiType={spiType}
                                        templateId={showSuccess && showSuccess.templateId}
                                        shipmentId={showSuccess && showSuccess.shipmentId}
                                        packageCount={showSuccess && showSuccess.totalPackage}
                                        itemCount={showSuccess && showSuccess.totalItem}
                                        handleAddMore={handleAddMore}
                                    />
                                </Card>
                            </Modal>
                        </Grid>

                    </>
                }
                {
                    (!asnLoader && !templateLoader && !selectedTemplate && !template) &&
                    <Grid container direction={"column"} justifyContent="center" alignItems="center" sx={{ p: 4 }}>
                        <img src={SelectImage} />
                        <Typography sx={{ ...textClasses.cardTitle, mt: 2 }}>select a template first..</Typography>
                        <Typography sx={{ ...textClasses.t12n, textAlign: "center", maxWidth: "500px", mt: 2 }}>
                            Hey, you need to select a previously created inward data entry template, to enter details for upcoming shipment and to automate the inward operation(s).
                        </Typography>
                    </Grid>
                }
            </Paper>
            <ToastContainer position="bottom-center" autoClose={false} closeOnClick={false} />
        </Grid>
    </>
)
}




const ExcelDataEntrySPI = ({ otherTemplate = [], setExcelDataEntry, excelDataEntry, fileInputRef }) => {

    const mappingLocations = useSelector(state => state.inward.allMapping);

    const inernalAPICall = async (ele) => {
        let { apiEndPoint, apiDataReturnKey = "data", apiMethod = "POST", apiBindingValue = "", apiBindingKey="" } = ele
        let values = []
        await axios.post(`/${apiEndPoint}`, {})
            .then(({ data }) => {
                if (data.success) {
                    let allData = data[apiDataReturnKey] || []
                    const tempBindingKey = apiBindingKey || ele?.keyForLabel || ""
                    const tempBindingValue=apiBindingValue || ele?.keyForValue || ""
                    values = allData.map((dl) => { return { label: dl[tempBindingKey] || "", value: dl[tempBindingValue] || "" } })
                }
                else {
                    values = []
                }
            })
            .catch((error) => {
                console.log("Error****:", error.message);
                values = []
            });
        return values;
    }

    const getTableHeader = (head, filterKey = [], excelData = {}) => {
        head.filter(f => !filterKey.includes(f._id)).map((of) => {
            let label = of.label;
            if (["select", "radio", "checkbox"].indexOf(of.type) >= 0 && of.values != null && Array.isArray(of.values) && of.values?.length) {
                label = `${label} (${of.values.map(o => o.value).join(",")})`
            }
            excelData[label] = ""
        })

    }

    const downloadSample = async () => {
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml;charset=UTF-8";
        const fileExt = ".xlsx";

        let excelData = { "Packed In": "" }, filterKeys = [], isDownload = false;
    
        let item_temp = otherTemplate?.find(dl => dl.templateType == "itemDetailTemplate")?.attributes || []
        let UOM_Exist = item_temp.findIndex(dl => dl._id == "UOM")
        // console.log("UOM_Exist1",UOM_Exist);
        if (UOM_Exist >= 0) {
            let values = await inernalAPICall(item_temp[UOM_Exist])
            item_temp.splice(UOM_Exist, 1, {
                ...item_temp[UOM_Exist],
                "values": values
            })
            isDownload = true
            // console.log("UOM_Exist2",item_temp[UOM_Exist]);
        }
        else {
            isDownload = true
        }
        // console.log("UOM_Exist3",item_temp[UOM_Exist]);

        if (isDownload) {
            item_temp.push(
                {
                    type: "select",
                    label: "Mapping Area",
                    required: true,
                    _id: "mappingArea",
                    values: mappingLocations || []
                }
            )

            getTableHeader(item_temp, filterKeys, excelData)
            filterKeys = ["UOM", "count"]
            getTableHeader(otherTemplate?.find(dl => dl.templateType == "packageDetailTemplate")?.attributes || [], filterKeys, excelData)

            const ws = XLSX.utils.json_to_sheet([excelData]);
            const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
            const buffer = XLSX.write(wb, { bookType: 'xlsx', type: "array" });
            const data = new Blob([buffer], { type: fileType });
            FileSaver.saveAs(data, "Package_Item_Template_name" + fileExt);
        }

    }

    const uploadExcelFile = (e) => {
        e.preventDefault();
        let files = e.target?.files || ""
        if (e.target?.files?.length) {
            let fileObj = files[0];
            if (!fileObj || !fileObj.name) {
                showToast("File name is required in uploaded file!")
                return;
            }
            let fileExtension = fileObj.name.split('.');
            if (!["xlsx", "xls"].includes(fileExtension[fileExtension.length - 1])) {
                showToast("You can upload excel file only!!")
                return;

            }
        }
        else {
            files = ""
        }

        setExcelDataEntry({ ...excelDataEntry, file: files })

    }

    return (
        <Paper elevation={1} sx={{ mt: 2, width: "100%" }}>
            <Grid container sx={{ backgroundColor: theme.themeGray, p: "12px", borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}
                justifyContent={"space-between"} alignItems={"center"}
            >
                <Typography sx={{ ...textClasses.boldText }}>2. Download And Upload Excel</Typography>
                {/* <Box>
                    <Button
                        sx={{ ...buttonClasses.lynkitOrangeEmpty }}
                        onClick={() => downloadSample()}
                    >
                        <DownloadIcon />Download Sample
                    </Button>
                    <Button
                        sx={{ ...buttonClasses.lynkitOrangeEmpty,ml:1 }}
                        disabled={!excelDataEntry || !excelDataEntry.file}
                        onClick={() => {
                            setExcelDataEntry({ ...excelDataEntry, file: "" })
                            if(fileInputRef.current){
                                fileInputRef.current.value = ""
                            }
                        }}
                    >
                        <Delete />Delete File
                    </Button>
            </Box> */}
            </Grid>
            <Grid container spacing={4} sx={{ margin: "12px" }} justifyContent={"center"} alignItems={"center"}>
                <Box border={1} sx={{ padding: "5px", borderColor: 'grey.500', borderRadius: "4px", width: "fit-content" }}>
                    <Button
                        variant="contained"
                        spacing={1}
                        className='RF_sampledownload-btn'
                        sx={{ height: "34px", fontSize: "12px", marginRight: "5px", borderRadius: "8px", backgroundColor: "black" }}
                        onClick={() => downloadSample()}
                    >
                        <Download /> Sample Download
                    </Button>
                    <FormControl spacing={1} sx={{ marginRight: "5px", marginTop: "2px" }}>
                        <input
                            type="file"
                            name="upload"
                            id="upload"
                            className='RF_fileupload-input'
                            onChange={uploadExcelFile}
                            ref={fileInputRef}
                        />
                    </FormControl>
                    <Button
                        variant="outlined"
                        spacing={1}
                        className='RF_outline-btn'
                        sx={{ marginRight: "5px", borderRadius: "8px" }}
                        disabled={!excelDataEntry || !excelDataEntry.file}
                        onClick={() => {
                            setExcelDataEntry({ ...excelDataEntry, file: "" })
                            if (fileInputRef.current) {
                                fileInputRef.current.value = ""
                            }
                        }}
                    >
                        <Delete />
                    </Button>
                </Box>
            </Grid>
            {/* <Grid container spacing={4} sx={{margin: "12px"}} justifyContent={"center"} alignItems={"center"}>
                <Box>
                        <input
                            type='file'
                            style={{
                                borderRadius: "12px",
                                border: "1px solid #D9D9D9",
                                fontSize: "16px",
                                lineHeight: "22px",
                                fontWeight: "400",
                                padding:"10px 20px"
                            }}
                            onChange={uploadExcelFile}
                            ref={fileInputRef}
                        />
                    <FormControl >
                    </FormControl>
                </Box>
        </Grid>      */}
        </Paper>
    )
}