import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import theme, {
  buttonClasses,
  textClasses,
} from "../../../static/styles/theme";
import CloseIcon from "@mui/icons-material/Close";
import { useCallback, useEffect, useMemo, useState } from "react";
import { selectStylesOverride } from "../../../utils/util";
import TypeAndSearch from "../../Components/TypeAndSearch";
import { Add, Delete, Edit, Remove } from "@mui/icons-material";
import { showToast } from "../../../services/functions";
import React from "react";

const SelectLocationDialog = ({
  floorOptions,
  newLocations, 
  isEdit,
  onClose,
  open,
  selectedLocation,
  setNewLocations,
  onSubmit,
  setSelectedLocation
}) => {
  const initialNewLocationData = {floorId: "", cellId: "", count: ""};
  const [newLocationData, setNewLocationData] = useState(initialNewLocationData)
  const [ignoreEffect, setIgnoreEffect] = useState(false);

  useEffect(() => {
    if (ignoreEffect) {
      setIgnoreEffect(false); // Reset the ignoreEffect flag
      return; // Exit early to skip the effect
    }
    // Proceed with the effect logic if not ignoring
    if (selectedLocation) {
      if (selectedLocation?.cellDetail?.length > 0) {
        setNewLocations(selectedLocation?.cellDetail)
      } else {
        setNewLocations([])
      }
    }
  }, [selectedLocation]);

  const selectedCount = useMemo(()=>{
    return newLocations?.reduce((acc, item)=>{
      return acc + item?.count
    },0)

  },[newLocations])
  const component = {
    _id: "cellId",
    type: "select",
    disabled: false,
    label: `Select Cell`,
    placeholder: `Select cell`,
    description: "Select Cell",
    required: true,
    dynamic: true,
    auth: true,
    apiDomain: "/v1/",
    apiEndPoint: "grid/getGridDetail",
    apiMethod: "post",
    fetched: false,
    name: "Storage List",
    apiDataReturnKey: "data",
    dontFetch:false,
    apiParameters: [
      {
        label: "fetch_for",
        value: "drop",
      },
      {
        label: "dropAllowed",
        value: true,
      },
      {
        label: "mappingArea",
        value: selectedLocation?.mappingArea ?? "",
      },
      {
        label: "floorId",
        value: newLocationData.floorId,
      },
      {
        label: "status",
        value: ["available", "partial"],
      },
      {
        label: "cellName",
        value: "$self",
      },
      {
        label: "type",
        value: ["rack", "block"],
      },
      {
        label: "page",
        value: 1,
      },
      {
        label: "limit",
        value: 25,
      },
    ],
    apiBindingKey: "name",
    apiBindingValue: "name",
    suffix: "",
    extraValue: [
      { label: "cellId", value: "cellId" },
      { label: "parentId", value: "parentId" },
      { label: "name", value: "name" },
      { label: "type", value: "type" },
    ],
  }
  const handleChangeFloor = useCallback(
    (e, option) => {
      setNewLocationData(prev=>({...prev, floorId: option?.value, floorName: option?.label}))
    },
    []
  );

  const handleAddLocation = useCallback(()=>{
    const isAlreadySelected = ()=>{
      if(newLocationData?.rackId){
        const isCellExist = newLocations?.find((item)=> item.floorId === newLocationData?.floorId && item.cellId === newLocationData?.cellId && item.rackId === newLocationData?.rackId
          )
          if(isCellExist){
            showToast(`${newLocationData?.name} for ${newLocationData?.floorName} has already selected`, true)
            return true
          }
        
      }else{
        const isCellExist = newLocations?.find(item=> item.floorId === newLocationData?.floorId && item.cellId === newLocationData?.cellId)
        if(isCellExist){
          showToast(`${newLocationData?.name} for ${newLocationData?.floorName} has already selected`, true)
          return true
        }
      }
      return false
    }
   const value = isAlreadySelected() ;
    if(value === false){
      const count = newLocations?.reduce((acc, item) => {
        return acc + item?.count;
      }, 0);
  
  
      const newLocation = {...newLocationData, count: selectedLocation?.selectedQTY - count}
      setNewLocations(prev=>([newLocation, ...prev]))
      setNewLocationData(initialNewLocationData)
    }
   
  },[newLocationData])

  const handleCheckboxChange = (event) => {
    setIgnoreEffect(true);
    if (event.target.checked) {
      setSelectedLocation({ ...selectedLocation, isSameForAll: true })
    }
    else {
      setSelectedLocation({ ...selectedLocation, isSameForAll: false });
    }
  }
  const handleChangeCell = useCallback(
    (option, newValue, item) => {
      if(newValue?.type === "rack"){
          setNewLocationData(prev=>({...prev, rackId : newValue?.cellId, cellId: newValue?.parentId, name: newValue?.name}))
        }
        else if(newValue?.type === "block"){
          if(newLocationData?.rackId){
            delete newLocationData.rackId
          }
          setNewLocationData(prev=>({...prev,  cellId: newValue?.cellId, name: newValue?.name}))
        }
      else{        
        setNewLocationData(prev=>({...prev, rackId : "", cellId: "", name: ""}))
      }
    },
    [newLocationData]
  );

  const handleRowDelete = useCallback((indx) => {
    setNewLocations((o) => o.filter((_, i) => i !== indx));
  },[]);

  const header = [
    "Floor",
    "Rack",
    "Count",
    "Action",
  ];

  const rows = newLocations.map((data, index) => {
    let row = [];
    row.push(
      <Typography>
        {data.floorName ??  "-"}
      </Typography>,
      <Typography>
        {data?.name ??  "-"}
      </Typography>,
      <ButtonGroup
        size="small"
        sx={{
          minWidth: "24px",
          ".MuiButtonGroup-grouped": { minWidth: "25px" },
        }}
      >
        <Button
          key="one"
          size="small"
          sx={{
            ...buttonClasses.smallOrange,
            backgroundColor: (data?.count  <= 1) ? "#D9D9D9" : theme.themeOrange,
            fontSize: "12px",
          }}
          disabled={data?.count <=1}
          onClick={() => {
            handleItemCountChange(-1,index);
          }}
        >
          <Remove fontSize="inherit" />
        </Button>
        <input
          type="number"
          style={{
            border: "1px solid #D9D9D9",
            maxWidth: "60px",
            textAlign: "center",
          }}
          value={data?.count ?? null}
          onChange={(e) =>
            handleItemCountChange(e, index)
          }
          onKeyDown={(e) => {
            if (["e", "E", "+", "-"].includes(e.key)) {
              e.preventDefault();
            } else if (
              e.key == "0" &&
              (e.target.value == "" || Number(e.target.value) == 0)
            ) {
              e.preventDefault();
            }
          }}
        />
        <Button
          key="three"
          size="small"
          sx={{
            ...buttonClasses.smallOrange,
            backgroundColor: (data?.count  >= data?.selectedQTY) ? "#D9D9D9" : theme.themeOrange,
            fontSize: "12px",
          }}
          disabled={data?.count  >= data?.selectedQTY}
          onClick={() => handleItemCountChange(1, index)}
        >
          <Add fontSize="inherit" />
        </Button>
      </ButtonGroup>,
      <Typography sx={{display: "flex"}}>
<IconButton
          aria-label="delete"
          key={index}
          onClick={() => handleRowDelete(index)}
        >
          <Delete sx={{ color: "red" }} />
        </IconButton>
      </Typography>
    );

    return row;
  });

  const handleItemCountChange = (e, index) => {
    const oldAddedData = [...newLocations];

    if (e == -1) {
      if (oldAddedData[index]?.count >= 2) {
        oldAddedData[index].count = oldAddedData[index].count - 1;
      }
    } else if (e == 1) {
      if (oldAddedData[index]?.count < selectedLocation?.selectedQTY) {
        oldAddedData[index].count = oldAddedData[index]?.count + 1;
      }
    } else {
      oldAddedData[index].count =
        Number(e.target.value) >= selectedLocation?.selectedQTY
          ? selectedLocation?.selectedQTY
          : e.target.value
          ? Number(e.target.value)
          : e.target.value;
    }
    setNewLocations(oldAddedData);
  };

  const handleSubmit = useCallback(() => {
    const count = newLocations?.reduce((acc, item) => {
      return acc + item?.count;
    }, 0);
    if (count > selectedLocation?.selectedQTY) {
      showToast("Selected Quantity is greater than available", true);
    }
    else{
      onSubmit()
    }
    
  }, [newLocations, selectedLocation, onSubmit]);

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        scroll={"paper"}
        PaperProps={{ sx: { width: "100%" } }}
        maxWidth={"md"}
      >
        <DialogTitle id="responsive-dialog-title">
          <Typography sx={{ ...textClasses.cardTitle }}>
            Select Location
          </Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Grid container xs={12} sx={{mb: 1}}>
            <Grid item xs={4} sx={{display: "flex", flexDirection: "row", gap: 1}}>
              <Typography>
              Old Floor:
              </Typography>
              <Typography sx={{ color: theme.themeOrange }}>
                 {selectedLocation?.floorName}
              </Typography>
            </Grid>
            <Grid item xs={4} sx={{display: "flex", flexDirection: "row", gap: 1}}>
            <Typography>
              Old Cell:
              </Typography>
              <Typography sx={{ color: theme.themeOrange }}>
                {selectedLocation?.rackName ?? selectedLocation?.cellName}
              </Typography>
            </Grid>
            <Grid item xs={4} sx={{display: "flex", flexDirection: "row", gap: 1}}>
            <Typography>
              Maximum Quantity:
              </Typography>
              <Typography sx={{ color: theme.themeOrange }}>
                {selectedLocation?.selectedQTY}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{display: "flex", flexDirection: "row", gap: 1}}>
            <FormControlLabel
                   control={
                    <Checkbox 
                    checked={selectedLocation?.isSameForAll}
                    onChange={(event)=>{handleCheckboxChange(event)}}
                    />}
                 label="I want to put all selected quantity into a single/multiple location only.considering Location wise QTY ratio same for all."
              
               />
            </Grid>
          </Grid>
        <Grid
                container
                xs={12}
                border={1}
                sx={{ borderColor: "grey.500", borderRadius: "10px" }}
                p={1}
                spacing={1}
                mt={0}
                ml={0}
                mb={1}
              >
                <Grid item xs={4}>
                  <Autocomplete
                    id="floor-label"
                    data-testid="floor-select"
                    size="small"
                    required
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <Typography sx={{ color: theme.themeOrange }}>
                            Floor*
                          </Typography>
                        }
                        sx={{
                          ...selectStylesOverride,
                          color: theme.themeOrange,
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option.label}
                      </Box>
                    )}
                    sx={{
                      width: "100%",
                      mr: 1,
                      "& legend span": {
                        color: theme.themeOrange,
                      },
                    }}
                    options={floorOptions}
                    onChange={(e, option) =>
                      handleChangeFloor(e, option)
                    }
                    value={floorOptions.find((option)=> option?.value === newLocationData?.floorId)?.label || ""}
                  />
                </Grid>

                <Grid item xs={4}>
                  <TypeAndSearch
                    component={component}
                    setEmptyOnChange={false}
                    disabled={!newLocationData?.floorId}
                    value={newLocationData?.cellId}
                    label={newLocationData?.name}
                    handleChange={(option, newValue) => {
                      handleChangeCell(option?.target.value, newValue);
                      // if (option.target.value) {
                      // }
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                <Button
                      key="one"
                      disabled={!newLocationData?.floorId || !newLocationData?.cellId || selectedCount >= selectedLocation?.selectedQTY}
                      sx={{
                        ...buttonClasses.lynkitOrangeFill,
                        backgroundColor: theme.themeOrange,
                        fontSize: "12px",
                      }}
                      onClick={() => {
                        handleAddLocation();
                      }}
                    >
                     ADD
                    </Button>
                </Grid>
              </Grid>
           {newLocations.length ? (
          <TableContainer
            component={Paper}
            sx={{
              marginTop: "2em",
              maxWidth: "100%",
              overflowX: "auto",
            }}
          >
            <Table aria-label="simple table">
              <TableHead sx={{ backgroungColor: theme.themeGray }}>
                <TableRow sx={{ backgroundColor: theme.themeGray }}>
                  {header.map((head, i) => {
                    return (
                      <TableCell
                        sx={{
                          fontSize: "13px",
                          textAlign: "center",
                          fontWeight: 600,
                        }}
                        key={i}
                      >
                        {head}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.length > 0
                  ? rows.map((row, i) => (
                      <TableRow
                        key={i}
                        sx={{
                          "& .MuiTableCell-root": {
                          },
                        }}
                      >
                        {row.map((col, j) => {
                          return (
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{
                                fontSize: "12px",
                                textAlign: "center",
                                overflowWrap: "break-word",
                              }}
                              key={j}
                            >
                              {col}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
        </DialogContent>
        <DialogActions>
          <Grid
            container
            sx={{ display: "flex", justifyContent: "flex-start", pl: 2, pb: 2 }}
          >
            <Grid item>
              <Button
                variant="outlined"
                sx={{
                  size: "small",
                  ...buttonClasses?.lynkitBlackFill,
                  minHeight: "36px",
                  minWidth: "130px",
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                size="small"
                sx={{
                  ...buttonClasses.small,
                  minHeight: "40px",
                  backgroundColor: theme.themeOrange,
                  minWidth: "150px",
                  ml: 2,
                }}
                onClick={handleSubmit}
                disabled={
                  newLocations.length === 0 ||
                  newLocations?.find(
                    (item) => !item?.floorId || !item?.cellId || !item?.count
                  )
                }
              >
               {isEdit ? "Update" :  "Submit"}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SelectLocationDialog;
