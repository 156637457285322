import {
    GET_CELL_CAPACITY,
    GET_CELL_CAPACITY_DETAIL,
    GET_CELL_CAPACITy,
    GET_CELL_STORAGE_CAPACITY,
    GET_NAVIGATION_ROUTE, GET_WAREHOUSE_CAPACITY,
    GET_UTILIZATION_ANALYTICS_BLOCK,
    GET_UTILIZATION_ANALYTICS_RACK,
    GET_CELL_ACCESSBILITY_LIST,
    GET_GRID_CELL_BEHAVIOUR_ANALYTICS,
    GET_GRID_RACK_BEHAVIOUR_ANALYTICS,
    GET_GRID_ALL_BEHAVIOUR_ANALYTICS,
    GET_GRID_BEHAVIOUR_LIST,
    GET_GRID_BEHAVIOUR_DETAIL,
} from "../types";

const intialState = {
    filledLocation: {},
    shipmentByLocation: [],
    gridView: {},
    gridData: [],
    rackData: [],
    gridCellDetails: [],
    gridRackDetails: [],
    gridALLDetails: [],
    gridCellDimentions: [],
    gridRackDimentions: [],
    gridALLDimentions: [],
    gridBlockDataByLocation:{},
    FilledLocationByShipment:{},
    navigationRoute:{},
    behaviourAnalytics:{},
    rackBehaviourAnalytics:{},
    blockBehaviourAnalytics:{},
    gridBehaviourList:[],
    gridBehaviourDetail:{},
};

const warehouseReducer = (state = intialState, { type, payload }) => {
    switch (type) {

        case "GET_FILLED_LOCATION":

            return {
                ...state,
                filledLocation: payload
            }
        case "GET_ALL_SHIPMENT_BY_LOCATION":

            return {
                ...state,
                shipmentByLocation: payload.shipments
            }
        case "GET_GRID_VIEW":

            return {
                ...state,
                gridView: payload
            }
        case "GET_GRID_DATA":

            return {
                ...state,
                gridData: payload
            }
        case "GET_GRID_CELL_DETAIL":

            return {
                ...state,
                gridCellDetails: payload
            }
        case "GET_GRID_RACK_DETAIL":

            return {
                ...state,
                gridRackDetails: payload
            }
        case "GET_GRID_ALL_DETAIL":

            return {
                ...state,
                gridALLDetails: payload
            }
        case "GET_GRID_CELL_DIMENTION":

            return {
                ...state,
                gridCellDimentions: payload
            }
        case "GET_GRID_RACK_DIMENTION":

            return {
                ...state,
                gridRackDimentions: payload
            }
        case "GET_GRID_ALL_DIMENTION":

            return {
                ...state,
                gridALLDimentions: payload
            }
        case "GET_RACK_DATA":

            return {
                ...state,
                rackData: payload
            }
        case GET_NAVIGATION_ROUTE:

            return {
                ...state,
                navigationRoute: payload
            }
        case "GET_GRID_BLOCK_DATA_BY_LOCATION":
            return { ...state, gridBlockDataByLocation: payload }
        case "VIEW_FILLED_LOCATION_BY_SHIPMENT":
            return { ...state, FilledLocationByShipment: payload }
        case GET_WAREHOUSE_CAPACITY:
            return {...state,warehouseCapacityDetail:payload}
        case GET_CELL_CAPACITy:
            return {...state,saveCellCapacity:payload}
        case GET_CELL_STORAGE_CAPACITY :
            return{...state,cellStorageCapacity:payload}
        case GET_CELL_CAPACITY_DETAIL:
            return{...state,cellCapacityDetail:payload}    
        case GET_UTILIZATION_ANALYTICS_BLOCK:
            return { ...state, utilizationAnalytics: payload }
        case GET_UTILIZATION_ANALYTICS_RACK:
            return {...state,utilizationAnalytics:payload}    
       
        case GET_GRID_CELL_BEHAVIOUR_ANALYTICS:
            return {...state,blockBehaviourAnalytics:payload}    
        case GET_GRID_RACK_BEHAVIOUR_ANALYTICS:
            return {...state,rackBehaviourAnalytics:payload}    
        case GET_GRID_ALL_BEHAVIOUR_ANALYTICS:
            return {...state,behaviourAnalytics:payload}    
        case GET_GRID_BEHAVIOUR_LIST:
            return {...state,gridBehaviourList:payload}    
        case GET_GRID_BEHAVIOUR_DETAIL:
            return {...state,gridBehaviourDetail:payload}    
        default:
            return state;
    }
}
export default warehouseReducer;

