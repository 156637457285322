import { DRAWER_OPEN, DRAWER_CLOSE, GRID_HEATMAP_OPEN_CLOSE } from "../types";

const INIT_STATE = {
    drawerOpen: false,
    heatMapOpen:false
};
  
export default (state = INIT_STATE, action) => {
  // console.log("red",action)
    switch (action.type) {
      case DRAWER_OPEN: {
        return {...state, drawerOpen: true};
      }
      case DRAWER_CLOSE: {
        return {...state, drawerOpen: false};
      }
      case GRID_HEATMAP_OPEN_CLOSE: {
        return {...state, heatMapOpen: !action?.payload};
      }
      
      default:
        return state;
    }
}