import React, { useEffect, useState } from 'react'
import { useHistory, useParams, useLocation, Link } from "react-router-dom";
import { Breadcrumbs, Grid, Tabs, Tab, Typography, Button, IconButton, Divider, Collapse, DialogContent, Dialog, TextField, FormLabel, FormControl, FormControlLabel, Radio, Autocomplete, TablePagination, Box, Checkbox } from '@mui/material'
import theme, { textClasses, buttonClasses, inputClasses } from "../../../static/styles/theme"
import GenericLoader from '../../Components/Generic/GenericLoader';
import { useDispatch, useSelector } from 'react-redux'
import { dateTimeFormater, textCapitalize } from '../../../services/functions';
import { getSkuJourneyMapDetails } from '../../../redux/actions';
import {
    FiberManualRecord as FiberManualRecordIcon
} from "@mui/icons-material";
import { useTheme, useMediaQuery } from '@mui/material';
import { DataNotFound } from '../../Components/TemplateInputConfiguration';
import { PaginationComp } from '../../Components/CustomTable/GenericTable';

const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
    }
    return result;
};

const FullJourneyMap = ({ skuId, setTabIndex, fromDate, toDate }) => {
    const dispatch = useDispatch();
    const muiTheme = useTheme();
    const isMd = useMediaQuery(muiTheme.breakpoints.up('md'));
    const isSm = useMediaQuery(muiTheme.breakpoints.between('sm', 'md'));
    const [loader, setLoader] = useState(false);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(9);
    const [journeyMapDetails, setJourneyMapDetails] = useState({ data: [], totalRows: 0 })

    const rowGridItems = isMd ? 3 : 2;
    let emptyGridInserted = rowGridItems - (journeyMapDetails?.data?.length % rowGridItems)
    if (journeyMapDetails?.data?.length == 0) {
        emptyGridInserted = 0;
    }
    while (emptyGridInserted != 0) {
        journeyMapDetails?.data?.push({ doNotRender: true })
        emptyGridInserted--;
    }

    // Split the data into chunks of the determined size
    const chunks = chunkArray(journeyMapDetails?.data, rowGridItems);

    // Reverse only alternate chunks, regardless of their length
    const processedData = chunks.map((chunk, index) => (index % 2 === 1 ? chunk.reverse() : chunk)).flat();

    const getJourneyMapDetailsData = (page, limit) => {
        const payload = {
            SKU_ID: skuId,
            page: page,
            limit: limit,
        };
        if (fromDate && toDate) {
            payload.fromDate = fromDate;
            payload.toDate = toDate;
        }
        dispatch(getSkuJourneyMapDetails(payload, (data) => {
            setLoader(false);
            if (data?.success) {
                setJourneyMapDetails({ ...journeyMapDetails, data: data?.data, totalRows: data?.count })
            }
        }));
    };

    useEffect(() => {
        setLoader(true);
        getJourneyMapDetailsData(page + 1, limit);
    }, [skuId, page, limit, fromDate, toDate]);
   
    return loader ? (
        <Grid container data-testid="loader" direction="column" justifyContent="center" alignItems="center" sx={{ p: 15 }}>
            <Grid item>
                <GenericLoader />
            </Grid>
        </Grid>
    ) : (<> {journeyMapDetails?.data?.length > 0 ? (
        <>
            <Grid container sx={{ m: 2, p: 2 }} data-testid="journey-container">
                {processedData?.map((item, index) => (
                    item?.doNotRender ? <Grid item
                        xs={12}
                        sm={6}
                        md={4}></Grid> : <Grid
                            container
                            item
                            key={index}
                            xs={12}
                            sm={6}
                            md={4}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexDirection: 'column',
                                flexGrow: 1,
                                position: 'relative', // Needed for ::before and ::after to position properly
                                // Right border using ::after to move it up
                                ...((index !== 0 && isMd && (index + 1) % (rowGridItems * 2) === 0) && {
                                    '&::after': {
                                        content: '""',
                                        position: 'absolute',
                                        top: '-40px', // Moves the right border up by 40px
                                        right: 0,
                                        height: 'calc(100% + 0px)', // Extends the height to cover the gap
                                        width: '6px',
                                        backgroundColor: theme.themeGray, // Border color
                                    },
                                }),
                                // Left border using ::before to move it up
                                ...((index !== 0 && isMd && index % (rowGridItems * 2) === 0) && {
                                    '&::before': {
                                        content: '""',
                                        position: 'absolute',
                                        top: '-40px', // Moves the left border up by 40px
                                        left: 0,
                                        height: 'calc(100% + 0px)', // Extends the height to cover the gap
                                        width: '6px',
                                        backgroundColor: theme.themeGray, // Border color
                                    },
                                }),
                                // Right border using ::after for smaller screens (if needed)
                                ...((index !== 0 && isSm && (index + 1) % (rowGridItems * 2) === 0) && {
                                    '&::after': {
                                        content: '""',
                                        position: 'absolute',
                                        top: '-40px',
                                        right: 0,
                                        height: 'calc(100% + 0px)',
                                        width: '6px',
                                        backgroundColor: theme.themeGray,
                                    },
                                }),
                                // Left border using ::before for smaller screens (if needed)
                                ...((index !== 0 && isSm && index % (rowGridItems * 2) === 0) && {
                                    '&::before': {
                                        content: '""',
                                        position: 'absolute',
                                        top: '-40px',
                                        left: 0,
                                        height: 'calc(100% + 0px)',
                                        width: '6px',
                                        backgroundColor: theme.themeGray,
                                    },
                                }),
                            }}
                        >
                        <Grid item data-tesid="job-name">
                            <Typography
                                sx={{
                                    ...textClasses.t15nBold,
                                    color: theme.themeBlack,
                                    ml: 1,
                                    whiteSpace: 'normal',
                                }}
                            >
                                {textCapitalize(item?.jobType)} : {dateTimeFormater(item?.scannedDate)}
                            </Typography>
                        </Grid>

                        <Grid item sx={{ ...textClasses.normalText, color: theme.themeOrange, ml: 1, whiteSpace: 'nowrap' }}>
                            <Typography>scanned by : {item?.scannedBy}</Typography>
                            <Typography>scanned QTY : {item?.scannedCount || 0}</Typography>
                            <Typography>Stock Level : {item?.currentStock || 0}</Typography>
                        </Grid>

                        {/* Conditionally update the entire grid at index 5 and 6 */}

                        <Grid
                            item
                            sx={{
                                width: '100%',
                                height: '40px',  
                                mt: 2
                            }}
                        >
                            <Grid
                                item
                                sx={{
                                    width: '100%',
                                    background: theme.themeGray,
                                    height: '6px',  // Adjust the height to be smaller
                                    display: 'flex', // Aligns the icon properly
                                    alignItems: 'center', // Vertically centers the icon inside the smaller height
                                    flexShrink: 0
                                }}
                            >
                                <FiberManualRecordIcon
                                    sx={{
                                        color: item?.transactionType=="inward"
                                            ? theme.themeGreen
                                            : item?.transactionType=="outward"
                                                ? theme.themeRed
                                                : theme.themeYellow,
                                        ml: 1,
                                        size: 'small'
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                position: 'sticky',
                bottom: 0,
                backgroundColor: 'white',
                zIndex: 1000,
            }}>
                <PaginationComp
                    pageCount={journeyMapDetails?.totalRows}
                    pageNumber={page}
                    rowsPerPage={limit}
                    handleChangePage={(event, pagevalue) => {
                        setPage(pagevalue);
                    }}
                    handleChangeRowsPerPage={(event) => {
                        setLimit(+event.target.value);
                        setPage(0);
                    }}
                />
            </Box>

        </>) : (
        <Grid container justifyContent="center" alignItems="center" sx={{ p: 4 }}>
            <DataNotFound label="No data found for this selected date range." />
        </Grid>
    )

    }

    </>)
}

export default FullJourneyMap;