import { Button, Checkbox, Grid,Box,Popper,Paper } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getTableHeader, updateTableHeader } from '../../../redux/actions';
import { showToast } from '../../../services/functions';
import { buttonClasses, textClasses } from '../../../static/styles/theme'
import {ClickAwayListener} from '@mui/base';


export default function GenericCustomiseHeader({tableName, tableType}) {

    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [customiseHeader, setCustomiseHeader] = useState([]);

    const open = Boolean(anchorEl);

    const tableHeader = useSelector(state => state.customHeader[tableName]);


    
    const handleOpen = (e) => {
        setAnchorEl(e.currentTarget);
        if(tableHeader.validHeader){
            setCustomiseHeader([...tableHeader.validHeader.map((h) => h._id)])
        }
    }

    const handleCheck = (e, id) => {
        let old = [...customiseHeader];
        let index = old.indexOf(id)
        if ( index >= 0) {
            old.splice(index, 1)
        }
        else{
            old.push(id)
        }
        setCustomiseHeader(old);
    }

    const handleCheckAll = () => {
        let mandatory = tableHeader.allHeader.filter(h => h.isRequired);
        if(tableHeader.allHeader && tableHeader.allHeader.length > 0 && customiseHeader.length < tableHeader.allHeader.length){
            setCustomiseHeader([...tableHeader.allHeader.map(h => h._id)])
        }
        else{
            setCustomiseHeader([...mandatory.map(h => h._id)])
        }
    }

    const handleSaveHeader = () => {
        if (customiseHeader.length > 0) {
            
            dispatch(
                updateTableHeader({
                    tableType: tableType,
                    validHeaders: customiseHeader
                }, (res) => {
                    if (res) {
                        if(res.success) {
                            dispatch(
                                getTableHeader(tableName, {tableType: tableType})
                            )
                            setAnchorEl(null);
                        }
                        showToast(res.message, !res.success)
                    }
                })
            )
        }
        else{
            showToast("Table headers cannot be blank", true);
        }
    }

    useEffect(() => {
        dispatch(
            getTableHeader(tableName, {tableType: tableType})
        )
    }, [tableType])


    return (
        <>
            {tableHeader?.allHeader?.length &&
                <>
                    <Button variant='outlined' size='mediam' sx={{ ...buttonClasses.lynkitOrangeEmpty }} onClick={handleOpen}>
                        Customise Table
                    </Button>
                    <Popper
                        open={open}
                        anchorEl={anchorEl}
                        placement="bottom-start"
                        modifiers={[
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, 0], // Adjust the offset if needed
                                },
                            },
                        ]}
                        style={{ zIndex: 1300 }}
                        disablePortal
                    >
                        <ClickAwayListener onClickAway={(event) => {
                            if (anchorEl && anchorEl.contains(event.target)) {
                                // Do not close the popper if the user clicks inside the TextField
                                return;
                            }
                            setAnchorEl(null);
                            setCustomiseHeader([])
                        }}>
                            <Paper sx={{
                                maxHeight: '330px', width: '220px', display: 'flex',
                                flexDirection: 'column', overflow: 'hidden',
                                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                            }}>
                                {/* Sticky button at the bottom */}
                                <Grid container justifyContent={"center"} sx={{ position: 'sticky', bottom: 0, background: '#fff', zIndex: 1, padding: '8px' }}>
                                    <Button
                                        variant='outlined'
                                        size='small'
                                        sx={{ ...buttonClasses.lynkitBlackEmpty, textAlign: 'center', minWidth: '150px' }}
                                        onClick={handleSaveHeader}
                                        disabled={!customiseHeader.length} // Disable if no headers are selected
                                    >
                                        Update Header
                                    </Button>
                                </Grid>

                                {/* Scrollable section */}
                                <Box sx={{ overflowY: 'auto', flexGrow: 1, padding: '8px' }}>
                                    <Grid container alignItems="center"
                                        sx={{ ...textClasses.normalText }}
                                    >
                                        <Checkbox
                                            size='small'
                                            checked={customiseHeader.length === tableHeader.allHeader.length}
                                            onClick={handleCheckAll}
                                        />
                                        <span>Select All</span>
                                    </Grid>
                                    {tableHeader?.allHeader?.map((h, i) => (
                                        <Grid
                                            container
                                            key={i}
                                            alignItems="center"
                                            sx={{ ...textClasses.normalText }}
                                        >
                                            <Checkbox
                                                size='small'
                                                value={h._id}
                                                checked={customiseHeader.includes(h._id)}
                                                onClick={(e) => handleCheck(e, h._id)}
                                                disabled={h.isRequired}
                                            />
                                            <span>{h.fieldName}</span>
                                        </Grid>
                                    ))}
                                </Box>
                            </Paper>
                        </ClickAwayListener>
                    </Popper>
                </>
            }
        </>
    )
}
