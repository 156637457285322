import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";

import theme, {
  textClasses,
  buttonClasses,
} from "../../../../../static/styles/theme";
import { useCallback, useMemo, useState } from "react";
import { selectStylesOverride } from "../../../../../utils/util";
import TypeAndSearch from "../../../TypeAndSearch";
import { Add, Remove, DeleteOutline } from "@mui/icons-material";
import GenericTable from "../../../CustomTable/GenericTable";
import { deepCopy_Object, showToast } from "../../../../../services/functions";
import { AutoSizer } from "react-virtualized";
import { FixedSizeGrid } from "react-window";
const AreaUtilizationStep2 = ({

  capacityMatrix,
  capacityMatrixOptions,
  handleSubmitDimensionsForStep2,
  initialCapacityMatrix,
  initialRowData,
  inventoryTypes,
  setAcceptedInventoryType,
  setCapacityMatrix,
  setSKU_IDs,
  setStorageCell,
  setWeightCapacity,
  SKU_IDs,
  storageCell,
  weightCapacity,
  cellCapacityDetail,
}) => {
  const [selectedInventoryOptions, setSelectedInventoryOptions] = useState(cellCapacityDetail?.capacityDetail?.acceptedInventoryType?.map(dl => ({ label: dl, value: dl })) || []);
  const [InventoryForAddingEntity, setInventoryForAddingEntity] = useState();
  const initialID = { SKU_ID: "", maxCount: 1, type: "", label: "" };
  const [selectedData, setSelectedData] = useState(initialID);
  const [pageNumber, setPageNumber] = useState(1)
  const [pageLimits, setPageLimits] = useState(10)
  const headers = ["Entity ID", "Entity Type", "Max Count", "Action"]

  const weightOptions = [
    { label: "kg", value: "kg" },
    { label: "gm", value: "gm" },
  ];

  const component = {
    _id: { InventoryForAddingEntity },
    type: "select",
    icon: "simple-icon-note",
    label: "SKU",
    description: "SKU",
    placeholder: "SKU",
    className: "form-control",
    subtype: "text",
    regex: "",
    handle: true,
    forType: "all",
    elementType: "Selection Type",
    value: "",
    required: true,
    dynamic: true,
    auth: true,
    apiBindingValue: "label",
    apiBindingKey: "value",
    apiDomain: "/v1/",
    apiEndPoint: "inventory/getDistinctMasterList",
    "renderOption" : [
      {
          "label" : "",
          "multiple" : false,
          "prefix" : "",
          "postfix" : "",
          "key" : "label",
          "keys" : [

          ]
      },
      {
          "label" : "",
          "multiple" : false,
          "prefix" : " (",
          "postfix" : ")",
          "key" : "SKU_Detail"
      }
  ],
    apiMethod: "post",
    name: "Item Master List",
    apiDataReturnKey: "data",
    dontFetch:false,
    apiParameters: [
      // {
      //   label: "template_id",
      //   value: "64d9fa9f3a88eacaf69998f0",
      // },
      {
        label: "isSkipTemplate",
        value: true,
      },
      {
        label: "master_Type",
        value: "item-master",
      },
      {
        label: "page",
        value: 1,
      },
      {
        label: "limit",
        value: 25,
      },
      {
        label: "base_ID",
        value: "onSearch",
      },
      {
        label: "dataEntryType",
        value: InventoryForAddingEntity === "boxOrPackages" ? "package" : "item",
      },
    ],
    values: [],
    fetched: false,
  };

  const handleDelete = (item, index) => {
    setSKU_IDs(oldSKU_IDs => {
      // Create a new array by filtering out the item at the specified index
      const newSKU_IDs = oldSKU_IDs.filter((_, itemIndex) => itemIndex !== index);
      return newSKU_IDs;
    });
  };
  const handleItemCountChange = useCallback((e, index) => {
    let oldEntries = [...SKU_IDs];
    if (e == -1) {
      if (oldEntries[index].maxCount >= 2) {
        oldEntries[index].maxCount = oldEntries[index].maxCount - 1
      }
    }
    else if (e == 1) {
      oldEntries[index].maxCount = oldEntries[index].maxCount + 1
    }
    else {
      e.target.value !== "0" ? oldEntries[index].maxCount = e.target.value : oldEntries[index].maxCount = Number(e.target.value)
    }
    setSKU_IDs(oldEntries);
  }, [SKU_IDs, setSKU_IDs])

  const rows = useMemo(() => {
    const data = SKU_IDs?.length && SKU_IDs?.map((item, i) => {
      let row = [];
      row.push(
        <Typography
          sx={{ ...textClasses.normalText, color: theme.themeOrange }}
        >
          {item.SKU_ID ?? "-"}
        </Typography>
      );
      row.push(
        <Typography
          sx={{ ...textClasses.normalText }}
        >
          {item.type ?? "-"}
        </Typography>
      );
      row.push(
        <Typography
          sx={{ ...textClasses.normalText, display: "flex", flexDirection: "row", justifyContent: "center" }}
        >
          <ButtonGroup
            size="small"
            sx={{
              minWidth: "24px",
              ".MuiButtonGroup-grouped": { minWidth: "25px" },
            }}
          >
            <Button
              key="one"
              size="small"
              sx={{ ...buttonClasses.smallOrange, fontSize: "12px" }}
              onClick={() => handleItemCountChange(-1, i)}
            >
              <Remove fontSize="inherit" />
            </Button>
            <input
              type="number"
              style={{
                border: "1px solid #D9D9D9",
                maxWidth: "60px",
                fontSize: "12px",
                textAlign: "center",
              }}
              value={item?.maxCount}
              onChange={(e) => handleItemCountChange(e, i)}
              onWheel={(event) => event.target.blur()}
              onKeyDown={(e) => {
                if (["e", "E", "+", "-"].includes(e.key)) {
                  e.preventDefault();
                } else if (
                  e.key == "0" &&
                  (e.target.value == "" || Number(e.target.value) == 0)
                ) {
                  e.preventDefault();
                }
              }}
            />
            <Button
              key="three"
              size="small"
              sx={{ ...buttonClasses.smallOrange, fontSize: "12px" }}
              onClick={() => handleItemCountChange(1, i)}
            >
              <Add fontSize="inherit" />
            </Button>
          </ButtonGroup>
        </Typography>
      );
      row.push(<Typography sx={{ display: "flex", "flexDirection": "row", justifyContent: "center" }}>
        <IconButton
          sx={{ color: "red" }}
          disabled={item.SKU_ID === "default"}
          aria-label="edit"
          onClick={() => handleDelete(item, i)}
        >
          <DeleteOutline />
        </IconButton>
      </Typography>)
      return row;
    });
    return data
  }, [SKU_IDs])

  const handleSelectInventory = useCallback(
    (event, value, valueOption) => {
      let filteredOptions = []
      if (value === true) {
        filteredOptions = [valueOption, ...selectedInventoryOptions,]
      }
      else {
        filteredOptions = selectedInventoryOptions?.filter(
          (option) => option.value !== valueOption?.value
        );
      }
      setSelectedInventoryOptions(filteredOptions);
      setAcceptedInventoryType((prev) => ({
        ...prev,
        [valueOption?.value]: { ["isActive"]: value },
      }));
      let tempSKU = [...SKU_IDs]
      if (value === true) {
        let appendRowItem = initialRowData?.find(dl => dl.type == valueOption.value)
        tempSKU.push(appendRowItem)
      }
      else {
        tempSKU = tempSKU?.filter(dl =>
          filteredOptions.map(item => item.value).includes(dl.type)
        )

      }
      setSKU_IDs(tempSKU)
    },
    [setSelectedInventoryOptions, selectedInventoryOptions, SKU_IDs]
  );

  const handleChangeInventory = useCallback(
    (option) => {
      setInventoryForAddingEntity(option?.value);
      setSelectedData((prev) => ({ ...prev, type: option?.value }));
    },
    [setInventoryForAddingEntity, setSelectedData]
  );

  const handleSelectCategory = useCallback(
    (e, option) => {
      // Early return if the selected option does not exist in capacityMatrix
      if (!capacityMatrix[option]) {
        showToast("Please select the related Matrix", true);
        return;
      }

      // Check if the option has already been selected in other matrices, excluding "notApplicable"
      const isOptionAlreadySelected = Object.entries(capacityMatrix).some(([matrix, values]) => {
        return matrix !== option && e !== "notApplicable" && values[e];
      });

      if (isOptionAlreadySelected) {
        showToast("This option has already been selected for other matrix", true);
        return;
      }

      // Update the capacityMatrix for the selected option
      const updatedOption = Object.keys(capacityMatrix[option]).reduce((acc, item) => {
        acc[item] = item === e;
        return acc;
      }, {});

      // Using functional update to ensure we always have the latest state
      setCapacityMatrix(prevMatrix => ({
        ...prevMatrix,
        [option]: updatedOption
      }));
    },
    [setCapacityMatrix, capacityMatrix]
  );

  const handleChangeCapacityMatrix = useCallback((value, option) => {
    if (value === true) {
      setCapacityMatrix((prev) => ({
        ...prev,
        [option]: {
          isPrimary: false,
          isSecondary: false,
          notApplicable: true,
        },
      }));
    } else {
      const tempCapacityMatrix = capacityMatrix;
      delete tempCapacityMatrix[option];
      setCapacityMatrix((prev) => {
        const newState = { ...prev };
        delete newState[option];
        return newState;
      });
    }
  }, [setCapacityMatrix, capacityMatrix])

  const handleItemCount = (direction) => {
    if (direction == 1) {
      setSelectedData((prev) => ({ ...prev, maxCount: prev.maxCount + 1 }));

    } else if (direction == -1) {
      if (selectedData?.maxCount > 1) {
        setSelectedData((prev) => ({ ...prev, maxCount: prev.maxCount - 1 }));
      }
    } else {
      setSelectedData((prev) => ({
        ...prev,
        maxCount: direction.target.value !== "0" ? direction.target.value : Number(direction.target.value),
      }));
    }
  };

  const handleSelectMaximumWeight = (e) => {
    setWeightCapacity((prev) => ({ ...prev, maxWeight: e.target.value }))
  }

  const clearStates = () => {
    setSelectedInventoryOptions([])
    setInventoryForAddingEntity([])
    setCapacityMatrix(initialCapacityMatrix)
    setSKU_IDs(initialRowData)
    setSelectedData(initialID)
    setWeightCapacity({ maxWeight: "", unit: "" })
  }
  const numberOfRows = Math.ceil(
    (storageCell?.length || 0) / 4
  );
  const cellRenderer = ({ columnIndex, rowIndex, style }) => {
    const index = rowIndex * 4 + columnIndex; // Assuming 3 columns
    const itemdata = storageCell?.map(
      (dl) => dl.value
    );
    const item = itemdata[index];
    return (
      <div style={{ ...style,padding:'1px' }}>
        {/* <CustomChip
          data={item || []}
          makeDisabled={false}
          handleDelete={""}
          readLabelKey={"$self"}
        /> */}
        {item && (
          <div style={{ margin: '6px' }}>
            <Grid
            container
            direction={"row"}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              backgroundColor: theme.themeLightOrange,
              border: `1px solid ${theme.themeOrange}`,
              borderRadius: "12px",
              pl: 1,
              pr: 1,
              minHeight: "32px",
              ml: 2,
              mr: 2
            }}
          >
            <Grid item sm={10} xs={8}>
              {item && item.length > 12 ? (
                <Tooltip arrow placement="top" title={item}>
                  <Typography
                    sx={{
                      ...textClasses.normalText,
                      color: theme.themeOrange,
                      textAlign: "start",
                    }}
                  >
                    {item && item.length > 20
                      ? item.substring(0, 20) + "..."
                      : item}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography
                  sx={{
                    ...textClasses.normalText,
                    color: theme.themeOrange,
                    textAlign: "start",
                  }}
                >
                  {item}
                </Typography>
              )}
            </Grid>
            <Grid item xs={2} sx={{ textAlign: "end" }}>
                <IconButton
                  sx={{ alignContent: "right", padding: '1px' }}
                  onClick={() => {
                    const filteredData = storageCell.filter((_, i) => i !== index);
                    setStorageCell(filteredData);
                  }}
                >
                  <Cancel sx={{ color: theme.themeOrange }} />
                </IconButton>
              </Grid>
          </Grid>
          </div>
        )}
      </div>
    );
  };
  return (
    <>
      <Box
        sx={{
          boxShadow:
            "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset",
          borderRadius: "8px",
          m: "8px",
          p: "8px",
        }}
      >
        <Typography
          sx={{
            fontSize: "15px",
            fontWeight: "700",
          }}
        >
          Inventory Holding Capacity
        </Typography>
        <Box
          sx={{
            //   textAlign: "center",
            margin: "8px",
            border: "1px solid #FF7200",
            borderRadius: "5px",
            //   bgcolor: "#ff720014",
            padding: "8px",
            color: "#FF7200",
            fontSize: "15px",
            fontWeight: "700",
          }}
        >
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "700",
              color: "black",
              mb: 1,
            }}
          >
            Selected Locations ( Shelves Details ) :
          </Typography>
          <Box
          sx={{
            // border: "2px solid #FF7200",
            // borderRadius: "8px",
            padding: "0px 20px 10px 0",
            marginBottom: "20px",
            width:'100%',
            height:'200px'
          }}
        >
          <AutoSizer>
                {({ width, height }) => {
                  return (
                    <FixedSizeGrid
                      columnCount={4} // Number of columns
                      columnWidth={190} // Width of each column
                      height={numberOfRows < 3 ? numberOfRows * 50 : 200} // Total height of the grid
                      rowCount={numberOfRows}
                      rowHeight={50} // Height of each row
                      width={width} // Total width of the grid
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width:'786px',
                        position:'absolute',
                        left:'-8px'
                      }}
                    >
                      {cellRenderer}
                    </FixedSizeGrid>
                  );
                }}
              </AutoSizer>
              </Box>
          <Box>

          </Box>
        </Box>

        <Box
          sx={{
            boxShadow:
              "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset",
            borderRadius: "8px",
            m: "8px",
            p: "8px",
          }}
        >
          <Grid container xs={12}>
            <Grid item xs={4} sx={{ display: 'flex', alignItems: "center", justifyContent: 'center' }}>
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: "700",

                }}
              >
                Accepted Inventory Type :
              </Typography>
            </Grid>
            <Grid item xs={8} sx={{ display: 'flex', alignItems: "center", justifyContent: 'center' }}>
              <FormGroup row sx={{ mt: 1 }}>
                {inventoryTypes.map((type, i) => {
                  const optionValue = selectedInventoryOptions.find((option) => option.value === type.value)
                  return (
                    <FormControlLabel
                      key={i}
                      label={
                        <Typography sx={{ fontSize: "14px" }}>
                          {type.label}
                        </Typography>
                      }
                      control={
                        <Checkbox
                          onChange={(e, value, valueOPtion) =>
                            handleSelectInventory(e, value, type)
                          }
                          checked={optionValue ? true : false}
                          size="small"
                          sx={{
                            "&, &.Mui-checked": { color: theme.themeOrange },
                          }}
                        />
                      }
                    />
                  );
                })}
              </FormGroup>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            boxShadow:
              "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset",
            borderRadius: "8px",
            m: "8px",
            p: "8px",
          }}
        >
          <Grid container xs={12}>
            <Grid item xs={2}>
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: "700",
                }}
              >
                Capacity Matrix :
              </Typography>
            </Grid>
            <Grid container item xs={10} sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box>
                  <FormControlLabel
                    label="Max. Counts"
                    control={<Checkbox disabled={true} checked={true} />}
                  />
                </Box>
                <Box>
                  <RadioGroup
                    row
                    value={
                      capacityMatrix?.count && Object.keys(capacityMatrix.count).find((option) => capacityMatrix.count[option] === true)
                    }
                    onChange={(e) => {
                      handleSelectCategory(e.target.value, "count");
                    }}
                  >
                    {capacityMatrixOptions.map((option) => {
                      return (
                        <FormControlLabel
                          value={option.value}
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: theme.themeOrange,
                                "&, &.Mui-checked": {
                                  color: theme.themeOrange,
                                },
                              }}
                            />
                          }
                          label={option.label}
                        />
                      );
                    })}
                  </RadioGroup>
                </Box>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box>
                  <FormControlLabel
                    label="Max. Weight"
                    control={
                      <Checkbox
                        checked={!!capacityMatrix["weight"]}
                        onChange={(e) =>
                          handleChangeCapacityMatrix(e.target.checked, "weight")
                        }
                      />
                    }
                  />
                </Box>
                <Box>
                  <RadioGroup
                    row
                    disabled={!capacityMatrix.weight}
                    value={
                      capacityMatrix?.weight ? Object.keys(capacityMatrix.weight).find((option) => capacityMatrix.weight[option] === true) : "notApplicable"
                    }
                    onChange={(e) => {
                      handleSelectCategory(e.target.value, "weight");
                    }}
                  >
                    {capacityMatrixOptions.map((option) => {
                      return (
                        <FormControlLabel
                          value={option.value}
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: theme.themeOrange,
                                "&, &.Mui-checked": {
                                  color: theme.themeOrange,
                                },
                              }}
                            />
                          }
                          label={option.label}
                        />
                      );
                    })}
                  </RadioGroup>
                </Box>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box>
                  <FormControlLabel
                    label="Dimensions"
                    control={
                      <Checkbox
                        checked={!!capacityMatrix["dimension"]}
                        onChange={(e) =>
                          handleChangeCapacityMatrix(
                            e.target.checked,
                            "dimension"
                          )
                        }
                      />
                    }
                  />
                </Box>
                <Box>
                  <RadioGroup
                    row
                    value={
                      capacityMatrix?.dimension ? Object.keys(capacityMatrix.dimension).find((option) => capacityMatrix.dimension[option] === true) : "notApplicable"
                    }
                    onChange={(e) => {
                      handleSelectCategory(e.target.value, "dimension");
                    }}
                  >
                    {capacityMatrixOptions.map((option) => {
                      return (
                        <FormControlLabel
                          value={option.value}
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: theme.themeOrange,
                                "&, &.Mui-checked": {
                                  color: theme.themeOrange,
                                },
                              }}
                            />
                          }
                          label={option.label}
                        />
                      );
                    })}
                  </RadioGroup>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {!!selectedInventoryOptions.length && <Box
          sx={{
            boxShadow:
              "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset",
            borderRadius: "8px",
            m: "8px",
            p: "8px",
          }}
        >
          <Grid container sx={{ display: "flex", justifyContent: "space-between" }}>
            <Grid container item xs={7} spacing={1}>
              <Grid item xs={4.5}>
                <Autocomplete
                  size="small"
                  value={
                    selectedInventoryOptions?.find(
                      (option) => option.value === selectedData?.type
                    ) || ""
                  }
                  options={selectedInventoryOptions}
                  onChange={(e, option) => {
                    handleChangeInventory(option);
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        fontSize: "12px",
                        ...selectStylesOverride,
                      }}
                      {...params}
                      label={
                        <Typography sx={{ fontSize: "14px" }}>
                          Category
                        </Typography>
                      }
                    />
                  )}
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      style={{
                        color: option.isCreateOption ? "#ff7200" : "#727376",
                        fontSize: "14px",
                      }}
                    >
                      {option.label}
                    </li>
                  )}
                />
              </Grid>
              <Grid item xs={7.5}>
                <TypeAndSearch
                  component={deepCopy_Object(component)}
                  value={selectedData?.SKU_ID}
                  label={selectedData?.SKU_ID}
                  disabled={!InventoryForAddingEntity}
                  setEmptyOnChange={false}
                  handleChange={(option, value) => {
                    if (value) {
                      setSelectedData((prev) => ({
                        ...prev,
                        SKU_ID: value.value,
                        label: value.label,
                      }));
                    } else {
                      setSelectedData((prev) => ({
                        ...prev,
                        SKU_ID: "",
                        label: "",
                      }))
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={5} sx={{ display: "flex", flexDirection: "row", gap: "0.5rem", alignItems: "center", justifyContent: "center" }}>
              <Typography sx={{ fontSize: "14px" }}>Max Count* :</Typography>
              <Grid item xs={4}>
                <ButtonGroup
                  disabled={!InventoryForAddingEntity}
                  size="small"
                  sx={{
                    minWidth: "24px",
                    ".MuiButtonGroup-grouped": { minWidth: "25px" },
                  }}
                >
                  <Button
                    key="one"
                    size="small"
                    sx={{ ...buttonClasses.smallOrange, fontSize: "12px" }}
                    onClick={() => handleItemCount(-1)}
                  >
                    <Remove fontSize="inherit" />
                  </Button>
                  <input
                    type="number"
                    defaultValue={1}
                    disabled={!InventoryForAddingEntity}
                    style={{
                      border: "1px solid #D9D9D9",
                      maxWidth: "60px",
                      fontSize: "12px",
                      textAlign: "center",
                    }}
                    value={selectedData?.maxCount}
                    onChange={(e) => handleItemCount(e)}
                    onWheel={(event) => event.target.blur()}
                    onKeyDown={(e) => {
                      if (["e", "E", "+", "-"].includes(e.key)) {
                        e.preventDefault();
                      } else if (
                        e.key == "0" &&
                        (e.target.value == "" || Number(e.target.value) == 0)
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <Button
                    key="three"
                    size="small"
                    sx={{ ...buttonClasses.smallOrange, fontSize: "12px" }}
                    onClick={() => handleItemCount(1)}
                  >
                    <Add fontSize="inherit" />
                  </Button>
                </ButtonGroup>
              </Grid>
              <Grid item xs={1}>
                <Button
                  variant="outlined"
                  data-testid="cancelButton"
                  disabled={
                    !selectedData?.SKU_ID ||
                    !selectedData?.maxCount ||
                    !selectedData?.type
                  }
                  sx={{
                    ...buttonClasses.lynkitBlackFill, minHeight: "32px",
                    backgroundColor: theme.themeBlack, ml: 0.5,
                    "&:disabled": { cursor: "not-allowed !important", backgroundColor: "rgba(0, 0, 0, 0.12)", color: "rgba(0, 0, 0, 0.26)", border: "none" }
                  }}
                  onClick={() => {
                    setSKU_IDs((prev) => [selectedData, ...prev]);
                    setSelectedData(initialID);
                  }}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>}
        {!!capacityMatrix["weight"] && <Box
          sx={{
            textAlign: "center",
            margin: "8px",
            border: "1px solid #FF7200",
            borderRadius: "5px",
            bgcolor: "#ff720014",
            padding: "10px 0px",
            color: "#FF7200",
            fontSize: "15px",
            fontWeight: "700",
          }}
        >
          <Grid container spacing={1} xs={12}>
            <Grid item xs={3}>
              Max Accepted Weight:
            </Grid>
            <Grid item xs={2}>
              <Autocomplete
                size="small"
                options={weightOptions}
                onChange={(e, option) => {
                  setWeightCapacity(prev => ({ ...prev, unit: option.value }))
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      fontSize: "12px",
                      ...selectStylesOverride,
                    }}
                    {...params}
                    label={
                      <Typography sx={{ fontSize: "14px" }}>
                        Unit
                      </Typography>
                    }
                  />
                )}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    style={{
                      color: option.isCreateOption ? "#ff7200" : "#727376",
                      fontSize: "14px",
                    }}
                  >
                    {option.label}
                  </li>
                )}
                value={weightCapacity?.unit}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                sx={{ ...selectStylesOverride, }}
                size="small"
                id="outlined-number"
                label="Maximum holding weight"
                type="number"
                value={weightCapacity?.maxWeight}
                onChange={(e) => {
                  handleSelectMaximumWeight(e)
                }}
              />

            </Grid>
          </Grid>
        </Box>}
        <GenericTable
          header={headers}
          rows={rows}
          pageCount={SKU_IDs.length}
          pageNumber={(pageNumber - 1)}
          handleChangePage={(event, pagevalue) => {
            setPageNumber(pagevalue + 1);
          }}
          handleChangeRowsPerPage={(event) => {
            setPageLimits(event.target.value)
          }}
          rowsPerPage={pageLimits}
        />
        <Grid container xs={12}>
          <Button
            variant="contained"
            spacing={1}
            onClick={clearStates}
            sx={{
              ...buttonClasses.lynkitBlackFill,
              marginRight: "4px",
              borderRadius: "8px",
              textTransform: "none",
              margin: "20px 0 12px 16px",
              padding: "5px 35px",
            }}
          >
            Clear All
          </Button>
          <Button
            variant="contained"
            spacing={1}
            disabled={!SKU_IDs.length}
            onClick={handleSubmitDimensionsForStep2}
            sx={{
              ...buttonClasses.lynkitOrangeFill,
              marginRight: "4px",
              borderRadius: "8px",
              textTransform: "none",
              margin: "20px 0 12px 16px",
              padding: "5px 35px",
              "&:disabled": { cursor: "not-allowed !important", backgroundColor: "rgba(0, 0, 0, 0.12)", color: "rgba(0, 0, 0, 0.26)", border: "none" }
            }}
          >
            Submit
          </Button>

        </Grid>
      </Box>
    </>
  );
};

export default AreaUtilizationStep2;
