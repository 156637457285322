import { Add, Delete } from '@mui/icons-material';
import { Button, Dialog, DialogContent, Grid, IconButton, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addUom, changeUomModalStatus, getAllUom } from '../../../redux/actions';
import { showToast } from '../../../services/functions';
import theme, { buttonClasses, inputClasses, textClasses } from '../../../static/styles/theme'

export default function AddUom({open,handleClose,inputValue}) {
    const dispatch = useDispatch();
    const [unitToAdd, setUnitToAdd] = useState([{unitName:"", unitValue:""}]);

    const handleChange = (e, i, field) => {
        let old = JSON.parse(JSON.stringify(unitToAdd));
        old[i][field] = e.target.value;
        setUnitToAdd(old);
    }

    const handleDelete = (i) => {
        let old = JSON.parse(JSON.stringify(unitToAdd));
        old.splice(i, 1);
        setUnitToAdd(old);
    }

    const handleAdd = () => {
        let old = JSON.parse(JSON.stringify(unitToAdd));
        old.push({unitName: "", unitValue: ""})
        setUnitToAdd(old);
    }
    
    const handleSubmit = () => {
        const invalid = unitToAdd.filter(u => u.unitName == "" || u.unitValue == "");
        if(invalid.length > 0){
            showToast("Please fill correct details", true)
        }
        else{
            dispatch(addUom(unitToAdd, (data) => {
                showToast(data.message, !data.success);
                if(data.success){
                    // dispatch(getAllUom({}))
                    setUnitToAdd([{unitName: "", unitValue: ""}])
                    handleClose();
                }
                
            }));
        }
    }

    useEffect(() => {
        if (open) {
            setUnitToAdd([{ unitName: inputValue || "", unitValue: inputValue || "" }])
        }
    }, [open])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll={"paper"}
            PaperProps={{ sx: { width: "100%" } }}
            maxWidth={"sm"}
        >
            <DialogContent>
                <Typography sx={{...textClasses.cardTitle}}>Add Unit of Measurement</Typography>
                <Grid container sx={{border: "1px solid #D9D9D9", borderRadius: "8px", p: 3, mt: 2}}>
                    <Grid container spacing={2} sx={{mb: 1}}>
                        <Grid container item sm={5} xs={5}>
                            <Typography sx={{...textClasses.boldText}}>Unit</Typography>
                        </Grid>
                        <Grid container item sm={5} xs={5}>
                            <Typography sx={{...textClasses.boldText}}>View as</Typography>
                        </Grid>
                        <Grid container item sm={2} xs={5} justifyContent="center">
                        </Grid>
                    </Grid>
                    {
                        unitToAdd.map((r, i) => {
                            return (
                                <Grid container spacing={2}>
                                    <Grid container item sm={5} xs={5}>
                                        <TextField value={r.unitName} onChange={(e) => handleChange(e, i, "unitName")} size='small' fullWidth type="text" variant="outlined" sx={{...inputClasses.shadowField}} />
                                    </Grid>
                                    <Grid container item sm={5} xs={5}>
                                        <TextField value={r.unitValue} onChange={(e) => handleChange(e, i, "unitValue")} size='small' fullWidth type="text" variant="outlined" sx={{...inputClasses.shadowField}} />
                                    </Grid>
                                    <Grid container item sm={2} xs={5} justifyContent="center">
                                        <IconButton sx={{color: "red"}} onClick={() => handleDelete(i)}>
                                            <Delete fontSize='inherit'/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                    
                    <Typography sx={{...textClasses.normalText, color: theme.themeOrange, cursor: "pointer", mt: 2}} onClick={handleAdd}><Add fontSize='inherit'/>Add New UOM</Typography>
                </Grid>
                <Grid container sx={{mt: 2}}>
                    <Button size='small' variant='outlined' sx={{...buttonClasses.lynkitBlackFill, height: "40px", minWidth: "150px"}} onClick={handleClose}>Cancel</Button>
                    <Button size='small' variant='outlined' sx={{...buttonClasses.lynkitOrangeFill, height: "40px",  minWidth: "150px", ml: 2}} onClick={handleSubmit}>Submit</Button>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
