import React, { useEffect , useState  } from 'react'
import { Grid, Paper, Typography, Tooltip } from '@mui/material'
import theme, { textClasses,buttonClasses } from '../../../../../static/styles/theme'
import { CustomControlledInput, getFieldsLayoutForEntry } from '../../../../Components/TemplateInputConfiguration';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFile } from '../../../../../redux/actions';
import { dateTimeFormater } from '../../../../../services/functions';
import FileModal from '../../../EntityMaster/FileModal';


export default function ShipmentSection({template, spiType, asn, asnTemplateHeader, receivingMethod, formHooks}) {

    const sectionIndex = 1;
    const name = "Shipment Details";
    const dispatch = useDispatch();
    const {register, control, setValue, errors,watch,getValues,reset} = formHooks;
    const mappingLocations = useSelector(state => state.inward.allMapping);
    const dimensions = useSelector(state => state.inventory.parcelgetDimensions);
    const connectedMaster = (() => {
        if(asn && asn.shipmentDetail && asn.shipmentDetail.customerDetail && Object.keys(asn.shipmentDetail.customerDetail).length > 0){
            return "customer"
        }
        if(asn && asn.shipmentDetail && asn.shipmentDetail.supplierDetail && Object.keys(asn.shipmentDetail.supplierDetail).length > 0){
            return "supplier"
        }
        return null
    })();
    const allFields = template?.attributes|| [];
    
    const mandatoryIds = useSelector(state => state?.canvas?.components?.attributes?.mandatory)?.filter(
        (f) => f?.templateType == template.templateType
    )?.map( f => f._id) ||[];

    let mandatoryFields = allFields?.filter(f => [...mandatoryIds,"customShipment"].indexOf(f._id) >= 0);
    
    const otherFields = allFields?.filter(f => mandatoryFields.map(m => m._id).indexOf(f._id) < 0)
    // Add Mapping Area if its Shipment Only 
    if(spiType == "shipmentOnly" && mappingLocations.length > 0){
        let field = {
            type: "select",
            label: "Mapping Area",
            _id: "mappingArea",
            required: true,
            values: mappingLocations || []
            // .map((loc) => ({
            //     ...loc,
            //     label: loc.label,
            //     value: loc.value
            // }))
            ,
            defaultValue: "PUT IT ANY WHERE"
        }
        otherFields.push(field);
    }

    const autoFillOnFocus = () => {
        const socketInputs = ["weight", "length", "breadth", "height"]
        if(dimensions){
            for (let i = 0; i < otherFields.length; i++) {
                let compId = otherFields[i]._id
                if(socketInputs.indexOf(compId) >= 0 && dimensions[compId]){
                    setValue(compId, dimensions[compId]);
                }
            }
        }
    }

    let handleChange = (e, _id,component,option={}) => {
        // console.log("handleChange", option);
        if(component.type == "select"){
            let allValues=getValues() || {}
            allValues["isSelectFromList"]={
                ...allValues["isSelectFromList"] || {},
                [_id]: !(option?.isNewGenerated==true)
            }
            allValues[_id]=e.target.value
            if (component._id=="customShipment") {
                let start=0
                let formData=option?.formData && typeof option?.formData == "object" ? option?.formData : {}
                for(let ele of allFields){
                    if (["customShipment", "packageQuantity"].indexOf(ele?._id) >= 0) {
                        start++
                        continue;
                    }
                    let value = formData[ele._id]
                    if (ele.type == "checkbox" ? (!value || value.length == 0) : !value) {
                        if (ele.type == "checkbox") {
                            value = []
                        }
                        else {
                            value = ""
                        }
                        allFields[start].disabled=false
                    }
                    else{
                        allFields[start].disabled=true
                    }
                    allValues[ele._id] = value
                    start++
                }
            }
            reset({...allValues})
        }
        else{
            setValue(_id, e.target.value)
        }
    }

    const layout = getFieldsLayoutForEntry(otherFields, register, control, autoFillOnFocus, errors,"",handleChange);

    ////("Mandatory Fields", mandatoryFields);
    ////("Other Fields", otherFields);
    
    useEffect(() => {
        if(asn){
            let quantity = asn.shipmentDetail.totalQuantity; //- asn.shipmentDetail.receivedQuantity - asn.shipmentDetail.rejectedQuantity
            setValue("packageQuantity", quantity);
        }
    }, [template, asn])
    const [displayUrl, setDisplayUrl] = useState("");
    const [openModal, setOpenModal] = React.useState(false);
  
    const displayFile = (fileUrl, fileKey) => {
      const imageUrlRegex = /\.(jpg|jpeg|png|gif|bmp|tiff)$/i;
      const isValidImageUrl = imageUrlRegex.test(fileKey);
      if (isValidImageUrl) {
        setOpenModal(true);
        setDisplayUrl(fileUrl);
      } else {
        window.open(fileUrl, "_blank");
        // window.open(fileUrl, "_blank")
      }
    };
  
    const handleFile = (fileKey) => {
      setOpenModal(false);
      setDisplayUrl('');
      dispatch(fetchFile({ fileKey }, displayFile));
    };

    return (
        <Paper key={sectionIndex} elevation={1}>
            <Grid sx={{backgroundColor: theme.themeGray, p: "12px", borderTopLeftRadius: "8px", borderTopRightRadius: "8px"}}>
                <Typography sx={{...textClasses.boldText}}>{sectionIndex}. {name}</Typography>
            </Grid>
            <Grid container spacing={4} sx={{p: 2}}>
                {   
                    mandatoryFields.map((field, i) => {
                        return (
                            <Grid container item xs={6} sm={4} key={i}>
                                <Grid container justifyContent={"center"} alignItems="center">
                                    <CustomControlledInput
                                        action={"use"}
                                        register={register}
                                        component={asn && field._id == "packageQuantity" ? {...field, disabled: true} : field}
                                        control={control}
                                        errors={errors}
                                        autoFillOnFocus={() => {}}
                                        multiline={false}
                                        handleChange={handleChange}
                                        />
                                        {/* {field._id=="customShipment" && "hhhhhhhhh"} */}
                                </Grid>
                            </Grid>
                        )
                    })
                }
                
                {   
                    layout.single.map((field, i) => {
                        return (
                            <Grid container item xs={6} sm={4} justifyContent={"center"} alignItems="center" key={i}>
                                {
                                    field
                                }
                            </Grid>
                        )
                    })
                }

            </Grid>

            <Grid container spacing={2} sx={{p: 2, pt: 0}}>
                {
                    layout.multi.map((field, i) => {
                        return (
                            <Grid container item xs={6} sm={4} justifyContent={"center"} alignItems="center" key={i}>
                                {
                                    field
                                }
                            </Grid>
                        )
                    })
                }
            </Grid>
            {
                asn &&
                <Grid container sx={{p: 2}}>
                    <Paper sx={{width: "100%", p: 2, border: "1px solid #D9D9D9"}}>
                        <>
                            <Grid container>
                                    {
                                        asnTemplateHeader && asn.shipmentDetail.formData && asnTemplateHeader.shipmentHeader.filter(f => typeof asn.shipmentDetail.formData[f.name] == "string" && asn.shipmentDetail.formData[f.name].toString().length < 25).map((f, i) => {
                                            return (
                                                <Grid container item sm={4} xs={6} alignItems="center" key={i} sx={{ mb: 2 }}>
                                                    <Grid container>
                                                        <Grid container item sm={4} xs={6}>
                                                            <Typography sx={{ ...textClasses.normalText, color: "#827878" }}>{f.label} : </Typography>
                                                        </Grid>
                                                        <Grid container item sm={8} xs={6}>
                                                            <Typography sx={{ ...textClasses.boldText, ml: 2 }}>{
                                                                f.type == "date"
                                                                    ? dateTimeFormater(asn.shipmentDetail.formData[f.name], f.format || "DD MMM YYYY")
                                                                    : asn.shipmentDetail.formData[f.name]
                                                            }</Typography>
                                                        </Grid>
                                                    </Grid>


                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>

                            <Grid container>
                                {
                                    asnTemplateHeader && asn.shipmentDetail.formData   && asnTemplateHeader.shipmentHeader.filter(f => typeof asn.shipmentDetail.formData[f.name] != "string" || asn.shipmentDetail.formData[f.name].toString().length > 25).map((f, i) => {
                                        return (
                                          <Grid
                                            container
                                            item
                                            sm={4}
                                            xs={6}
                                            alignItems="center"
                                            key={i}
                                            sx={{ mb: 2 }}
                                          >
                                            <Grid container>
                                              <Grid
                                                container
                                                item
                                                sm={4}
                                                xs={6}
                                              >
                                                <Typography
                                                  sx={{
                                                    ...textClasses.normalText,
                                                    color: "#827878",
                                                  }}
                                                >
                                                  {f.label} :{" "}
                                                </Typography>
                                              </Grid>
                                              <Grid
                                                container
                                                        item
                                                        sm={8}
                                                        xs={6}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                ...textClasses.normalText,
                                                                ml: 1,
                                                            }}
                                                        >
                                                            {asn.shipmentDetail.formData[f.name] ? (
                                                                f.type === "file" ? (
                                                                    <div
                                                                        onClick={() =>
                                                                            handleFile(asn.shipmentDetail.formData[f.name])
                                                                        }
                                                                    >
                                                                        <span
                                                                            style={{
                                                                                ...buttonClasses.viewFile,
                                                                                cursor: "pointer",
                                                                            }}
                                                                        >
                                                                            View File
                                                                        </span>
                                                                    </div>
                                                                ) : (
                                                                    <Typography
                                                                        sx={{
                                                                            ...textClasses.boldText,
                                                                            ml: 2,
                                                                        }}
                                                                    >
                                                                        {
                                                                            f.type === "date"
                                                                                ? dateTimeFormater(asn.shipmentDetail.formData[f.name], f.format || "DD MMM YYYY")
                                                                                : asn.shipmentDetail.formData[f.name]
                                                                        }
                                                                    </Typography>
                                                                )
                                                            ) : (
                                                                "-"
                                                            )}
                                                        </Typography>
                                                    </Grid>
                                            </Grid>

                                            <FileModal
                                              openModal={openModal}
                                              displayUrl={displayUrl}
                                              setOpenModal={setOpenModal}
                                            />
                                          </Grid>
                                        );
                                    })
                                }
                            </Grid>
                            {
                                connectedMaster &&
                                <>
                                    {/* <Grid container sx={{mb: 2}}>
                                        <Grid container item sm={2} xs={3} alignItems={"center"} >
                                            <Typography sx={{...textClasses.normalText}}>
                                                {getCamelCaseText(connectedMaster)} Details:
                                                <span style={{marginLeft: "5px", color: "#455A64"}}>
                                                    {asn.shipmentDetail[`${connectedMaster}Detail`] && asn.shipmentDetail[`${connectedMaster}Detail`][`${connectedMaster}Id`]}
                                                </span>
                                            </Typography>
                                            
                                        </Grid>
                                    </Grid> */}

                                    <Grid container>
                                        {
                                            asnTemplateHeader && asn.shipmentDetail[`${connectedMaster}Detail`] && asn.shipmentDetail[`${connectedMaster}Detail`][`${connectedMaster}Data`]  && asnTemplateHeader[`${connectedMaster}Master`].filter(f => asn.shipmentDetail[`${connectedMaster}Detail`][`${connectedMaster}Data`][f.name].toString().length < 25).map((f, i) => {
                                                return (
                                                    <Grid container item sm={4} xs={6} alignItems="center" key={i} sx={{mb: 2}}>
                                                        <Grid container>
                                                            <Grid container item sm={4} xs={6}>
                                                                <Typography sx={{...textClasses.normalText, color: "#827878"}}>{f.label} : </Typography>
                                                            </Grid>
                                                            <Grid container item sm={8} xs={6}>
                                                                <Typography sx={{...textClasses.boldText, ml: 2}}>{asn.shipmentDetail[`${connectedMaster}Detail`][`${connectedMaster}Data`][f.name]}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        
                                                        
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                    <Grid container>
                                        {
                                            asnTemplateHeader && asn.shipmentDetail[`${connectedMaster}Detail`] && asn.shipmentDetail[`${connectedMaster}Detail`][`${connectedMaster}Data`]  && asnTemplateHeader[`${connectedMaster}Master`].filter(f => asn.shipmentDetail[`${connectedMaster}Detail`][`${connectedMaster}Data`][f.name].toString().length > 25).map((f, i) => {
                                                return (
                                                    <Grid container item sm={4} xs={6} alignItems="center" key={i} sx={{mb: 2}}>
                                                        <Grid container>
                                                            <Grid container item sm={4} xs={6}>
                                                                <Typography sx={{...textClasses.normalText, color: "#827878"}}>{f.label} : </Typography>
                                                            </Grid>
                                                            <Grid container item sm={8} xs={6}>
                                                                <Tooltip title={asn.shipmentDetail[`${connectedMaster}Detail`][`${connectedMaster}Data`][f.name]}>
                                                                    <Typography sx={{...textClasses.boldText, ml: 2}}>{asn.shipmentDetail[`${connectedMaster}Detail`][`${connectedMaster}Data`][f.name].toString().substring(0, 100)}</Typography>
                                                                </Tooltip>
                                                                
                                                            </Grid>
                                                        </Grid>
                                                        
                                                    </Grid>
                                                )
                                            })
                                        }

                                    </Grid>
                                </>
                            }
                            
                        </>
                    </Paper>
                </Grid>
            }
            
            
        </Paper>
    )
    
}