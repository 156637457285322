import AxiosInatance from "axios";
import config from "./config";
import history from './history';
import CryptoJS from 'crypto-js';

export const encryptData = (data) => {
  const secretKey = '12345678901234567890123456789012';
  const key = CryptoJS.enc.Utf8.parse(secretKey);

  // Generate a random IV
  const iv = CryptoJS.lib.WordArray.random(16);

  // Encrypt the data
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
    iv: iv
  });

  // Prepend the IV to the encrypted data
  return iv.toString() + encrypted.toString();
};


// The below function we are using to encrypt the payload data in forgot and change password and login
export const encryptTheData = (data) =>{  
  let _key = CryptoJS.enc.Utf8.parse("pAUGvR0awF5ot2N5");
  let _iv = CryptoJS.enc.Utf8.parse("pAUGvR0awF5ot2N5");
  let encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), _key,
      {
          keySize: 16,
          iv: _iv,
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
      });
  return encrypted.toString();
}

const axios = AxiosInatance.create({
  baseURL: config.baseURL || "/v1/",
  headers: {
    "Content-Type": "application/json",
    // "Authorization": JSON.parse(localStorage.getItem("token"))
  }
});

var token2 = "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6eyJjcmVhdGVkRGF0ZSI6IjIwMjMtMDYtMTZUMDU6NTM6NDUuNjU1Wi02NDM3OWM2NmMzNDJmMDA0ZGQwZmEyOGQiLCJwZXJtaXNzaW9ucyI6eyJpbmRvb3JUcmFja2luZyI6dHJ1ZX0sIl9pZCI6IjY0Mzc5YzY2YzM0MmYwMDRkZDBmYTI4ZCIsInVzZXJUeXBlIjoiYWRtaW4iLCJuYW1lIjoiRXhwZXJpZW5jZSBDZW50ZXIiLCJlbWFpbCI6ImV4cGVyaWVuY2VAbHlua2l0LmluIiwiaXNPbkR1dHkiOmZhbHNlLCJhcHByb3ZlZCI6dHJ1ZSwiY29tcGFueSI6IkV4cGVyaWVuY2UgQ2VudGVyIiwiYmFzZSI6IjEzNjQyN2M3LTJjYWMtNDUxNS04MWFkLWI4YWJkNDZiZGYzNy0xNjgxMzY1OTkxNzc4IiwiZGVzaWduYXRpb24iOiJFeHBlcmllbmNlIn0sImlhdCI6MTY4Njg5NDgyNSwiZXhwIjo2LjMxMTM5MDQ4ODg4ODkwNmUrMjN9.kNejXKM2eYzdE8o-nyfsR32opgBBQxDW0SQQyUnUBbs"

axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  // console.log("config>>>>>>",config)

  let project=localStorage.getItem("project")
  if(project){
    config.headers["project"] = project
  }

  if (["/inventory/saveInventoryWithExcel", "/areaMapping/updateMasterId","/ASN/bulkASNUpload","/sales/bulkSalesOrderUpload"].includes(config.url)) {
    config["headers"]["Content-Type"] = 'multipart/form-data'
  }
  if (config.url.includes("getAllUserData")) {
    config["headers"]["Authorization"] = token2
  }
  else  if (["responseForm/getExternalFormConfigs","responseForm/submitResponse"].includes(config.url) && window.localStorage.getItem("passToken") && config["headers"]) {
    // if (config.data) {
    //   config = {
    //     ...config,
    //     "data": {"encryptedData":encryptData(config.data)}
    //   };
    //   console.log(config,"uuu",encryptData(config.data))
    // }
    config["headers"]["partialaccesstoken"] = JSON.parse(window.localStorage.getItem("passToken"))
  }
  else if (window.localStorage.getItem("token") && config["headers"]) {
    // if (config.data) {
    //   config = {
    //     ...config,
    //     "data": {"encryptedData":encryptData(config.data)}
    //   };
    //   console.log(config,"uuu",encryptData(config.data))
    // }
    config["headers"]["Authorization"] = JSON.parse(window.localStorage.getItem("token"))
  }
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {

  // Do something with response data
  let { config: { url }, data: { inValidToken = false, message } } = response

  
  // //("response>>>", {inValidToken,url});
  if (url && !url.includes("getAllUserData") && inValidToken) {//response.status == 201 &&
    window.localStorage.clear();
    window.localStorage.setItem("TokenExpirymesssage", message || "Session expired, please logout and login again!");

    history.push("/")
    // window.location.href = '/';
  }

  return response

}, function (error) {
  // Do something with response error
  console.log("error.response>>>", error);
  // window.localStorage.clear();
  // window.localStorage.setItem("TokenExpirymesssage", error.message || "Some error ocuured!");
  // history.push("/")
  // history.push("/error?type==internal-server-error")
  return Promise.reject(error);
});


export default axios;



