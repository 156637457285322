import { FETCH_ITEM_DETAILS, FETCH_STOCK_UNIT_DATA, FETCH_ASSOCIATED_JOB_DETAILS, FETCH_JOB_QTY_DATA, FETCH_TRENDING_GRAPH_DATA, FETCH_JOURNEY_MAP_DATA} from "../types"
const INIT_STATE = {
    itemDetails: null,
    tableHeader: [],
    stockUnitData: {},
    associatedJobDetails: {},
    totalJobsCount: 0,
    jobQtyData: [],
    trandingGraphData: [],
    journeyMapData: [],
    journeyMapCount: 0,
}
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_ITEM_DETAILS: return { ...state, itemDetails: action?.payload?.data || {}, tableHeader: action?.payload?.templateHeader || [] }
        case FETCH_STOCK_UNIT_DATA: return { ...state, stockUnitData: action?.payload?.data || {} }
        case FETCH_ASSOCIATED_JOB_DETAILS: return { ...state, associatedJobDetails: action?.payload?.data || {}, totalJobsCount: action?.payload?.count || 0 }
        case FETCH_JOB_QTY_DATA: return { ...state, jobQtyData: action?.payload?.data || [] }
        case FETCH_TRENDING_GRAPH_DATA: return { ...state, trandingGraphData: action?.payload?.data || [] }
        case FETCH_JOURNEY_MAP_DATA: return { ...state, journeyMapData: action?.payload?.data || [], journeyMapCount: action?.payload?.count || 0 }

        default: return state
    }
}