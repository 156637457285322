import React, { useEffect, useRef,useMemo } from 'react'
import { useState } from 'react'
import {
    Grid, Button, MenuItem, Typography, Tab, Tabs, Box, Checkbox, IconButton, Menu,
    Card, CardHeader, CardContent,Breadcrumbs
} from '@mui/material'
// import { makeStyles } from '@mui/system'
import { DataGrid } from '@mui/x-data-grid'
// import EmptyPage from './EmptyPage';
import { Add, Close, Delete, KeyboardArrowDown } from '@mui/icons-material';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getQCStatusCount, getAllQCList,getTemplateHeader } from '../../../redux/actions/Inventory'
import theme, { buttonClasses, cardClasses, textClasses } from '../../../static/styles/theme';
import { Link } from 'react-router-dom';
import { getCamelCaseText, getShortText, showToast } from '../../../services/functions';
import history from '../../../services/history';
import { renderHeadersFilter, dataReadFunction, dateTimeFormater } from "../../../services/functions"
import GenericLoader from '../../Components/Generic/GenericLoader';
import GenericCustomiseHeader from '../../Components/Generic/GenericCustomiseHeader';
import { useDebounce } from '../../../utils/hooks';
import GenericTable from '../../Components/CustomTable/GenericTable';
import { useLocation } from 'react-router-dom';


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

let initial_state = {
    "search": {},
    "other": {},
    "page": 1,
    "limit": 10,
}

export default function InventoryAdjustments() {

    const dispatch = useDispatch();
    const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const inventoryID = queryParams.get('inventoryId');
	const inventoryType = queryParams.get('inventoryType');

    const statusCount = useSelector(state => state?.inventory?.qcStatusCount) || {};
    const tableHeader = useSelector((state) => state.customHeader["qcTable"]);

    const [tabIndex, setTabIndex] = useState(0);
    const [filter, setFilter] = useState({ ...initial_state });
    const [initial, setinitial] = useState(true);
    const [loader, setLoader] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [checkedRow, setCheckedRow] = useState([]);
    const [allQCData, setAllQCData] = useState({});

    const debouncedFilterSearch = useDebounce(filter?.search, 500);
    const [anchorEl, setAnchorEl] = useState(null);
    const [actionIndex, setActionIndex] = useState(null);


    const statusList = [
        {
            "label": "Pending QC",
            "value": "pending",
            "color": "#069306"
        },
        {
            "label": "Ongoing QC",
            "value": "ongoing",
            "color": "#FFFF00"
        },
        {
            "label": "Completed QC",
            "value": "completed",
            "color": "#FF7200"
        },
        {
            "label": "Rejected QC",
            "value": "rejected",
            "color": "#FF0000"
        },
    ];

    const statusElement = useMemo(() => {
        return tableHeader?.allHeader?.find(item => item.renderFunction === "status") || {}

      }, [tableHeader]);


    const handleSelectedRow = (option) => {
        ////(option)
        setSelectedRow(option);
        handleCloseAction();
    }

    const handleOpenAction = (event, i) => {
        setAnchorEl(event.currentTarget);
        setActionIndex(i);

    }

    const handleCloseAction = () => {
        setActionIndex(null);
        setTimeout(() => {
            setAnchorEl(null);
        }, 1);

    }

    const isFilterApplied = (() => {
        if (tabIndex == 0 && filter?.other?.status) {
            return true;
        }
        let {status,...restOther}= { ...filter["other"] || {} }
        // delete other["status"]
        if (Object.values(restOther || {})?.some(dl => dl) || Object.values(filter["search"]).some(dl => dl && dl.length > 0)) {
            return true;
        }
        // console.log("filter",filter)
        return false;
    })();

    const handleTabChange = (event, value) => {
        if(value<=0 || value>4){
            setFilter({ ...filter, other: { ...filter["other"] || {}, status: null }, page: 1, limit: 10 })
        }
        else{
            let status=statusList?.at(value-1)?.value || ""
            setFilter({ ...filter, other: { ...filter["other"] || {}, status}, page: 1, limit: 10 })
        }
        setTabIndex(value)
        handleSelectedRow({})
        dispatch(getQCStatusCount({inventoryID,inventoryType,}));
    }

    const handleCheckAll = (e) => {
        let tempSelect = [...checkedRow]
        let checked = e.target.checked;
        if (checked) {
            let sT = allQCData?.data?.filter(st => !tempSelect.map(el => el._id).includes(st._id))
            setCheckedRow([...tempSelect, ...sT]);
        }
        else {
            tempSelect = tempSelect?.filter(st => !allQCData?.data?.map(el => el._id).includes(st._id))
            setCheckedRow(tempSelect);
        }
    }
    const handleRowCheckChange = (row = {}) => {
        let tempSelect = [...checkedRow]
        let { _id } = row || {}
        let index = tempSelect.findIndex(el => el._id == _id)
        if (index >= 0) {
            tempSelect.splice(index, 1)
        }
        else {
            tempSelect.push(row)
        }
        setCheckedRow(tempSelect);
    }

    const getTableCell = (header, row, i) => {

        let value = "",color=header?.color || "",viewLabel=null
        if (header?.mappingPath) {
            value = dataReadFunction({ name: header?.mappingPath || "",renderOption:header?.renderOption || [] }, row)
        }

        if (header?.handleType) {
            if (header?.handleType === "selectRow") {
                return (
                    <Checkbox
                        size="small"
                        checked={checkedRow?.map((o) => o._id).indexOf(row._id) >= 0}
                        onChange={(e) => handleRowCheckChange(row)}
                    />
                );
            }
            else if (header?.handleType === "showDetail") {

                return (
                    <Typography
                        onClick={(e) => handleSelectedRow(row)}
                        style={{
                            ...textClasses.normalText,
                            textDecoration: "none",
                            color: color || theme.themeOrange,
                            textAlign: "center",
                            cursor: "pointer",
                        }}
                    >
                        {value ?? "-"}
                    </Typography>
                );
            }
            else if (header?.handleType === "action") {
                return (
                    <Typography
                        data-testid="dropdown"
                        sx={{
                            ...textClasses.normalText,
                            color: color || theme.themeOrange,
                            cursor: "pointer",
                        }}
                        onClick={(e) => handleOpenAction(e, i)}
                    >
                        Action <KeyboardArrowDown />
                    </Typography>
                );
            }
        }
        else if (header?.isFromRenderFunction === true) {
            if (header?.renderFunction === "srNo") {
                let no;
                if (filter?.page == 0) no = i + 1;
                else no = ((filter.page - 1) * filter.limit) + (i + 1);
                return (
                    <Typography sx={{ ...textClasses.normalText, textAlign: "center", color: header?.color || theme.themeText }}>
                        {no}
                    </Typography>
                );
            }
            else if (["status","dropdown"].includes(header?.renderFunction)) {
                let option = header?.values?.find(o => o.value == value) || {}
                return (
                    <>
                        <Typography
                            sx={{
                                ...textClasses.normalText,
                                // textTransform: "capitalize",
                                textAlign: "center",
                                color: option?.color || theme.themeText,
                            }}
                        >
                            {option?.label || "-"}
                        </Typography>
                    </>
                );
            }
        }
        else {
            if (header?.fieldType === "array") {
                return (
                    <Typography sx={{ ...textClasses.normalText, textAlign: "center", textTransform: "capitalize", color: color || theme.themeText }}>
                        {value && Array.isArray(value) ? value.join(", ") : value || "-"}
                    </Typography>
                );
            }
            else if (header?.fieldType === "date") {
                return (
                    <Typography
                        sx={{ ...textClasses.normalText, textAlign: "center", textTransform: "none", color: color || theme.themeText }}
                    >
                        {value ? moment(value).format(header?.format || "DD MMM YYYY hh:mm A") : "-"}
                    </Typography>
                );
            }
            else {
                return (
                    <Typography
                        sx={{ ...textClasses.normalText, textAlign: "center", textTransform: "none", textTransform: "capitalize", color: color || theme.themeText }}
                    >
                        {value ?? "-"}
                    </Typography>
                );
            }
        }
    };

    //"Action"
    let header = tableHeader?.validHeader?.map((h) => {
        if (h.handleType === "selectRow") {
            return (
                <Checkbox
                    data-testid="checkbox"
                    disabled={!allQCData || !allQCData.count}
                    checked={
                        allQCData?.data?.length > 0 && allQCData?.data?.every(st => checkedRow.map(el => el._id).includes(st._id))
                    }
                    onChange={handleCheckAll}
                    size="small"
                />
            );
        }
        return h.fieldName;
    }) || [];

    const tableRows = allQCData?.data?.map((row, i) => {
        let items = [];

        const validHeaders = tableHeader?.validHeader ?? [];
        for (const header of validHeaders) {
            items.push(getTableCell(header, row, i));
        }

        return items;
    }) || [];

    const setPageHandler = (pageNo = 0) => {
        // setFilter({ ...filter, page: pageNo + 1 });
    }

    const setLimitHandler = (pageLimit = 10) => {
        // setFilter({ ...filter, limit: pageLimit });
    }

    const handleClearFilter = () => {
        let tempFilter = { ...initial_state }
        tempFilter["other"]["status"] = tabIndex == 0 ? null : filter?.other?.status || ""
        setFilter(tempFilter)
    };


    const refreshTableData = (pageNo, limitNo, fetchCount = false,makeInitial=false) => {

        setLoader(true);
        setSelectedRow({})
        let payload = {
            page: pageNo,
            limit: limitNo,
            inventoryID,
            inventoryType,
        }
        if(makeInitial){
            setFilter({...initial_state,page: pageNo,limit: limitNo,})
        }
        else{
            if (filter && Object.keys(filter).length > 0) {
                for (let key in filter) {
                  if (key && Object.keys(filter[key]).length > 0) {
                    Object.assign(payload, filter[key])
                  }
                }
            }
        }
        if(payload?.status && (statusElement?.payloadKey || statusElement?.mappingPath)){
            payload[statusElement?.payloadKey || statusElement?.mappingPath]=payload?.status
            delete payload["status"]
        }
        dispatch(
            getAllQCList(payload, ({ data = [], count }) => {
                setLoader(false);
                setAllQCData({ data, count });
            })
        )
        // delete payload.status
        if (fetchCount) {
            dispatch(getQCStatusCount({inventoryID,inventoryType,}));
        }
    }


    useEffect(() => {
        
        if(!inventoryID || !inventoryType){
            history.goBack()
        }
        setCheckedRow([]);
        setAnchorEl(null);
        setActionIndex(null);
        if (initial) {
            setinitial(false)
            refreshTableData(filter.page, filter.limit, initial);
        }
    }, [])
    useEffect(() => {
        setAnchorEl(null);
        setActionIndex(null);
        setinitial(false)
        if(!initial){
            refreshTableData(filter.page, filter.limit, initial);
        }
    }, [filter.other, debouncedFilterSearch, filter.page, filter.limit])

    return (
        <>
            <Grid container >
                {/* title section */}
                <Grid item xs={12}>
                    <Typography sx={{ ...textClasses.cardTitle }}>Quality Control & Quality Checks</Typography>
                    <Grid container item sm={9} xs={6}>
                        <Breadcrumbs sx={{ mb: 1 }}>
                            <Typography sx={{ ...textClasses.normalText, fontSize: "12px", color: theme.themeOrange }}>
                                <Link style={{ color: theme.themeOrange, textDecoration: "none" }} to="/dashboard/inventory/all">
                                    All Inventory
                                </Link>
                            </Typography>

                            <Typography sx={{ ...textClasses.normalText, fontSize: "12px" }}>Quality Check - {inventoryID}</Typography>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
            </Grid>

            {/* tab section */}
            <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 1 }}>
                <Tabs
                    value={tabIndex}
                    aria-label="disabled tabs example"
                    onChange={handleTabChange}
                    sx={{
                        "& .MuiTabs-indicator": { backgroundColor: theme.themeOrange },
                        "& .MuiTab-root.Mui-selected": { color: theme.themeOrange },
                    }}
                >
                    <Tab
                        label={`All QC (${statusCount?.totalCount || 0})`}
                        {...a11yProps(0)}
                        sx={{ ...textClasses.normalText, textTransform: "none" }}
                    />

                    {statusList.map((s, i) => {
                        return (
                            <Tab
                                data-testid={`${s.label}`}
                                label={`${s.label} (${statusCount && statusCount[`${s.value}Count`]
                                    ? statusCount[`${s.value}Count`]
                                    : 0
                                    })`}
                                {...a11yProps(1)}
                                sx={{ ...textClasses.normalText, textTransform: "none" }}
                                key={i}
                            />
                        );
                    })}
                </Tabs>
            </Box>

            {/* Filter section */}
            <Grid container spacing={1}>
                {renderHeadersFilter(tableHeader?.validHeader?.filter(dl => dl.isSearchable && dl?.mappingPath), filter, (tempFilter) => { setFilter({ ...tempFilter, page: 1, limit: 10 }) }, setPageHandler, setLimitHandler, tabIndex)}
                {isFilterApplied && (
                    <Grid container item xs={"auto"} alignItems={"center"}>
                        <Typography
                            sx={{
                                ...textClasses.boldText,
                                color: theme.themeOrange,
                                textDecoration: "underline",
                                cursor: "pointer",
                            }}
                            onClick={handleClearFilter}
                        >
                            Clear Filter(s)
                        </Typography>
                    </Grid>
                )}
                <Grid container item xs={"auto"} alignItems={"center"}>
                    <GenericCustomiseHeader
                        tableName={"qcTable"}
                        tableType={"qcTable"}
                    />
                </Grid>
            </Grid>


            {/* Action Button section */}
            {
                checkedRow.length > 0 &&
                <Grid container sx={{ mt: 2, textTransform: "none" }}>
                    {
                        checkedRow.length == 1 &&
                        <>
                            <Button
                                size='small' variant="contained" sx={{ ...buttonClasses.lynkitOrangeFill, mr: 1, textTransform: "none" }}
                                onClick={() => setSelectedRow(checkedRow[0] || {})}
                            >View</Button>
                        </>
                    }

                </Grid>
            }
            <Grid container sx={{ mt: 2 }}>
                <Grid item md={selectedRow?._id ? 8 : 12} sm={12} xs={12} order={{ xs: 2, sm: 2, md: 1 }} >
                    {
                        loader
                            ?
                            <Grid container direction={"column"} justifyContent={"center"} alignItems="center" sx={{ p: 15 }}>
                                <Grid item >
                                    <GenericLoader />
                                </Grid>
                            </Grid>
                            :
                            <GenericTable
                                header={header}
                                rows={tableRows}
                                pageCount={allQCData?.count || 0}
                                pageNumber={filter?.page - 1}
                                handleChangePage={(event, pagevalue) => {
                                    setFilter({ ...filter, page: pagevalue + 1 });
                                }}
                                handleChangeRowsPerPage={(event) => {
                                    setFilter({ ...filter, limit: +event.target.value });
                                }}
                                rowsPerPage={filter?.limit}
                            />
                    }
                </Grid>
                {
                    selectedRow?._id &&
                    <Grid item md={4} sm={12} xs={12} order={{ xs: 1, sm: 1, md: 2 }} sx={{ p: 1, pt: 0, }}>
                        <DetailCard
                            data-testid="cardDetail"
                            data={selectedRow}
                            handleSelectedRow={handleSelectedRow}
                            statusMapping={statusElement?.values || []}
                            allHeader={tableHeader?.allHeader || []}
                        />
                    </Grid>
                }
            </Grid>
            {
                actionIndex != null &&
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={actionIndex != null}
                    onClose={handleCloseAction}
                    MenuListProps={{
                        "aria-labelledby": "basic-button",
                    }}
                    sx={{
                        display:
                            actionIndex != null ? "block !important" : "none !important",
                    }}
                >

                    <MenuItem
                        sx={{ ...textClasses.normalText }}
                        key={1}
                        onClick={() => handleSelectedRow(allQCData?.data?.at(actionIndex) || {})}
                    >View
                    </MenuItem>
                </Menu>
            }
        </>
    )
}

const DetailCard = ({ data = {}, handleSelectedRow, statusMapping = [] ,allHeader={}}) => {

    let dispatch = useDispatch()
    const [templateHeader,setTemplateHeader]=useState([])
    const [loader,setLoader]=useState("")
    let status = data?.status && statusMapping?.find(dl => dl.value == data.status.toLowerCase()) || { color: theme.themeText, background: "#ff720026", label: data?.status || "N/A" }
    if (!status.color) status["color"] = theme.themeText
    if (!status.background) status["background"] = "#ff720026"

    const DetailRow = ({ title, children }) => {
        return (
            <Grid container sx={{ mt: 1 }}>
                <Grid item sm={5.5} xs={5.5}>
                    <Typography sx={{ ...textClasses.t12n, color: "#827878" }}>{title}</Typography>
                </Grid>
                <Grid container item sm={6.5} xs={6.5} direction="row" alignItems={"center"} sx={{ textTransform: "capitalize", color: theme.themeText,pl:1 }}>
                    {children}
                </Grid>
            </Grid>
        )
    }

    useEffect(() => {
        setTemplateHeader([])
        if (data?.jobTemplateDetail?.templateId) {
            setLoader("detail")
            dispatch(
                getTemplateHeader({
                    template_id: data?.jobTemplateDetail?.templateId
                },(response)=>{
                    setTemplateHeader(response?.templateHeader || [])
                    setLoader("")
                })
            )
        }
    }, [data])

    return (
        <>
            {
                data &&
                <Card sx={{ ...cardClasses.basic, borderRadius: "8px", border: `1px solid ${theme.themeOrange}`, maxHeight: "600px", minHeight: "300px", overflow: "auto" }} >
                    <CardHeader
                        sx={{ height: "46px", backgroundColor: theme.themeOrange }}
                        title={
                            <Typography sx={{ ...textClasses.boldText, color: "white", fontSize: "16px" }}>
                                Job ID: {data?.jobGroupById || ""}
                            </Typography>
                        }
                        action={
                            <IconButton size='small' sx={{ p: 0, mb: 1 }} onClick={() => handleSelectedRow([])}>
                                <Close sx={{ color: "white" }} />
                            </IconButton>
                        }
                    />
                    <CardContent sx={{ minHeight: "350px", maxHeight: "600px", overflowY: "auto" }}>
                            {loader == "detail" ?
                                <Grid container direction={"column"} justifyContent={"center"} alignItems="center" sx={{ p: 1 }}>
                                    <Grid item >
                                        <GenericLoader />
                                    </Grid>
                                </Grid>
                                : <>
                                    <DetailRow title="Job Name:">
                                        <Typography sx={{ ...textClasses.t12n }}>{data?.jobName || "-"}</Typography>
                                    </DetailRow>

                                    <DetailRow title="Job Status:">
                                        <Button sx={{ p: 0, cursor: "text !important", ...buttonClasses.small, color: status.color, backgroundColor: status, border: `1px solid ${status.color}`, "&:hover": { backgroundColor: status.background } }}>{status?.label}</Button>
                                        <Typography sx={{ ...textClasses.t12n, color: theme.themeOrange, ml: 1 }}>
                                        </Typography>
                                    </DetailRow>
                                    {
                                        templateHeader?.map((ele, index) => {
                                            let value = dataReadFunction(ele, data?.jobTemplateDetail?.templateData)
                                            return <DetailRow title={`${ele?.label}:`} index={index}>
                                                <Typography sx={{ ...textClasses.t12n }}>{value || "-"}</Typography>
                                            </DetailRow>
                                        })
                                    }

                                    <DetailRow title="Created On:">
                                        <Typography sx={{ ...textClasses.t12n }}>{dateTimeFormater(data?.allocated_date || null) || "-"}</Typography>
                                    </DetailRow>
                                    <DetailRow title="Created By:">
                                        <Typography sx={{ ...textClasses.t12n }}>
                                            {
                                                dataReadFunction({
                                                    name: "assignedBy.name" || "",
                                                    renderOption: [
                                                        {
                                                            "label": "",
                                                            "multiple": false,
                                                            "prefix": "",
                                                            "postfix": "",
                                                            "key": "assignedBy.name",
                                                            "keys": [

                                                            ]
                                                        },
                                                        {
                                                            "label": "",
                                                            "multiple": false,
                                                            "prefix": ", ",
                                                            "postfix": "",
                                                            "key": "assignedBy.designation"
                                                        }
                                                    ]
                                                }, data) || "-"
                                            }
                                        </Typography>
                                    </DetailRow>
                                    <DetailRow title="Completed On:">
                                        <Typography sx={{ ...textClasses.t12n }}>{dateTimeFormater(data?.scanned_date || null) || "-"}</Typography>
                                    </DetailRow>
                                    <DetailRow title="Completed By:">
                                        <Typography sx={{ ...textClasses.t12n }}>
                                            {
                                                dataReadFunction({
                                                    name: "completedBy.name" || "",
                                                    renderOption: [
                                                        {
                                                            "label": "",
                                                            "multiple": false,
                                                            "prefix": "",
                                                            "postfix": "",
                                                            "key": "completedBy.name",
                                                            "keys": [

                                                            ]
                                                        },
                                                        {
                                                            "label": "",
                                                            "multiple": false,
                                                            "prefix": ", ",
                                                            "postfix": "",
                                                            "key": "completedBy.designation"
                                                        }
                                                    ]
                                                }, data) || "-"
                                            }
                                        </Typography>
                                    </DetailRow>
                                </>
                            }
                    </CardContent>
                </Card>
            }
        </>
    )
}

