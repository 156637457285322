import React, { useEffect, useState } from "react";
import {
    Grid,
    Button,
    MenuItem,
    Typography,
    Checkbox,
    Paper,
    Menu,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import theme, {
    buttonClasses,
    inputClasses,
    textClasses,
    cardClasses,
} from "../../../../static/styles/theme"
import { dataReadFunction, dateTimeFormater } from "../../../../services/functions";
import GenericCustomiseHeader from "../../../Components/Generic/GenericCustomiseHeader";
import GenericTable from "../../../Components/CustomTable/GenericTable";
import { automationHeader, automationTableDataOutward, automationTableDataInventory } from "./constant";
import GenericToggle from "../../../Components/GenericToggle";
import SelectImage from "../../../../../src/static/images/data_entry/select_location.svg"
import AddIcon from '@mui/icons-material/Add';
import GenericLoader from "../../../Components/Generic/GenericLoader";


const OutwardOpRules = ({ dataCount }) => {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [tableData, setTableData] = useState(automationTableDataOutward || []);

    const automationRow =
        tableData.length > 0
            ? tableData.map((row, index) => {
                const item = [];
                item.push(
                    <Typography
                        sx={{
                            color: " #FF7200",
                            fontFamily: "Nunito",
                            ...textClasses.normalText,
                        }}
                    >
                        {row.Name}
                    </Typography>
                );
                item.push(
                    <Typography
                        sx={{
                            ...textClasses.normalText,
                        }}
                    >
                        {row.Details}
                    </Typography>
                );
                item.push(
                    <Typography
                        sx={{
                            ...textClasses.normalText,
                        }}
                    >
                        {row.WorkFlow}
                    </Typography>
                );
                item.push(
                    <Typography
                        sx={{
                            ...textClasses.normalText,
                        }}
                    >
                        {row.CreatedBy}
                    </Typography>
                );
                item.push(
                    <Typography
                        sx={{
                            ...textClasses.normalText,
                        }}
                    >
                        {row.UpdatedAt}
                    </Typography>
                );
                item.push(<GenericToggle isEnable={true} />);
                // item.push(
                //   <Typography
                //     data-testid="dropdown"
                //     sx={{
                //       ...textClasses.t13n,
                //       color: theme.themeOrange,
                //       cursor: "pointer",
                //     }}
                //     onClick={(e) => handleOpenAction(e, index)}
                //   >
                //     Action <KeyboardArrowDown />
                //   </Typography>
                // );
                return item;
            })
            : "";

    return (<>
        {
            dataCount > 0 ?  loader  ? (
                <Grid
                    data-testid="loader"
                    container
                    direction={"column"}
                    justifyContent={"center"}
                    alignItems="center"
                    sx={{ p: 10 }}
                >
                    <Grid item>
                        <GenericLoader />
                    </Grid>
                </Grid>
            ) :
                <GenericTable
                header={automationHeader}
                rows={automationRow}
                pageCount={automationRow.length}
                pageNumber={page}
                handleChangePage={(event, pagevalue) => {
                  setPage(pagevalue);
                }}
                handleChangeRowsPerPage={(event) => {
                  setLimit(+event.target.value);
                }}
                rowsPerPage={limit}
                />
                :

                <Grid container direction={"column"} justifyContent="center" alignItems="center" sx={{ p: 4, border: `1px solid ${theme.themeGray}`, borderRadius: 2 }}>
                    <img src={SelectImage} />
                    <Typography sx={{ ...textClasses.cardTitle, mt: 2 }}>create your first outward operation rule(s)</Typography>
                    <Typography sx={{ ...textClasses.t12n, textAlign: "center", maxWidth: "500px", mt: 2 }}>
                        Hey, you need to create a group of previously created SKU(s), apply some placement related rules on SKU groups build a smart space utilization.
                    </Typography>
                    <Button
                        variant="contained"
                        spacing={1}
                        sx={{
                            ...buttonClasses.lynkitBlackFill,
                            mt: 2,
                            ...textClasses.t14n,
                        }}
                        startIcon={<AddIcon />}
                    >
                        Create Rule
                    </Button>
                </Grid>
        }
    </>)
}

export default OutwardOpRules;