import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumbs } from '@mui/material';
import { Grid, Box, InputLabel, Select, MenuItem, FormControl, Typography } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import './Style/AreaMapping.css';
import BlankScreen from './Components/BlankScreen';
import AddIcon from '@mui/icons-material/Add';
import MapTable from './Components/MapTable';
import { getCompany } from '../../../redux/actions';
import { getGridDetail } from '../../../redux/actions/Warehouse';
import { getAreaMappingDetail, getMappedArea } from '../../../redux/actions/AreaMapping';
import GridViewLayout from '../GridView/Components/GridViewLayout';
import AllocateModel from './Components/AllocateModel';
import EmptyPage from '../../Modules/EntityMaster/EmptyPage';
import { dataModifer } from './Components/utils';


function AreaMapping() {

    const dispatch = useDispatch();
    const { message, error, loading } = useSelector((state) => state.common);
    const { companies, warehouseDetail, floorDetail } = useSelector(state => state.superadmin);
    const superFloorData = useSelector(state => state.superadmin.selectedFloor || {}) // floor data got when coming from superadmin page
    const { mappedArea } = useSelector((state) => state.areaMappingData)
    const { gridCellDetails } = useSelector((state) => state.warehouseData)
    const { userPermissions,sidebarList=[] } = useSelector((state) => state.userPermission);

    const [userType, setUserType] = useState("");
    const [condition, setCondition] = useState(true);
    const [superCondition, setSuperCondition] = useState(true);
    const [companiesData, setCompaniesData] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState("") // for selecting company by the superadmin
    const [warehouseId, setWarehouseId] = useState('all')
    const [warehouseData, setWarehouseData] = useState([]);
    const [warehouseName, setWarehouseName] = useState("")
    const [cellData, setCellData] = useState([])
    const [cellInfo, setCellInfo] = useState({});
    const [cellNameObj, setCellNameObj] = useState({})
    const [floorId, setfloorId] = useState('all')
    const [floorData, setFloorData] = useState([]);
    const [floorName, setFloorName] = useState("")
    const [floorInfo, setFloorInfo] = useState();
    const [allMappedArea, setAllMappedArea] = useState([])
    const [tableLoader, setTableLoader] = useState("tableData")
    const [area, setArea] = useState([]);
    const [allEmptyLocation, setAllEmptyLocation] = useState([]);
    const [cellIdObj, setCellIdObj] = useState({});
    const [withWFFetch, setWithWFFetch] = useState({ warehouse: true, floor: true, company: true, graph: true })
    const [pageNumber, setPageNumber] = useState(1)
    const [pageCount, setPageCount] = useState(0)
    const [pageLimits, setPageLimits] = useState(10)
    const [viewAllocation, setViewAllocation] = useState(false);
    const [allocateModelVisible, setAllocateModelVisible] = useState(false);
    const [selectedRack, setSelectedRack] = useState({});
    const [selectedRackArr, setSelectedRackArr] = useState([]);
    const [isEmpty, setIsEmpty] = useState(true);
    const [count, setCount] = useState({
        available: 0,
        mapped: 0,
        disabled: 0
    })

    const selectedCompanyHandler = (company) => {
        if (company) {
            dispatch(getCompany({
                "companyName": company,
                "type": "warehouse",
                "status": "active",
                "fetch_for":"gridView"
            }));
            setSelectedCompany(company)
        }
        else {
            setSelectedCompany('')
        }
        // setWarehouseId("")
        // setfloorId("")
        // setWarehouseData([])
        // setFloorData([])
        // setFloorInfo({})
        // setCellNameObj({})
        // setCellData([])
        // setAllMappedArea([])
        // setAllFilledLocation([])
        // setQrCalled(false)
        // setGraphData([])
    }

    const selectWarehouseHandler = (warehouse_Id = "") => {
        setTableLoader("tableData")
        if (warehouse_Id == 'all') {
            dispatch(getCompany({
                "type": "company",
                "status": "active"
            }));
            dispatch(getMappedArea({ page: pageNumber, limit: 10 }, ()=>{
                setTableLoader("")
            }))
            setfloorId('all')
        } else if (warehouse_Id) {
            dispatch(getCompany({
                "warehouseId": warehouse_Id,
                "type": "floor",
                "status": "active",
                "fetch_for":"gridView"
            }));
            dispatch(getMappedArea({ warehouseId: warehouse_Id, page: pageNumber, limit: 10 }, ()=>{
                setTableLoader("")
            }))
            setfloorId("all")
        }
        setWarehouseId(warehouse_Id)
        getWarehouseName(warehouse_Id)
        setPageNumber(1)
        setFloorData([])
        setCellNameObj({})
        setCellData([])
        setFloorInfo({})
        setAllMappedArea([])
        // setAllFilledLocation([])
    }

    const selectFloorHandler = (floor_Id = "") => {
        // //("data-floorid", floor_Id);
        if (floor_Id && warehouseId) {
            let data = floorDetail.find((ele) => ele._id == floor_Id)
            if (viewAllocation) {
                dispatch(getGridDetail({
                    "floorId": floor_Id,
                    "type": "block",
                    "fetch_for": 'gridview'
                }));
            } else {
                setTableLoader("tableData")
                if (floor_Id == 'all') {
                    dispatch(getMappedArea({ warehouseId: warehouseId, page: pageNumber, limit: 10 }, ()=>{
                        setTableLoader("")
                    }))
                } else {
                    dispatch(getMappedArea({ warehouseId: warehouseId, floorId: floor_Id, page: pageNumber, limit: 10 }, ()=>{
                        setTableLoader("")
                    }))
                }

            }
            setFloorInfo(data)
            setfloorId(floor_Id)
            getFloorName(floor_Id)
        }
        else {
            setTableLoader("tableData")
            dispatch(getMappedArea({ warehouseId: warehouseId, page: pageNumber, limit: 10 }, ()=>{
                setTableLoader("")
            }))
            setFloorInfo({})
            setfloorId("")
            setFloorName("");
        }
        setPageNumber(1)
        // setCellNameObj({})
        // setCellData([])
        setAllMappedArea([])
        setSelectedRack({})
        // setAllFilledLocation([])
        // setQrCalled(false)
        // setGraphData([])
        // setWithWFFetch({ ...withWFFetch, graph: true })
    }

    const onFetchPage = (pageNumber, pageLimits) => {
        if (pageNumber || pageLimits) {
            setTableLoader("tableData")
            // //('warehouseIdfloorId', warehouseId, floorId)
            dispatch(getMappedArea({
                warehouseId: warehouseId != 'all' ? warehouseId : "",
                floorId: floorId != 'all' ? floorId : "",
                page: pageNumber,
                limit: pageLimits
            },()=>{
                setTableLoader("")
            }))


        }
    }

    const addSelectedRack = (data) => {
        setSelectedRack(data)
        if (data.areaTypeData?.length == 0) {
            setIsEmpty(true)
        } else {
            setIsEmpty(false)
        }
        // //('selectedrack', data)
    }

    const showAllocateModel = () => {
        const updatedMappingArea = selectedRack.areaTypeData
            .filter(type => type.actionType === "update")
            .map(type => type.oldMaterialType).flat()
            .filter(Boolean) // Filters out undefined or null values

        const cellIds = selectedRack.areaTypeData
            .filter(type => type.actionType === "update")
            .map(type => type.cellId);

        const warehouseDetails = {
            warehouseId: selectedRack.warehouseId,
            floorId: selectedRack.floorId,
        };
        const payload = {
            floorId: selectedRack.floorId,
            mappingArea: updatedMappingArea,
            cellId: cellIds,
        };
        setTableLoader("areaDetail")
        dispatch(
            getAreaMappingDetail(payload, ({ success, message, data }) => {
                setTableLoader("")
                if (success) {
                    setSelectedRack(currentSelectedRack => {
                        const modifiedData = dataModifer({
                            warehouseId: currentSelectedRack.warehouseId,
                            floorId: currentSelectedRack.floorId,
                        }, data);
                        const newAreaTypeData = currentSelectedRack.areaTypeData
                            .filter(type => type.actionType === "addNew")
                            .concat(modifiedData.areaTypeData);
                        //   console.log(newAreaTypeData, "show newAreaTypeData hgv")
                        // Return a new object to ensure immutability
                        return { ...currentSelectedRack, areaTypeData: newAreaTypeData };
                    });
                }
            })
        );
        setAllocateModelVisible(true)
    }

    const countAvailblity = () => {
        let disable = 0;
        let available = 0;
        let mapped = 0;
        gridCellDetails.forEach((element) => {
            if(element.type=="block"){
                if (element.disable) {
                    disable += 1;
                } else if (!element.disable && element.subProductType?.length > 0) {
                    mapped += 1;
                } else if (!element.disable && element.subProductType?.length == 0) {
                    available += 1;
                }
            }
        });
        setCount({
            ...count, available: available,
            mapped: mapped,
            disabled: disable
        })

    }

    const getWarehouseName = (warehouse_id) => {
        let name = warehouseDetail.data.filter((ele) => ele._id == warehouse_id).map((item) => item.name)[0];
        setWarehouseName(name);
    }

    const getFloorName = (floor_id) => {
        // //('warehouse-name1', floorDetail, floor_id)
        let name = floorDetail.filter((ele) => ele._id == floor_id).map((item) => item.name)[0];
        setFloorName(name);
    }

    const handleRefreshTable = ()=>{
        onFetchPage(1, 10)
        setPageNumber(1);
        setPageLimits(10)
    }
    const closeAllocateModel = () => {
        setAllocateModelVisible(false)
        setSelectedRackArr([])
        setSelectedRack({})
        setIsEmpty(true)
        dispatch(getGridDetail({
            "floorId": floorId,
            "type": "block",
            "fetch_for": 'gridview'
        }));
    }


    useEffect(() => {
        if (viewAllocation) {
            dispatch(getGridDetail({
                "floorId": floorId,
                "type": "block",
                "fetch_for": 'gridview'
            }));
        }
    }, [viewAllocation])

    useEffect(() => {
        // if (floorId && FilledLocationByShipment) {
        //     setAllFilledLocation(FilledLocationByShipment)
        // }
        if (gridCellDetails) {
            let tempObj = {}
            let tempCellIdObj = {}
            countAvailblity()
            for (let ele of gridCellDetails) {
                let obj = { cellId: ele.cellId, name: ele.name, disable: ele.disable, status: ele.status, type: ele.type }
                let key = `${ele.rowIndex}${ele.colIndex}`
                tempObj[key] = obj
                tempCellIdObj[ele.cellId] = ele.name
            }
            let tempArr = []
            tempArr = gridCellDetails.filter((ele) => ele.status == "available")
            setAllEmptyLocation(tempArr)
            setCellData(gridCellDetails)
            setCellNameObj(tempObj)
            setCellIdObj(tempCellIdObj)
            // //('selectedrack-20', gridCellDetails)
        }
        else {
            setAllEmptyLocation([])
            setCellData([])
            setCellNameObj({})
            setCellIdObj({})
        }



    }, [gridCellDetails,])

    useEffect(() => {
        // //('maparea', mappedArea)
        if (((!allMappedArea || allMappedArea?.length == 0) || mappedArea?.data)) {
            if (mappedArea && mappedArea.data && mappedArea.data?.length > 0) {
                setAllMappedArea(mappedArea.data);
                setPageCount(mappedArea.count)
            }
            else {
                setAllMappedArea([])
                setPageCount(0)
            }
        } else {
            if (message) {
                setAllMappedArea(mappedArea.data);
                setPageCount(mappedArea.count)
            } else {
                setAllMappedArea([])
                setPageCount(0)
            }

        }
    }, [message, mappedArea, error])

    useEffect(() => {
        let userData = localStorage.getItem('user');
        if (userData && userData.userType) {
            setUserType(userData.userType)
        }
        if (condition && userData && userData.userType == "superadmin" && (!companiesData || companiesData?.length == 0)) {
            dispatch(getCompany({
                "type": "company",
                "status": "active"
            }));
        } else {
            if (superCondition && userData && userData.userType == "superadmin") {
                if (companies && companies.data && companies.data?.length > 0) {
                    // //('data', "superadmin2")
                    setCompaniesData(companies.data)
                    if (withWFFetch && withWFFetch.company) {
                        if (!selectedCompany) {
                            selectedCompanyHandler(superFloorData.companyName || companies.data[0].companyName)
                        }
                        let tempFetch = { ...withWFFetch, company: false }
                        setWithWFFetch(tempFetch)
                    }

                }
                else {
                    setCompaniesData([])
                }
                if (superCondition) {
                    setSuperCondition(false)
                }
            } else if (condition && (!warehouseData || warehouseData?.length == 0)) {
                dispatch(getCompany({
                    "companyName": userData.company,
                    "type": "warehouse",
                    "status": "active",
                    "fetch_for":"gridView"
                }));
                setTableLoader("tableData")
                dispatch(getMappedArea({ page: pageNumber, limit: pageLimits },()=>{
                    setTableLoader("")
                }))
            } else {
                if (warehouseDetail && warehouseDetail?.data && warehouseDetail?.data?.length > 0) {
                    setWarehouseData(warehouseDetail.data)
                }
                else {
                    setWarehouseData([])
                }
                let tempFloor=(floorDetail || [])?.filter(dl=>dl.warehouseId==warehouseId)
                if (warehouseId && tempFloor && tempFloor?.length > 0) {
                    setFloorData(tempFloor)
                    // if (userData && userData.userType == "superadmin") {
                    //     dispatch(setFloorInStore(null))
                    // }
                }
                else {
                    setFloorData([])
                }

            }
        }
        if (condition) {
            setCondition(false)
        }

    }, [companies, warehouseDetail, floorDetail, withWFFetch])

    return (
        <>
            {(allMappedArea) ?
                <Box sx={{ flexGrow: 1 }} className="AreaMap_container" data-testid="areaMapping-dashboard">
                    <Grid container spacing={2} margin="normal">
                        <Grid item xs={4}>
                            <h2 className='fs-24 mt-1 font-Nunito fw-600'>Area Mapping - Material Allocation </h2>
                            {viewAllocation ?
                                <Box>
                                    <Typography variant="subtitle2" sx={{ marginTop: "-8px" }} gutterBottom>
                                        <Breadcrumbs aria-label="breadcrumb">
                                            <Typography color="orange" sx={{ fontWeight: '400', fontSize : '12px', lineHeight: "10px" }} className='font-Nunito curser' onClick={() => setViewAllocation(false)}>Area Mapping</Typography>
                                            <Typography color="text.primary" sx={{ fontWeight: '400',fontSize : '12px', lineHeight: "10px" }} className='font-Nunito'>Area Allocation </Typography>
                                        </Breadcrumbs>
                                    </Typography>
                                </Box> : ""}
                        </Grid>
                        <Grid item xs={8}>
                            <Grid container spacing={2} justifyContent="end">
                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <InputLabel size="small" id="select-warehouse-label">Select Warehouse</InputLabel>
                                        <Select
                                            labelId="select-warehouse-label"
                                            id="select-warehouse"
                                            label="Select Warehouse"
                                            data-testid="warehouse"
                                            size="small"
                                            sx={{ boxShadow: 1 }}
                                            onChange={(event) => selectWarehouseHandler(event.target.value)}
                                            // displayEmpty
                                            value={warehouseId}
                                        >
                                            <MenuItem value={"all"}>All</MenuItem>
                                            {warehouseData && warehouseData.map((ele, index) => {
                                                return (
                                                    <MenuItem value={ele._id} key={index}>{ele.name}</MenuItem>
                                                )
                                            })}


                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <InputLabel size="small" id="select-floor-label">Select Floor</InputLabel>
                                        <Select
                                            labelId="select-floor-label"
                                            id="select-floor"
                                            label="Select Floor"
                                            data-testid="floor"
                                            size="small"
                                            value={floorId}
                                            sx={{ boxShadow: 1 }}
                                            // disabled={!warehouseId || warehouseId == 'all' ? true : false}
                                            readOnly={!warehouseId || warehouseId == 'all' ? true : false}
                                            // displayEmpty
                                            onChange={(event) => selectFloorHandler(event.target.value)}
                                        >
                                            <MenuItem value={"all"}>All</MenuItem>
                                            {floorData && floorData.map((ele, index) => {
                                                return (
                                                    <MenuItem value={ele._id} key={index}>{ele.name}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Box sx={{ my: 2, borderRadius: '10px', border: '1px solid #D9D9D9' }}>
                        <Grid container spacing={2} padding={2}>
                            <Grid item xs={6} display={'flex'} justifyContent={'start'}>
                                <Box mt={1}>
                                    <span className='font-Nunito fs-18 fw-600'>Mapped Area Details :&nbsp;
                                        <span className='orange-color font-Nunito fs-18 fw-600'>{warehouseName ? warehouseName : "All Warehouse"} - {floorName ? floorName : "All Floor"} Layout </span></span>
                                </Box>

                            </Grid>
                            {   userPermissions?.permissions?.find(dl=>dl.permissionId=="allocateArea") && 
                                <Grid item xs={6} padding={0} sx={{ display: 'flex', justifyContent: 'end' }}>
                                {viewAllocation ?
                                    <>
                                        <Box className='AM-label-container'>
                                            <Box className='AM-container'>
                                                <Box className='AM-green-box'></Box>&nbsp;&nbsp;<span className='fs-12 AM-center'>Available ({count.available})</span>
                                            </Box>
                                            <Box className='AM-container'>
                                                <Box className='AM-yellow-box'></Box>&nbsp;&nbsp;<span className='fs-12 AM-center' >Mapped ({count.mapped})</span>
                                            </Box>
                                            <Box className='AM-container'>
                                                <Box className='AM-white-box'></Box>&nbsp;&nbsp;<span className='fs-12 AM-center'>Disabled ({count.disabled})</span>
                                            </Box>
                                        </Box>
                                    </>
                                    :
                                    <Tooltip title={!floorId || !warehouseId ? "Please select before Warehouse and Floor" : ""}>
                                        <IconButton>
                                            <Button
                                               data-testid="allocateArea"
                                                className='addRack-btn font-Nunito'
                                                sx={{ borderRadius: "8px", bgcolor: 'warning.main' }}
                                                variant="contained"
                                                onClick={() => setViewAllocation(true)}
                                                disabled={(!floorId || !warehouseId) ? true : false}
                                            >
                                                <AddIcon sx={{ marginBottom: "2px" }} /> Allocate Area</Button>
                                        </IconButton>
                                    </Tooltip>}

                            </Grid>}
                        </Grid>
                        <Box padding={2}>
                            {viewAllocation ?
                                <>
                                    {floorInfo && Object.keys(floorInfo)?.length > 0 && floorInfo.row && floorInfo.col
                                        ?
                                        <>
                                            <GridViewLayout
                                                floorInfo={floorInfo}
                                                gridCellDetails={gridCellDetails}
                                                area={area}
                                                viewAllocation={viewAllocation}
                                                addSelectedRack={(data) => addSelectedRack(data)}
                                                selectedRackArr={selectedRackArr}
                                            />
                                            <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: '1rem' }}>
                                                <Button
                                                    className='addRack-btn font-Nunito'
                                                    sx={{ borderRadius: "8px", bgcolor: 'warning.main' }}
                                                    variant="contained"
                                                    onClick={() => showAllocateModel(true)}
                                                    disabled={isEmpty ? true : false}
                                                >
                                                    Allocate</Button>
                                            </Box>
                                        </>


                                        :
                                        <div>
                                            <EmptyPage description="No Grid View To Show - Select Warehouse And Floor First"/>
                                        </div>
                                    }
                                </>
                                :
                                <>
                                    <MapTable
                                        tableData={allMappedArea}
                                        pageCount={pageCount}
                                        tableLoader={tableLoader}
                                        setTableLoader={setTableLoader}
                                        rowsPerPage={pageLimits}
                                        pageNumber={(pageNumber - 1)}
                                        handleRefreshTable = {handleRefreshTable}
                                        handleChangePage={(event, pagevalue) => {
                                            setPageNumber(pagevalue + 1);
                                            onFetchPage((pagevalue + 1), pageLimits)
                                        }}
                                        handleChangeRowsPerPage={(event) => {
                                            setPageLimits(event.target.value)
                                            onFetchPage(pageNumber, event.target.value)
                                        }}
                                    />

                                </>
                            }
                        </Box>
                    </Box>
                </Box> :
                <BlankScreen />}
            {allocateModelVisible==true && <AllocateModel
                show={allocateModelVisible}
                onClose={() => closeAllocateModel()}
                selectedRackData={selectedRack}
                tableLoader={tableLoader}
                setTableLoader={setTableLoader}
                onSuccess={handleRefreshTable}
            />}

        </>
    )
}

export default AreaMapping;
