import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation, Link } from "react-router-dom";
import { Breadcrumbs, Grid, Tabs, Tab, Typography, Button, IconButton, Divider, Collapse, DialogContent, Dialog, TextField, FormLabel, FormControl, FormControlLabel, Radio, Autocomplete, TablePagination, Box, Checkbox } from '@mui/material'
import theme, { textClasses, buttonClasses, inputClasses } from "../../../static/styles/theme"
import SidebarCard from "../Settings/AutomationRules/CreateRuleAutomation/SidebarCard";
import AutomationHeader from "../Settings/AutomationRules/CreateRuleAutomation/AutomationHeader";
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { makeStyles } from "@mui/styles";
import ClearIcon from "@mui/icons-material/Clear";
import ItemDetailsSection from "./ItemDetailsSection";
import AssociatedJobsSection from "./AssociatedJobsSection";
import ProjectionSection from "./ProjectionSection";
import SkuTrendingGraph from "./SkuTrendingGraph";
import FullJourneyMap from "./FullJourneyMap";
import { showToast } from "../../../services/functions";

const useStyles = makeStyles({
    datePickerWrapper: {
        ".datePickerWrapper-1 MuiBox-root css-0": {
            with: "30%",
        },

        "& .react-datepicker-wrapper": {
            width: "100%",
            "& .react-datepicker__close-icon::after": {
                backgroundColor: "#FF7200",
                right: "30px",
                position: "absolute",
                top: "9px",
            },
            "& .react-datepicker__input-container .react-datepicker__calendar-icon": {
                right: "0px",
                "& path": {
                    fill: "grey !important",
                },
            },
        },
    },
    datePickerContainer: {
        // border: "1px solid #FF7200",
        minHeight: "35px",
        minWidth: "100%",
        ...inputClasses.filterField,
        backgroundColor: "white",
        boxShadow: "none",
        "&::placeholder": {
            color: "#455A64",
            fontWeight: "300",
        },
        // "&:hover": {
        //     border: "1px solid white",
        // },
    },
});

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

let tabList = [
    {
        "tabHeading": "Item Details",
        "tabKey": "itemDetails"
    },
    {
        "tabHeading": "Associated Jobs",
        "tabKey": "associatedJobs"
    },
    // {
    //     "tabHeading": "SKU Projection",
    //     "tabKey": "skuProjection"
    // },
    {
        "tabHeading": "SKU Trending Graph",
        "tabKey": "skuTrendingGraph"
    },
    {
        "tabHeading": "Full Journey Map",
        "tabKey": "fullJourneyMap"
    },
    // {
    //     "tabHeading": "In Stock QTY & Area Utilization",
    //     "tabKey": "stockQtyUtilization"
    // }
]

let getToDate = () => {
    let current1 = dayjs().startOf('day'); // Start of the current day (no time)
    let fromDate = current1.subtract(6, "day").startOf('day'); // Ensure fromDate has no time
    let toDate = current1.endOf('day'); // End of the current day for "To Date"

    return [fromDate, toDate];
};


const ViewInventoryJourney = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const skuId = queryParams.get('skuId');
    const invType = queryParams.get('invType');
    const itemTemplateId = queryParams.get('itemId');
    const [loader, setLoader] = useState("");
    const [tabIndex, setTabIndex] = useState(0);
    const [dateRange, setDateRange] = useState(getToDate);
    let tabKey = tabList?.at(tabIndex)?.tabKey || ""
    const handlTabChange = (tab_key, tab_Index = 0) => {
        // setFilter({ ...initail_filter, fetch: false })
        setTabIndex(tab_Index)
        // fetchAreautilization(floorInfo, initail_filter, tab_Index)
    }
    // const getFormattedHeader = () => {
    //     let header = templateDummyHeader?.templateHeader[invType.toLowerCase() + "Header"] || [];
    //     header = header?.map(dl => ({ ...dl, name: `${["Package", "Item"].includes(invType) ? 'dynamicData' : 'formData'}.${dl?.name}` }))
    //     if (["Package", "Item", "Kit"].includes(invType)) {
    //         if (invType == "Item") {
    //             header = header.concat((templateDummyHeader?.templateHeader["packageHeader"] || []).map(dl => ({ ...dl, name: `packageDetail.${dl?.name}` })))

    //         }
    //         header = header.concat((templateDummyHeader?.templateHeader["shipmentHeader"] || []).map(dl => ({ ...dl, name: `shipmentDetail.${dl?.name}` })))
    //     }
    //     else {

    //     }
    //     return header;
    // }
    const handleDateChange = useCallback(
        (date, type) => {
            let tem_data = [];
            if (type === "from") {
                if (!date) {
                    tem_data = [null, dateRange[1] || null];
                } else if (date.isAfter(dateRange[1])) {
                    showToast("Start Date must be smaller than End Date", true);
                    tem_data = [null, dateRange[1] || null];
                } else {
                    // Ensure the correct day is selected without any time manipulation
                    tem_data = [date, dateRange[1] || null];
                }
            } else { // if (type === "to")
                if (!date) {
                    tem_data = [dateRange[0] || null, null];
                } else if (date.isBefore(dateRange[0])) {
                    showToast("End Date must be Greater than Start Date", true);
                    tem_data = [dateRange[0] || null, null];
                } else {
                    // Ensure the correct "to" date is selected without any time manipulation
                    let tempEndDate = date.endOf('day'); // End of the day for "To Date"
                    tem_data = [dateRange[0] || null, tempEndDate];
                }
            }
            setDateRange(tem_data);
        },
        [dateRange]
    );
    

    return (<>
        <Typography sx={{ ...textClasses.cardTitle }}>SKU Details & History</Typography>
        <Grid container>
            <Grid container item sm={9} xs={6}>
                <Breadcrumbs sx={{ mb: 1 }}>
                    <Typography sx={{ ...textClasses.normalText, fontSize: "12px", color: theme.themeOrange }}>
                        <Link style={{ color: theme.themeOrange, textDecoration: "none" }} to="/dashboard/entity-master/item-master">
                            Item Master
                        </Link>
                    </Typography>

                    <Typography sx={{ ...textClasses.normalText, fontSize: "12px" }}>SKU Details & History / {skuId}</Typography>
                </Breadcrumbs>
            </Grid>
        </Grid>
        <Grid
            sx={{
                flexWrap: "nowrap",
                marginTop: "20px",
            }}
            container
            data-testid="create-automation"
        >
            {/* <SidebarCard
              setStep={setStep}
              isJobCreationStep={isJobCreationStep}
              selectJobType={selectJobType}
              setCreationStep={setCreationStep}
              creationStep={creationStep}
              jobTypefilter={jobTypefilter}
            /> */}
            {/* <Box sx={{ width: "80vw", marginLeft: "20px" }}>   Note : when sidebarcard enabled uncomment this and remove below 100vw */}
            <Box sx={{ width: "100vw" }}>
                <Grid
                    sx={{
                        backgroundColor: "#000",
                        borderTopRightRadius: "8px",
                        borderTopLeftRadius: "8px",
                        width: "100%",
                        padding: '6px'
                    }}
                    container
                    data-testid="create-automation"
                >
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid
                            item
                            xs={6}
                            sx={{
                                ...textClasses.t18n,
                                color: theme.themeWhite,
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Typography sx={{ marginRight: "20px" }}>
                                    Inventory Details & History
                                </Typography>
                                <IconButton>
                                    <SellOutlinedIcon sx={{ color: theme.themeWhite }} />
                                </IconButton>
                                <Typography>
                                    SKU ID: {skuId}
                                </Typography>
                            </Box>
                        </Grid>

                        {tabKey != "skuTrendingGraph" ?
                            <Grid item xs={6} container justifyContent="flex-end">
                                <Grid item xs={4} sx={{ position: "relative", marginRight: "16px" }}>
                                    <DatePicker
                                        className={`${classes.datePickerContainer}`}
                                        slotProps={{ textField: { size: "small" } }}
                                        placeholderText="From Date"
                                        label="From Date"
                                        format="DD/MM/YYYY"
                                        value={dateRange[0] || null} // The "From Date" value
                                        onChange={(date) => handleDateChange(date, "from")} // Handler for date change
                                    />
                                    {dateRange[0] && (
                                        <ClearIcon
                                            sx={{
                                                color: "gray",
                                                position: "absolute",
                                                top: 6,
                                                right: 30,
                                                cursor: "pointer"
                                            }}
                                            onClick={() => handleDateChange("", "from")}
                                        />
                                    )}
                                </Grid>

                                <Grid item xs={4} sx={{ position: "relative" }}>
                                    <DatePicker
                                        className={`${classes.datePickerContainer}`}
                                        value={dateRange[1] || null} // The "To Date" value
                                        onChange={(date) => handleDateChange(date, "to")} // Handler for date change
                                        label="To Date"
                                        format="DD/MM/YYYY"
                                        slotProps={{ textField: { size: "small" } }}
                                        placeholderText="To Date"
                                    />
                                    {dateRange[1] && (
                                        <ClearIcon
                                            sx={{
                                                color: "grey",
                                                position: "absolute",
                                                top: 6,
                                                right: 30,
                                            }}
                                            onClick={() => handleDateChange("", "to")}
                                        />
                                    )}
                                </Grid>
                            </Grid> : <></>}
                    </Grid>
                </Grid>

                <Grid container>

                    <Box
                        sx={{ border: 1, borderColor: "divider", width: '100%', borderBottom: 0 }}
                    >
                        <Tabs
                            value={tabIndex}
                            aria-label="disabled tabs example"
                            onChange={(event, tab_Index) => {
                                handlTabChange(event?.target?.name, tab_Index);
                            }}
                            sx={{
                                "ml": 2,
                                display: "flex",
                                justifyContent: "space-between",
                                "& .MuiTabs-indicator": { display: "none" }, // Remove the blue underline
                            }}
                        >
                            {tabList?.map((ele, index) => (
                                <Tab
                                    name={ele?.tabKey}
                                    key={index}
                                    data-testid={ele.tabHeading}
                                    label={ele.tabHeading}
                                    {...a11yProps(index)}
                                    sx={{
                                        ...textClasses.normalText,
                                        textTransform: "none",
                                        flexGrow: 1,
                                        m: 1,
                                        minWidth: "unset",
                                        padding: "8px 10px",
                                        border: "1px solid",
                                        borderColor: tabIndex === index ? theme.themeOrange : "divider",
                                        borderRadius: "8px",
                                        color: tabIndex === index ? theme.themeOrange : "inherit",
                                        "&.Mui-selected": {
                                            color: theme.themeOrange,
                                        },
                                        "&:hover": {
                                            backgroundColor: "rgba(0, 0, 0, 0.06)",
                                        },
                                    }}
                                    disabled={!!loader}
                                />
                            ))}
                        </Tabs>
                    </Box>
                </Grid>
                <Grid container sx={{ border: 1, borderColor: "divider", mb: 1, width: '100%', borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
                    {
                        tabKey == "itemDetails" && (
                            <ItemDetailsSection
                                skuId={skuId}
                                setTabIndex={setTabIndex}
                                fromDate={dateRange[0]}
                                toDate={dateRange[1]}
                            />
                        )
                    }
                    {
                        tabKey == "associatedJobs" && (
                            <Grid container sx={{overflowY: "scroll",height: '70vh'}}>
                            <AssociatedJobsSection
                                skuId={skuId}
                                setTabIndex={setTabIndex}
                                fromDate={dateRange[0]}
                                toDate={dateRange[1]}
                            />
                            </Grid>
                        )
                    }
                    {
                        tabKey == "skuProjection" && (
                            <ProjectionSection />
                        )
                    }
                    {
                        tabKey == "skuTrendingGraph" && (
                            <SkuTrendingGraph 
                            skuId={skuId}
                            />
                        )
                    }
                    {
                        tabKey == "fullJourneyMap" && (
                            <FullJourneyMap 
                            skuId={skuId}
                            setTabIndex={setTabIndex}
                            fromDate={dateRange[0]}
                            toDate={dateRange[1]}
                            />
                        )
                    }
                </Grid>
            </Box>
        </Grid>
    </>);
}

export default ViewInventoryJourney;