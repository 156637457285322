
import React, { useState, useEffect, useRef } from "react";
import { Autocomplete, Checkbox, Grid, TextField, Chip} from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import theme from "../../../static/styles/theme";
import { dataReadFunction } from "../../../services/functions";
import { selectStylesOverride } from "../../../utils/util";

const MultiSelectAll = ({
  items,
  selectAllLabel,
  onChange,
  value,
  label,
  limitTag = 1,
  defaultSelectAll = true,
  disabled =false,
  renderOption = []
}) => {
  const [intitalRender, setInitialRender] = useState(true);
  const multiSelectRef = useRef(null);

  useEffect(() => {
    if (items?.length && !value?.length && defaultSelectAll) {
      setInitialRender(false);
      onChange(items); // Initially select all if defaultSelectAll is true
    }
  }, [items]);

  const allItemsSelected = (selectedOptions) => {
    const allSelected = items?.length > 0 && items?.length === selectedOptions?.length;
    return allSelected;
  };

  const handleClearOptions = () => {
    onChange([]);
  };

  const handleSelectAll = (isSelected, filteredOptions) => {
    if (isSelected) {
      // Select all visible options (filteredOptions)
      onChange(filteredOptions?.length ? filteredOptions : items);
    } else {
      // Clear selection
      onChange([]);
    }
  };

  const handleToggleSelectAll = (selectedOptions, filteredOptions) => {
    handleSelectAll(!allItemsSelected(selectedOptions), filteredOptions);
  };

  const handleChange = (event, selectedOptions, reason, filteredOptions) => {
    if (reason === "clear") {
      handleClearOptions();
    } else if (selectedOptions.find((option) => option._id === "select-all")) {
      handleToggleSelectAll(selectedOptions, filteredOptions);
    } else {
      onChange(selectedOptions);
    }
  };

  const handleCheckboxChange = (selected, option, selectedOptions) => {
    if (option._id === "select-all") {
      handleToggleSelectAll(selectedOptions);
    } else {
      const result = selected
        ? selectedOptions?.filter((selOption) => selOption?._id !== option?._id)
        : [...selectedOptions, option];
      onChange(result);
    }
  };

  const optionRenderer = (props, option, { selected }, selectedOptions, filteredOptions) => {
    let optionName = option?.name || ""; // Safeguard for option.name
    if(renderOption?.length > 0){
      optionName = dataReadFunction({renderOption},option)
    }
    const selectAllProps =
      option._id === "select-all"
        ? { checked: allItemsSelected(selectedOptions) }
        : {};
    return (
      <Grid container key={optionName}>
        <Grid item xs={12} sx={{ pl: 1, pr: 1 }}>
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={(e) => handleCheckboxChange(selected, option, selectedOptions)}
          >
            <Checkbox key={optionName} checked={selected} {...selectAllProps} sx={{ mr: 1 }} />
            <span>{optionName}</span> {/* Use safe access here */}
          </div>
        </Grid>
      </Grid>
    );
  };

  const filteredOptions = (options = [], params = {}) => {
    const filter = createFilterOptions();
    const inputValue = params?.inputValue?.toLowerCase?.() || "";
    const filtered = filter(options, { ...params, inputValue });
    return filtered?.length >0 ? [{ name: selectAllLabel, _id: "select-all" }, ...filtered] : [];
  };

  const inputRenderer = (params) => (
    <TextField
      {...params}
      variant="outlined"
      label={label}
      placeholder={label}
      sx={{
        ...selectStylesOverride,
        color: theme.themeOrange,
      }}
    />
  );

  return (
    <Autocomplete
      ref={multiSelectRef}
      disabled={disabled}
      multiple
      size="small"
      limitTags={disabled ? 10 : limitTag}
      options={items}
      value={value}
      disableCloseOnSelect
      getOptionLabel={(option) =>option.name}
      isOptionEqualToValue={(option, val) => option._id === val._id}
      filterOptions={(options, params) => filteredOptions(options, params)}
      onChange={(event, selectedOptions, reason) =>
        handleChange(event, selectedOptions, reason, filteredOptions(items))
      }
      renderOption={(props, option, { selected }) =>
        optionRenderer(props, option, { selected }, value, filteredOptions(items))
      }
      renderInput={inputRenderer}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          let label = option?.name
          if(renderOption?.length > 0){
            label = dataReadFunction({renderOption},option)
          }
          return <Chip
          label={label}
          {...getTagProps({ index })}
        />
        })
      }
    />
  );
};

export default MultiSelectAll;
