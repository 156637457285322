

module.exports = {
  footerText: 'Copyright Company Name © 2023',
  // socketURL: "http://localhost:9008/",
  // baseURL: `http://localhost:9008/v1/`,
  // socketURL: "https://warehouse-new.lynkgrid.com/",
  // baseURL:`https://warehouse-new.lynkgrid.com/v1/`,
  lynkitIOURL: process.env.REACT_APP_LYNKIT_IO_URL,
  lynkID: process.env.REACT_APP_LYNKIT_ID_URL,
  socketURL : "/",
  baseURL: `/v1/`,
}


