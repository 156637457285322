import React, {useState, useEffect} from 'react'
import { 
    Grid, Typography, Button, Dialog, DialogContent, IconButton, ButtonGroup, Autocomplete, TextField ,FormControlLabel,Checkbox,
    Box,Tooltip} from '@mui/material'
import theme, { inputClasses, buttonClasses, textClasses, cardClasses } from '../../../../../../static/styles/theme'
import { Add, AddOutlined, Cancel, Remove } from '@mui/icons-material';
import { capitalizeSentence, getCamelCaseText, showToast,validateFieldsMultiple ,deepCopy_Object,calculateFormulaHelper} from '../../../../../../services/functions';
import { useSelector } from 'react-redux';
import { getInputFieldForTableRow } from '../../../../../Components/TemplateInputConfiguration';

const AsnPackageItemTableSection = (props) => {
    let {
        template, selectedAsnItems, onClose, onBack,open,openFrom,
        totalItemCount=0,itemDetail=[],saveItemHandler,multiSKUSelected,openType="add",
        pIndex=0, packageObj,//use in case of SPI only in both add and edit
        setPIndex,packages,setPackageObj, //use in case of SPI only only in add
        isRejectReasonRequired=false
    }=props


    //("Items in selected", items);
    const [items, setItems] = useState([]);
    const [errors, setErrors] = useState([]);
    const [isInitial, setIsInitial] = useState(true);
    const [boxCount, setBoxCount] = useState(1);
    const [countPerBox, setCountPerBox] = useState(null);
    // console.log("Items now", items);
    const countTillNow = items?.map((i) => Number(i.count)).reduce((a, b) => a+b , 0)
   

    const remainingItems = totalItemCount - countTillNow;

    const dimensions = useSelector(state => state.inventory.parcelgetDimensions);
    const mappingLocations = useSelector(state => state.inward.allMapping);

    let field = {
        type: "select",
        label: "Mapping Area",
        required: true,
        _id: "mappingArea",
        values: mappingLocations || []
        // .map((loc) => ({
        //     label: loc.label,
        //     value: loc.value
        // }))
        ,
        defaultValue: "PUT IT ANY WHERE"
    }

    let mandatoryFieldsId = useSelector(state => state.canvas.components[`itemTemplate`].mandatory.filter(f => {
        if(!f.templateType) return true;
        if(f.templateType && f.templateType == template.templateType){
            return true;
        }
        return false;
    }).map(f => f._id));
    
    //("Mandatory Ids", mandatoryFieldsId);

    const templateDetails = template?.otherTemplate?.find(ot => ot?.templateType == `itemDetailTemplate`) || [];
    const otherFields = templateDetails ? templateDetails.attributes.filter(f => mandatoryFieldsId.indexOf(f._id) < 0) : [];
    const mandatoryFields = templateDetails ? templateDetails.attributes.filter(f => mandatoryFieldsId.indexOf(f._id) >= 0) : [];


    const completeFields = mandatoryFields.concat(otherFields).concat([field])
    if(items?.some(dl=>dl.receivingType=="rejectedQuantity")){
        completeFields.splice(3,0,{
            _id: "rejectedReason",
            type: "input",
            label: "Reject Reason",
            placeholder: "Reject Reason",
            required: !!isRejectReasonRequired,
        })
    }
    // console.log("Item Template Details ---- ", completeFields);
    // console.log();

    const getTableHeader = (headerType) => {
        let base = ["Count"]
        let otherHeader = completeFields.filter(f => f._id != "count").map((of) => {
            let label = of.label;
            return label
        })
        return [...base, ...otherHeader];
    }

    const convertDocsBase64 = (file) => {
        if (file) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file)
                    fileReader.onload = () => {
                        resolve(fileReader.result);
                    }
                    fileReader.onerror = (error) => {
                        reject(error);
                    }
                }, 400)
            })
        }
        else {
        }
    }

    const handleGenericChange = async (event, i, fieldId) => {
        const targetField = completeFields.find(f => f._id == fieldId) || {};
        let oldItems = deepCopy_Object(items);
        let oldObject = {...oldItems[i]};
        if(targetField.type == "file"){
            const file = await convertDocsBase64(event.target.files[0])
            oldObject[fieldId] = file;
        }
        else if(targetField.type == "checkbox") {
            let oldValues = oldObject[fieldId] ? oldObject[fieldId] : [];
            if(event.checked){
                oldValues.push(event.value);
            }
            else{
                let pos = oldValues.indexOf(event.value);
                oldValues.splice(pos, 1)
            }
            oldObject[fieldId] = [...oldValues];
        }
        else{
            oldObject[fieldId] = event.target.value;
        }
        let formulaFields = completeFields?.find(dl=>dl?._id==fieldId)?.formulaFields || []
        if (formulaFields?.length > 0) {
            calculateFormulaHelper(oldObject, formulaFields, oldObject)
        }
        oldItems[i] = {...oldObject};
        if(fieldId=="mappingArea" && oldObject.sameForNext){
            oldItems = oldItems.map((oi, j) => {
                let ni = {...oi};
                if(j > i){
                    let change=true
                    ni.sameForNext = false
                    if(!oldObject.mappingArea){
                        ni.mappingArea = ""
                    }
                    else if (ni?.restrictedMappingArea?.length>0 && !ni?.restrictedMappingArea?.includes(oldObject.mappingArea)) {
                        change=false
                    }
                    else{
                        change=true
                    }
                    if(change){
                        ni.mappingArea = oldObject.mappingArea;
                    }
                }
                return ni;
            })
        }
        setItems([...oldItems]);
    }

    const autoFillOnFocus = (index) => {
        if(dimensions){
            let oldItems = deepCopy_Object(items);
            for (let i = 0; i < completeFields.length; i++) {
                let compId = completeFields[i]._id
                if(["weight", "length", "breadth", "height"].indexOf(compId) >= 0 && dimensions[compId]){
                    oldItems[index][compId] = dimensions[compId];
                }
            }
            setItems(oldItems)
        }
    }

    
    const handleItemCount = (i, direction,maxCount=0) => {
        if(!multiSKUSelected){
            return;
        }
        let oldItems = deepCopy_Object(items);
        if(direction == 1){
            if(Number(oldItems?.count|| 0) < Number(maxCount)){
                oldItems[i].count = Number(oldItems[i].count) + 1
            }  
        }
        else if(direction == -1 ){
            if(Number(oldItems[i].count) > 1){
                oldItems[i].count = Number(oldItems[i].count) - 1
            }
        }
        else{
            
            let incValue=Number(oldItems?.count|| 0)+Number(direction.target.value)
            incValue=Math.min(incValue, Number(maxCount || 0))
            oldItems[i].count = incValue
        }
        if(oldItems[i].count > 1){
            oldItems[i].USN_Value = "Not Applicable"
            oldItems[i].disabled.push("USN_Value")
        }
        else{
            oldItems[i].USN_Value = ""
            oldItems[i].disabled= oldItems[i].disabled?.filter(dl=>dl!="USN_Value")
        }
        let formulaFields = completeFields?.find(dl=>dl?._id=="count")?.formulaFields || []
        if (formulaFields?.length > 0) {
            calculateFormulaHelper(oldItems[i], formulaFields, oldItems[i])
        }
        setItems(oldItems);
    }

    const handleSaveForNext = (i, e) => {
        if(items[i].sameForNext){
            let oldItems = deepCopy_Object(items)
            let object = {
                ...oldItems[i],
                sameForNext: false
            };
            oldItems[i] = object
            setItems([...oldItems]);
        }
        else{
            let oldItems = deepCopy_Object(items)
            let object = {...oldItems[i]};
            let newItems = oldItems.map((oi, j) => {
                let ni = {...oi};
                if(j == i){
                    ni.sameForNext = e.target.checked
                }
                if(j > i){
                    let change=true
                    ni.sameForNext = false
                    if(!object.mappingArea){
                        ni.mappingArea = ""
                    }
                    else if (ni?.restrictedMappingArea?.length>0 && !ni?.restrictedMappingArea?.includes(object.mappingArea)) {
                        change=false
                    }
                    else{
                        change=true
                    }
                    if(change){
                        ni.mappingArea = object.mappingArea;
                    }
                }
                return ni;
            })
            setItems([...newItems]);
        }
    }


    const getItemRow = (i) => {
        let item = items[i] || {};
       
        let base = [
            (   <Box sx={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",gap:1}}>

                {multiSKUSelected && items?.length > 0 &&
                <Tooltip title="This is for removing item" placement='right'>
                    <Button
                        disabled={items?.length==0}
                        key="four" size='small'
                        sx={{
                            ...buttonClasses.lynkitBlackFill, fontSize: "12px", 
                            maxHeight: "13px!important",
                             maxWidth: "30px!important",
                            minWidth: "30px!important"
                        }}
                        onClick={() => handleDeleteItem(i)}
                    >
                        <Remove fontSize='inherit' />
                    </Button>
                </Tooltip>
                }
                
                <ButtonGroup size='small' sx={{minWidth: "24px", ".MuiButtonGroup-grouped": {minWidth: "25px"}}} disabled={!multiSKUSelected}>
                    <Button  disabled={(item?.count== 0)} key="one" size='small' sx={{...buttonClasses.smallOrange, fontSize: "12px"}} 
                    onClick={() => handleItemCount(i, -1,item?.maxCount || 0)}>
                        <Remove fontSize='inherit'/>
                    </Button>
                    {/* <Button key="two" disabled size='small' sx={{...buttonClasses.smallOrange, border: "1px solid #FF2700", backgroundColor: "white", fontSize: "12px"}}>
                        {items[i].count}
                    </Button> */}
                    <input
                        type="number"
                        disabled={!multiSKUSelected}
                        style={{border: "1px solid #D9D9D9", maxWidth: "60px", fontSize: "12px", textAlign: "center"}}
                        value={items[i].count || null}
                        onChange={(e) => handleItemCount(i, e,item?.maxCount || 0)}
                        onWheel={(event) => event.target.blur()}
                        
                        onKeyDown={(e) => {
                            if(['e', 'E', '+', '-'].includes(e.key)){
                                e.preventDefault();
                            }
                            else if(e.key == '0' && (e.target.value == "" || Number(e.target.value) == 0)){
                                e.preventDefault();
                            }
                        }}
                    />
                    <Button disabled={(item?.count || 0)==(item?.maxCount || 0)} key="three" size='small' sx={{...buttonClasses.smallOrange, fontSize: "12px"}} onClick={() => handleItemCount(i, 1,item?.maxCount || 0)}>
                        <Add fontSize='inherit'/>
                    </Button>
                </ButtonGroup>
               {multiSKUSelected && Number(item?.count || 0) < Number(item?.maxCount || 0) &&
               <Tooltip title="This is for adding new item from same item for remaining count" placement='right'>
                    <Button
                        disabled={Number(item?.count || 0) >= Number(item?.maxCount || 0)}
                        key="four" size='small'
                        sx={{
                            ...buttonClasses.lynkitOrangeFill, fontSize: "12px",
                            maxHeight: "13px!important",
                            maxWidth: "30px!important",
                            minWidth: "30px!important"
                        }}
                        onClick={() => handleAddItem(i)}
                    >
                        <Add fontSize='inherit' />
                    </Button>
               </Tooltip>
                }
            </Box>
            )
        ]

        const others = completeFields.filter( f => f._id != "count").map((of) => {
            let component = {...of};
            if(of._id == "UOM"){
                component.type = "input"
                component.disabled = true;
                component.dontFetch = true
            }
            else if (of._id == "USN_Value") {
                component.disabled = items[i].count > 1
                // console.log("fff",items[i])
            }
            else if (["SKU_Number", "UOM",].indexOf(of._id) >= 0 && items[i][of._id] != null && items[i][of._id] != "") {
                component.disabled = true;
            }
            else if (["SKU_Name"].indexOf(of._id) >= 0 && items[i].masterType && items[i][of._id] != null && items[i][of._id] != "") {
                component.disabled = true;
            }
            else{
                if (items[i].disabled?.length>0 && items[i].disabled.includes(of._id)) {
                    component.disabled = true
                }
                else {
                    component.disabled = false;
                }
            }

            let isVisible=true
            if(of._id=="mappingArea"){
                if(items[i]?.masterType && items[i]?.restrictedMappingArea?.length>0){
                    //(component?.values?.find(dl=>dl.isDefault) || {}
                    // console.log(items)
                    let restrict=items[i]?.restrictedMappingArea?.map(dl=>({label:dl,value:dl}))
                    component.values=[...restrict]
                    component.label=component.label + " (Restricted)"
                    isVisible=true
                }
            }
            // console.log("Component", component);
            if(i==items.length-1){
                isVisible=false
            }
            return (
                <> 
                    {
                        getInputFieldForTableRow(component, items,  i, handleGenericChange, autoFillOnFocus, errors, false, null)
                    }
                    {
                        isVisible && of._id == "mappingArea" &&
                        <FormControlLabel control={<Checkbox checked={items[i]?.sameForNext==true} onClick={(e) => handleSaveForNext(i, e)} size='small' />} label={<Typography sx={{fontSize: "12px"}}>Same for next</Typography>}/>
                    }
                </>
            )
        })
        
        return [...base, ...others]
        
    }

    const header = getTableHeader();
    const rows = items?.map((item, i) => {
        let objRes= getItemRow(i);
        
        return objRes;
    })

    const getItemObj = (count=0, si = {}) => {
        let fieldsData = {}
        if((openType=="edit" || packages?.length>0)? true:!isInitial && items?.length > 0){//&& (packages?.length>0 ? true:items?.length>0)
            fieldsData= {...si,count};
            if( count > 1){
                fieldsData["USN_Value"]="Not Applicable" 
                fieldsData.disabled = [...(fieldsData.disabled || []),"USN_Value"]
            }
            else{
                fieldsData["USN_Value"]=""
                fieldsData.disabled = fieldsData.disabled?.filter(dl=>dl!="USN_Value") || []
            }
           
            let formulaFields=completeFields?.find(dl=>dl?._id=="count")?.formulaFields || []
            if (formulaFields?.length > 0) {
                calculateFormulaHelper(fieldsData, formulaFields || [], fieldsData,true)
            }
            return fieldsData
        }
        else{
            let fields = completeFields.filter(f => f._id != "count")
            for (let i = 0; i < fields.length; i++) {
                if (fields[i].type == "checkbox") {
                    fieldsData[fields[i]._id] = []
                }
                else if (fields[i].type == "file") {
                    fieldsData[fields[i]._id] = null;
                }
                else {
                    fieldsData[fields[i]._id] = ""
                }
            }

            let p = {
                count: count,
                mappingArea: "PUT IT ANY WHERE",
                USN_Value: count > 1 ? "Not Applicable" : "",
                receivingType: si?.receivingType || "availableQuantity",
                ASN_ComponentId: si._id,
                UOM: si?.formData?.UOM ? si.formData.UOM : "",
                sameForNext: false,
                disabled: [],
                maxCount: si?.maxCount || 0,
                product_ID:si?.product_ID || "",
                masterDetail: (si?.itemMasterDetail || {}),
                asnDetail: (si?.formData || {})
    
            }
            if (count > 1) {
                p.disabled.push("USN_Value")
            }
            p["SKU_Number"] = si?.formData?.product_ID || ''
            p["SKU_Name"] = (si?.formData?.SKU_Detail || si?.itemMasterDetail?.SKU_Detail) ? (si?.formData?.SKU_Detail || si?.itemMasterDetail?.SKU_Detail) : ""
            p.masterType = si.itemMasterDetail != null
    
            if (p["SKU_Number"]) {
                p.disabled.push("SKU_Number")
            }
            if (p["SKU_Name"]) {
                p.disabled.push("SKU_Name")
            }
            let skipFields=["SKU_Number", "SKU_Name"]
            for (let item of completeFields) {
                if (!skipFields.includes(item._id)) {
                    if(item._id!="count"){
                        if (si?.formData && si?.formData[item._id]) {
                            p[item._id] = si?.formData[item._id]
                            p.disabled.push(item._id)
                        }
                        else if (si?.itemMasterDetail && si?.itemMasterDetail[item._id]) {
                            p[item._id] = si?.itemMasterDetail[item._id]
                            p.disabled.push(item._id)
                        }
                    }
                    if (item?.formulaFields?.length > 0) {
                        calculateFormulaHelper({ ...fieldsData,...(si?.itemMasterDetail || {}), ...p },  (item?.formulaFields || [])?.filter(ell=>ell.isPrimary), p,true,skipFields)
                        // console.log({ ...(si?.itemMasterDetail || {}), ...p },"p",item?.formulaFields);
                    }
                }
            }
    
            if (si.itemMasterDetail != null && si?.itemMasterMappingArea?.length) {
                p["restrictedMappingArea"] = si?.itemMasterMappingArea || []
                p["mappingArea"] = si?.itemMasterMappingArea?.at(0) || ""
            }
       
    
            let newRow = {
                count: count,
                ...fieldsData,
                ...p
            }
            return newRow;
        }

    }

    const handleDeleteItem = (i) => {
        let oldItems = deepCopy_Object(items);
        let itemDeleted=oldItems[i] || {}
        oldItems.splice(i, 1);
        let nextItemWithSKU=oldItems.findIndex(dl=>dl.SKU_Number==itemDeleted.SKU_Number)
        if(nextItemWithSKU>=0){
            oldItems.splice(nextItemWithSKU, 1,{
                ...oldItems[nextItemWithSKU],
                maxCount:Number(oldItems[nextItemWithSKU]?.maxCount || 0)+Number(itemDeleted.maxCount || 0)
            });
        }
        setItems(oldItems);
    }

    const handleAddItem = (i) => {
        let oldItems = deepCopy_Object(items);
        let itemFromAdded=oldItems[i] || {}
        if(Number(itemFromAdded?.maxCount || 0)>Number(itemFromAdded?.count || 0)){
            let newItem=deepCopy_Object(itemFromAdded)
            let availCount=Number(itemFromAdded?.maxCount || 0)-Number(itemFromAdded?.count || 0)
            newItem["count"]=availCount
            newItem["maxCount"]=availCount

            itemFromAdded["maxCount"]=Number(itemFromAdded?.count || 0)

            let formulaFields = completeFields?.find(dl=>dl?._id=="count")?.formulaFields || []
            if (formulaFields?.length > 0) {
                calculateFormulaHelper(newItem, formulaFields, newItem)
            }

            oldItems.splice(i, 1,itemFromAdded,newItem);

            setItems(oldItems);
        } 
    }
    
    const validateFields = () => {
        return validateFieldsMultiple(completeFields || [], items || [])
    }

    const getOtherDetail=(item)=>{
        let other={
            mappingArea:"PUT IT ANY WHERE",
            masterType: item.itemMasterDetail != null
        }
        
        if(item.itemMasterDetail != null && item?.itemMasterMappingArea?.length){
            other["restrictedMappingArea"]=item?.itemMasterMappingArea || []
            other["mappingArea"]=item?.itemMasterMappingArea?.at(0) || ""
        }
        return other
    }

    const handleSubmitItems = () => {
        if(!items || items.length==0){
            showToast("Minimum one item need to added", true);
            return
        }
        let newErrors = validateFields().filter(row => Object.keys(row).length > 0);
        if (newErrors.length > 0) {
            showToast("Form Error: Please check for errors", true);
            setErrors(newErrors);
        }
        else{
            if(saveItemHandler){
                saveItemHandler(items,pIndex)
                let tempMessage="Added Item(s) successfully"
                if(openType=="edit"){
                    tempMessage="Item(s) updated successfully"
                }
                showToast(tempMessage, false)
                if(onBack){
                    onBack()
                }
            }
        }
        
    }

    const handleBoxChangeCount = (e) => {
        //console.log("Log event", e);
        let totalCount = totalItemCount
        let count_temp = 0
        // let count_temp = totalCount
        if (Number(e) == -1 && boxCount > 1) {
            count_temp = Number(boxCount) - 1
        }
        else if (Number(e) == 1 && boxCount < totalCount) {
            
            count_temp = Number(boxCount) + 1
            // console.log("Box Count temp", count_temp);
        }
        else if (typeof e == "object") {
            count_temp = Number(e.target.value) <= totalCount ? Number(e.target.value) : totalCount
        }
        let cpb;
        if((count_temp - 1)*Math.ceil(totalCount/count_temp) < totalCount){
            cpb = Math.ceil(totalCount/count_temp)
        }
        else{
            cpb = Math.floor(totalCount/count_temp)
        }
        // let cpb = totalCount % 2 == 0 ? Math.ceil(totalCount/count_temp) : Math.floor(totalCount/count_temp)
        // if(count_temp==0){
        //     setCountPerBox(null)
        // }
        // console.log("Box Count", count_temp);
        setBoxCount(count_temp)
        setCountPerBox(cpb);
    }

    const countPerBoxHandler = (e) => {
        let totalCount = totalItemCount;
        let count_temp = totalCount
        if (Number(e) == -1 && countPerBox > 1) {
            count_temp = Number(countPerBox) - 1
        }
        else if (Number(e) == 1 && countPerBox < totalCount) {
            count_temp = Number(countPerBox) + 1
        }
        else if (typeof e == "object") {
            count_temp = Number(e.target.value);
        }
        if((boxCount-1)*count_temp < totalCount){
            setCountPerBox(count_temp)
        }
        else{
            showToast(`Cannot allocate ${count_temp} item per package`, true)
        }
    }

    useEffect(() => {
        if(!multiSKUSelected && !isInitial){
            if (itemDetail?.length>0 && boxCount >= 1 && countPerBox >= 1) {
    
                let totalCount = totalItemCount;
                let newItems=[];
                let itemToDivide=selectedAsnItems[0] || {}
                if(items?.length>0){
                    itemToDivide=items[0] || {}
                }
                if (countPerBox * boxCount == totalCount) {
                    // In case of equal division
                    // console.log("Equal division", countPerBox);
                    newItems = Array(boxCount).fill(1).map((it, i) => {
                        return getItemObj(Number(countPerBox),itemToDivide);
                        // return getItemObj(Number(countPerBox),selectedAsnItems[0] || {});

                    });
                }
                else {
                    newItems = Array(boxCount - 1).fill(1).map((it, i) => {
                        return getItemObj(Number(countPerBox),itemToDivide);
                        // return getItemObj(Number(countPerBox),selectedAsnItems[0] || {});

                    });
                    newItems.push({
                        ...getItemObj(Number(totalCount - (countPerBox * (boxCount - 1))),itemToDivide)
                        // ...getItemObj(Number(totalCount - (countPerBox * (boxCount - 1))),selectedAsnItems[0] || {})
                    })
                }
                setItems(newItems);
            }
            else if (boxCount == "") {
                setItems([])
            }
        }
    }, [boxCount, countPerBox])

    useEffect(() => {
        // setValue("count", items.map(i => i.count).reduce((a, b) => a + b, 0))
        if(itemDetail && isInitial){
            if(multiSKUSelected){
                // console.log("itemDetail",itemDetail)
                if(packages?.length>0){
                    setItems(deepCopy_Object(itemDetail|| []));
                }
                else{
                    let tempItems=selectedAsnItems.reduce((acc,itemInfo)=>{
                        let objI=getItemObj(Number(itemInfo?.count || 0),itemInfo || {})
                        if(open=="uniqueItem"){
                            let arr=new Array(Number(itemInfo?.count || 0)).fill({...objI,count:1,maxCount:1})
                            acc.push(...arr)
                        }
                        else{
                            acc.push(objI)
                        }

                        return acc
                    },[])
                    setItems(tempItems);
                }
            }
            else{
                let boxCount_temp=itemDetail?.length || 1
                let countPerBox_temp=itemDetail[0]?.count || null
                if(packages?.length>0){
                    setItems(deepCopy_Object(itemDetail|| []));
                }
                else{
                    if(open=="uniqueItem"){
                        boxCount_temp=itemDetail?.reduce((a, b) =>Number(a) + Number(b.count || 0), 0)
                        countPerBox_temp=1
                    }
                }
                setBoxCount(boxCount_temp);
                setCountPerBox(countPerBox_temp);
            }
            setIsInitial(false)
        }
    }, [itemDetail])
    return (
        
        <Grid container direction={"column"}>
            <Typography sx={{...textClasses.cardTitle}}>Item(s) Details</Typography>
            {multiSKUSelected ? "" : <Grid sx={{ p: 2, border: "1px solid #D9D9D9", borderRadius: "8px", mt: 2 }}>

                <Grid container spacing={1}>
                    {
                        ["package", "pallet", "bothPackageItem","bothPalletItem"].includes(open)
                            ?
                            (packages?.length<=1 || openType == "edit")
                                ? packages?.length==1?<Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, mr: 2 ,mt:1.2}}>Package ({packageObj?.packageName || ""})</Typography>:""
                                :
                                <Grid container item sm={3} md={3} xs={6}>
                                    <Autocomplete
                                        fullWidth
                                        clearIcon={false}
                                        value={packageObj}
                                        onChange={(e, option) => {
                                            setPackageObj(option);
                                            setPIndex(option?.pIndex || null);
                                            setItems(option?.itemDetail || [])
                                        }}
                                        getOptionLabel={(option) =>
                                            capitalizeSentence(option.packageName || "")
                                        }
                                        size="small"
                                        options={packages.map((p, i) => ({ ...p, pIndex: i })).filter(p => p.packageName)}
                                        sx={{
                                            ...inputClasses.filterField,
                                            minWidth: "220px",
                                            m: 0,
                                            ".MuiInputBase-input": {
                                                fontSize: "14px",
                                                color: "#455A64",
                                            },
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                sx={{ color: "#455A64", ...textClasses.normalText }}
                                                {...params}
                                                label="Package Name or Package SKU"
                                            />
                                        )}
                                    />
                                </Grid>
                            : ""
                    }
                    <Grid container item sm={3} md={3} xs={6} justifyContent={"center"} alignItems={"center"}>
                        <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, mr: 2 }}>Total Packed QTY: </Typography>
                        <ButtonGroup size='small' sx={{ minWidth: "24px", ".MuiButtonGroup-grouped": { minWidth: "25px" } }} disabled>
                            <Button key="one" size='small' sx={{ ...buttonClasses.smallOrange }}>
                                <Remove fontSize='inherit' />
                            </Button>
                            <input
                                type="number"
                                style={{ border: "1px solid #D9D9D9", maxWidth: "60px", textAlign: "center", fontSize: "12px" }}
                                value={totalItemCount || null}
                                disabled
                                onWheel={(event) => event.target.blur()}
                                onKeyDown={(e) => {
                                    if (['e', 'E', '+', '-'].includes(e.key)) {
                                        e.preventDefault();
                                    }
                                    else if (e.key == '0' && (e.target.value == "" || Number(e.target.value) == 0)) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                            <Button key="three" size='small' sx={{ ...buttonClasses.smallOrange }}>
                                <Add fontSize='inherit' />
                            </Button>
                        </ButtonGroup>
                    </Grid>
                    <Grid container item sm={3} md={3} xs={6} justifyContent={"center"} alignItems={"center"}>
                        <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, mr: 2 }}>No Of Items: </Typography>
                        <ButtonGroup size='small' sx={{ minWidth: "24px", ".MuiButtonGroup-grouped": { minWidth: "25px" } }}>
                            <Button key="one" size='small' sx={{ ...buttonClasses.smallOrange }} onClick={() => handleBoxChangeCount(-1)} disabled={!boxCount}>
                                <Remove fontSize='inherit' />
                            </Button>
                            <input
                                disabled
                                type="number"
                                style={{ border: "1px solid #D9D9D9", maxWidth: "60px", textAlign: "center", fontSize: "12px" }}
                                value={!boxCount ? "": boxCount}
                                onChange={(e) => handleBoxChangeCount(e)}
                                onWheel={(event) => event.target.blur()}
                                onKeyDown={(e) => {
                                    if (['e', 'E', '+', '-'].includes(e.key)) {
                                        e.preventDefault();
                                    }
                                    else if (e.key == '0' && (e.target.value == "" || Number(e.target.value) == 0)) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                            <Button key="three" size='small' sx={{ ...buttonClasses.smallOrange }} 
                            onClick={() => handleBoxChangeCount(1)}
                            disabled={boxCount==totalItemCount}
                            >
                                <Add fontSize='inherit' />
                            </Button>
                        </ButtonGroup>
                    </Grid>
                    <Grid container item sm={3} md={3} xs={6} justifyContent={"center"} alignItems={"center"}>
                        <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, mr: 2 }}>Per Item QTY: </Typography>
                        <ButtonGroup size='small' sx={{ minWidth: "24px", ".MuiButtonGroup-grouped": { minWidth: "25px" } }}>
                            <Button key="one" size='small' sx={{ ...buttonClasses.smallOrange }} onClick={() => countPerBoxHandler(-1)}>
                                <Remove fontSize='inherit' />
                            </Button>
                            <input
                                type="number"
                                style={{ border: "1px solid #D9D9D9", maxWidth: "60px", textAlign: "center", fontSize: "12px" }}
                                value={countPerBox}
                                onChange={(e) => countPerBoxHandler(e)}
                                onWheel={(event) => event.target.blur()}
                                onKeyDown={(e) => {
                                    if (['e', 'E', '+', '-'].includes(e.key)) {
                                        e.preventDefault();
                                    }
                                    else if (e.key == '0' && (e.target.value == "" || Number(e.target.value) == 0)) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                            <Button key="three" size='small' sx={{ ...buttonClasses.smallOrange }} onClick={() => countPerBoxHandler(1)}>
                                <Add fontSize='inherit' />
                            </Button>
                        </ButtonGroup>
                    </Grid>

                </Grid>
            </Grid>}
            {<Grid container sx={{width:"fit-to-content",margin: "0 auto",overflow: "auto",mt: 2,maxHeight:"50vh"}}>
                <Table header={header} rows={rows}  countTillNow={countTillNow} totalItemCount={totalItemCount} remainingItems={remainingItems}/>
            </Grid>}
            <Grid container item direction={"row"} sm={12} xs={12} sx={{mt: 2}}>
                <Button variant='contained' size='small' sx={{...buttonClasses.lynkitBlackEmpty, minHeight: "40px",  minWidth: "150px", mr: 2}} onClick={onClose}> 
                    Cancel
                </Button>
                {openFrom=="pkgTable" &&
                    ["package", "pallet", "bothPackageItem","bothPalletItem"].includes(open) && <Button variant='contained' size='small' sx={{ ...buttonClasses.lynkitBlackFill, minHeight: "40px", minWidth: "150px", mr: 2 }} onClick={onBack}>
                        Go Back
                    </Button>
                }
                <Button disabled={!items || items.length==0} variant='contained' size='small' sx={{...buttonClasses.lynkitOrangeFill, minHeight: "40px",  minWidth: "150px"}} onClick={handleSubmitItems}> 
                    {openType=="edit"? "Update":["package", "pallet", "bothPackageItem","bothPalletItem"].includes(open)?"Save" :"Submit"} Item(s)
                </Button>
                
            </Grid>
        </Grid>
        
    )
}


const Table = ({header, rows}) => {

    return (
        <table
            style={{
                width:"inherit",
                // display: "block",
                // maxWidth: "-moz-fit-content",
                // maxWidth: "fit-content",
                // width:"90vw",
                // margin: "0 auto",
                // overflow: "auto",
                whiteSpace: "nowrap",
                borderRadius: "8px",
                border: `1px solid ${theme.themeGray}`
            }}
        >
            <thead style={{backgroundColor: theme.themeGray}}>
                <tr className='roundedRow'>

                    {
                        header.slice(0, 1).map((head, i) => {
                            return (
                                <td style={{padding: "13px 10px 13px 10px", minWidth: "100px", ...textClasses.boldText, textAlign: "center"}} key={i}>{head}</td>
                            )
                        })
                    }
                    {
                        header.slice(1, header.length).map((head, i) => {
                            return (
                                <td style={{padding: "13px 10px 13px 10px", minWidth: "200px", ...textClasses.boldText, textAlign: "center"}} key={i}>{head}</td>
                            )
                        })
                    }
                    {/* <td style={{padding: "13px 10px 13px 10px", minWidth: "100px", ...textClasses.boldText, textAlign: "center"}}>Action</td> */}
                </tr>
            </thead>
            <tbody>
                {
                    rows.length > 0
                    &&
                    <>
                        {
                            rows.map((row, i) => (
                                <tr
                                    key={i}
                                    style={{borderBottom: "1px solid #0000002B"}}   
                                >
                                    {/*
                                        {
                                            <td style={{padding: "10px 5px 10px 10px", ...textClasses.normalText, "textAlign": "center"}}>
                                                <IconButton sx={{backgroundColor: theme.themeOrange, color: "white"}} size="small" onClick={()=> handleAddItem(i)} disabled={countTillNow >= totalItemCount}>
                                                    <AddOutlined/>
                                                </IconButton>
                                            </td>
                                        }
                                        */}
                                    {
                                        row.map((col, j) => {
                                            return (
                                                // <td component="th" scope="row" style={{...textClasses.normalText, textAlign: "left", overflowWrap: "break-word", padding: "5px"}}>
                                                <td style={{padding: "10px 5px 10px 10px", ...textClasses.normalText}}key={i+"/"+j}>
                                                    {col}
                                                </td>    
                                            )
                                        })
                                    }
                                    {/* <td style={{padding: "10px 5px 10px 10px", ...textClasses.normalText, textAlign: "center"}}>
                                        <IconButton size='small' sx={{backgroundColor: "red", color: "white"}} onClick={() => handleDeleteItem(i)}>
                                            <Remove fontSize='inherit'/>
                                        </IconButton>
                                    </td> */}
                                
                                </tr>
                            ))
                        }  
                         
                    </>    
                }
            </tbody>
        </table>
    )
}

export default AsnPackageItemTableSection;