import React from 'react';
import {lynkitIOURL} from '../../../../services/config';
import Integration from '../../../Components/Integration';


const Addressbook = () => {
    const todayDate = new Date().getTime();
    // const myVariable = `http://192.168.1.86:4406/login?action=/dashboard/inventory/all&auth=WMS&timestamp=${todayDate}`;
    // const myVariable = `http://localhost:4406/login?action=/dashboard/entity-master/item-master&auth=WMS&timestamp=${todayDate}`;
    const myVariable = `${lynkitIOURL}/login?action=/address-book&auth=WMS&timestamp=${todayDate}`;
    return (
       <>
        <Integration
            myVariable={myVariable}
            // baseURL={"http://192.168.1.86:4406"}
            // baseURL={"http://localhost:4406"}
            baseURL={lynkitIOURL}
        />
       </>
    );
};

export default Addressbook;
