import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { FormControl, Grid, InputAdornment, OutlinedInput, Button, TextField, Select, MenuItem, FormLabel, InputLabel, Typography, Tab, Tabs, Box, Checkbox, Dialog, DialogContent, Chip, Paper, IconButton, Menu, Tooltip, setRef , Autocomplete, FormControlLabel, Radio} from '@mui/material'
// import { makeStyles } from '@mui/system'
import { DataGrid } from '@mui/x-data-grid'
// import EmptyPage from './EmptyPage';
import { Add, Clear, KeyboardArrowDown, Search} from '@mui/icons-material';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getASNStatusCount, getStockReturnList } from '../../../redux/actions';
import theme, { buttonClasses, inputClasses, textClasses } from '../../../static/styles/theme';
import { Link } from 'react-router-dom';
import { getAllWarehouses, getWarehouseFloors } from '../../../redux/actions';
import { useForm } from 'react-hook-form';
import history from '../../../services/history';
import { DatePicker } from '@mui/x-date-pickers';
import GenericCustomiseHeader from '../../Components/Generic/GenericCustomiseHeader';
import GenericTable from '../../Components/CustomTable/GenericTable';
import { useDebounce } from '../../../utils/hooks';
import GenericLoader from '../../Components/Generic/GenericLoader';




function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const getSpentTime = (then, now) => {
    if (then != null) {
        then = moment(then);
        now = now ? moment(now) : moment();

        let dayDiff = now.diff(then, "days");
        let hourDiff = now.diff(then, "hours");
        let minDiff = now.diff(then, "minutes");
        let timeSpent;

        if (dayDiff < 1) {
            if (hourDiff < 1) {
                timeSpent = `${minDiff} min ago`
            }
            else {
                timeSpent = `${hourDiff} hrs ago`
            }
        }
        else {
            timeSpent = `${dayDiff} d ago`
        }
        return timeSpent
    }
    else {
        return "-"
    }
}   

export default function AdvanceShipment() {
    const dispatch = useDispatch();
    const allWarehouses = useSelector((state) => state.inward.allWarehouses);
    const allFloors = useSelector((state) => state.inward.allFloors);
    const allReturns = useSelector(state=>state.returnProcess.returnList)
    const totalReturns = useSelector(state=> state.returnProcess.totalReturns)
    const [tabIndex, setTabIndex] = useState(0);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [loader, setLoader] = useState(false);
    const [refFilter, setRefFilter] = useState("")
    const [entityFilter, setEntityFilter] = useState("")
    const [statusFilter, setStatusFilter] = useState(null)
    const [createdAt, setCreatedAt] = useState(null);
    const [receivedAt, setReceivedAt] = useState(null);
    const [warehouseFilter, setWarehouseFilter] = useState(null);
    const [floorFilter, setFloorFilter] = useState(null);
    const statusList = [
        { label: "All Returns", value: "all" },
        { label: "Incoming Returns", value: "incoming" },
        // { label: "Pending Returns", value: "pending" },
        { label: "Received Returns", value: "received" },
        { label: "Rejected Returns", value: "rejected" }
    ]
    // //(statusList,"stttats")
    const debouncedRefFilter = useDebounce(refFilter, 500);
    const debouncedEntityFilter = useDebounce(entityFilter, 500);

    const isFilterApplied = (()=>{
        if(tabIndex == 0 && statusFilter){
            return true;
        }
        if(refFilter || entityFilter || createdAt || receivedAt){
            return true;
        }
        return false;
    })();

    const [anchorEl, setAnchorEl] = useState(null);
    const [actionIndex, setActionIndex] = useState(null);

    const { userPermissions } = useSelector((state) => state.userPermission);
    let permissions = userPermissions || {};

    const handleOpenAction = (event, i) => {
        setAnchorEl(event.currentTarget);
        setActionIndex(i);

    }
    const handleCloseAction = () => {
        setActionIndex(null);
        setTimeout(() => {
            setAnchorEl(null);
        }, 1);

    }
    const [selectedEntries, setSelectedEntries] = useState([]);


    const handleTabChange = (event, value) => {
        
        switch (value) {
            case 0: setStatusFilter(null); break;
            case 1: setStatusFilter(statusList[value]); break;
            case 2: setStatusFilter(statusList[value]); break;
            case 3: setStatusFilter(statusList[value]); break;
            case 4: setStatusFilter(statusList[value]); break;
            case 5: setStatusFilter(statusList[value]); break;
        }
        setPage(0);
        setTabIndex(value)
    }

    // TODO
    const handleCheckAll = () => {
        if (selectedEntries.length < allReturns.length) {
            let sT = allReturns.map(t => ({ _id: t._id, status: t.status, name: t.referenceNumber }))
            setSelectedEntries(sT);
        }
        else {
            setSelectedEntries([]);
        }
    }
    
    
    const tableHeader = useSelector(state => state.customHeader["returnHeader"]);
    
    let validHeaderIds = tableHeader && tableHeader.validHeader? tableHeader.validHeader.map(h => h.fieldKey) : [];
    let header = tableHeader && tableHeader.validHeader? tableHeader.validHeader.map(h => h.fieldName) : [];
    if(validHeaderIds.indexOf("checkbox") >= 0){
        header[validHeaderIds.indexOf("checkbox")] = (
            <Checkbox data-testid="checkbox" checked={allReturns.length != 0 && selectedEntries.length == allReturns.length} onChange={handleCheckAll} size='small' />
        )
    }

    const filterIndex = {
        referenceNo: tableHeader && tableHeader.validHeader ? tableHeader.validHeader.map(h => h.fieldKey).indexOf(`referenceNo`) : -1,
        entityId: tableHeader && tableHeader.validHeader ? tableHeader.validHeader.map(h => h.fieldKey).indexOf(`entityId`) : -1,
        status: tableHeader && tableHeader.validHeader ? tableHeader.validHeader.map(h => h.fieldKey).indexOf(`status`) : -1,
        receivedDate: tableHeader && tableHeader.validHeader ? tableHeader.validHeader.map(h => h.fieldKey).indexOf(`receivedDate`) : -1,
        createdDate: tableHeader && tableHeader.validHeader ? tableHeader.validHeader.map(h => h.fieldKey).indexOf(`createdDate`) : -1
    }
   
   

    const getTableCell = (id, row) => {
        if (id == "returnId") {
            return (
                <Link to={`#`} style={{ ...textClasses.normalText, textDecoration: "none", color: theme.themeOrange, textAlign: "center" }}>
                    {row.Return_ID ? row.Return_ID: "-"}
                </Link>
            )
        }
        else if (id == "referenceNo") {
            return (
                <Link to={`#`} style={{ ...textClasses.normalText, textDecoration: "none", color: theme.themeOrange, textAlign: "center" }}>
                    {row.referenceNumber ? row.referenceNumber: "-"}
                </Link>
            )
            // return (
            //     <Typography sx={{...textClasses.normalText, textAlign: "center"}}>
            //         {row.purchaseOrderID? row.purchaseOrderID: "-"}
            //     </Typography>
            // )
        }
        else if (id == "entityId") {
            return (
                <Typography sx={{ ...textClasses.normalText, textAlign: "center" }}>
                    {
                        row.entityName
                            ? Array.isArray(row.entityName) ? row.entityName.join(", ") : row.entityName
                            : "-"
                    }
                </Typography>
            )
        }
        else if (id == "entityType") {
            return (
                <Typography sx={{ ...textClasses.normalText, textAlign: "center" }}>
                    {
                        // row.entityType
                        row.entityType
                            ? Array.isArray(row.entityType) ? row.entityType.join(", ") : row.entityType
                            : "-"
                    }
                </Typography>
            )
        }
        else if (id == "returnedQty") {
            return (
                <Typography sx={{...textClasses.normalText, textAlign: "center"}}>
                    {row.totalQuantity? row.totalQuantity: "-"}
                </Typography>
            )
        }
        else if(id == "status"){
            const statusColor = {
                partialreceived: "#3E621E",
                received: "#3E621E",
                rejected: "#E9001A",
                drafted: "#FF0101",
                incoming: "#FF7200",
                default: "#000"
            }
            const statusText = {
                partialreceived: "Partially Received",
                received: "Received",
                rejected: "Rejected",
                drafted: "Drafted",
                incoming: "Incoming"
                
            }
            return (
                <>
                {
                    row.status=="drafted"?
                    <Typography sx={{ ...textClasses.normalText, textAlign: "center", color: statusColor[row.status ? row.status.toLowerCase() : "default"] }}>{row.status ? statusText[row.status.toLowerCase() ] : "-"}</Typography>
                    :
                    <></>
 

                }
                {
                    row.status=="incoming"?
                    <Typography sx={{ ...textClasses.normalText, textAlign: "center", color: statusColor[row.status ? row.status.toLowerCase() : "default"] }}>{row.status ? statusText[row.status.toLowerCase()] : "-"} ({row.totalQuantity})</Typography>
                    :
                    <>
                    </>

                }
                {
                    row.status=="received"|| row.status=="partialReceived"?
                    <Typography sx={{ ...textClasses.normalText, textAlign: "center", color: statusColor[row.status ? row.status.toLowerCase() : "default"] }}>{row.status ? statusText[row.status.toLowerCase()] : "-"} ({row.receivedQuantity})</Typography>
                    :
                    <></>

                }

                </>
            )
        }
        else if(id == "createdDate"){
            return (
                <Typography sx={{ ...textClasses.normalText, textAlign: "center", textTransform:"none" }}>
                    {moment(row.submissionDate).format("DD MMM YYYY hh:mm A")}
                </Typography>
            )
        }
        else if(id == "receivedDate"){
            return (
                <Typography sx={{ ...textClasses.normalText, textAlign: "center", textTransform:"none" }}>
                    {row.receivedDate ? moment(row.receivedDate).format("DD MMM YYYY hh:mm A"): "-"}
                </Typography>
            )
        }
        else{
            return (
                <Typography sx={{ ...textClasses.normalText, textAlign: "center", textTransform:"none" }}>
                    {"-"}
                </Typography>
            )
        }
    }

    const tableRows = allReturns.map((row, i) => {
        let items = [];
        for (let j = 0; j < validHeaderIds.length; j++) {
            if(validHeaderIds[j] == "srNo"){
                items.push(
                    <Typography sx={{...textClasses.normalText, textAlign: "center"}}>{i + 1}</Typography>
                )
            }
            else if(validHeaderIds[j] == "checkbox"){
                items.push(
                    <Checkbox
                        size="small"
                        checked={selectedEntries.map(o => o._id).indexOf(row._id) >= 0}
                        onChange={(e) => handleCheckChange(e, row)}
                    />
                )
            }
            else if(validHeaderIds[j] == "action"){
                items.push(
                    <Typography data-testid="dropdown" sx={{ ...textClasses.normalText, color: theme.themeOrange, cursor: "pointer" }} onClick={(e) => handleOpenAction(e, i)}>Action <KeyboardArrowDown /></Typography>
                )
            }
            else{
                items.push(
                    getTableCell(validHeaderIds[j], row)
                )
            }
        }
        return items;

    })



    const handleCheckChange = (event, row) => {
        
        if (event.target.checked) {
            handleAddSelection(row);
        }
        else {
            handleRemoveSelection(row._id);
        }
    }
 

    const handleAddSelection = (obj) => {
        setSelectedEntries([...selectedEntries, obj]);
    }

    const handleRemoveSelection = (id) => {
        let oldEntries = [...selectedEntries];
        const i = oldEntries.map(o => o._id).indexOf(id);
        if (i >= 0) {
            oldEntries.splice(i, 1);
            setSelectedEntries(oldEntries);
        }

    }


    const handleClearFilter = () => {
        setRefFilter("")
        setEntityFilter("")
        setCreatedAt(null)
        setReceivedAt(null)
        setPage(0);
        setLimit(10);
        if(tabIndex == 0){
            setStatusFilter(null);
        }
    }


    const refreshTableData = (pageNo, limitNo) => {
        setLoader(true);
        const payload = {
            page: pageNo,
            limit: limitNo, 
            warehouseId:warehouseFilter ? warehouseFilter._id : "",
            floorId: floorFilter ? floorFilter._id : "",
        }
        if (refFilter && refFilter != "") {
            payload["referenceNumber"] = refFilter;
        }
        if (entityFilter && entityFilter != "") {
            payload["entityName"] = entityFilter;
        }
        
        if (statusFilter != null) {
            payload["status"] = statusFilter.value
        }
        if (createdAt && createdAt != null) {
            payload["submissionDate"] = createdAt;
        }
        if (receivedAt && receivedAt != null) {
            payload["receivedDate"] = receivedAt;
        }

        dispatch(
            getStockReturnList(payload, () => {
                setLoader(false);
            })
        )
    
    }

    let fetchData=()=>{
        refreshTableData(page + 1, limit);
        setActionIndex(null);
    }
       

    useEffect(() => {
        fetchData()
    }, [statusFilter, createdAt, receivedAt, page, limit, debouncedRefFilter, debouncedEntityFilter,warehouseFilter,floorFilter])

    useEffect(()=>{
        dispatch(getAllWarehouses({ type: "warehouse" }));
      },[])

      useEffect(() => {
        if (warehouseFilter) {
          dispatch(
            getWarehouseFloors({ type: "floor", warehouseId: warehouseFilter?._id })
          );
        }
      }, [warehouseFilter]);

    return (
        <>
            {
                // loader
                // ?
                //     <EmptyPage/>
                // :
                <>
               
                    <Grid container>
                        {/* Title Section */}
                        <Grid item sm={12} xs={12}>
                            <Grid container>
                                <Grid item sm={3} xs={4}>
                                    <Typography sx={{ ...textClasses.cardTitle }}>
                                    Manage Returns
                                    </Typography>
                                </Grid>

                                <Grid container item sm={9} xs={8} justifyContent="end">
                                    <Autocomplete
                                        value={warehouseFilter}
                                        onChange={(e, option) => {
                                            setWarehouseFilter(option);
                                            setFloorFilter(null)
                                            setPage(0);
                                            setLimit(10);
                                        }}
                                        getOptionLabel={(option) => option.name}
                                        size="small"
                                        options={allWarehouses}
                                        sx={{
                                            ...inputClasses.filterField,
                                            minWidth: "250px",
                                            mr:1,
                                            ".MuiInputBase-input": {
                                                fontSize: "15px",
                                                color: "#455A64",
                                            },
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                sx={{ color: "#455A64", ...textClasses.normalText }}
                                                {...params}
                                                label="All Warehouse"
                                            />
                                        )}
                                    />
                                    {warehouseFilter && (
                                        <Autocomplete
                                            value={floorFilter}
                                            onChange={(e, option) => {
                                                setFloorFilter(option);
                                                  setPage(0);
                                                  setLimit(10);
                                            }}
                                            getOptionLabel={(option) => option.name}
                                            size="small"
                                            options={allFloors}
                                            sx={{
                                                ...inputClasses.filterField,
                                                minWidth: "250px",
                                                mr:1,
                                                ".MuiInputBase-input": {
                                                    fontSize: "15px",
                                                    color: "#455A64",
                                                },
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    sx={{ color: "#455A64", ...textClasses.normalText }}
                                                    {...params}
                                                    label="Select Floor"
                                                />
                                            )}
                                        />
                                    )}
                                      {permissions && (permissions.permissions || []).some(item => item.permissionId === "addReturns") ?
                                <Button data-testid="add" variant='outlined' size='small' startIcon={<Add />} sx={{ ...buttonClasses.lynkitOrangeEmpty }} onClick={() => history.push("/dashboard/inventory/add-return")}>
                                    Create New
                                </Button>
                                : ''}
                                  
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 1 }}>
                        <Tabs value={tabIndex} aria-label="disabled tabs example" onChange={handleTabChange} sx={{ '& .MuiTabs-indicator': { backgroundColor: theme.themeOrange }, "& .MuiTab-root.Mui-selected": { color: theme.themeOrange } }}>
                            {
                                statusList.map((s, i) => {
                                    return (
                                        <Tab 
                                            data-testid= {`${s.label}`}
                                            label={`${s.label} `}
                                            {...a11yProps(1)}
                                            sx={{ ...textClasses.normalText, textTransform: "none" }}
                                            key={i}
                                        />
                                    )
                                })
                            }
                            
                        </Tabs>
                    </Box>
                    <Grid container spacing={2}>
                        {
                            filterIndex.referenceNo >= 0 &&
                            <Grid item sx={4} sm={2}>
                                <FormControl variant='outlined' size='small'>
                                    <OutlinedInput
                                        data-testid="search-input"
                                        value={refFilter}
                                        onChange={(e) => { setRefFilter(e.target.value); setPage(0); setLimit(10); }}
                                        placeholder={`Search by ${tableHeader.validHeader[filterIndex.referenceNo].fieldName}`}
                                        sx={{ ...inputClasses.filterField, "& input::placeholder": { fontSize: "14px" } ,"& .MuiSelect-iconOutlined": {display: refFilter != "" ? 'none': ''}}}
                                        endAdornment={
                                            refFilter == ""
                                            ?
                                                <InputAdornment position='end'><Search fontSize='small' /></InputAdornment>
                                            :
                                                <IconButton sx={{visibility: refFilter != "" ? "visible": "hidden", fontSize: "14px"}} onClick={() => {setRefFilter(""); setPage(0); setLimit(10);}}><Clear fontSize='inherit'/></IconButton>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        }
                        {
                            filterIndex.entityId >= 0 &&
                            
                            <Grid item sx={4} sm={2}>
                                <FormControl variant='outlined' size='small'>
                                    <OutlinedInput
                                        data-testid="poNumber"
                                        value={entityFilter}
                                        onChange={(e) => { setEntityFilter(e.target.value); setPage(0); setLimit(10); }}
                                        placeholder={`Search by ${tableHeader.validHeader[filterIndex.entityId].fieldName}`}
                                        sx={{ ...inputClasses.filterField, "& input::placeholder": { fontSize: "14px" } ,"& .MuiSelect-iconOutlined": {display: entityFilter != "" ? 'none': ''}}}
                                        endAdornment={
                                            entityFilter == ""
                                            ?
                                                <InputAdornment position='end'><Search fontSize='small' /></InputAdornment>
                                            :
                                                <IconButton sx={{visibility: entityFilter != "" ? "visible": "hidden", fontSize: "14px"}} onClick={() => {setEntityFilter(""); setPage(0); setLimit(10);}}><Clear fontSize='inherit'/></IconButton>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        }
                        
                        {
                            filterIndex.status >= 0 && tabIndex==0 &&
                            <Grid item sx={4} sm={2}>
                                <Autocomplete
                                    data-testid="status"
                                    value={statusFilter}
                                    onChange={(e, option) => {
                                        setStatusFilter(option);
                                        setPage(0);
                                        setLimit(10);
                                    }}
                                    // getOptionLabel={(option) => option.label}
                                    size='small'
                                    fullWidth
                                    options={statusList}
                                    sx={{...inputClasses.filterField, ".MuiInputBase-input": {
                                        fontSize: "15px",
                                        color: "#455A64"
                                    }}}
                                    renderInput={(params) => <TextField sx={{color: "#455A64", ...textClasses.normalText}} {...params} label={`${tableHeader.validHeader[filterIndex.status].fieldName}`} />}
                                />
                            </Grid>
                        }
                        {
                            filterIndex.receivedDate >= 0 &&
                        
                            <Grid item sx={4} sm={2}>
                                <DatePicker
                                    data-testid="receivedAt"
                                    format="DD/MM/YYYY"
                                    value={receivedAt}
                                    onChange={(d) => { setReceivedAt(d); setPage(0); setLimit(10); }}
                                    label={`${tableHeader.validHeader[filterIndex.receivedDate].fieldName}`}
                                    sx={{...inputClasses.filterField}}
                                    slotProps={{textField: {size: "small"}}}
                                />
                            </Grid>
                        }
                        {
                            filterIndex.createdDate >= 0 &&
                            <Grid item sx={4} sm={2}>
                                <DatePicker
                                    data-testid="createdAt"
                                    format="DD/MM/YYYY"
                                    value={createdAt}
                                    onChange={(d) => { setCreatedAt(d); setPage(0); setLimit(10); }}
                                    label={tableHeader.validHeader[filterIndex.createdDate].fieldName}
                                    sx={{...inputClasses.filterField}}
                                    slotProps={{textField: {size: "small"}}}
                                />
                            </Grid>
                        }
                        
                        {
                            isFilterApplied && 
                            <Grid container item sx={4} sm={2} alignItems={"center"}>
                                <Typography sx={{...textClasses.boldText, color: theme.themeOrange, textDecoration: "underline", cursor: "pointer"}} onClick={handleClearFilter}>
                                    Clear Filter(s)
                                </Typography>
                            </Grid>
                        }
                        <Grid container item sx={4} sm={2} alignItems={"center"}>
                            <GenericCustomiseHeader
                                tableName={"returnHeader"}
                                tableType={"ReturnTable"}
                            />
                        </Grid>
                        
                    </Grid>

                    <Box container sx={{ mt: 2 }}>
                        {
                            loader
                                ?
                                <Grid  data-testid='loader' container direction={"column"} justifyContent={"center"} alignItems="center" sx={{ p: 15 }}>
                                    <Grid item >
                                        <GenericLoader />
                                    </Grid>
                                </Grid>
                                :
                                <GenericTable
                                    data-testid="return-table"
                                    header={header}
                                    rows={tableRows}
                                    pageCount={totalReturns}
                                    pageNumber={page}
                                    handleChangePage={(event, pagevalue) => {
                                        setPage(pagevalue)
                                    }}
                                    handleChangeRowsPerPage={(event) => {
                                        setLimit(+event.target.value)
                                    }}
                                    rowsPerPage={limit}
                                />
                        }

                    </Box>
                    {
                        actionIndex != null &&
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={actionIndex != null}
                            onClose={handleCloseAction}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                            sx={{ display: actionIndex != null ? "block !important" : "none !important" }}
                        >
                            <MenuItem key={1} sx={{...textClasses.normalText}} >View</MenuItem>
                        </Menu>
                    }
                </>
            }
        </>
    )
}

