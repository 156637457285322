import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import theme, { buttonClasses } from "../../../../static/styles/theme";
import {
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  FormControl,
  Autocomplete,
  ListSubheader,
  ListItemText,
  RadioGroup,
  Radio,
  Link,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useForm, Controller } from "react-hook-form";
import MenuItem from "@mui/material/MenuItem";
import {
  getGridDetail,
  addMappedArea,
  checkDistinctMasterExist,
  getAreaCategoryOptions
} from "../../../../redux/actions";
import "../Style/AreaMapping.css";
import { showToast,deepCopy_Object } from "../../../../services/functions";
import { ToastContainer, toast } from "react-toastify";
import { selectStylesOverride } from "../../../../utils/util";
import TypeAndSearch from "../../TypeAndSearch";
import CustomChip from "../../CustomChip";
import GenericLoader from "../../Generic/GenericLoader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  transition: "all 0.3s ease-out",
  minWidth: 600,
  // maxHeight: '80vh',
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 3,
};

const component = {
  _id: "SKU_Number",
  type: "select",
  icon: "simple-icon-note",
  label: "SKU",
  description: "SKU",
  placeholder: "SKU",
  className: "form-control",
  subtype: "text",
  forType: "table",
  subtype: "text",
  required: true,
  dynamic: true,
  auth: true,
  elementType: "Selection Type",
  apiBindingValue: "label",
  apiBindingKey: "value",
  apiDomain: "/v1/",
  apiEndPoint: "inventory/getDistinctMasterList",
  "renderOption" : [
    {
        "label" : "",
        "multiple" : false,
        "prefix" : "",
        "postfix" : "",
        "key" : "label",
        "keys" : [

        ]
    },
    {
        "label" : "",
        "multiple" : false,
        "prefix" : " (",
        "postfix" : ")",
        "key" : "SKU_Detail"
    }
],
  forType: "all",
  apiMethod: "post",
  name: "Item Master List",
  apiDataReturnKey: "data",
  multipleAPICall: true,
  dontFetch:false,
  apiParameters: [
    {
      label: "isSkipTemplate",
      value: true,
    },
    {
      label: "master_Type",
      value: "item-master",
    },
    {
      label: "page",
      value: 1,
    },
    {
      label: "limit",
      value: 25,
    },
    {
      label: "base_ID",
      value: "onSearch",
    },
  ],
  setTargetValue: false,
  values: [],
  fetched: false,
  multiple: false,
  hideLabel: false,
  delete: false,
};


function AllocateModel(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { selectedRackData, isEdit, onSuccess,tableLoader="",setTableLoader } = props;
 
  const [open, setOpen] = useState(false);
  const handleClose = (e,) => {
    setOpen(!open);
    setSelectedRack([]);
    props.onClose(false);
  };
  useEffect(() => {
    setOpen(props.show);
  }, [props.show]);

  const { control,formState: { errors },} = useForm();

  const { gridRackDetails } = useSelector((state) => state.warehouseData);
  const [selectedRack, setSelectedRack] = useState([]);
  const [areaCategories,setAreaCategories] =  useState([])
  const distinctMasters = useSelector(
    (state) => state.areaMappingData.distinctMasters
  );

  useEffect(() => {
    if (selectedRackData?.areaTypeData) {
      let fetchcellID = selectedRackData.areaTypeData.map(
        (item) => item.cellId
      );
      if (setTableLoader) setTableLoader("areaDetail")
      dispatch(getGridDetail({ floorId: selectedRackData.floorId, type: "rack", parentId: fetchcellID, }, () => {
        dispatch(checkDistinctMasterExist({ master_Type: ["item-master"] }, ({ data = {} }) => {
          let tempArea = [...deepCopy_Object(selectedRackData.areaTypeData)]
          if (data["item-master"]) {
            tempArea = tempArea.map(dl => {
              let temObj = { ...dl }
              if (dl?.actionType != "update") {
                if (temObj?.data?.length) {
                  temObj["data"] = dl?.data?.map(innerEl => {
                    return { ...innerEl, category: "itemMaster" }
                  })
                }
              }
              return temObj
            })
          }
          // console.log("tempArea",tempArea);
          setSelectedRack(tempArea);
          if (setTableLoader) setTableLoader("")
        }));
      })
      );
      dispatch(getAreaCategoryOptions({dataType:"areaCategory"},(data)=>{
        if(data?.success){
          setAreaCategories(data?.data)
        }  else {
          setAreaCategories([])
        }
      }))
    }
    else {
      setSelectedRack([]);
    }
  }, [selectedRackData]);

  const handleSelectRackChange = (index, innerIndex, option) => {
    let tempArray = [...selectedRack];
    let obj = tempArray[index] || {};
    let innerobj = obj?.data[innerIndex] || {};
    innerobj.rackId = option.map((item) => item.cellId);
    setSelectedRack(tempArray);
  };
  const handleSelectAreaCategory = (index, innerIndex, option) => {
    let tempArray = [...selectedRack];
    let obj = tempArray[index] || {};
    let innerobj = obj?.data[innerIndex] || {};
    innerobj.areaCategory = option?.value;
    setSelectedRack(tempArray);
  };

  const handleSelectCategory = (index, innerIndex, category) => {
    let tempArray = [...selectedRack];
    let obj = tempArray[index] || {};
    let innerobj = obj?.data[innerIndex] || {};
    innerobj.category = category;
    setSelectedRack(tempArray);
  }
  const handleSelectEntityMasterId = (index, innerIndex, id) => {
    let tempArray = [...selectedRack];

    let obj = tempArray[index] || {};
    let innerobj = obj?.data[innerIndex] || {};
    innerobj.entityMasterId = innerobj.entityMasterId ? [...innerobj.entityMasterId, id] : [id];
    setSelectedRack(tempArray);
  }

  const handleRemoveEntityMasterId = (index, innerIndex, id) => {
    let tempArray = [...selectedRack];
    let obj = tempArray[index] || {};
    let innerobj = obj?.data[innerIndex] || {};

    innerobj.entityMasterId = innerobj.entityMasterId ? innerobj.entityMasterId.filter((option) => option !== id && option !== undefined) : []
    setSelectedRack(tempArray);
  }

  const findSelectedValue = () => {
    return selectedRack.reduce((prev, current) => {
      let temp = [...prev];
      if (current?.isRackExist && current?.data?.length > 0) {
        let all_rack_temp = current?.data?.reduce((next_prev, next_current) => {
          let next_temp = [...next_prev];
          if (next_current?.rackId?.length) {
            next_temp.push(...next_current?.rackId);
          }
          return next_temp;
        }, []);
        temp.push(...all_rack_temp);
      }
      return temp;
    }, []);
  };

  const findRackOption = (cellId = "", rack_Ids = [], actionType = "AddNew", oldMaterialType = "") => {

    return gridRackDetails.filter((data) => {
      let condition = data.parentId == cellId
      if (actionType == "update") {
        if (isEdit) {
          condition = condition && (!data.productType ||( Array.isArray(oldMaterialType)?oldMaterialType.includes(data.productType):data.productType == oldMaterialType))
        }
        else {
          condition = condition
        }
      }
      else {
        condition = condition && !data.productType
      }
      condition = condition && (!findSelectedValue().includes(data.cellId) || (rack_Ids || []).includes(data.cellId))

      return condition
    });
  };

  const selectAllRackHandler = (
    checked = false,
    index,
    innerIndex,
    all_rack = []
  ) => {
    let tempArray = [...selectedRack];
    let obj = tempArray[index] || {};
    let innerobj = obj?.data[innerIndex] || {};
    if (checked) {
      innerobj.rackId = all_rack;
    } else {
      innerobj.rackId = [];
    }
    setSelectedRack(tempArray);
  };

  const selectMutipleNameForBlock = (checked = false, index, item) => {
    let tempArr = [...selectedRack];
    let materialType=""
    if(item.selectType=="block" || item?.data?.length==1){
      // console.log("item?.data[innerIndex]",item?.data[0]);
   
      materialType=item?.data[0]?.materialType || ""
    }
    tempArr.splice(index, 1, {
      ...item,
      multiple: checked,
      data: [{
        entityMasterId: item.data[0].entityMasterId, 
        materialType, rackId: [], category: getCategory(),
        oldMaterialType:item?.actionType=="update" && item?.data?.at(0)?.oldMaterialType || "",
      }],
    });

    setSelectedRack(tempArr);
  };

  const addRack = (index) => {
    let tempArray = [...selectedRack];
    let obj = tempArray[index];
    let innerobj = {
      materialType: "",
      oldMaterialType:obj?.actionType=="update" && obj?.data?.at(0)?.oldMaterialType || "",
      rackId: [],
      category: getCategory(),
    };
    obj.data.push(innerobj);
    setSelectedRack(tempArray);
  };

  const getCategory = () => {
    let allKeyValue = {
      "item-master": "itemMaster"
    }
    for (let key in (distinctMasters || {})) {
      if (distinctMasters[key]) {
        return allKeyValue[key]
      }
    }
    return "other"
  }

  const removeRack = (index, innerIndex) => {
    let tempArray = [...selectedRack];
    let obj = tempArray[index];
    obj.data.splice(innerIndex, 1);
    setSelectedRack(tempArray);
  };

  const onRackHandleChange = (action, index, innerIndex, event) => {

    let tempArray = [...selectedRack];
    let obj = tempArray[index];
    let areaDetail = selectedRackData?.areaTypeData?.at(index) || {}
    if (action === "changemappingtype") {
      let rackId = [], materialType = ""
      if (obj.selectType == "block" || obj?.data?.length == 1) {
        // console.log("innerIndex",obj?.data[0]);
        rackId = obj?.data[0]?.rackId || []
        materialType = obj?.data[0]?.materialType || ""
      }
      let entityMasterId=(areaDetail?.data?.map(dl=>dl.entityMasterId))?.flat() || []

      obj.selectType = event.target.value;
      if (obj.selectType == areaDetail?.selectType) {
        obj = {
          ...areaDetail,
          selectType: obj.selectType
        }
      }
      else {
        obj.data = [{
          materialType: materialType, rackId, category: getCategory(),
          oldMaterialType: obj?.actionType == "update" && obj?.data?.at(0)?.oldMaterialType || "",
          entityMasterId
        }];
      }
      tempArray[index] = { ...obj }
      setSelectedRack(tempArray);
    }
    else if (action === "changeAreaNamewithIsRackTrue") {
      let innerobj = { ...obj.data[innerIndex] };
      innerobj.materialType = event.target.value;
      obj.data = [innerobj];
      setSelectedRack(tempArray);
    }
    else if (action === "changeAreaName") {
      let innerobj = { ...obj.data[innerIndex] };
      innerobj.materialType = event.target.value;
      obj.data.splice(innerIndex, 1, innerobj);
      setSelectedRack(tempArray);
    }
  };


  const checkdisableValue = (cellId) => {
    if (findSelectedValue().includes(cellId)) {
      return true;
    } else {
      return false;
    }
  };

  const collectData = () => {
    let submitArray = [];
    // console.log("selectedRack",selectedRack)
    for (let i = 0; i < selectedRack.length; i++) {
      for (let j = 0; j < selectedRack[i].data.length; j++) {
        let obj = {
          actionType: selectedRack[i].actionType,
          cellId: selectedRack[i].cellId,
          multiple: selectedRack[i].multiple || false,
          isRackExist: selectedRack[i].isRackExist,
          renderId: selectedRack[i].renderId,
          selectType: selectedRack[i].selectType,
          materialType: selectedRack[i].data[j].materialType,
          oldMaterialType: selectedRack[i]?.actionType=="update" && selectedRack[i]?.data[j]?.oldMaterialType || "",
          category: selectedRack[i].data[j].category || "",//?? "item-master"
          entityMasterId: selectedRack[i]?.data[j]?.entityMasterId || [],
          areaCategory: selectedRack[i]?.data[j]?.areaCategory || ""
        };

        if (selectedRack[i]?.data[j]?.category && ["itemMaster"].includes(selectedRack[i]?.data[j]?.category)
          && (!selectedRack[i]?.data[j]?.entityMasterId || selectedRack[i]?.data[j]?.entityMasterId?.length == 0)) {
          let tempMess = "SKU"
          if (selectedRack[i]?.data[j]?.category == "itemMaster") {
            tempMess = "SKU"
          }
          let message_d = `${tempMess} is empty for block ${selectedRack[i]?.name || ""
            }!`;
          if (obj.selectType === "rack" || obj.multiple) {
            message_d = `${tempMess} is empty for block ${selectedRack[i]?.name || ""
              } in ${j + 1} row!`;
          }
          return { success: false, message: message_d };
        }

        if (!obj.materialType || obj.materialType.length === 0) {
          let message_d = `Area name is empty for block ${selectedRack[i]?.name || ""
            }!`;
          if (obj.selectType === "rack" || obj.multiple) {
            message_d = `Area name is empty for block ${selectedRack[i]?.name || ""
              } in ${j + 1} row!`;
          }
          return { success: false, message: message_d };
        }

        if (selectedRack[i].isRackExist && obj.selectType === "rack") {
          let allRack = selectedRack[i]?.data[j]?.rackId || [];

          if (allRack.length === 0) {
            let message_d = `Select at least one rack for block ${selectedRack[i]?.name || ""
              } in ${j + 1} row!`;
            return { success: false, message: message_d };
          }
          obj.rackId = allRack;
        }

        submitArray.push(obj);
      }
    }

    return { success: true, data: submitArray };
  };

  const onhandleAddAllocation = () => {
    let finalSumbitObj = { ...selectedRackData };
    const { success, message, data } = collectData();
    if (!success) {
      showToast(message, true);
      return;
    }

    let payload = {
      type: "wms",
      floorId: finalSumbitObj.floorId,
      warehouseId: finalSumbitObj.warehouseId,
      areaTypeData: data,
      isAddEdit:!isEdit
    };
    if(setTableLoader)setTableLoader("areaDetail")
      
      dispatch(
        addMappedArea(payload, ({ success = false, message }) => {
        if(setTableLoader)setTableLoader("")
        showToast(message, !success);
        if (success) {
          if(onSuccess){
            onSuccess()
          }
          handleClose();
        }
      })
    );
  };

  return (
    <>
      <Modal
        open={open}
        data-testid = "allocateModel"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="overFlowAuto">
          <>
            <Box>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <span className="AM-header">Define Area Mapping</span>
                <span className="curser" onClick={() => handleClose()}>
                  <CloseIcon />
                </span>
              </Typography>
            </Box>

            {!!tableLoader ? <Grid item={true} data-testid='loader' container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 1 }}>
              <Grid item><GenericLoader /></Grid>
            </Grid>
              : <Box sx={{ overflow: "auto", maxHeight: "70vh" }}>
                {selectedRack.map((item, index) => {
                  return (
                    <div key={index}>
                      {item.isRackExist === true ? (
                        <Box
                          border={1}
                          sx={{
                            marginTop: "10px",
                            borderRadius: "10px",
                            padding: "1rem",
                            minWidth: "500px",
                            borderColor: "grey.500",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="body2" sx={{ alignSelf: "center", fontSize: "14px", fontWeight: "800" }}>
                              Block: {item.name}
                            </Typography>

                            <Box sx={{ display: "flex", gap: "1rem" }}>
                              <Typography
                                data-testid="mapping-options"
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignSelf: "center",
                                  fontSize: "14px",
                                  fontWeight: 800,
                                }}
                              >
                                <span>Select Mapping Type : </span>
                              </Typography>
                              <Controller
                                name="radio-buttons-group"
                                control={control}
                                render={({ field }) => (
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    value={item.selectType}
                                    onChange={(e) => {
                                      onRackHandleChange(
                                        "changemappingtype",
                                        index,
                                        "",
                                        { target: { value: e.target.value || "" } }
                                      );
                                    }}
                                  >
                                    <FormControlLabel
                                      sx={{
                                        color: "rgb(128, 128, 128)",
                                        "& .MuiFormControlLabel-label": {
                                          fontSize: "13px",
                                        },
                                      }}
                                      value="block"
                                      label="Block"
                                      control={
                                        <Radio
                                          size="small"
                                          sx={{
                                            "&, &.Mui-checked": {
                                              color: "#FF7200",
                                            },
                                          }}
                                        />
                                      }
                                    />
                                    <FormControlLabel
                                      sx={{
                                        color: "rgb(128, 128, 128)",
                                        "& .MuiFormControlLabel-label": {
                                          fontSize: "13px",
                                        },
                                      }}
                                      value="rack"
                                      label="Rack"
                                      control={
                                        <Radio
                                          size="small"
                                          sx={{
                                            "&, &.Mui-checked": {
                                              color: "#FF7200",
                                            },
                                          }}
                                        />
                                      }
                                    />
                                  </RadioGroup>
                                )}
                              />
                              {errors["radio-buttons-group"] && (
                                <p style={{ color: "red", fontSize: "11px" }}>
                                  {errors["radio-buttons-group"].message}
                                </p>
                              )}
                            </Box>
                          </Box>
                          <Box
                          >
                            {item.selectType == "rack"
                              ?
                              <>
                                {item.data.map((value, innerIndex) => {
                                  return (
                                    <>
                                      <Box
                                        border={1}
                                        data-testid="rackArea"
                                        sx={{
                                          marginTop: "10px",
                                          borderRadius: "10px",
                                          padding: "1rem",
                                          minWidth: "500px",
                                          borderColor: "grey.500",
                                        }}
                                        key={innerIndex + "inner"}
                                      >
                                        {item.data.length > 1 && <Box sx={{ float: "right" }}>
                                          <span className="curser" onClick={() => removeRack(index, innerIndex)}>
                                            <CloseIcon />
                                          </span>
                                        </Box>}

                                        <Grid container xs={12} sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          gap: "0.5rem",
                                        }}>
                                          <Grid item xs={3}>
                                            <Typography
                                              sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                fontSize: "14px",
                                                fontWeight: 800,
                                                alignSelf: "center",
                                              }}
                                            >
                                              <span>Selected Area :</span>
                                            </Typography>

                                          </Grid>
                                          <Grid item xs>
                                            <Autocomplete
                                              sx={{
                                                height: "auto",
                                                padding: "0px",
                                                marginTop: "8px",
                                              }}
                                              fullWidth
                                              id="select-Rack-label"
                                              multiple
                                              disableCloseOnSelect
                                              limitTags={4}
                                              data-testid="select-rack"
                                              options={findRackOption(
                                                item?.cellId || "",
                                                value?.rackId || [],
                                                item?.actionType || "AddNew",
                                                value?.oldMaterialType || ""
                                              )}
                                              getOptionLabel={(option) => option.name}
                                              getOptionDisabled={(option) =>
                                                checkdisableValue(option?.cellId)
                                              }
                                              onChange={(event, option = [], reason, detail) => {
                                                // console.log("gfff",event, option, reason, detail);
                                                handleSelectRackChange(
                                                  index,
                                                  innerIndex,
                                                  option
                                                );
                                              }}
                                              renderOption={(props, option, state) => {
                                                let selected = state.selected || false;
                                                let current_index = state.index;
                                                return (
                                                  <>
                                                    {current_index == 0 ? (
                                                      <ListSubheader
                                                        key={"select_all"}
                                                        sx={{ mt: 0, pt: 0 }}
                                                      >
                                                        Select ALL
                                                        <Checkbox
                                                          onChange={(e) => {
                                                            selectAllRackHandler(
                                                              e.target.checked,
                                                              index,
                                                              innerIndex,
                                                              findRackOption(
                                                                item?.cellId || "",
                                                                value?.rackId || [],
                                                                item?.actionType || "AddNew",
                                                                value?.oldMaterialType || ""
                                                              ).map((dll) => dll.cellId)
                                                            );
                                                          }}
                                                          checked={
                                                            findRackOption(
                                                              item?.cellId || "",
                                                              value?.rackId || [],
                                                              item?.actionType || "AddNew",
                                                              value?.oldMaterialType || ""
                                                            )?.length ==
                                                            value?.rackId?.length
                                                          }
                                                        />
                                                      </ListSubheader>
                                                    ) : (
                                                      ""
                                                    )}
                                                    <MenuItem
                                                      key={option.cellId}
                                                      value={option.cellId}
                                                      sx={{
                                                        justifyContent: "space-between",
                                                      }}
                                                      {...props}
                                                    >
                                                      <Checkbox
                                                        checked={
                                                          (option?.cellId &&
                                                            findSelectedValue().includes(
                                                              option?.cellId
                                                            )) ||
                                                          false
                                                        }
                                                      />
                                                      <ListItemText
                                                        primary={option?.name || 0}
                                                      />
                                                      {selected ? (
                                                        <CheckIcon color="info" />
                                                      ) : null}
                                                    </MenuItem>
                                                  </>
                                                );
                                              }}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  variant="outlined"
                                                  label="Select Rack"
                                                  placeholder="Select Rack"
                                                  sx={{
                                                    ...selectStylesOverride,
                                                    color: theme.themeOrange,
                                                  }}
                                                />
                                              )}
                                              value={findRackOption(
                                                item?.cellId || "",
                                                value?.rackId || [],
                                                item?.actionType || "AddNew",
                                                value?.oldMaterialType || ""
                                              ).filter((dll) => {
                                                const check = (value?.rackId || []).includes(dll.cellId)
                                                return (value?.rackId || []).includes(dll.cellId)
                                              }
                                              )}
                                            />
                                          </Grid>


                                        </Grid>
                                        {distinctMasters["item-master"] &&
                                          <CategoryComponent
                                            data-testid="Category-component"
                                            value={value}
                                            index={index}
                                            innerIndex={innerIndex}
                                            handleSelectCategory={handleSelectCategory}
                                            handleRemoveEntityMasterId={handleRemoveEntityMasterId}
                                            handleSelectEntityMasterId={handleSelectEntityMasterId}
                                          />
                                        }
                                        {["other"].includes(value?.category) && 
                                        <Grid container sx={{ display: "flex", gap: "1rem" }}>
                                        <Grid item xs={3}>
                                            <Typography
                                              sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignSelf: "center",
                                                fontSize: "14px",
                                                fontWeight: 800,
                                              }}
                                            >
                                              Area Category :
                                            </Typography>
                                          </Grid>
                                          <Grid item xs>
                                            <Autocomplete
                                              data-testid="areaChangeFilter"
                                              value={value?.areaCategory || ""}
                                              onChange={(event,option) =>{
                                                handleSelectAreaCategory(
                                                  index,
                                                  innerIndex,
                                                  option
                                                );
                                              }
                                              }
                                              size="small"
                                              fullWidth
                                              options={areaCategories}
                                              renderInput={(params) => (
                                                <TextField
                                                  sx={{
                                                    ...selectStylesOverride,
                                                    fontSize: "12px",
                                                    "& input::placeholder": {
                                                      fontSize: "9px",
                                                    },
                                                  }}
                                                  {...params}
                                                  label="Select Area Category"
                                                />
                                              )}
                                            />
                                            </Grid>
                                        </Grid>
                                        }
                                        <Grid
                                          container
                                          sx={{ display: "flex", gap: "1rem" }}
                                        >
                                          <Grid item xs={3}>
                                            <Typography
                                              sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignSelf: "center",
                                                fontSize: "14px",
                                                fontWeight: 800,
                                              }}
                                            >
                                              Area Name :
                                            </Typography>
                                          </Grid>
                                          <Grid item xs>
                                            <FormControl
                                              fullWidth
                                              sx={{
                                                mt: 1,
                                                display: "flex",
                                                flexDirection: "row",
                                              }}
                                            >
                                              <Box sx={{ width: "100%" }}>
                                                <TextField
                                                  fullWidth
                                                  data-testid="areaNameWithSelectingRack"
                                                  id="outlined-adornment-Rows"
                                                  label="Area Name"
                                                  type="text"
                                                  size="small"
                                                  value={value.materialType}
                                                  onChange={(event) =>
                                                    onRackHandleChange(
                                                      "changeAreaName",
                                                      index,
                                                      innerIndex,
                                                      event
                                                    )
                                                  }
                                                />
                                              </Box>
                                            </FormControl>
                                          </Grid>
                                        </Grid>
                                        <Grid container spacing={2} margin="normal">
                                          <Grid item xs={6}></Grid>
                                        </Grid>
                                      </Box>
                                    </>
                                  )
                                }
                                )}
                                <Link
                                  data-testid="addNewArea"
                                  style={{
                                    color: "#FF7200",
                                    textDecoration: "underline",
                                  }}
                                  component="button"
                                  variant="body2"
                                  // to="/dashboard"
                                  onClick={() =>
                                    addRack(index)
                                  }
                                >
                                  + Add New Area
                                </Link>

                              </>
                              :

                              <Box border={1}
                                sx={{
                                  marginTop: "10px",
                                  borderRadius: "10px",
                                  padding: "1rem",
                                  paddingTop: "8px",
                                  minWidth: "500px",
                                  borderColor: "grey.500",
                                }}>

                                {distinctMasters["item-master"] &&
                                  <CategoryComponent
                                    value={item?.data?.at(0) || {}}
                                    index={index}
                                    innerIndex={0}
                                    handleSelectCategory={handleSelectCategory}
                                    handleRemoveEntityMasterId={handleRemoveEntityMasterId}
                                    handleSelectEntityMasterId={handleSelectEntityMasterId}
                                  />
                                }
                                {["other"].includes(item?.data?.at(0)?.category) && 
                                        <Grid container sx={{ display: "flex", gap: "1rem" }}>
                                        <Grid item xs={3}>
                                            <Typography
                                              sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignSelf: "center",
                                                fontSize: "14px",
                                                fontWeight: 800,
                                              }}
                                            >
                                              Area Category:
                                            </Typography>
                                          </Grid>
                                          <Grid item xs>
                                            <Autocomplete
                                              data-testid="areaChangeFilter"
                                              value={item?.data?.at(0)?.areaCategory || ""}
                                              onChange={(event,option) =>{
                                                handleSelectAreaCategory(
                                                  index,
                                                  0,
                                                  option
                                                );
                                              }
                                              }
                                              size="small"
                                              fullWidth
                                              options={areaCategories}
                                              renderInput={(params) => (
                                                <TextField
                                                  sx={{
                                                    ...selectStylesOverride,
                                                    fontSize: "12px",
                                                    "& input::placeholder": {
                                                      fontSize: "9px",
                                                    },
                                                  }}
                                                  {...params}
                                                  label="Select Area Category"
                                                />
                                              )}
                                            />
                                            </Grid>
                                        </Grid>
                                        }
                                <Grid
                                  container
                                  sx={{ display: "flex", gap: "1rem" }}
                                >
                                  <Grid item xs={3}>
                                    <Typography
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        fontSize: "14px",
                                        fontWeight: 800,
                                        alignSelf: "center",
                                      }}
                                    >
                                      Area Name :
                                    </Typography>
                                  </Grid>
                                  <Grid item xs>
                                    <FormControl fullWidth sx={{ mt: 1 }}>
                                      <TextField
                                        id="outlined-adornment-Rows"
                                        label="Area Name"
                                        type='text'
                                        value={item?.data?.at(0)?.materialType || ''}
                                        size="small"
                                        sx={{
                                          ...selectStylesOverride,
                                          color: theme.themeOrange
                                        }}
                                        onChange={(event) => onRackHandleChange("changeAreaNamewithIsRackTrue", index, 0, event)}
                                      />
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              </Box>
                            }
                          </Box>
                        </Box>
                      )
                        :
                        (
                          <>
                            <Box
                              border={1}
                              sx={{
                                marginTop: "10px",
                                borderRadius: "10px",
                                padding: "1rem",
                                minWidth: "500px",
                                borderColor: "grey.500",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography variant="body2" sx={{ fontSize: "14px", fontWeight: "800" }}>
                                  Block : {item.name}
                                </Typography>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      onChange={(e) =>
                                        selectMutipleNameForBlock(
                                          e.target.checked,
                                          index,
                                          item
                                        )
                                      }
                                      checked={item.multiple || false}
                                    />
                                  }
                                  sx={{
                                    margin: "-10px 2px",
                                  }}
                                  variant="body2"
                                  label="Multiple"
                                />
                              </Box>
                              <Box key={index + "blk"}
                              >
                                <Grid container spacing={2} margin="normal">
                                  <Grid item xs={12}>
                                    {item.selectType === "block"
                                      ? item.data.map((value, innerIndex) => (
                                        <Box border={1}
                                          sx={{
                                            marginTop: "10px",
                                            borderRadius: "10px",
                                            padding: "1rem",
                                            paddingTop: "8px",
                                            minWidth: "500px",
                                            borderColor: "grey.500",
                                          }}>
                                          {item.data.length > 1 && <Box sx={{ float: "right" }}>
                                            <span className="curser" onClick={() => removeRack(index, innerIndex)}>
                                              <CloseIcon />
                                            </span>
                                          </Box>}

                                          {distinctMasters["item-master"] &&
                                            <CategoryComponent
                                              value={value}
                                              index={index}
                                              innerIndex={innerIndex}
                                              handleSelectCategory={handleSelectCategory}
                                              handleRemoveEntityMasterId={handleRemoveEntityMasterId}
                                              handleSelectEntityMasterId={handleSelectEntityMasterId}
                                            />
                                          }
                                        {["other"].includes(value?.category) && 
                                        <Grid container sx={{ display: "flex", gap: "1rem" }}>
                                        <Grid item xs={3}>
                                            <Typography
                                              sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignSelf: "center",
                                                fontSize: "14px",
                                                fontWeight: 800,
                                              }}
                                            >
                                              Area Category :
                                            </Typography>
                                          </Grid>
                                          <Grid item xs>
                                            <Autocomplete
                                              data-testid="areaChangeFilter"
                                              value={value?.areaCategory || ""}
                                              onChange={(event,option) =>{
                                                handleSelectAreaCategory(
                                                  index,
                                                  innerIndex,
                                                  option
                                                );
                                              }
                                              }
                                              size="small"
                                              fullWidth
                                              options={areaCategories}
                                              renderInput={(params) => (
                                                <TextField
                                                  sx={{
                                                    ...selectStylesOverride,
                                                    fontSize: "12px",
                                                    "& input::placeholder": {
                                                      fontSize: "9px",
                                                    },
                                                  }}
                                                  {...params}
                                                  label="Select Area Category"
                                                />
                                              )}
                                            />
                                            </Grid>
                                        </Grid>
                                        }
                                          <Grid
                                            container
                                            sx={{ display: "flex", gap: "1rem" }}
                                          >
                                            <Grid item xs={3}>
                                              <Typography
                                                sx={{
                                                  display: "flex",
                                                  justifyContent: "space-between",
                                                  fontSize: "14px",
                                                  fontWeight: 800,
                                                  alignSelf: "center",
                                                }}
                                              >
                                                Area Name :
                                              </Typography>
                                            </Grid>
                                            <Grid item xs>
                                              <FormControl
                                                fullWidth
                                                sx={{
                                                  mt: 1,
                                                  display: "flex",
                                                  flexDirection: "row",
                                                }}
                                              >
                                                <Box>
                                                  <TextField
                                                    id="outlined-adornment-Rows"
                                                    label="Area Name"
                                                    type="text"
                                                    size="small"
                                                    sx={{
                                                      ...selectStylesOverride,
                                                      width: "100%",
                                                      color: theme.themeOrange,
                                                    }}
                                                    value={value.materialType}
                                                    onChange={(event) =>
                                                      onRackHandleChange(
                                                        "changeAreaName",
                                                        index,
                                                        innerIndex,
                                                        event
                                                      )
                                                    }
                                                  />
                                                </Box>
                                              </FormControl>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      ))
                                      : ""}
                                  </Grid>
                                </Grid>
                              </Box>
                              {item.multiple === true && <Link
                                style={{
                                  color: "#FF7200",
                                  textDecoration: "underline",
                                }}
                                component="button"
                                variant="body2"
                                // to="/dashboard"
                                onClick={() =>
                                  addRack(index)
                                }
                              >
                                + Add New Area
                              </Link>}
                            </Box>
                          </>
                        )}
                    </div>
                  )
                })}
              </Box>
              }
          </>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Button
                className="AllocateCancel-btn"
                sx={{
                  ...buttonClasses.lynkitOrangeEmpty,
                  textTransform: "none",
                  width: "50%",
                  marginLeft: "5px",
                }}
                onClick={() => handleClose()}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                className="addAllocate-btn"
                sx={{
                  ...buttonClasses.lynkitOrangeEmpty,
                  textTransform: "none",
                  width: "50%",
                  marginLeft: "5px",
                }}
                variant="contained"
                disabled={!collectData()?.success || !!tableLoader}
                onClick={() => onhandleAddAllocation()}
              >
               {isEdit ? "Update" :  "Allocate"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <ToastContainer
        position="bottom-center"
        autoClose={false}
        closeOnClick={false}
      />
    </>
  );
}

const CategoryComponent = ({ value, index, innerIndex = 0, handleSelectCategory, handleRemoveEntityMasterId, handleSelectEntityMasterId }) => {
  //  console.log("values",value);
  return (
    <Grid
      container
      xs={12}
      sx={{
        display: "flex",
        gap: "1rem",
        flexDirection: "row",
      }}
    >
      <Grid item xs={3}>
        <Typography
          sx={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "14px",
            fontWeight: 800,
            alignSelf: "center",
          }}
        >
          <span>Category : </span>
        </Typography>
      </Grid>

      <Grid container xs>
        <Grid item xs={12}>
          <RadioGroup
            row
            data-testid="areaTypeRadioButtons"
            aria-labelledby="demo-radio-buttons-group-label"
            name="areaTypeRadioButtons"
            // defaultValue={"itemMaster"}
            value={value.category}
            onChange={(e) => {
              handleSelectCategory(index, innerIndex, e.target.value)

            }}
          >
            <FormControlLabel
              sx={{
                color: "rgb(128, 128, 128)",
                "& .MuiFormControlLabel-label":
                {
                  fontSize: "13px",
                },
              }}
              value="itemMaster"
              label="Item Master"
              control={
                <Radio
                  size="small"
                  data-testid="item-master-radio-button"
                  sx={{
                    "&, &.Mui-checked": {
                      color: "#FF7200",
                    },
                  }}

                />
              }
            />

            <FormControlLabel
              sx={{
                color: "rgb(128, 128, 128)",
                "& .MuiFormControlLabel-label":
                {
                  fontSize: "13px",
                },
              }}
              value="other"
              label="Other"
              control={
                <Radio
                  size="small"
                  sx={{
                    "&, &.Mui-checked": {
                      color: "#FF7200",
                    },
                  }}
                />
              }
            />
          </RadioGroup>
        </Grid>
        {["itemMaster"].includes(value.category) && <Grid item xs={12}>
          <TypeAndSearch
            component={deepCopy_Object(component)}
            value={""}
            setEmptyOnChange={true}
            handleChange={(option) => {
              const isAlreadyExist = value?.entityMasterId?.find((id) => id === option?.target.value)
              if (option.target.value && !isAlreadyExist) {
                handleSelectEntityMasterId(index, innerIndex, option?.target.value)
              }
            }}
          />
        </Grid>}
        {
          value?.entityMasterId?.length ?
            <Grid container xs={12} sx={{ mt: 1 }} spacing={1}>
              <CustomChip
                data={value?.entityMasterId || []}
                makeDisabled={false}
                handleDelete={(eleIndex, elem) => handleRemoveEntityMasterId(index, innerIndex, elem)}
                readLabelKey={"$self"}
              />
            </Grid>
            : ""
        }
      </Grid>
    </Grid>
  )
}
export default AllocateModel;
