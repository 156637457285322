import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { Breadcrumbs, Tooltip, FormControl, Button, Divider, Grid, OutlinedInput, Autocomplete, Paper, IconButton, Box, Checkbox, TextField, Typography } from '@mui/material'
import theme, { textClasses, cardClasses, inputClasses, buttonClasses } from "../../../static/styles/theme";
import { Cancel } from "@mui/icons-material";
import { useMediaQuery } from '@mui/material';
import { AutoSizer } from "react-virtualized";
import { FixedSizeGrid } from "react-window";


const GenericAutoSizerComponent = ({ mappedData = [], containerHeight, onDelete, customStyle=null, xsCol = 2, smCol = 3 , mdCol = 5 , lgCol = 6, xlCol=9}) => {
    // Use media queries to determine the number of items per row
    const isXs = useMediaQuery((theme) => theme.breakpoints.down('xs')); // Extra small screens
    const isSm = useMediaQuery((theme) => theme.breakpoints.between('sm', 'md')); // Small screens
    const isMd = useMediaQuery((theme) => theme.breakpoints.between('md', 'lg')); // Medium screens
    const isLg = useMediaQuery((theme) => theme.breakpoints.between('lg', 'xl')); // Large screens

    // Dynamically set the column count
    const columnCount = isXs ? xsCol : isSm ? smCol : isMd ? mdCol : isLg ? lgCol : xlCol; // 8 if greater than 1320px, else 6

    const numberOfRows = Math.ceil(
        (mappedData?.length || 0) / 4
    );
    const cellRenderer = ({ columnIndex, rowIndex, style }) => {
        const index = rowIndex * columnCount + columnIndex; // Assuming 3 columns
        const itemdata = mappedData
        const item = itemdata[index];
        return (
            <div style={{ ...style, padding: '1px', }}>
                {/* <CustomChip
          data={item || []}
          makeDisabled={false}
          handleDelete={""}
          readLabelKey={"$self"}
        /> */}
                {item && (
                    <div style={{ margin: '6px' }}>
                        <Grid
                            container
                            direction={"row"}
                            justifyContent="center"
                            alignItems={"center"}
                            sx={{
                                backgroundColor: theme.themeLightOrange,
                                border: `1px solid ${theme.themeOrange}`,
                                borderRadius: "12px",
                                pl: 1,
                                pr: 1,
                                minHeight: "32px",
                                ml: 2,
                                mr: 2
                            }}
                        >
                            <Grid item sm={10} xs={8}>
                                {item && item.length > 12 ? (
                                    <Tooltip arrow placement="top" title={item}>
                                        <Typography
                                            sx={{
                                                ...textClasses.normalText,
                                                color: theme.themeOrange,
                                                textAlign: "start",
                                            }}
                                        >
                                            {item && item.length > 20
                                                ? item.substring(0, 20) + "..."
                                                : item}
                                        </Typography>
                                    </Tooltip>
                                ) : (
                                    <Typography
                                        sx={{
                                            ...textClasses.normalText,
                                            color: theme.themeOrange,
                                            textAlign: "start",
                                        }}
                                    >
                                        {item}
                                    </Typography>
                                )}
                            </Grid>
                            {onDelete &&
                                <Grid item xs={2} sx={{ textAlign: "end" }}>
                                    <IconButton
                                        sx={{ alignContent: "right", padding: '1px' }}
                                        onClick={() => onDelete(index)}
                                    >
                                        <Cancel sx={{ color: theme.themeOrange }} />
                                    </IconButton>
                                </Grid>
                            }
                        </Grid>
                    </div>
                )}
            </div>
        );
    };
    return (
        <Box
            sx={{
                border: "2px dotted #FF7200",
                borderRadius: "8px",
                padding: "0px 20px 10px 0",
                marginBottom: "20px",
                width: '100%',
                height: containerHeight || "220px",

            }}
        >
            <AutoSizer>
                {({ width }) => {
                    return (
                        <FixedSizeGrid
                            columnCount={columnCount} // Number of columns
                            columnWidth={190} // Width of each column
                            height={numberOfRows < 3 ? numberOfRows * 50 : 200} // Total height of the grid
                            rowCount={numberOfRows}
                            rowHeight={50} // Height of each row
                            width={width} // Total width of the grid
                            style={customStyle || {
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                // width: 'px',
                                position: 'absolute',
                                left: '18px'

                            }}
                        >
                            {cellRenderer}
                        </FixedSizeGrid>
                    );
                }}
            </AutoSizer>
        </Box>
        // }
    )
}

export default GenericAutoSizerComponent;