// 429def97-2d3c-40db-9dd6-10d902c69be7
import React, { useState, useRef, useEffect } from 'react'
import { Card, CardHeader, CardContent, Button, Typography, Grid, Select, MenuItem, Divider, Checkbox  } from '@mui/material'
import { Add, Delete } from '@mui/icons-material'
import { useDrop } from 'react-dnd';
import GenericInputField from './GenericInputField'
import theme, { inputClasses, textClasses } from '../../../static/styles/theme'
import { useDispatch, useSelector } from 'react-redux';
import { addField, addSection, getEntityMasterTemplates, removeField, removeSection, selectMasterTemplate, selectSubTemplate, setAddressBookField, setEditField } from '../../../redux/actions';
import { getTemplateTypeName } from '../../../services/constants';
import { customAlphabet } from 'nanoid';
import { showToast } from '../../../services/functions';
import { component } from '../../Modules/TMS/Addressbook/utils';

const alphabet =
"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"; // Excluding hyphen (-)
const nanoid = customAlphabet(alphabet, 21);

export default function GenericInputSection({components, sectionName, helperText, sectionId, removable, sx}) {
    const ref = useRef(null);
    const dispatch = useDispatch();
    const { userPermissions } = useSelector((state) => state.userPermission);
    const preDefineElement = useSelector(state=> state.templates.predefinedElements)?.elements || []
    const selectedSubTemplate = useSelector(state => state.canvas.selectedSubTemplate);
    const itemMasterTemplates = useSelector(state => state.canvas.itemMasterTemplates);
    const selectedItemMaster = useSelector(state => state.canvas.selectedItemMaster);
    const addressBookFields = component({type:"customer"})
    // //("changed sm ", selectedSupplierMaster);
    // //("changed cm ", selectedCustomerMaster);
    // //("changed im ", selectedItemMaster);

    
    const handleItemDrop = (item) => {
        item = JSON.parse(JSON.stringify(item))
        // //("Dragged Item", item);
        if(item.templateType && item.templateType == "freeTemplate") {
            // In case of templateType component drop
            let mergedArr = (components[sectionId]?.mandatory||[]).concat(components[sectionId]?.other ||[])
            for(let ele of mergedArr){
                let isElementExist = item?.attributes?.find((dl)=>dl?._id==ele?._id)
                if(isElementExist){
                    showToast(`Field ${isElementExist.label} already exists`, true)
                    return;
                }
            }
            dispatch(addField(sectionId, item?.attributes || []))
            const currIndex = components[sectionId].other.length;
            dispatch(setEditField(sectionId,"other",currIndex-item?.attributes?.length))

        }
        else{
            // //("Dropping ", item);
            // //("Predefined elements ", preDefineElement.map(pd => pd._id));
            // In case of normal component drop

            if(item.componentType && item.componentType == "previous"){
                // //("DROPPED PREVIOUS");
                // Previous used components
                let mandatoryFields = components[sectionId].mandatory.filter(f => {
                    if(!f.templateType){
                        return true;
                    }
                    if(f.templateType && f.templateType == selectedSubTemplate){
                        return true;
                    }
                    return false;
                });
                // //("Mandatory Fields", mandatoryFields);
                if(components[sectionId].other.filter(f => f._id == item.elementId).length > 0 || mandatoryFields.filter(f => f._id == item.elementId).length > 0){
                    showToast(`Field ${item.label} already exists`, true)     
                }
                else{
                    // Add field to canvas
                    item._id = item.elementId;
                    dispatch(addField(sectionId, [item]))
                    const currIndex = components[sectionId].other.length;
                    dispatch(setEditField(sectionId,"other",currIndex-1))
                }
            }
            else{
                // //("DROPPED STANDARD");
                // Standard form components

                if(item._id && preDefineElement.filter(pd => pd._id == item._id).length > 0){
                    // Its a predefined component
                    // Check if its already present in canvas section
                    let mandatoryFields = components[sectionId].mandatory.filter(f => {
                        if(!f.templateType){
                            return true;
                        }
                        if(f.templateType && f.templateType == selectedSubTemplate){
                            return true;
                        }
                        return false;
                    });
                    // //("Mandatory Fields", mandatoryFields);
                    if(components[sectionId].other.filter(f => f._id == item._id).length > 0 || mandatoryFields.filter(f => f._id == item._id).length > 0){
                        showToast(`Field ${item.label} already exists`, true)
                    }
                    else{
                        // Add field to canvas
                        dispatch(addField(sectionId, [item]))
                        const currIndex = components[sectionId].other.length;
                        dispatch(setEditField(sectionId,"other",currIndex-1))

                    }
                }
                else{

                    const uuid = nanoid()
                    item._id = uuid;
                    dispatch(addField(sectionId, [item]))
                    const currIndex = components[sectionId].other.length;
                    dispatch(setEditField(sectionId,"other",currIndex-1))

                }
            }   
        }
    }

    const handleRemoveField = (index, type) => {
  
        dispatch(removeField(sectionId, index,type))
    }

    const handleDeleteSection = () => {
        dispatch(removeSection(sectionId))
    }

    const handleAddSection = () => {
        dispatch(addSection(sectionId))
    }

    const [{isOver}, drop] = useDrop({
        accept: "component",
        drop: handleItemDrop,
        collect: (monitor) => ({
            isOver: monitor.isOver()
        }),
        hover: (item, monitor) => {
            
        }
    })
    
    drop(ref);



    useEffect(() => {
        if(["ASNTemplate","salesOrderTemplate"].includes(selectedSubTemplate)){
            dispatch(
                getEntityMasterTemplates({
                    type: "wms", 
                    templateType: "itemMaster", 
                    status: "published",
                    "dataEntryType": ["item","package"],
                })
            )    
        }

        
    }, [selectedSubTemplate])

    return (
        <Card sx={{width: "100%", borderRadius: "10px", ...sx}}>
            <CardHeader
                sx={
                    {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
                        backgroundColor: theme.themeGray,
                        p: 1,
                    }
                }
                title={
                    <Typography sx={{...textClasses.boldText}}>
                        {
                            ["inwardDataEntry", "ASNTemplate", "kittingTemplate", "salesOrderTemplate"].indexOf(selectedSubTemplate) < 0  ? getTemplateTypeName(selectedSubTemplate) : sectionName
                        }
                        <i style={{marginLeft: "15px", fontSize: "11px", fontWeight: "400", textDecoration: "italic"}}>
                            {helperText}
                        </i>
                    </Typography>
                }
                action={
                    selectedSubTemplate == "inwardDataEntry" &&
                    <>
                        {
                            components[sectionId].status 
                            ?
                                <Button disabled={!removable} onClick={()=> handleDeleteSection()} variant='outlined' color='inherit' size='small' startIcon={<Delete/>} sx={{fontSize: "14px", p: 0, pl: 1, pr: 1, mr: 2, mt: "2px", backgroundColor: "white", textTransform:"none"}}>
                                    Remove
                                </Button>
                            :
                                <Button disabled={!removable} onClick={()=> handleAddSection()} variant='outlined' color='inherit' size='small'startIcon={<Add/>} sx={{fontSize: "14px", p: 0, pl: 1, pr: 1, mr: 2, mt: "2px", backgroundColor: "white", textTransform:"none" }}>
                                    Include
                                </Button>
                        }
                    </>
                    
                    
                }
            />
            {
                components[sectionId].status &&
            
                <CardContent>
                    {
                        sectionId == "attributes"
                        ?
                            components[sectionId].mandatory.filter(f => f.templateType == selectedSubTemplate).map((component, i) => {
                                return (
                                    <GenericInputField
                                        component={component}
                                        key={i}
                                        index={i}
                                        editable
                                        sectionId={sectionId}
                                        type="mandatory"
                                    />
                                )
                            })
                        :
                            components[sectionId].mandatory.filter(f => {
                                if(f.templateType && f.templateType == selectedSubTemplate){
                                    return true;
                                }
                                else if(!f.templateType){
                                    return true;
                                }
                                return false;
                            }).map((component, i) => {
                                return (
                                    <GenericInputField
                                        component={component}
                                        key={i}
                                        index={i}
                                        editable
                                        sectionId={sectionId}
                                        type="mandatory"
                                    />
                                )
                            })   
                    }
              {
                (["ASNTemplate", "salesOrderTemplate"].includes(selectedSubTemplate)) &&
                <>
                  <Grid container justifyContent={"left"} alignItems="center" sx={{ mt: 2 }}>
                    {
                      sectionId == "attributes"
                        ?
                        <>
                          {userPermissions && userPermissions?.permissions?.some(dl => (["get_business_partners", "get_registered_address", "address_book"]).includes(dl.permissionId)) &&
                            <>
                              <Checkbox
                                checked={components[sectionId]?.addressBook?.length > 0}
                                onChange={(e) => { dispatch(setAddressBookField("attributes",e.target.checked ?[addressBookFields] :[])) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                              />
                              <Typography sx={{ ...textClasses.boldText }}>Map with Address Book (Optional)</Typography>
                              {
                                 components[sectionId]?.addressBook?.map((component, i) => {
                                  return (
                                      <GenericInputField
                                      component={component}
                                      key={i}
                                      index={i}
                                      removable={component.removable != null ? component.removable : true }
                                      editable
                                      movable
                                      handleRemove={handleRemoveField}
                                      sectionId={sectionId}
                                      type="addressBook"
                                      />
                                  )
                              })}
                              
                            </>
                          }
                        </>
                        :
                        <>
                          {userPermissions && userPermissions?.permissions && userPermissions?.permissions?.some(dl => dl.permissionId == 'viewItemMaster') &&

                            <Grid container>
                              <Grid container item sm={5} xs={12} justifyContent="center" alignItems={"center"}>
                                <Select
                                  fullWidth
                                  sx={{
                                    backgroundColor: "white",
                                    color: theme.themeOrange,
                                    minWidth: "200px",
                                  }}
                                  size='small'
                                  value={selectedItemMaster}
                                  onChange={(e) => dispatch(selectMasterTemplate("itemMaster", e.target.value))}
                                >
                                  <MenuItem value={"-"}>Select Item Master Template{selectedSubTemplate == "salesOrderTemplate" ? '*' : ''}</MenuItem>
                                  {
                                    itemMasterTemplates.map((s, i) => {
                                      return (<MenuItem value={s._id}>{s.name}</MenuItem>)
                                    })
                                  }
                                </Select>
                              </Grid>
                            </Grid>
                          }
                        </>
                    }
                  </Grid>
                </>
              }
                    
                    {
                        components[sectionId].other.map((component, i) => {
                            return (
                                <GenericInputField
                                    component={component}
                                    key={i}
                                    index={i}
                                    removable={component.removable != null ? component.removable : true }
                                    editable
                                    movable
                                    handleRemove={handleRemoveField}
                                    sectionId={sectionId}
                                    type="other"
                                />
                            )
                        })
                    }
                    <Grid ref={ref} container sx={{border: isOver ? "1px dashed red" : "1px dashed gray", minHeight: "40px", mt: 1, borderRadius: "5px"}} justifyContent="center" alignItems={"center"}>
                        <Typography variant='string' color={"gray"}>Drop Here</Typography>
                    </Grid>
                </CardContent>
}           
        </Card>
    )
}
